import { EGridColType, FormItem, GridCol, MBSInput, MBSSelect } from 'mbs-common';
import { UserAddress, userAddressSchema } from '../../../../user';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';
import GenericAddress from '../../../AddressInput/GenericAddress';
import { CountryOptionType, IsoCountryCodes, getCanariasValidator } from '../../../../helper/addressSupport';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setShippingAddress } from '../../../checkoutSlice';
import sortByKey from '../../../../helper/sortByKey';
import NLAddress from '../../../AddressInput/NLAddress';

export default function ShippingAddressInput({ onComplete }: { onComplete?: () => void }) {
  const intl = useIntl();
  const reduxI18n = useAppSelector((state) => state.i18n);
  const dispatch = useAppDispatch();
  const methods = useForm<UserAddress>({
    resolver: zodResolver(userAddressSchema),
    shouldFocusError: true,
    mode: 'onBlur'
    // reValidateMode: 'onSubmit'
  });
  const {
    register,
    getValues,
    resetField,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue
  } = methods;

  useEffect(() => {
    if (reduxI18n.country) {
      const country = reduxI18n.country as string;
      setValue('l', country);
    }
  }, [reduxI18n.country, setValue]);

  const onSubmit: SubmitHandler<UserAddress> = (data, event) => {
    event?.preventDefault();

    data.n = `${data.fn} ${data.ln}`;

    const { nra, ...shippingAddress } = data;
    dispatch(setShippingAddress(shippingAddress));
    // call oncomplete callback
    if (onComplete) {
      onComplete();
    }
  };

  const handleChangeCountry = () => {
    resetField('p');
    resetField('nr');
    resetField('nra');
    resetField('s');
    resetField('s2');
    resetField('c');
    resetField('r');
  };

  const selectedCountry = watch('l', '');
  const currentPostalcode = watch('p');
  const showVatField = selectedCountry === 'ES' && getCanariasValidator().test(currentPostalcode);

  const countryOptions: CountryOptionType[] = sortByKey(
    IsoCountryCodes.map((val) => ({
      value: val,
      label: intl.formatMessage({ id: `Country-${val}`, defaultMessage: val })
    })),
    'label'
  );

  return (
    <form className="grid2 formgrid" onSubmit={handleSubmit(onSubmit)}>
      <GridCol type={[EGridColType.Default6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-Fn',
            defaultMessage: 'First name'
          })}
        >
          <MBSInput
            {...methods.register('fn')}
            error={errors.fn?.message && intl.formatMessage({ id: errors.fn?.message })}
            autoComplete="given-name"
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-Ln',
            defaultMessage: 'Last name'
          })}
        >
          <MBSInput
            {...methods.register('ln')}
            error={errors.ln?.message && intl.formatMessage({ id: errors.ln?.message })}
            autoComplete="family-name"
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default12]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-Input-L',
            defaultMessage: 'Country'
          })}
        >
          <Controller
            control={methods.control}
            name="l"
            render={({ field: { onChange, value, name, ref } }) => (
              <MBSSelect
                options={countryOptions}
                isSearchable={true}
                menuPortalTarget={document.body}
                value={countryOptions.find((c) => c.value === value)}
                onChange={(val) => {
                  if (val) {
                    onChange(val.value);
                    handleChangeCountry();
                  }
                }}
              />
            )}
          />
        </FormItem>
      </GridCol>
      {selectedCountry && (
        <>
          {selectedCountry === 'NL' ? (
            <NLAddress methods={methods} />
          ) : (
            <GenericAddress methods={methods} selectedCountry={selectedCountry} />
          )}
          <GridCol type={[EGridColType.Default6, EGridColType.DefaultRowEnd]}>
            <FormItem
              label={intl.formatMessage({
                id: 'Checkout-Address-Input-T',
                defaultMessage: 'Phone number'
              })}
            >
              <MBSInput
                type="tel"
                maxLength={20}
                {...register('t')}
                error={errors.t?.message && intl.formatMessage({ id: errors.t?.message })}
                autoComplete="tel"
              />
            </FormItem>
          </GridCol>
          <GridCol type={[EGridColType.Default12]}>
            <FormItem
              label={intl.formatMessage({
                id: 'Checkout-Address-Input-Shipping-Cp',
                defaultMessage: 'Company name (on shipping label)'
              })}
            >
              <MBSInput
                {...register('cp')}
                error={errors.cp?.message && intl.formatMessage({ id: errors.cp?.message })}
              />
            </FormItem>
          </GridCol>
          {showVatField && (
            <GridCol type={[EGridColType.Default6, EGridColType.DefaultRowEnd]}>
              <FormItem
                label={intl.formatMessage({
                  id: 'Checkout-Address-Input-Shipping-V',
                  defaultMessage: 'VAT number for shipping purposes'
                })}
              >
                <MBSInput
                  maxLength={20}
                  {...register('v')}
                  error={errors.v?.message && intl.formatMessage({ id: errors.v?.message })}
                />
              </FormItem>
            </GridCol>
          )}
          <GridCol type={[EGridColType.Default12]}>
            <button type="submit" className="app-button-cta">
              <FormattedMessage id={'Checkout-Address-Button-Save'} defaultMessage="Save" />
            </button>
          </GridCol>
        </>
      )}
    </form>
  );
}
