const messages = {
  'Promotion': 'Vermarktung',
  'binding-1': 'Hardcover',
  'binding-3': 'Softcover',
  'binding-6': 'eBook',
  'binding-7': 'PDF',
  'interiorcolor-bw': 'Schwarz/weiß',
  'interiorcolor-fc': 'Farbe',
  'interiorcolor-short-bw': 'S/W',
  'interiorcolor-short-fc': 'Farbe',
  'Interior color': 'Farbe Innenseite',
  'Papertype-short': 'Papier',
  'Papertype-short-1': 'Cremeweiß',
  'Papertype-short-2': 'Cremeweiß',
  'Papertype-short-3': 'Weiß',
  'Papertype-short-4': 'Weiß',
  'Papertype-short-5': 'Fotomatt',
  'Papertype-short-6': 'Fotomatt',
  'Papertype-short-7': 'Weiß',
  'Papertype-short-8': 'Weiß glossy',
  'Papertype-short-9': 'Tinted',
  'Papertype-short-10': '150 grams',
  'Papertype-short-12': 'Weiß',
  'Amountpages-short': 'Seiten',
  'Amountcolorpages-short': 'Farbeseiten',
  'Help-howdoesitwork-question': 'Wie funktioniert’s?',
  'ISBN': 'ISBN',
  'Author website': 'Autoren-website',
  'Author webshop': 'Autoren-webshop',
  'authorwebsite-notEnabled': 'Nicht aktiviert - {link}',
  'authorwebsite-notEnabled-enablenow': 'Jetzt aktivieren',
  'Link to book page': 'Link zur Buchseite',
  'button-downloadcover': 'Cover herunterladen',
  'button-downloadexcerpt': 'Auszug herunterladen',
  'button-copybooklink': 'Buchfink kopieren',
  'button-copyannouncementtext': 'Ankündigung kopieren',
  'button-openinstagram': 'Instagram App öffnen',
  'button-addtoshoppingcart': 'Zum Warenkorb hinzufügen',
  'button-orderbooks': 'Bücher bestellen',
  'button-registerbook': 'Buch registrieren',
  'Channels-availability': 'Verfügbarkeit',
  'Channelname': 'Shop',
  'Price': 'Buchpreis',
  'Your margin': 'Ihre marge',
  'Bookspec-ISBN-isbn': 'ISBN: {isbn}',
  'Bookspec-LastEdited-date': 'Letzte Version: {date}',

  'What do you have to do?': 'Was haben Sie zu tun?',

  // form labels
  'label-announcementtext': 'Ankündigung',
  'label-instagram-addbooklinktobio': 'Fügen Sie den Link zu Ihrem Buch in Ihre Instagram-Bio ein',
  'label-instagram-downloadcover': 'Cover herunterladen für Post',
  'label-instagram-openinstagram': 'Öffne Instagram',
  'label-instagram-chooseanimage': 'Wählen Sie ein Bild',
  'label-attachbooklinktomessage': 'Automatisches Hinzufügen des Links zu Ihrem Buch in die Nachricht',

  // zod validation errors
  'ZodValidationError-EmailRequired': 'E-Mail ist erforderlich',
  'ZodValidationError-EmailNotValidEmail': 'Geben Sie eine gültige E-Mail ein',
  'ZodValidationError-AddressFnRequired': 'Vorname ist erforderlich',
  'ZodValidationError-AddressFnLongerThan30': 'Vorname darf nicht länger als 30 Zeichen sein',
  'ZodValidationError-AddressLnRequired': 'Nachname ist erforderlich',
  'ZodValidationError-AddressLnLongerThan30': 'Nachname darf nicht länger als 30 Zeichen sein',
  'ZodValidationError-AddressSRequired': 'Straße und Hausnummer sind erforderlich',
  'ZodValidationError-AddressSLongerThan50': 'Straße und Hausnummer dürfen nicht länger als 50 Zeichen sein',
  'ZodValidationError-AddressS2LongerThan50': 'Zweite Adresszeile darf nicht länger als 50 Zeichen sein',
  'ZodValidationError-AddressNrMustBeNumber': 'Hausnummer muss eine Nummer sein',
  'ZodValidationError-AddressPRequired': 'PLZ ist erforderlich',
  'ZodValidationError-AddressPLongerThan20': 'PLZ darf nicht länger als 20 Zeichen sein',
  'ZodValidationError-AddressCRequired': 'Stadt ist erforderlich',
  'ZodValidationError-AddressCLongerThan35': 'Stadt darf nicht länger als 35 Zeichen sein',
  'ZodValidationError-AddressRLongerThan35': 'Bundesland darf nicht länger als 35 Zeichen sein',
  'ZodValidationError-AddressLRequired': 'Land is verplicht',
  'ZodValidationError-AddressTRequired': 'Telefon ist erforderlich',
  'ZodValidationError-AddressCountryNotTwoLetterCode': 'Das Land muss ein ISO-2-Buchstabencode sein',
  'ZodValidationError-AddressCpLongerThan35': 'Firma darf nicht länger als 35 Zeichen sein',
  'ZodValidationError-AddressTLongerThan20': 'Telefon darf nicht länger als 20 Zeichen sein',
  'ZodValidationError-AddressFullNameLongerThan40': 'Vollständiger Name darf nicht länger als 40 Zeichen sein',
  'ZodValidationError-AddressPostalCodeIncorrect': 'Falsche PLZ für das ausgewählte Land',
  'ZodValidationError-AddressS2SameAsS': 'Die zweite Adresszeile entspricht der ersten Adresszeile',
  'ZodValidationError-AddressVRequiredForCanarias': 'Steuernummer ist erforderlich für die Kanarischen Inseln',
  
  // guide popup
  'wikiguidepopup-title-selectaguide': 'Wählen Sie eine Anleitung',

  // errors
  'error-booknotpublished': 'Sie können dieses Buch nicht promoten, da es nicht veröffentlicht ist. Gehen Sie zu ‘Meine Bücher’, um dieses Buch zu bearbeiten.',
  'error-booknotavailable': 'Das Buch, das Sie zu öffnen versuchen, existiert nicht. Gehen Sie zu ‘Meine Bücher’ und wählen Sie ein Buch aus.',
  'error-booknotauthorized': 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen. Wenn diese Meldung falsch ist, wenden Sie sich bitte an den Support.',
  'error-bookunknownerror': 'Beim Laden dieser Seite ist ein unerwartetes Problem aufgetreten. Bitte aktualisieren Sie die Seite, um es erneut zu versuchen. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Support und erwähnen Sie buch id: {bookid}',

  // promo popup
  'promoapp-publishedpopup-title': 'Herzlichen Glückwunsch, Sie sind ein veröffentlichter Autor!',
  'promoapp-publishedpopup-paragraph1': 'Sie haben es geschafft! Ihr Buch ist jetzt veröffentlicht, also nehmen Sie sich einen Moment oder vielleicht zehn, um zu feiern! Erzählen Sie Ihren Freunden davon, gönnen Sie sich etwas und genießen Sie Ihre Leistung für eine Weile.',
  'promoapp-publishedpopup-paragraph2': 'Nun, da Ihr Buch zum Verkauf steht, ist es Zeit für den nächsten Schritt auf Ihrer Autorenreise: die Promotion Ihres Buches. Natürlich sind wir hier, um Ihnen dabei zu helfen. Unsere Promotionsschritte führen Sie durch einige der effektivsten Möglichkeiten, Ihr Buch bekannt zu machen.',
  'promoapp-publishedpopup-startbutton': 'Beginnen mit der Werbung',

  // promo categories
  'promoapp-havetodo-title': 'Maßnahmen, die Sie ergreifen müssen',
  'promoapp-shoulddo-title': 'Maßnahmen, die wir Ihnen empfehlen, zu ergreifen',
  'promoapp-coulddo-title': 'Aktionen, die Ihnen einen hilfreichen Schubs geben werden',
  'blocktitle-thingsyouneedtopromote': 'Dinge, die Sie benötigen, um Ihr Buch zu promoten',

  // promo overview
  'promoapp-overview-intro-p1': 'Ein Buch zu schreiben ist an sich schon eine große Leistung, aber wahrscheinlich wollen Sie es auch verkaufen. Um das zu tun, müssen Sie es vermarkten. Für viele Autoren beginnt hier die wahre Herausforderung. Es erfordert einiges an Arbeit und Hingabe, aber Sie werden die Ergebnisse schneller sehen, als Sie vielleicht denken. Um Ihnen den Einstieg zu erleichtern, haben wir eine Auswahl an Tipps, Anleitungen und Tools zusammengestellt, die die Promotion Ihres Buches einfacher und effektiver machen.',

  // list of all promotool descriptions
  'promotool-authorwebsite': 'Autoren-webshop',
  'promotool-description-authorwebsite': 'Der profitabelste Weg, Ihre Bücher zu verkaufen',
  'promotool-whatsapp': 'WhatsApp',
  'promotool-description-whatsapp': 'Verkaufen Sie Ihre Bücher direkt an Ihre Freund',
  'promotool-socialmedia': 'Social media',
  'promotool-description-socialmedia': 'Richten Sie Ihre sozialen Medien Seiten ein um Verkäufe zu steigern',
  'promotool-instagram': 'Instagram',
  'promotool-description-instagram': 'Vermarkten Sie Ihr Buch mit einzigartigen Bildern',
  'promotool-facebook': 'Facebook',
  'promotool-description-facebook': 'Verbinden Sie sich mit Lesern und steigern Sie Verkäufe',
  'promotool-twitter': 'Twitter',
  'promotool-description-twitter': 'Nutzen Sie Ihr Talent mit Worten um Ihr Buch zu vermarkten und zu verkaufen',
  'promotool-linkedin': 'LinkedIn',
  'promotool-description-linkedin': 'Dokumentieren Sie Ihre unternehmerische Autorenreise',
  'promotool-libraries': 'Bibliotheken',
  'promotool-description-libraries': 'Erreichen Sie eine größere Leserschaft, indem Sie Ihr Buch in Bibliotheken platzieren',
  'promotool-nbdbiblion': 'NBD Biblion',
  'promotool-description-nbdbiblion': 'Get NBD to review your book and get it in the libraries',
  'promotool-nationallibrary': 'National Bibliothek',
  'promotool-description-nationallibrary': 'Verewigen Sie Ihr Werk, indem Sie Ihr Buch in der Nationalbibliothek hinterlegen',
  'promotool-launchparty': 'Buch Veröffentlichungsparty',
  'promotool-description-launchparty': 'Keine Veröffentlichung ist vollständig ohne eine Buchvorstellungsparty!',
  'promotool-bookstores': 'Buchhandlungen',
  'promotool-description-bookstores': 'Lassen Sie Ihr Buch in lokalen Geschäften auslegen, um den Verkauf anzukurbeln',
  'promotool-pressrelease': 'Pressemitteilung',
  'promotool-description-pressrelease': 'Eine Berichterstattung in der Presse wirkt Wunder für Ihre Verkäufe',
  'promotool-businesscards': 'Visitekarten',
  'promotool-description-businesscards': 'Machen Sie Eindruck mit Ihren persönlich gestalteten Visitenkarten ',

  // launch party
  'launchparty-intro-p1': 'Eine Buchvorstellung ist ein wesentlicher Schritt im Prozess der Promotion Ihres Buches. Es ist nicht nur eine großartige Möglichkeit, die Aufmerksamkeit auf die Tatsache zu lenken, dass Ihr Buch veröffentlicht wurde, sondern auch eine wirklich schöne Art, den Anlass wahrzunehmen und eine großartige Leistung mit Freunden und Familie zu feiern. Sie können Ihr Buchveröffentlichungs-Event so groß oder klein planen, wie Sie möchten; was auch immer mit den Zielen übereinstimmt, die Sie sich selbst gesetzt haben. Wir haben einen Leitfaden sowohl für eine kleine, informelle Buchvorstellung als auch für eine größere, formellere Buchvorstellung erstellt, den Sie weiter unten einsehen können - schauen Sie, welcher Typ am besten zu Ihren persönlichen Ambitionen passt.',
  'launchparty-bookdrinks-title': 'Informelle Buchvorstellung',
  'launchparty-bookdrinks-intro': 'Wenn Sie die Dinge einfach halten und klein anfangen wollen, können Sie Ihre Buchvorstellung zu einer intimeren Veranstaltung machen, als es üblich ist. Es muss keine große Sache bei einem gemieteten Standort sein, bei der Gastredner und die lokale Presse anwesend sind. Vielmehr können Sie einfach Freunde, Familie und Bekannte einladen, um gemeinsam mit Ihnen den erreichten Meilenstein zu feiern. Betrachten Sie es als eine Party für Sie und Ihr Buch; es ist eine einfache und relativ kostengünstige Möglichkeit, die Aufmerksamkeit darauf zu lenken, dass Sie ein veröffentlichter Autor sind und Ihre ersten Bücher verkaufen. Hier sind ein paar Hinweise, wenn Sie eine Buchvorstellungsparty planen:',
  'launchparty-bookdrinks-whenandwho-title': 'Wen Sie einladen und wann?',
  'launchparty-bookdrinks-whenandwho-paragraph1': 'Machen Sie eine Liste mit allen Personen, die Sie einladen möchten. Ihre Freunde, Familie, Nachbarn und Leute, die in irgendeiner Weise zu Ihrem Buch beigetragen haben, sind hier immer eine gute Wahl. Legen Sie ein Datum fest, erstellen Sie eine Art Gruppenchat und verschicken Sie die Einladungen! Achten Sie darauf, dass Ihre Nachrichten fröhlich und einladend sind, und fügen Sie unbedingt ein Bild Ihres Buches bei!',
  'launchparty-bookdrinks-orderbooks-title': 'Bücher bestellen',
  'launchparty-bookdrinks-orderbooks-paragraph1': 'Wenn Sie die Einladungen im Chat verschickt haben, werden Sie wahrscheinlich schon eine ziemlich gute Vorstellung davon haben, wie viele Leute kommen werden. Gehen Sie davon aus, dass jeder Gast ein oder zwei Exemplare kaufen möchte, und bestellen Sie genügend Bücher im Voraus, damit Sie sich keine Sorgen machen müssen, dass sie rechtzeitig zur Buchvorstellung geliefert werden.',
  'launchparty-bookdrinks-agenda-title': 'Programm',
  'launchparty-bookdrinks-agenda-paragraph1': 'Obwohl die Idee ist, dass es sich um eine informelle, spontane Veranstaltung handelt, schadet es nie, sich ein wenig vorzubereiten, um die Veranstaltung zu einem unvergesslichen Erlebnis zu machen und Ihre Gäste in Stimmung zu bringen, Ihr Buch zu kaufen. Fragen Sie sich, was Sie sagen wollen und ob Sie einen Toast aussprechen wollen. Wollen Sie das erste Exemplar Ihres Buches jemandem Besonderen als Geschenk überreichen? Vielleicht möchten Sie allen von Ihren Erfahrungen mit dem Self-Publishing eines Buches erzählen? Machen Sie eine kurze Liste mit Punkten, die Sie auf der Veranstaltung behandeln möchten.',
  'launchparty-bookdrinks-foodandhouse-title': 'Snacks und Getränke',
  'launchparty-bookdrinks-foodandhouse-paragraph1': 'Sorgen Sie für ausreichend Snacks und Getränke für Ihre Gäste; es ist wichtig, dass die Leute sich wohlfühlen und sich amüsieren können. Denken Sie daran, auch alkoholfreie Getränke anzubieten und eventuelle Ernährungseinschränkungen zu berücksichtigen. Stellen Sie außerdem sicher, dass Ihr Haus makellos ist, und stellen Sie Ihre Bücher an einer gut sichtbaren Stelle auf. Es ist auch eine gute Idee, jemanden zu haben, der Fotos macht, da diese von den Gästen sehr geschätzt werden und sich nach der Veranstaltung hervorragend als Werbematerial für Ihr Buch eignen.',
  'launchparty-bookdrinks-enjoy-title': 'Genießen Sie!',
  'launchparty-bookdrinks-enjoy-paragraph1': 'Zu guter Letzt sollten Sie Ihre Buchvorstellungsparty genießen! Die Leute sind hier, um eine großartige Leistung mit Ihnen zu feiern, also genießen Sie ein wenig die Aufmerksamkeit! Überlegen Sie sich im Vorfeld, ob Sie jedem Gast Ihr Buch schenken oder diese bitten wollen, ihr Exemplar selbst zu kaufen. Letzteres mag zwar etwas seltsam klingen, ist aber eine ganz normale Bitte, und Ihre Gäste werden es verstehen. Die Anwesenden stehen Ihnen nahe und wollen Sie schließlich unterstützen!',
  'launchparty-bookpresentation-title': 'Formelle Buchvorstellung',
  'launchparty-bookpresentation-intro': 'Eine formelle Buchvorstellung ist einfach eine ambitioniertere Art, eine Buchvorstellungsparty zu veranstalten. Sie können sie zwar immer noch zu Hause veranstalten, aber vielleicht sollten Sie stattdessen ein Lokal mieten; etwas wie ein Café oder eine Buchhandlung ist immer eine gute Wahl. Außerdem sollten Sie die lokale Presse und vielleicht sogar Social-Media-Influencer einladen, die in Ihrer Gegend aktiv sind. Das erhöht den Einsatz ein wenig, also sollten Sie vorausplanen und ein solides Programm aufstellen. Hier sind einige Dinge, an die Sie bei der Planung Ihrer Buchvorstellung denken sollten:',
  'launchparty-guestlist-title': 'Legen Sie fest, wen Sie einladen',
  'launchparty-guestlist-paragraph1': 'Ein guter Anfang ist es, eine Gästeliste zu erstellen. Eine offensichtliche Wahl sind Freunde, Familie, jeder, der in irgendeiner Weise zu Ihrem Buch beigetragen hat, und vielleicht Kollegen oder andere Personen aus Ihrem beruflichen Netzwerk. Sie können sich normalerweise darauf verlassen, dass die Leute begeistert sind, diesen Meilenstein mit Ihnen zu feiern, also scheuen Sie sich nicht, Einladungen zu verschicken! Der Grund, warum es eine gute Idee ist, mit einer Gästeliste anzufangen, ist, dass es so viel einfacher ist, einen geeigneten Ort für die Veranstaltung zu finden.',
  'launchparty-findalocation-title': 'Finden Sie einen Ort und legen Sie ein Datum fest',
  'launchparty-findalocation-paragraph1': 'Es gibt viele Orte, die sich gut für eine Buchvorstellung eignen. Sie können die Veranstaltung zu Hause, in Ihrem Lieblingscafé oder in einer örtlichen Buchhandlung abhalten. Letzteres ist unser persönlicher Favorit, da wir der Meinung sind, dass die Atmosphäre einer Buchvorstellung inmitten von Büchern besonders gut zur Geltung kommt. Versuchen Sie, sich einen günstigen (oder kostenlosen) Standort zu sichern und beziehen Sie den Inhaber in die Planung mit ein, damit er weiß, was ihn erwartet.',
  'launchparty-sendinvites-title': 'Versenden Sie die Einladungen',
  'launchparty-sendinvites-paragraph1': 'Während eine physische Einladung heutzutage nicht unbedingt die Norm ist, könnte das an sich schon ein Grund sein, sie auszuprobieren. Es kann der Veranstaltung eine gewisse Legitimität verleihen und es erlaubt Ihnen, besondere Dinge zu tun, wie z.B. Einladungen mit Ihrem Buchcover zu gestalten. Natürlich können Sie sich auch dafür entscheiden, Einladungen per E-Mail oder Textnachricht zu verschicken. Das hat den Vorteil, dass es kostenlos ist und Ihnen Zeit spart. Vergessen Sie nicht, um Zusagen zu bitten; Sie müssen wissen, wie viele Leute kommen werden.',
  'launchparty-createagenda-title': 'Programm',
  'launchparty-createagenda-paragraph1': 'Sie sollten die wichtigsten Ereignisse der Buchvorstellung im Voraus planen. Wir sind große Befürworter davon, Ihre Buchvorstellung selbst zu gestalten, aber es gibt bestimmte Grundpfeiler, die wir Ihnen empfehlen:',
  'launchparty-createagenda-list1': 'Beginnen Sie mit einem Begrüßungsgetränk und lassen Sie die Gäste sich untereinander mischen, bis alle eingetroffen sind. Etwas Musik wäre auch hier eine gute Ergänzung.',
  'launchparty-createagenda-list2': 'Wenn alle Gäste eingetroffen sind, lassen Sie einen Freund oder eine andere erkennbare Person alle willkommen heißen.',
  'launchparty-createagenda-list3': 'Eine Frage-Antwort-Runde, in der Ihre Gäste Sie über Dinge befragen können, wie z.B. warum Sie sich entschieden haben, ein Buch zu schreiben, worum es geht, woher Sie Ihre Inspiration bekommen haben und ob Sie irgendwelche lustigen Anekdoten über den Prozess haben.',
  'launchparty-createagenda-list4': 'Lesen Sie Ihren Gästen eine Passage aus Ihrem Buch laut vor',
  'launchparty-createagenda-list5': 'Signieren Sie die Bücher, die die Leute kaufen',
  'launchparty-createagenda-list6': 'Snacks und geselliges Beisammensein',
  'launchparty-contactmedia-title': 'Medien',
  'launchparty-contactmedia-paragraph1': 'Wir empfehlen dringend, dass Sie versuchen, mit lokalen Medien oder vielleicht sogar mit relevanten Social-Media-Influencern in Ihrer Gegend in Kontakt zu treten. Fragen Sie sie, ob sie daran interessiert wären, über Ihre Buchvorstellung zu berichten, und laden Sie sie zu der Veranstaltung ein. Sie werden erstaunt sein, wie oft das funktioniert, also scheuen Sie sich nicht, es zu versuchen!',
  'launchparty-refreshments-title': 'Organisieren Sie Snacks und Getränke',
  'launchparty-refreshments-paragraph1': 'Unser Ratschlag ist hier, es einfach zu halten. Setzen Sie auf Publikumslieblinge und halten Sie die Auswahl begrenzt. Holen Sie sich vorher das Einverständnis des Besitzers Ihres Lokals. Wenn Sie an einem Ort veranstalten, an dem normalerweise keine Speisen und Getränke serviert werden, könnte es sein, dass es diesbezüglich Regeln gibt, und wenn Sie an einem Ort veranstalten, an dem dies der Fall ist, werden sie ihre eigenen Speisen und Getränke servieren wollen.',
  'launchparty-orderbooks-title': 'Bücher',
  'launchparty-orderbooks-paragraph1': 'Stellen Sie sicher, dass Sie genügend Exemplare Ihres Buches vorrätig haben. Ihre Gäste können dann während der Veranstaltung ein Exemplar kaufen, und Sie können es vor Ort für sie signieren, um das Erlebnis persönlicher zu gestalten. Denken Sie daran, dass, wenn Sie Ihre Buchvorstellung in einer Buchhandlung veranstalten, der Besitzer einen Anteil an den Buchverkäufen verlangen wird. Vergewissern Sie sich, dass Sie die Einzelheiten im Voraus aushandeln.',
  'launchparty-photos-title': 'Fotos und Videos',
  'launchparty-photos-paragraph1': 'Ihre Buchvorstellung ist nicht nur eine gute Möglichkeit, den Bekanntheitsgrad Ihres Buches zu erhöhen und einige Verkäufe zu generieren, sondern sie kann auch zukünftiges Marketingmaterial für Sie zu ermöglichen. Deshalb ist es wichtig, dass Sie jemanden beauftragen, Fotos zu machen und/oder die Veranstaltung aufzuzeichnen. Dies muss kein professioneller Fotograf sein; wahrscheinlich haben Sie einen Freund, der gut mit einer Kamera umgehen kann. Sie können dann einen Teil des Materials auswählen, um es in den sozialen Medien zu veröffentlichen, um Ihr Buch zu bewerben und zu legitimieren.',
  'launchparty-followup-title': 'Nachbereitung',
  'launchparty-followup-paragraph1': 'Stellen Sie sicher, dass Sie sich bei Ihren Gästen für ihr Kommen bedanken, spätestens ein paar Tage nach der Veranstaltung. Lassen Sie sie wissen, dass Sie ihre Unterstützung zu schätzen wissen, und teilen Sie die Fotos und Videos von der Veranstaltung. Sie können diese auch mit Leuten teilen, die es nicht geschafft haben, und sogar einen Link einfügen, wo sie Ihr Buch kaufen können. Wenn Sie es geschafft haben, dass lokale Medien oder Influencer an der Veranstaltung teilgenommen oder darüber berichtet haben, stellen Sie sicher, dass Sie dies ebenfalls verbreiten und mit ihnen anschließend in den sozialen Medien interagieren.',

  // bookstores
  'bookstores-intro-p1': 'Der stationäre Buchhandel ist trotz der immer größer werdenden Dominanz des Online-Shoppings immer noch ein starker Vertriebskanal für Bücher. Da sie jedoch seit einiger Zeit rückläufig sind, neigen Buchhandlungen dazu, Bücher von unbekannten Autoren nur zögerlich ins Sortiment aufzunehmen, da dies meist als unnötiges Risiko angesehen wird. Dennoch empfehlen wir Ihnen, sich bei lokalen Buchhandlungen zu erkundigen, ob sie bereit wären, Ihr Buch zu führen. Wenn Ihr Buch in einem physischen Geschäft erhältlich ist, hat das immer noch einen großen Legitimation Schub zur Folge und stellt eine großartige Marketingmöglichkeit dar.',
  'bookstores-intro-p2': 'Wenn es Ihnen gelingt, eine Buchhandlung dazu zu bringen, Ihr Buch vorrätig zu halten, wird diese einen Anteil am Gewinn verlangen, wenn Ihr Buch verkauft wird. Das sind in der Regel etwa 20 bis 40 Prozent. Außerdem wird man sich höchstwahrscheinlich nur bereit erklären, Ihr Buch risikofrei zu führen, d.h. man behält sich das Recht vor, nicht verkaufte Exemplare an Sie zurückzugeben. Wir haben eine Checkliste erstellt, die Ihnen einen Überblick über die Dinge gibt, die Sie beim Ansprechen und Verhandeln mit Buchhandlungen beachten sollten.',
  'bookstores-checklist-title': 'Checkliste für Buchhandlungen',
  'bookstores-selectbookstores-title': 'Wählen Sie die richtigen Buchhandlungen',
  'bookstores-selectbookstores-p1': 'Der erste Schritt besteht darin, die Buchhandlungen zu bestimmen, die Sie ansprechen wollen. Wir empfehlen, zu recherchieren, was es in Ihrer Nähe gibt, eine Liste zu erstellen und eine nach der anderen anzusprechen.',
  'bookstores-contactbookstore-title': 'Kontaktieren Sie die Buchhandlungen',
  'bookstores-contactbookstore-p1': 'Sobald Sie herausgefunden haben, in welchen Buchhandlungen Sie Ihr Buch verkaufen möchten, ist es an der Zeit, diese zu kontaktieren und ein erstes Treffen zu vereinbaren.',
  'bookstores-contactbookstore-p2': 'Hier sind unsere Tipps für Ihr erstes Treffen:',
  'bookstores-contactbookstore-list1': 'Erzählen Sie ein bisschen von sich',
  'bookstores-contactbookstore-list2': 'Erzählen Sie von Ihrem Buch: worum es geht, warum Sie es geschrieben haben und was Ihre Zielgruppe ist',
  'bookstores-contactbookstore-list3': 'Bringen Sie ein Exemplar Ihres Buches mit und lassen Sie es bei ihnen. Der Inhaber wird das Buch zweifellos prüfen wollen, bevor er es ins Sortiment aufnimmt.',
  'bookstores-agreement-title': 'Treffen Sie einige Vereinbarungen mit der Buchhandlung',
  'bookstores-agreement-p1': 'Wenn die Buchhandlung bereit ist, Ihr Buch in ihr Sortiment aufzunehmen, sollten Sie einige Vereinbarungen treffen, die verschiedene Dinge betreffen:',
  'bookstores-agreement-whobuysbooks': 'Wer zahlt für die Bücher?',
  'bookstores-agreement-buy-option1': 'Option 1: Sie zahlen für die Bücher und die Buchhandlung verkauft sie in Ihrem Namen. Dies wird die attraktivste Option für die Buchhandlung sein, da sie kein finanzielles Risiko eingehen wird. Sie können das Buch über die integrierte Funktion auf der Bookmundo-Plattform kaufen und sich mit der Buchhandlung auf eine Gewinnspanne zu einigen. Vergessen Sie nicht, zu vereinbaren, wann Sie bezahlt werden und wie Sie über die Verkäufe informiert werden.',
  'bookstores-agreement-buy-option2': 'Option 2: Die Buchhandlung kauft die Bücher von Ihnen. Dies ist die einfachste Lösung; Sie können beide automatisch Ihre jeweiligen Gewinnspannen kassieren. Wenn Sie sich für diese Option entscheiden, stellen Sie sicher, dass Sie bei der Preisgestaltung auf der Plattform die Reichweite auf "National" eingestellt haben.',
  'bookstores-agreement-howlongavailable': 'Wie lange wird das Buch in der Buchhandlung zum Verkauf stehen?',
  'bookstores-agreement-positioninstore': 'Wo wird das Buch platziert/ausgestellt?',
  'bookstores-beactive-title': 'Aktiv sein',
  'bookstores-beactive-p1': 'Sobald Ihr Buch im Shop verfügbar ist, müssen Sie unbedingt die Leute darüber informieren! Hier sind ein paar Möglichkeiten, dies zu tun:',
  'bookstores-beactive-list1': 'Eine festliche Art und Weise wäre es, Ihre Bucheinführungsparty in der Buchhandlung selbst zu veranstalten. Weitere Informationen finden Sie in unserem Leitfaden für Buchvorstellunge',
  'bookstores-beactive-list2': 'Kündigen Sie die Tatsache an, dass Ihr Buch in diesem speziellen Laden erhältlich ist, in den sozialen Medien, und / oder auf Ihrer Website',
  'bookstores-beactive-list3': 'Machen Sie Auftritte in der Buchhandlung und kündigen Sie diese bei Ihren Lesern an, um Aufmerksamkeit zu erzeugen und den Verkauf anzukurbeln',

  // press release
  'pressrelease-intro-p1': 'Eine Pressemitteilung ist eine offizielle Erklärung von Ihnen als Autor gegenüber den Medienvertretern. In der Verlagswelt werden Pressemitteilungen meist verwendet, um die Veröffentlichung eines Buches anzukündigen. Als Self-Publishing-Autor werden Sie wahrscheinlich nicht die Kontakte haben, die ein großer Verlag hat. Lassen Sie sich davon aber nicht aufhalten. Lokale Zeitungen und Nachrichtenseiten sind immer auf der Suche nach Inhalten, und Sie werden überrascht sein, wie groß das Interesse an einem aufstrebenden Autor sein kann.',
  'pressrelease-intro-p2': 'Unsere Plattform generiert eine automatische Pressemitteilung für Sie, basierend auf den Informationen, die Sie in den verschiedenen Veröffentlichungsschritten ausfüllen. Relevante Informationen wie Cover, Klappentext, ISBN, Preis und Link zum Shop sind darin enthalten.',
  'pressrelease-intro-p3': 'Wenn Sie die Chance auf eine Rezension maximieren wollen, senden Sie ein Exemplar Ihres Buches zusammen mit der Pressemitteilung.',
  'pressrelease-identifytarget-title' : 'Überprüfen Sie Ihr Zielpublikum',
  'pressrelease-identifytarget-p1': 'Es ist wichtig, dass Sie Ihre Zielgruppe im Auge behalten, wenn Sie die Empfänger für Ihre Pressemitteilung auswählen. Es würde keinen Sinn machen, eine Pressemitteilung an ein Medienunternehmen zu schicken, das eine völlig andere Zielgruppe hat als Sie selbst.',
  'pressrelease-identifytarget-p2': 'Obwohl wir bis zu diesem Punkt über lokale Medien gesprochen haben, sollten Sie sich nicht unnötig einschränken. Sie könnten auch in Erwägung ziehen, nach Websites zu suchen, die die gleichen Themen behandeln wie Sie in Ihrem Buch. Je spezifischer, desto besser.',
  'pressrelease-collectcontact-title': 'Sammeln Sie Kontaktinformationen',
  'pressrelease-collectcontact-p1': 'Zeit für den langweiligsten Teil des Prozesses: das Erstellen einer strukturierten Liste aller Personen, die Sie kontaktieren müssen. Ein guter Weg, dies zu tun, ist die Erstellung einer Tabelle mit den Namen und Adressen von vielversprechenden Verkaufsstellen, zusammen mit dem Namen, der E-Mail-Adresse und der Telefonnummer eines Ansprechpartners in der besagten Verkaufsstelle. Versuchen Sie, wann immer es möglich ist, die Kontaktdaten von bestimmten Personen zu erhalten, anstatt allgemeine E-Mails und Telefonnummern von Unternehmen. Je nachdem, wie groß Ihre Liste ist, möchten Sie vielleicht auch Spalten hinzufügen, in denen Sie nachverfolgen können, wen Sie kontaktiert haben und wie die Antwort ausfiel.',
  'pressrelease-sendpressrelease-title': 'Senden Sie die Pressemitteilung',
  'pressrelease-sendpressrelease-p1': 'Wenn Sie die Pressemitteilung per E-Mail versenden, achten Sie darauf, dass der Text kurz, überzeugend und auf den Punkt gebracht ist. Verwenden Sie keinen Text aus der Pressemitteilung wieder, sondern versuchen Sie, ihn so interessant zu gestalten, dass Ihr Kontakt versucht ist, mehr zu erfahren. Das primäre Ziel ist es, den Kontakt dazu zu bringen, die Pressemitteilung zu öffnen. Achten Sie darauf, die Pressemitteilung als PDF zu versenden. Sie können auch einen Auszug aus Ihrem Buch als PDF anhängen.',
  'pressrelease-sendpressrelease-p2': 'Wenn Sie eine hohe Priorität haben, sollten Sie in Erwägung ziehen, ein gedrucktes Exemplar Ihres Buches zu versenden. Das ist eine relativ kleine Investition, die einen großen Unterschied machen kann. Und schließlich sollten Sie in Ihrer E-Mail angeben, dass Sie für Interviews zur Verfügung stehen.',
  'pressrelease-downloadbutton': 'Pressemitteilung herunterladen',
  'pressrelease-followup-title': 'Nachbereitung',
  'pressrelease-followup-p1': 'Erwarten Sie nicht, dass man automatisch Ihre Pressemitteilung veröffentlicht oder etwas über Ihr Buch schreibt. Fragen Sie immer wieder mal nach, um zu überprüfen, ob etwas mit Ihrer Pressemitteilung gemacht wurde oder geplant ist. Ziehen Sie in Erwägung, Ihre Pressemitteilung nach ein paar Wochen erneut zu versenden, wenn Sie keine Antwort erhalten.',
  'pressrelease-sharelinks-title': 'Teilen Sie die resultierenden Medien Links',
  'pressrelease-sharelinks-p1': 'Jedes Mal, wenn Ihr Buch in den Medien erwähnt wird, müssen Sie dies in den sozialen Medien teilen. Es ist kostenlose Werbung für Ihr Buch, und je mehr Leute es sehen, desto höher ist die Chance, dass jemand Ihr Buch kauft.',

  // NBD Biblion
  'nbdbiblion-copyandpastetext': 'Klicken Sie hier, um den obigen Text zu kopieren',

  // Business cards
  'businesscards-intro-p1': 'Werbematerialien sind eine großartige Möglichkeit, Ihre Marketingstrategie zu ergänzen. Sie können eine greifbare, persönliche Note hinzufügen, die viele Menschen im digitalen Zeitalter zu schätzen wissen. Deshalb bieten wir Ihnen die Möglichkeit, Ihre eigenen Visitenkarten zu drucken, auf denen Sie ein Bild Ihres Buches zusammen mit der Information, wo es zu kaufen ist, platzieren können. Die Visitenkarten werden in voller Farbe auf stabilem 400-Gramm-Karton gedruckt. Ihre Bestellung wird innerhalb von zwei Wochen an Sie geliefert.  ',
  'businesscards-blocktitle-order': 'Visitenkarten bestellen',
  'businesscards-preview-label-front': 'Vorderseite',
  'businesscards-preview-label-back': 'Rückseite',
  'businesscards-numberofcards-label': 'Anzahl Visitenkarten',
  'businesscards-selectnumberofcards-item-label': '{number} Karten - {price}',

  // whatsapp
  'whatsapp-intro-p1': 'WhatsApp, oder welche Messaging-App auch immer Sie verwenden, ist wahrscheinlich der Ort, an dem Sie Ihre ersten Verkäufe erzielen werden. Die Menschen, die Sie über WhatsApp erreichen, bilden Ihr persönliches Netzwerk und werden daher diejenigen sein, die Sie als Autor am ehesten unterstützen. Beziehen Sie sie aber unbedingt in den Entstehungsprozess des Buches ein, bevor Sie die Nachricht von der Veröffentlichung verkünden und sie um den ultimativen Gefallen bitten, Ihr Buch zu kaufen! Sie könnten ihnen z. B. Ihre Cover-Ideen zeigen und sie um ihre Meinung bitten oder mit ihnen ein Brainstorming zum Titel machen. Sie könnten ihnen sogar den Klappentext vorzeitig schicken, um sie zu begeistern.',
  'whatsapp-intro-p2': 'Es mag nicht wie eine große Sache erscheinen, wenn Ihre Freunde und Familie Ihr Buch kaufen, aber sie könnten Ihre größten Botschafter sein. Hörensagen ist König, also vernachlässigen Sie diese Werbemethode nicht!',
  'whatsapp-promoteviawhatsapp': 'Vermarkten Sie Ihr Buch via WhatsApp',
  'whatsapp-button-sendviawhatsapp': 'Senden via WhatsApp',
  'whatsapp-week1-title': 'Week 1 - Ankündigung',
  'whatsapp-week1-message': 'Hallo zusammen, ich wollte euch nur wissen lassen, dass das Buch, über das ich so lange gequatscht habe, nun endlich veröffentlicht ist! Ich freue mich riesig darüber und würde mich freuen, wenn ihr es weiterverbreitet, wenn ihr jemanden kennt, der daran interessiert sein könnte!\n\nWenn ihr selbst daran interessiert seid, oder wenn ihr ein Geschenk für einen Buchliebhaber braucht, könnt ihr hier ein Exemplar bekommen:\n\n',
  'whatsapp-week2-title': 'Week 2 - Bucheinführungsparty',
  'whatsapp-week2-message': 'Hey nochmal, und danke an alle, die mein Buch schon gekauft haben, ihr seid fantastisch!\n\nIch wollte euch nur alle wissen lassen, dass ich eine Buchvorstellungsparty schmeiße! Ihr seid natürlich alle eingeladen. Es wird Snacks geben, Getränke und eine kurze Lesung eines Auszugs von mir! Ich werde sogar eure Bücher für euch signieren, wenn ihr wollt ;) Das wird ein Riesenspaß und ich würde mich freuen, wenn ihr kommt!',
  'whatsapp-week3-title': 'Week 3 - Danke fürs Kommen!',
  'whatsapp-week3-message': 'Hallo zusammen! Danke an alle, die zu meiner Buchvorstellungsparty gekommen sind, es war toll, euch alle dort zu sehen! Hier sind ein paar tolle Bilder, die ich während der Veranstaltung gemacht habe.\n\nIch möchte mich auch bei allen bedanken, die ein Exemplar gekauft haben, das bedeutet mir wirklich sehr viel! Wenn ihr es nicht geschafft habt und trotzdem ein Exemplar meines Buches in die Hände bekommen wollen, könnt ihr das gleich hier tun:',
  'whatsapp-week4-title': 'Week 4 - Rezensionsanfrage',
  'whatsapp-week4-message': 'Hey!\n\nWenn ich dich um einen winzigen Gefallen bitten dürfte, wäre es eine große Hilfe für mich, wenn du eine kurze Rezension des Buches auf Goodreads schreiben könntest? Es würde Wunder für die Sichtbarkeit des Buches online bewirken und mir helfen, mehr Leute zu erreichen. Ich würde mich sehr freuen, wenn ich mich eines Tages mit einem Kaffee revanchieren könnte!',
  'whatsapp-week4-goodreadswarning': 'Hinweis: Bevor Sie diese Nachricht abschicken, vergewissern Sie sich bitte, dass Sie Ihr Buch bereits zu Goodreads hinzugefügt haben!',
  'whatsapp-tips-title': 'Tipps',
  'whatsapp-tips-groups-label': 'Erstellen Sie spezielle WhatsApp-Gruppen',
  'whatsapp-tips-groups': 'Die Chancen stehen gut, dass Sie eine vielfältige Liste von Kontakten auf Whatsapp haben. Es kann eine gute Idee sein, mehrere Gruppen zu erstellen und an jede dieser Gruppen speziell gestaltete Nachrichten zu senden, die Ihrem üblichen Bekanntheitsgrad entsprechen.',
  'whatsapp-tips-readtheroom-label': 'Deuten Sie den Raum',
  'whatsapp-tips-readtheroom': 'Die Menschen, mit denen Sie auf WhatsApp sprechen, sind wahrscheinlich die Menschen, denen Sie am nächsten stehen, was bedeutet, dass sie wahrscheinlich Ihre treuesten Leser und Kunden sein werden. Ziehen Sie in Erwägung, diese Beziehungen zu pflegen, indem Sie nicht ständig Erinnerungen zum Kauf Ihres Buches senden, sondern sich bei den Leuten bedanken, die es kaufen. Dies ist in der Regel ein effektiverer Weg, um alle zu motivieren, die es noch nicht gekauft haben.',
  'whatsapp-tips-askspread-label': 'Bitten Sie die Leute, das Buch weiterzuempfehlen',
  'whatsapp-tips-askspread': 'Hörensagen ist eine der wirkungsvollsten Möglichkeiten, Ihr Buch bekannt zu machen. Es ist aber auch eine der schwierigsten. Nutzen Sie daher Ihre WhatsApp-Gruppen und lassen Sie Freunde und Familie das Wort in ihren eigenen sozialen Kreisen verbreiten.',
  'whatsapp-tips-participation-label': 'Schaffen Sie ein Gefühl der Beteiligung',
  'whatsapp-tips-participation': 'Die Menschen auf WhatsApp werden wahrscheinlich Ihre engsten und treuesten Leser und Kunden sein. Pflegen Sie diese Beziehungen, indem Sie sie wissen lassen, wie sehr Sie es zu schätzen wissen, dass sie Ihr Buch kaufen und die Nachricht verbreiten. Laden Sie sie zu Ihrer Buchvorstellungsparty und anderen buchbezogenen Veranstaltungen ein, teilen Sie Bilder von diesen Veranstaltungen in der Gruppe. Erinnern Sie sie daran, wie wichtig sie für Ihren Erfolg sind.',

  // social media
  'socialmedia-intro-p1': 'Wie Sie zweifellos wissen, sind die sozialen Medien eines der wichtigsten Werbetools, die heute zur Verfügung stehen. Als Autor im Selbstverlag ist es von unschätzbarem Wert, direkt mit Lesern kommunizieren zu können, die sich für die spezielle Nische interessieren, in der Sie schreiben. Im Folgenden haben wir einige Tipps und Tools für verschiedene Social-Media-Kanäle zusammengestellt, weitere werden folgen.',
  'socialmedia-tips-title': 'Tipps',
  'socialmedia-tips-beconsistent-label': 'Seien Sie konsistent',
  'socialmedia-tips-beconsistent': 'Als Schriftsteller haben Sie eine einzigartige Stimme, eine bestimmte Art und Weise, wie Sie Ihre Gedanken mitteilen, und diese sollte auch in Ihre Kommunikation in den sozialen Medien einfließen. Es ist wichtig, dass Sie Ihre Gedanken aktiv und eloquent teilen und Ihre Stimme konsequent auf eine Art und Weise projizieren, die Ihr Publikum wiedererkennen kann.',
  'socialmedia-tips-beshareable-label': 'Seien Sie teilbar',
  'socialmedia-tips-beshareable': 'Auch wenn Sie Gewohnheiten beim Posten in den sozialen Medien entwickeln und Ihr eigenes Ding machen sollten, ist es auch wichtig, Inhalte zu erstellen, die von Ihren Followern leicht geteilt werden können. Je mehr Leute Ihre Beiträge teilen, desto mehr Aufmerksamkeit wird Ihr Account bekommen.',
  'socialmedia-tips-berelatable-label': 'Seien Sie glaubwürdig',
  'socialmedia-tips-berelatable': 'Natürlich werden Sie so viel wie möglich über Ihr Buch erzählen wollen: Preise, bevorstehende Veranstaltungen usw. Dennoch ist es eine gute Idee, den Leuten auch einen Einblick in Ihr persönliches Leben zu geben, um Momente zu teilen, die menschlicher und nachvollziehbarer sind. Das macht Sie zu mehr als nur einem Buchverkäufer. Viele Leute werden sich nicht für Bücher oder das Schreiben interessieren, aber sie werden Ihnen trotzdem wegen der Inhalte folgen, die Sie hochladen. Also empfiehlt sich eine Mischung aus Ihrer Autorenreise und Ihrem Alltagsleben.',
  'socialmedia-tips-hashtaglover-label': 'Seien Sie ein Hashtag-Liebhaber',
  'socialmedia-tips-hashtaglover': 'Hashtags sind immer noch extrem wichtig. Verwenden Sie keine generischen Hashtags; machen Sie sich die Mühe, gute zu finden und zu implementieren, die Ihnen helfen, die richtigen Leute zu finden, und umgekehrt. Recherchieren Sie die Top-Hashtags z. B. in der Schreiber Community, bevor Sie posten. Natürlich sollten Sie nicht alles mit Hashtags versehen. Konzentrieren Sie sich nur auf die Inhalte, die Sie wirklich verbreiten wollen. Nicht jeder Beitrag braucht einen Hashtag.',
  'socialmedia-tips-beflexible-label': 'Seien Sie flexibel',
  'socialmedia-tips-beflexible': 'Jede Social-Media-Plattform ist anders. Bei Instagram geht es nur um Bilder. Bei LinkedIn dreht sich alles um Networking. Bei Facebook dreht sich alles um das Teilen. Bei Twitter dreht sich alles um Worte. Es ist wichtig, dass Sie Ihren Inhalt nicht kopieren; Sie müssen sich an den Stil und die Normen jeder Plattform anpassen. Ihre Stimme sollte dieselbe bleiben, aber die Art und Weise, wie Sie sie projizieren, sollte flexibel sein.',
  'socialmedia-tips-beanalytical-label': 'Seien Sie analytisch',
  'socialmedia-tips-beanalytical': 'Jede Social-Media-Plattform bietet Analysen an, machen Sie davon Gebrauch! Sie sollten darauf achten, welche Art von Publikum Sie erreichen, zu welchen Zeiten Sie am besten posten und welche Trends geliked und verfolgt werden. Ihre Social-Media-Plattformen stellen Ihnen diese Informationen zur Verfügung, sobald Sie Ihr Profil in ein Geschäftskonto ändern.',

  // Instagram
  'instagram-intro-p1': 'Instagram ist heutzutage praktisch ein Muss für jeden Autor. Die Buchszene auf Instagram, Bookstagram, ist eine riesige Community voller potenzieller Leser. Der Aufbau einer starken Instagram-Präsenz kann ziemlich schwierig sein, da Sie dafür regelmäßig hochwertige visuelle Inhalte produzieren müssen.',
  'instagram-intro-p2': 'Es gibt drei Hauptpunkte, die Sie auf Instagram priorisieren sollten: den Aufbau eines ästhetisch ansprechenden Feeds, die Verwendung relevanter und abwechslungsreicher Hashtags und das Posten von Stories.',
  'instagram-promoteoninstagram': 'Vermarkten Sie Ihr Buch via Instagram',
  'instagram-switchtophone': 'Zu Mobiltelefon wechseln',
  'instagram-switchtophone-description': 'Sie müssen Ihr Telefon verwenden, um Instagram-Inhalte zu posten. Sie können den QR-Code unten scannen, um zur mobilen Version dieser Seite zu gelangen. Die meisten Smartphones scannen QR-Codes automatisch, wenn Sie die Kamera-App öffnen und sie auf den Code richten. Wenn Sie den QR-Code nicht scannen können, können Sie sich auch auf Ihrem Telefon bei der Plattform anmelden.',
  'instagram-setupinsta-title': 'Instagram einrichten',
  'instagram-week1-title': 'Week 1 - Ankündigung',
  'instagram-week1-message': 'Hier ist es endlich: das erste gedruckte Exemplar meines neuen Buches, {title} - das ist so ein großer Meilenstein für mich und ich könnte nicht aufgeregter sein, es mit euch zu teilen! \n\n{keywords} #selbstverlag #bücherwurm #veröffentlicht #buchveröffentlichung',
  'instagram-week2-title': 'Week 2 - Erste(r) Verkauf(e) Post',
  'instagram-week2-chooseanimage-description': 'Wenn Sie das Bildmaterial für Ihren ersten Verkaufsbeitrag erstellen, ist es wichtig, es persönlich zu gestalten; das ist es, was Ihre Instagram-Follower sehen wollen. Versuchen Sie, Ihr Gesicht, Ihr Buch oder am besten beides zu zeigen. Sie könnten zum Beispiel ein Foto posten, auf dem Sie vor einem Buchladen stehen und ein Exemplar Ihres Buches in der Hand halten.',
  'instagram-week2-message': 'Ich bin wirklich begeistert, meine ersten 5 Bücher verkauft zu haben! Ein riesiges Dankeschön an alle, die ein Exemplar gekauft haben, es bedeutet mir die Welt. Ich hoffe, ihr verzeiht mir die schamlose Eigenwerbung, die ich jetzt mache, aber wenn ihr denkt, dass die Idee, ein Buch zu lesen, das ich mit meinen eigenen Händen geschrieben habe, auch nur im Entferntesten interessant klingt, solltet ihr euch überlegen, auf meiner Website zu gehen und es euch anzuschauen. Ihr könnt den Klappentext lesen, das Cover bewundern und vielleicht sogar euer eigenes Exemplar erwerben?\n\n#selbstverlag #autorenleben',
  'instagram-week3-title': 'Week 3 - Werbegeschenk-Post',
  'instagram-week3-chooseanimage-description': 'Wenn Sie das Bildmaterial für Ihr Werbegeschenk erstellen, ist es sinnvoll, zu zeigen, was genau Sie verschenken wollen. In unserem Beispiel unten verschenken wir 5 signierte Exemplare, und in diesem Fall könnten Sie ein Foto von 5 Exemplaren Ihres Buches machen, wobei eines aufgeschlagen ist, um Ihre Signatur zu zeigen. Stellen Sie sicher, dass die Beleuchtung stimmt, damit die Bücher so gut wie möglich aussehen.',
  'instagram-week3-message': 'Es waren ein paar verrückte Wochen, seit mein Buch {title} erschienen ist, und ich bin überwältigt von den unglaublich positiven Reaktionen, die es bisher bekommen hat, ganz zu schweigen von der Unterstützung, die ich persönlich erhalten habe. Ich danke euch allen für eure freundlichen Worte.\n\nWenn ihr es noch nicht geschafft habt, das Buch in die Hände zu bekommen, dann könnte das, was jetzt kommt, euch interessieren; ich werde 5 Exemplare an meine Follower verschenken! Die Bücher werden alle signiert sein, also falls ich wirklich berühmt werden sollte, habt ihr etwas Cooles in eurem Regal ;)\n\nAlles, was ihr tun müsst, ist, diesen Beitrag zu liken und einen Freund zu markieren, von dem ihr denkt, dass er sich auch für das Buch interessieren könnte! Ich werde 5 Kommentare nach dem Zufallsprinzip auswählen und die Gewinner nächste Woche in meinen Stories bekannt geben!\n\n#verlosung #bookstagram #indieautor',
  'instagram-week4-title': 'Week 4 - Rezensionsanfrage',
  'instagram-week4-chooseanimage-description': 'Wenn Sie um Rezensionen bitten, sind Sie auf das Wohlwollen Ihrer Follower angewiesen, daher ist es wichtig, dass Sie sich mit ihnen durch Ihr Bildmaterial verbinden. Ein Schnappschuss von Ihnen, auf dem Sie Ihr Buch in die Kamera halten, ist eine einfache Möglichkeit, dies zu tun. Wenn Sie es schrullig mögen, können Sie ein paar Bücher in einen Korb legen und ein Stück Pappe mit der Aufschrift "Rezensiere mich" davor platzieren. Sie könnten sich auch dafür entscheiden, eine Story zu machen, wenn Sie um Rezensionen bitten, anstatt nur einen Beitrag zu schreiben; diese Option wird wahrscheinlich mehr Leute erreichen.',
  'instagram-week4-message': 'Hallo zusammen, ich bin hier, um euch um einen Gefallen zu bitten!\n\nWenn ihr mein Buch gelesen habt, dann schreibt doch bitte eine kurze Rezension darüber auf Goodreads! Es wäre unglaublich hilfreich, um seine Sichtbarkeit online zu erhöhen und mehr Leute zu erreichen. Auch wenn ihr keine Zeit habt, eine echte Rezension zu schreiben, wäre eine Bewertung einfach toll. Ich würde das wirklich zu schätzen wissen.\n\nAußerdem vielen Dank an alle, die mein Buch gekauft haben, ihr habt keine Ahnung, wie viel mir das bedeutet!\n\n#rezension #selbstverlag #bookstragram #indieautor',
  'instagram-tips-title': 'Tipps',
  'instagram-tips-visuals-label': 'Alles über Visuals',
  'instagram-tips-visuals': 'Wie Sie wahrscheinlich wissen, dreht sich bei Instagram alles um das Visuelle. Das mag für Sie als Autor, der versucht, ein Buch zu promoten, wie eine Herausforderung erscheinen, aber es muss kein Nachteil sein. Einige Eckpfeiler Ihrer Instagram-Inhalte sollten das Cover Ihres Buches, Fotos von Ihrem Schreibprozess hinter den Kulissen und einige Bilder aus Ihrem täglichen Leben als Autor sein.',
  'instagram-tips-quotes-label': 'Zitate',
  'instagram-tips-quotes': 'Um die Neugierde Ihres Publikums zu wecken, ist das Teilen von Zitaten aus Ihrem Buch oft eine erfolgreiche Taktik! Auch wenn Instagram sehr visuell ist, ist das Bearbeiten von Fotos mit einem Satz darauf immer eine gute Möglichkeit, Ihr Schreiben zu teilen.',
  'instagram-tips-interact-label': 'Interagieren Sie',
  'instagram-tips-interact': 'Ihr Fokus sollte nicht nur auf der Promotion liegen, sondern auch auf der Interaktion mit Ihrem Publikum. Je mehr Interaktion, desto mehr Aufmerksamkeit wird Ihr Account erhalten. Es ist wichtig, die Beiträge Ihrer Leser in Ihren Stories zu teilen oder ihre Beiträge zu kommentieren. Halten Sie die Community engagiert. ',
  'instagram-tips-bookstagram-label': 'Bookstagram',
  'instagram-tips-bookstagram': 'Auf Instagram gibt es eine riesige Gemeinschaft von Buchlesern, die sich unter dem Oberbegriff "Bookstagram" zusammenfinden. Diese Online-Community teilt Fotos von den Büchern, die sie gerade lesen und von dem, was sie planen zu lesen, und sie ist extrem einflussreich. Sie als Autor sollten auf diese Bookstagrammer zugehen und ihnen folgen, mit dem Ziel, dass sie Ihr Buch auf ihrem Profil teilen. Für den Anfang sollten Sie Konten mit 1.000 bis 20.000 Followern anvisieren, da deren Engagement oft hoch ist und es für Sie einfacher ist, ein Gespräch zu beginnen. Denken Sie daran, personalisierte Nachrichten zu erstellen!',
  'instagram-tips-addlinks-label': 'Links hinzufügen',
  'instagram-tips-addlinks': 'Es ist wichtig, in Ihrer Bio einen Link hinzuzufügen, der zu einer Webseite führt, auf der Ihr Buch zum Verkauf steht. Sie können den Link zu unserem Shop oder zu Ihrer Autoren-Website hinzufügen, und die Leute auf Ihrer Instagram-Seite können Ihr Buch mit ein paar Klicks kaufen!',
  'instagram-tips-balance-label': 'Schaffen Sie ein Gleichgewicht',
  'instagram-tips-balance': 'Denken Sie daran, dass bei Instagram das Engagement Vorrang vor dem Teilen von Inhalten hat. Es ist also nicht notwendig, jeden Tag zu posten, aber es ist gut, auf 2-3 Tage pro Woche hinzuarbeiten. Auch die Verwendung von Instagram Stories, um weniger bearbeitete Fotos mit mehr alltäglichen Inhalten zu teilen, kann das Engagement erhöhen und die Aufmerksamkeit von Instagram gewinnen.',
  'instagram-tips-contests-label': 'Führen Sie Wettbewerbe/Verlosungen durch',
  'instagram-tips-contests': 'Es gibt nichts, was Menschen mehr lieben, als etwas kostenlos zu bekommen. Ein Werbegeschenk zu erstellen ist der perfekte Weg, um die Aufmerksamkeit der Leute auf Sie zu lenken und Ihr Publikum zu vergrößern. Sie können ein signiertes Exemplar Ihres Buches anbieten und die Leute auffordern, Ihnen zu folgen und zu kommentieren, um an der Verlosung teilzunehmen!',
  'instagram-tips-scheduleposts-label': 'Planen Sie Ihre Beiträge',
  'instagram-tips-scheduleposts': 'Ein Autor zu sein ist harte Arbeit, und Sie werden oft unter Zeitdruck stehen, wenn Sie neue Inhalte erstellen und hochladen müssen. Eine gute Strategie ist es, verschiedene Online-Tools zu verwenden, um Ihre Beiträge zu planen. Auf diese Weise können Sie mehrere Beiträge auf einmal erstellen und für die kommenden Wochen planen. Wenn Sie ein Geschäftskonto auf Facebook erstellen, erhalten Sie Zugriff auf Creator Studio, mit dem Sie Posts sowohl auf Facebook als auch auf Instagram erstellen und planen können. Dies ist eine gute Plattform, um damit anzufangen. ',

  // facebook
  'facebook-copyandpastetext': 'Klicken Sie hier, um den obigen Text zu kopieren. Fügen Sie anschließend diesen Text in das Statusfeld ein, nachdem Sie auf die Schaltfläche ‘Teilen’ geklickt haben.',
  'facebook-button-shareonfb': 'Teilen bei Facebook',
  'facebook-intro-p1': 'Der Schlüssel, um das meiste aus Ihren Marketing Bemühungen auf Facebook herauszuholen, ist die Maximierung Ihres Engagements. Versuchen Sie zu diesem Zweck, so viele Likes und Kommentare wie möglich zu erhalten und relevante Gruppen zu finden, denen Sie beitreten und in denen Sie aktiv sein können.',
  'facebook-promoteviafacebook': 'Vermarkten Sie Ihr Buch via Facebook',
  'facebook-week1-title': 'Week 1 - Ankündigung',
  'facebook-week1-message': 'Heute ist es soweit! Ich bin jetzt offiziell ein veröffentlichter Autor, es war ein langer Weg und viel harte Arbeit, aber mein Buch in den Händen zu halten, ist die Arbeit wirklich wert gewesen. Falls ihr meine Fortschritte nicht verfolgt habt und euch dafür interessiert, worum es in diesem Buch geht, ist hier der Klappentext:\n\n{shortdesc}\n\nWenn der Klappentext euer Interesse geweckt hat, könnt ihr auf meine Website gehen:',
  'facebook-week2-title': 'Week 2 - Erste(r) Verkauf(e) Post',
  'facebook-week2-message': 'Ich bin wirklich begeistert, meine ersten 5 Bücher verkauft zu haben! Ein riesiges Dankeschön an alle, die ein Exemplar gekauft haben, es bedeutet mir die Welt. Ich hoffe, ihr verzeiht mir die schamlose Eigenwerbung, die ich jetzt mache, aber wenn ihr denkt, dass die Idee, ein Buch zu lesen, das ich mit meinen eigenen Händen geschrieben habe, auch nur im Entferntesten interessant klingt, solltet ihr euch überlegen, auf meiner Website zu gehen und es euch anzuschauen. Ihr könnt den Klappentext lesen, das Cover bewundern und vielleicht sogar euer eigenes Exemplar erwerben?',
  'facebook-week3-title': 'Week 3 - Verlosung',
  'facebook-week3-message': 'Autorin zu sein, war bisher wirklich großartig; jeder hat mich unglaublich unterstützt, und ich könnte wirklich nicht glücklicher sein. Ich hätte nie gedacht, dass mein Buch so gut ankommen würde, also organisiere ich zur Feier des Tages eine Verlosung! Wenn ihr also noch kein Exemplar in den Händen haltet, schauen Sie doch mal bei Instagram vorbei, um eines zu gewinnen!',
  'facebook-week4-title': 'Week 4 - Bitte um Rezensionen',
  'facebook-week4-message': 'Vielen Dank an alle, die mein Buch gekauft haben; ihr seid fantastisch! Ich bin zurück, um euch um einen weiteren kleinen Gefallen zu bitten - es wäre eine große Hilfe für mich, wenn ihr auf Goodreads gehen und eine Rezension für mein Buch schreiben würdet! Selbst eine einfache Bewertung wäre großartig, und wenn ihr nicht auf Goodreads seid oder sein wollt, wäre eine Rezension auf einer beliebigen Plattform oder in einem beliebigen Geschäft ebenfalls sehr willkommen. Vielen Dank im Voraus!',
  'facebook-tips-title': 'Tipps',
  'facebook-tips-authorpage-label': 'Autorenseite',
  'facebook-tips-authorpage': 'Das Erstellen von Seiten zur Entwicklung spezifischer Inhalte auf Facebook ist wichtig. Indem Sie eine Autorenseite erstellen, kann Ihr Publikum Sie besser kennenlernen und die neuesten Informationen über Ihr Buch erhalten.',
  'facebook-tips-engagement-label': 'Engagement',
  'facebook-tips-engagement': 'Beim Algorithmus von Facebook dreht sich alles um Engagement. Es gibt keine Garantie, dass Follower und Freunde Ihre Beiträge sehen, daher ist die beste Möglichkeit, die Anzahl der Eindrücke Ihrer Inhalte zu maximieren, die Förderung des Engagements. Je mehr Likes und Kommentare Sie haben, desto höher ist die Chance, dass Facebook Ihre Beiträge für andere anzeigt. Wenn Sie es richtig anstellen, erhalten Sie einen Schneeballeffekt, bei dem die ersten Likes und Kommentare zu weiterem Engagement anregen, wodurch Ihre Beiträge sichtbarer werden und zu noch mehr Engagement einladen!',
  'facebook-tips-sellyourbook-label': 'Verkaufen Sie Ihr Buch',
  'facebook-tips-sellyourbook': 'Es ist wichtig, direkt auf die Verkaufskanäle zu verlinken, über die Ihr Buch erhältlich ist, wie z. B. unseren Shop oder Ihre Website. Es ist wichtig, Ihrem Publikum sowohl den Zugang zu Ihrem Buch als auch zu Informationen über das Buch und Sie selbst zu erleichtern.',
  'facebook-tips-promoteothers-label': 'Vermarkten Sie andere Bücher',
  'facebook-tips-promoteothers': 'Dieser Ratschlag mag kontraproduktiv erscheinen, aber er ist tatsächlich eine häufig angewandte Strategie. Je mehr Sie mit anderen Autoren interagieren, desto mehr werden diese Ihnen im Gegenzug helfen. Es ist immer gut, Rezensionen zu teilen und einige Bücher zu promoten, um Teil einer aktiven Community zu sein.',
  'facebook-tips-sharewriting-label': 'Teilen Sie Ihr Werk',
  'facebook-tips-sharewriting': 'Damit Ihr Publikum Sie als Autor kennenlernt, ist es wichtig, etwas von Ihrem Schreiben mitzuteilen, also: Erstellen Sie Posts mit einigen Zitaten oder Passagen aus Ihrem Buch! Vorschau-Zitate sind wichtig, um das Interesse der Leute zu motivieren, Ihr Buch zu kaufen und Ihre Autorenreise zu verfolgen.',
  'facebook-tips-startagroup-label': 'Gründen Sie eine Gruppe oder treten Sie einer bei',
  'facebook-tips-startagroup': 'Gruppen sind ein zentraler Aspekt von Facebook. Viele Menschen genießen es, in der großen Welt von Facebook Teil kleinerer Gemeinschaften zu sein. Sie als Autor können relevante Gruppen innerhalb Ihres Genres finden, die ausgezeichnete Orte sind, um mehr über sich und Ihr Buch zu teilen. Alternativ können Sie sogar eine Gruppe von Grund auf neu erstellen, um langsam Ihre eigene Facebook-Gemeinschaft aufzubauen!',

  // Twitter
  'twitter-intro-p1': 'Twitter ist der Ort für Menschen, die es schaffen, dass ihre Worte zählen, also sollten Sie sich als Autorin oder Autor hier zu Hause fühlen.',
  'twitter-intro-p2': 'Damit Twitter für Sie funktioniert, müssen Sie häufig twittern und, was vielleicht noch wichtiger ist, retweeten. Achten Sie auf Hashtags, die für Bücher, das Verlagswesen, Ihr spezielles Genre und sogar Ihre persönlichen Interessen relevant sind. Stellen Sie sicher, dass Sie mit Substanz zu allen Diskursen beitragen, an denen Sie teilnehmen. Das Verkaufspotenzial von Twitter ist weit weniger direkt als das von z. B. Instagram, aber es kann dennoch nützlich sein, um eine Anhängerschaft und damit Botschafter für Ihr Buch zu gewinnen.',
  'twitter-promoteviatwitter': 'Vermarkten Sie Ihr Buch via Twitter',
  'twitter-button-shareviatwitter': 'Teilen bei Twitter',
  'twitter-charcount': 'Sie haben {chars} von 280 Zeichen verwendet',
  'twitter-week1-title': 'Week 1 - Ankündigung',
  'twitter-week1-message': 'Mein Buch, {title} ist endlich draußen, Leute! Das ist ein großartiges Gefühl und ich musste es mit euch teilen. Geht auf meine Website, um euer eigenes Exemplar zu bestellen, oder lest einige Probekapitel, um ein Gefühl für das Buch zu bekommen! #indieauthor',
  'twitter-week2-title': 'Week 2 - Erste(r) Verkauf(e) posten',
  'twitter-week2-message': 'Es ist soweit! Die ersten 5 Bücher sind verkauft; vielen Dank an alle, die ein Exemplar gekauft haben! Wenn noch jemand an meinem Buch interessiert ist, {title} können Sie es auf meiner Website bekommen.',
  'twitter-week3-title': 'Week 3 - Verlosung',
  'twitter-week3-message': 'Bücherwürmer aufgepasst! Es ist Zeit für ein #verlosung! Was gibt es zu verschenken? Nun, mein Buch, {title}; natürlich. Was müsst ihr tun? Folgt mir, liket diesen Tweet, retweetet, und hinterlasst einen Kommentar, warum ihr ein Exemplar des Buches möchtet!',
  'twitter-week4-title': 'Week 4 - Rezensionsanfrage',
  'twitter-week4-message': 'Achtung an alle Leser! Wenn ihr mein Buch {title} gelesen habt und es euch gefallen hat, geht bitte auf Goodreads und hinterlasst eine Rezension! Ich würde mich nicht nur freuen, eure Meinung zu lesen, sondern es wirkt auch Wunder für die Sichtbarkeit des Buches! #selbstverlag',
  'twitter-tips-title': 'Tipps',
  'twitter-tips-sharequotes-label': 'Zitate teilen',
  'twitter-tips-sharequotes': 'Da Sie sehr wahrscheinlich mit Twitter vertraut sind, werden wir nicht zu tief in die Grundlagen einsteigen. Da es sich um eine Plattform handelt, die sich auf prägnante, textbasierte Nachrichten konzentriert, eignet sie sich sehr gut für Autoren. Eine offensichtliche Möglichkeit, Twitter zu nutzen, ist das Teilen von Zitaten aus Ihrem Buch. Versuchen Sie, Ihrem Publikum zu zeigen, wer Sie als Autor sind.',
  'twitter-tips-interaction-label': 'Interaktion über alles',
  'twitter-tips-interaction': 'Bei Twitter geht es nicht nur darum, eigene Ideen mitzuteilen, sondern auch an laufenden Diskussionen teilzunehmen; wahrscheinlich vor allem zu Beginn. Twitter schafft die Möglichkeit, mit potenziellen Lesern, anderen Autoren und natürlich Ihren eigenen Fans zu interagieren. Je mehr Interaktion, desto besser!',
  'twitter-tips-balance-label': 'Gleichgewicht',
  'twitter-tips-balance': 'Wenn der Buchverkauf Ihr Hauptziel ist, kann man sich leicht in der ständigen Vermarktung Ihres Buches verlieren. Es ist jedoch wichtig, eine persönliche, und noch besser, eine lustige Seite von sich zu zeigen. Die Leute wollen nicht ständig Tweets lesen, die auf "kauft mein Buch" hinauslaufen; sie wollen auch Ihre Geschichten hören und einen Einblick in Ihr Leben bekommen!',
  'twitter-tips-pinapost-label': 'Einen Beitrag anheften',
  'twitter-tips-pinapost': 'Wenn Sie regelmäßig auf Twitter posten und sich Sorgen machen, dass bestimmte Informationen über Ihr Buch dadurch verloren gehen oder übersehen werden könnten, wäre es eine gute Idee, einen Beitrag anzupinnen! Sie können einen ansprechenden Beitrag anpinnen, der einen Link zum Kauf Ihres Buches enthält und den jeder sehen kann, sobald er Ihr Profil aufruft. Dann müssen Sie sie nur noch dorthin bringen.',
  'twitter-tips-bio-label': 'Bio',
  'twitter-tips-bio': 'Ein großer Teil Ihrer Zeit auf Twitter konzentriert sich ausschließlich auf das Tweeten, aber vernachlässigen Sie nicht Ihre Bio! Sie wird für jeden sichtbar sein, also ist es ein guter Platz, um ein paar Worte zu Ihrer Person und den Titel Ihres Buches hineinzuschreiben. Machen Sie sich interessant!',

  // LinkedIn
  'linkedin-intro-p1': 'LinkedIn hat mehr Potenzial für selbst veröffentlichte Autoren, als man denkt. Zum einen ist es praktisch maßgeschneidert für diejenigen, die Sachbücher geschrieben haben. Aber auch wenn Sie ein belletristisches Buch geschrieben haben, können Sie auf LinkedIn ein unterstützendes Publikum finden. Es ist eine Plattform, die sich gut eignet, um Ihre Reise als Autor und Verleger aus einer "unternehmerischen" Perspektive zu teilen. Stellen Sie Ihr Buch als ein Projekt dar und sprechen Sie über die Herausforderungen und Belohnungen, die mit der Arbeit daran verbunden sind. Vergessen Sie dabei aber nicht, menschlich zu sein!',
  'linkedin-intro-p2': 'Das Posten auf LinkedIn erfordert ein wenig mehr Vorbereitungsarbeit als auf anderen Plattformen. Sie sollten vermeiden, zu verkaufsorientiert zu sein und sich stattdessen darauf konzentrieren, Wissen und Erfahrungen zu teilen. Das Schreiben eines Artikels zum Beispiel wird etwas Aufwand erfordern, aber er wird auf der Plattform viel besser ankommen als ein unverschämt verkaufsorientierter Beitrag.',
  'linkedin-promotevialinkedin': 'Vermarkten Sie Ihr Buch viaLinkedIn',
  'linkedin-copyandpastetext': 'Klicken Sie hier, um den obigen Text zu kopieren. Fügen Sie anschließend diesen Text in das Statusfeld ein, nachdem Sie auf die Schaltfläche ‘Teilen’ geklickt haben.',
  'linkedin-button-shareonlinkedin': 'Teilen bei LinkedIn',
  'linkedin-week1-title': 'Week 1 - Ankündigung',
  'linkedin-week1-message': 'Ich bin so aufgeregt zu verkünden, dass mein Buch, {title}, endlich veröffentlicht ist! Es war schon immer ein Traum von mir, Autor / in zu werden, und es war eine unglaubliche Erfahrung, diesen Traum tatsächlich für mich zu verwirklichen. Wenn ihr auf meiner Website vorbeischaut, könnt ihr einige Kapitel lesen und sogar euer eigenes Exemplar in die Hände bekommen!\n\n#autor #veröffentlichen #bücher',
  'linkedin-week2-title': 'Week 2 - Buchvorstellung',
  'linkedin-week2-message': 'Vor kurzem habe ich meine allererste Buchvorstellung veranstaltet und es war ein absoluter Knaller! Alle zu sehen, die sich versammelt haben, um die Veröffentlichung meines Buches mit mir zu feiern, war sowohl unglaublich aufregend als auch extrem zufriedenstellend. Ein herzliches Dankeschön an alle, die dabei waren, und für diejenigen, die es nicht geschafft haben, gibt es hier ein paar schöne Fotos von der Veranstaltung!\n\n#buchveröffentlichen #selbstverlag #autor',
  'linkedin-week3-title': 'Week 3 - Workshop',
  'linkedin-week3-message': 'Mein Buch {title} hat sich in letzter Zeit wirklich gut verkauft, und ich habe ein reges Interesse nicht nur am Thema, sondern auch am Prozess des Schreibens und Self-Publishing festgestellt. Das brachte mich auf die Idee, einen kostenlosen Workshop zu organisieren, um mit euch allen über das Schreiben, Self-Publishing und das Unternehmertum als Autor zu diskutieren!  Ich denke, es wäre extrem hilfreich, Ideen auszutauschen und Erkenntnisse miteinander zu teilen. Lasst mich wissen, ob ihr interessiert seid!\n\n#workshop #autor',
  'linkedin-week4-title': 'Week 4 - Verlosung',
  'linkedin-week4-message': 'Mein Buch zu veröffentlichen war eine der größten Herausforderungen, die ich erlebt habe, aber es hat sich wirklich gelohnt! Ich hätte nie gedacht, dass sich das Buch am Ende so gut verkaufen würde, und das möchte ich feiern, indem ich 5 Exemplare verschenke! Wenn ihr an der Verlosung teilnehmen möchtet, reagiert einfach auf diesen Beitrag und hinterlasst einen Kommentar, in dem ihr beschreibt, warum ihr denkt, dass *Schlüsselwort* heute wichtig ist! Die 5 inspirierendsten Kommentare erhalten ein Exemplar von {title}!\n\n#autor #selbstverlag #verlosung',
  'linkedin-tips-title': 'Tipps',
  'linkedin-tips-finishprofile-label': 'Vervollständigen Sie Ihr Profil... aber vervollständigen Sie es wirklich',
  'linkedin-tips-finishprofile': 'Das mag offensichtlich erscheinen, aber viele Leute schieben die Fertigstellung aller Schritte ihres Linkedin-Profils auf unbestimmte Zeit hinaus. Wenn Sie Ihr Profil nicht fertigstellen, gibt es weniger Möglichkeiten für andere, Sie zu finden oder sich mit Ihnen zu identifizieren.',
  'linkedin-tips-connect-label': 'Verbinden Sie sich mit anderen',
  'linkedin-tips-connect': 'Bei Linkedin dreht sich alles um Networking; die Plattform basiert auf dem Aufbau von Verbindungen mit Menschen. Sie müssen sicherstellen, dass Sie andere Autoren, potenzielle Leser und so weiter hinzufügen. Das Erstellen von Inhalten für ein kleines Netzwerk wird Sie nicht weiterbringen.',
  'linkedin-tips-publisharticles-label': 'Artikel veröffentlichen',
  'linkedin-tips-publisharticles': 'Linkedin bietet die Möglichkeit, Artikel zu veröffentlichen, und das ist extrem nützlich für einen aufstrebenden Autor! Sie können diese Plattform nutzen, um Teile Ihres Romans zu veröffentlichen, oder vielleicht Artikel schreiben, in denen Sie Tipps und Erfahrungen teilen, die anderen aufstrebenden Autoren gefallen könnten. Menschen neigen dazu, auf Linkedin zu engagieren und viel zu lesen, und Artikel sind perfekt für einen Autor, der etwas zu sagen hat! Außerdem sind Artikel eine gute Möglichkeit, Besucher auf Ihre Website zu leiten.',
  'linkedin-tips-jobposition-label': 'Machen Sie Ihr Buch zu einem Stellenangebot',
  'linkedin-tips-jobposition': 'Sie können Ihr Buch zu einer eigenen Stelle machen! Der Titel würde sich auf Ihren Namen beziehen und die Firma wäre der Name Ihres Buches. Auf diese Weise können die Leute Ihr Buch deutlich auf Ihrem Linkedin-Profil sehen. Sie können die Zeit, an der Sie an Ihrem Roman gearbeitet haben, zusammen mit einer netten Beschreibung hinzufügen. Dies ist auch eine kleine Möglichkeit, mögliche Fortsetzungen anzukündigen.',
  'linkedin-tips-behuman-label': 'Seien Sie menschlich',
  'linkedin-tips-behuman': 'Da Linkedin in erster Linie als Business-Plattform bekannt ist, denken die Leute oft, dass sie formell sein müssen, wenn sie dort posten. Wenn Sie jedoch ein Autor sind, der versucht, sein Buch zu verkaufen, werden Sie Punkte sammeln, indem Sie ein personalisiertes Profil entwickeln, und Sie sollten sich darauf konzentrieren! Versuchen Sie nicht, etwas zu sein, was Sie nicht sind. Die Leute auf LinkedIn sind verrückt nach Geschichten über persönliches Wachstum und das Überwinden von Herausforderungen, also nutzen Sie das zu Ihrem Vorteil.',

  // Libraries
  'libraries-intro-p1': 'Jeder ernsthafte Schriftsteller sollte sein Buch in der Bibliothek bereitstellen. Tatsächlich hat die Nationalbibliothek ein Anrecht auf ein Exemplar; dies wird als gesetzliche Hinterlegung bezeichnet. Abgesehen von den Formalitäten ist die Hinterlegung Ihres Buches in der Bibliothek jedoch eine großartige Möglichkeit, sich als Autor zu legitimieren. Es ist auch ein toller Weg, Ihr Buch zu vermarkten, und wenn Sie genug Leute dazu bringen können, Ihr Buch in ihren örtlichen Bibliotheken anzufordern, werden Sie vielleicht sogar einige Exemplare verkaufen können!',

  // Upload zone
  'uploadzone-default-title': 'Hochladen',
  'uploadzone-default-title-progress': 'Manuskript hochladen...',
  'uploadzone-filerequirements': 'Datei: max. {filesize}, Formate: {filetypes}',
  'uploadzone-button-selectfile': 'Datei wählen',
  'uploadzone-ordragfilehere': 'oder Datei hier ziehen und ablegen',
  'uploadzone-dropfiletoupload': 'Datei hier ziehen und ablegen',

  // Book problem translations
  'bookproblem-link-moreinfo': 'Weitere Informationen',
  'bookproblem-stepScriptSizeModifiedUploadAgain': 'Sie haben das Format Ihres Buches geändert. Bitte laden Sie Ihre Manuskriptdatei erneut hoch.',
  'bookproblem-stepScriptColorModifiedUploadAgain': 'Sie haben die Farbeeinstellungen Ihres Manuskripts geändert. Bitte laden Sie Ihre Manuskriptdatei erneut hoch.',
  'bookproblem-stepScriptBindingModifiedUploadAgain': 'Sie haben Ihren Buchtyp geändert. Bitte laden Sie Ihre Manuskriptdatei erneut hoch.',
  'bookproblem-stepScriptReaddirectionModifiedUploadAgain': 'Sie haben die Sprache Ihres Buches in einere Sprache mit einer anderen Schreibrichtung geändert. Bitte laden Sie Ihr Manuskript erneut hoch, damit die Ausrichtung mit der von Ihnen gewählten Sprache übereinstimm',
  'bookproblem-stepScriptManuscriptConversionFailed': 'Wir konnten Ihre Datei nicht konvertieren. Das liegt wahrscheinlich daran, dass Ihre Datei beschädigt ist. Versuchen Sie, Ihre Datei zu reparieren und erneut hochzuladen.',
  'bookproblem-stepScriptManuscriptColorpagesNotCalculated': 'Wir waren nicht in der Lage, die Anzahl der Farbseiten in Ihrem Buch korrekt zu berechnen. Bitte kontaktieren Sie uns, um dieses Problem zu beheben, oder laden Sie eine neue Version Ihrer Manuskriptdatei hoch.',
  'bookproblem-stepScriptManuscriptConversionTechnicalError': 'Bei der Konvertierung Ihres Manuskripts ist ein technischer Fehler aufgetreten. Fehlercode:  {lasterror}',
  'bookproblem-stepScriptFontsNotEmbedded': 'Einige Schriftarten in diesem Dokument sind nicht korrekt eingebettet. Weitere Informationen finden Sie in unserem Leitfaden zur Erstellung von PDF-Dokumenten. ',
  'bookproblem-stepScriptEbookConversionHasErrors': 'Wir haben bei der Konvertierung Ihres eBooks mehrere Fehler entdeckt. Sie müssen diese Probleme beheben, bevor Sie Ihr Buch veröffentlichen können.',
  'bookproblem-printerConstraintNumberpagesLessThanMinimum': 'Ihr Buch hat nicht genug Seiten. Bitte sorgen Sie dafür, dass Ihr Buch mindestens {min} Seiten enthält (die aktuelle Seitenzahl ist {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanMaximum': 'Ihr Buch hat zu viele Seiten. Bitte sorgen Sie dafür, dass Ihr Buch mindestens {min} Seiten enthält (die aktuelle Seitenzahl ist {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanCoverwidth': 'Ihr Buch enthält zu viele Seiten für das gewählte Format und den gewählten Papiertyp. Stellen Sie sicher, dass Ihr Buch höchstens {max} Seiten enthält, oder wählen Sie einen anderen Papiertyp und/oder ein kleineres Format.',

  // Bookstep: manuscript
  'bookstep-Manuscript': 'Manuskript',
  'bookstep-Manuscript-intro-p1-1': 'Wenn Sie Ihr Buch zu einem finanziellen Erfolg machen wollen, ist ein gut geschriebenes und professionell formatiertes Manuskript unerlässlich. Wir stehen Ihnen während des Schreibprozesses zur Seite. Wenn Sie möchten, dass ein Fachmann einen Blick auf Ihre Arbeit wirft, können Sie unsere Autorendienste in Anspruch nehmen. Laden Sie Ihr Manuskript als Word-Dokument oder als PDF-Datei hoch. Nachdem Sie die Datei hochgeladen haben, wandeln wir die Datei in ein druckfertiges PDF um. Überprüfen Sie diese Datei sorgfältig und bestellen Sie vor der Veröffentlichung immer ein Korrekturexemplar. Es gibt wenig ärgerlicheres, als die Entdeckung von Tipp- oder Formatierungsfehlern in Ihrem veröffentlichten Buch.',
  'bookstep-Manuscript-intro-p1-3': 'Wenn Sie Ihr Buch zu einem finanziellen Erfolg machen wollen, ist ein gut geschriebenes und professionell formatiertes Manuskript unerlässlich. Wir stehen Ihnen während des Schreibprozesses zur Seite. Wenn Sie möchten, dass ein Fachmann einen Blick auf Ihre Arbeit wirft, können Sie unsere Autorendienste in Anspruch nehmen. Laden Sie Ihr Manuskript als Word-Dokument oder als PDF-Datei hoch. Nachdem Sie die Datei hochgeladen haben, wandeln wir die Datei in ein druckfertiges PDF um. Überprüfen Sie diese Datei sorgfältig und bestellen Sie vor der Veröffentlichung immer ein Korrekturexemplar. Es gibt wenig ärgerlicheres, als die Entdeckung von Tipp- oder Formatierungsfehlern in Ihrem veröffentlichten Buch.',
  'bookstep-Manuscript-intro-p1-6': 'Wenn Sie Ihr Buch zu einem finanziellen Erfolg machen wollen, ist ein gut geschriebenes und professionell formatiertes Manuskript unerlässlich. Wir stehen Ihnen während des Schreibprozesses zur Seite und unterstützen Sie. Sie können entweder ein Word-Dokument oder eine ePub-Datei hochladen, wenn Sie ein eBook erstellen. Unsere Plattform verarbeitet Ihre hochgeladene Datei und konvertiert sie gegebenenfalls in ein veröffentlichungsbereite ePub. Prüfen Sie diese veröffentlichungsbereite Datei gründlich, bevor Sie fortfahren. Wenn Sie ein Word-Dokument hochladen möchten, ist es wichtig, dass Sie eine Formatierung verwenden, welche ePub freundlich ist, da das resultierende eBook sonst möglicherweise nicht lesbar ist. Wenn Sie eine ePub-Datei hochladen möchten, empfehlen wir Ihnen, diese mit Calibre zu erstellen.',
  'bookstep-Manuscript-intro-p1-7': 'Ein gut geschriebenes und professionell formatiertes Manuskript ist unerlässlich. Es lohnt sich, das Manuskript auf Anhieb richtig zu gestalten, als es später noch einmal zu überarbeiten. Wir stehen Ihnen während des Schreibprozesses zur Seite und unterstützen Sie. Laden Sie Ihr Manuskript als PDF-Datei hoch. Unsere Plattform verarbeitet Ihre hochgeladene Datei (und konvertiert sie gegebenenfalls in ein veröffentlichungsbereite PDF). Prüfen Sie diese veröffentlichungsbereite Datei gründlich, bevor Sie fortfahren.',
  'bookstep-Manuscript-button-nextstep': 'Nächster Schritt',
  'bookstep-Manuscript-resources-toolstoimprovescript': 'Unterstützung bei Ihrem Manuskript',
  'bookstep-Manuscript-resources-wordtemplate': 'Word-Vorlage und Einstellungen',
  'bookstep-Manuscript-resources-wordtemplate-description': 'Verwenden Sie diese Einstellungen und Vorlagen zur Formatierung Ihres Manuskripts',
  'bookstep-Manuscript-resources-bookspecs': 'Spezifikationen für Fachleute',
  'bookstep-Manuscript-resources-bookspecs-description': 'Einstellungen für Indesign, QuarkXPress, etc.',
  'bookstep-Manuscript-resources-bookformatting': 'Formatieren Sie Ihr Buch',
  'bookstep-Manuscript-resources-bookformatting-description': 'Eine Kurzanleitung, wie Sie Ihr Buch am besten formatieren',
  'bookstep-Manuscript-resources-services-needhelp': 'Brauchen Sie Hilfe?',
  'bookstep-Manuscript-resources-services-needhelp-description': 'Weitere Informationen über unsere kostenpflichtigen Services',
  'bookstep-Manuscript-UploadBlock-title-0': 'Mijn Manuskript',
  'bookstep-Manuscript-UploadBlock-title-1': 'Ihre Datei wird konvertiert',
  'bookstep-Manuscript-UploadBlock-title-2': 'Überprüfen Sie Ihr Manuskript',
  'bookstep-Manuscript-uploadblock-stillwriting-title': 'Ich bin immer noch am schreiben',
  'bookstep-Manuscript-uploadblock-stillwriting-description': 'Arbeiten Sie noch an Ihrem Manuskript? Falls Sie etwas Inspiration brauchen, empfehlen wir Ihnen, sich einige unserer praktischen Tipps anzuschauen, oder einen Blick auf die verschiedenen Veröffentlichungsdienste zu werfen, welche wir anbieten.',
  'bookstep-Manuscript-uploadblock-stillwriting-button': 'Schreiben',
  'bookstep-Manuscript-uploadblock-stillformatting-title': 'Formatierung',
  'bookstep-Manuscript-uploadblock-stillformatting-description': 'Müssen Sie Ihr Manuskript noch formatieren? Nutzen Sie unsere Vorlagen oder machen Sie Gebrauch von unseren Formatierungsservices, um sicherzustellen, dass Ihr Manuskript so gut wie möglich ist.',
  'bookstep-Manuscript-uploadblock-stillformatting-button': 'Formatieren',
  'bookstep-Manuscript-uploadblock-conversioninprogress-title': 'Ihre Datei wird konvertiert. Bitte haben Sie einen Moment Geduld.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1': 'Überprüfen Sie Ihre Druckvorschau',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-desc': 'Wir haben Ihr Manuskript in eine druckfertige PDF-Datei umgewandelt. Ihr Buch wird so gedruckt, wie es in diesem Dokument dargestellt wird, überprüfen Sie es also sorgfältig! Sie können eine neue Version beliebig oft hochladen.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-previewbutton': 'Druckdatei überprüfen',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3': 'Überprüfen Sie Ihre Druckvorschau',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-desc': 'Wir haben Ihr Manuskript in eine druckfertige PDF-Datei umgewandelt. Ihr Buch wird so gedruckt, wie es in diesem Dokument dargestellt wird, überprüfen Sie es also sorgfältig! Sie können eine neue Version beliebig oft hochladen.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-previewbutton': 'Druckdatei überprüfen',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6': 'Überprüfen Sie Ihr eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-desc': 'Wir haben Ihr Manuskript in eine ePub-Datei umgewandelt. Dies ist die endgültige Fassung Ihres Manuskripts, welche die Leser/innen erhalten, wenn sie Ihr Buch kaufen, überprüfen Sie das Dokument also sorgfältig! Sie können eine neue Version beliebig oft hochladen.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-previewbutton': 'eBook überprüfen',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7': 'Überprüfen Sie Ihr PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-desc': 'Wir haben Ihr Manuskript in eine PDF-Datei umgewandelt. Dies ist die endgültige Fassung Ihres Manuskripts, welche die Leser/innen erhalten, wenn sie Ihr Buch kaufen, überprüfen Sie das Dokument also sorgfältig! Sie können eine neue Version beliebig oft hochladen.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-previewbutton': 'PDF eBook überprüfen',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-uploadnewbutton': 'Neue Datei hochladen',
  'bookstep-Manuscript-tips-title': 'Tipps für ein gutes Manuskript',
  'bookstep-Manuscript-tips-print-sizeinterior-label': 'Innenformatierung / Spezifikationen',
  'bookstep-Manuscript-tips-print-sizeinterior-word': 'Wenn Sie Word verwenden, empfehlen wir Ihnen, eine unserer Manuskriptvorlagen herunterzuladen, indem Sie auf die obige Schaltfläche klicken. Diese Vorlage wurde speziell für den von Ihnen ausgewählten Buchtyp erstellt. Sie müssen sich also nicht um die Formatierung kümmern und können einfach Ihren Text einfügen. Dies ist der einfachste Weg, um sicherzustellen, dass das Layout Ihres Buches mit dem Ihres Manuskripts übereinstimmt.',
  'bookstep-Manuscript-tips-print-sizeinterior-indesign': 'Wenn Sie InDesign oder ein anderes professionelles Formatierungsprogramm verwenden, klicken Sie auf die Schaltfläche “Buchspezifikationen”. Dort finden Sie alle Informationen, die Sie benötigen, um das perfekte Manuskript zu formatieren.',
  'bookstep-Manuscript-tips-print-images-label': 'Wie Sie Bilder zu Ihrem Manuskript hinzufügen',
  'bookstep-Manuscript-tips-print-images': 'Selbstverständlich können Sie zu Ihrem Manuskript Bilder hinzufügen, wenn Sie dies wünschen. Ein Bild mit einer Auflösung von 250 dpi ist ausreichend, um eine hohe Druckqualität zu gewährleisten. Platzieren Sie die Bilder einfach dort, wo sie in Ihrem Manuskript erscheinen sollen (achten Sie auf den Beschnitt, wenn Sie Bilder in Randnähe platzieren). Wenn Sie ein Bild einfügen möchten, welches sich über zwei Seiten erstreckt, können Sie das Bild in zwei Hälften schneiden und den linken Teil auf einer geraden Seite und den rechten Teil auf der folgenden ungeraden Seite platzieren.',
  'bookstep-Manuscript-tips-print-colophon-label': 'Impressum',
  'bookstep-Manuscript-tips-print-colophon-p1': 'Es ist üblich, dass Ihr Buch mit einer Titelseite sowie einem Impressum beginnt. Im Allgemeinen ist die Reihenfolge wie folgt:',
  'bookstep-Manuscript-tips-print-colophon-firstpage': 'Erste Seite: Enthält den Titel des Buches ',
  'bookstep-Manuscript-tips-print-colophon-secondpage': 'Zweite Seite: Leere Seite',
  'bookstep-Manuscript-tips-print-colophon-thirdpage': 'Dritte Seite: Titel des Buches, gefolgt von dem Namen des Autors',
  'bookstep-Manuscript-tips-print-colophon-fourthpage': 'Vierte Seite: Dies ist das Impressum, die den Namen des Autors, die ISBN (falls vorhanden), den Namen des Umschlagdesigners, den Copyright-Vermerk, das Erscheinungsjahr und Verlagsportal: {publicbrand}',
  'bookstep-Manuscript-tips-print-colophon-p2': 'Diese Seiten werden als "Titelei" Ihres Buches bezeichnet. Die oben aufgeführten Seiten sind nicht nummeriert. Das bedeutet, dass Ihre Seitennummerierung auf Seite 5 beginnt. Unsere Manuskriptvorlagen sind bereits nach diesem Standard eingerichtet.',
  'bookstep-Manuscript-tips-print-formattinginterior-label': 'Formatierung Ihres Textblocks',
  'bookstep-Manuscript-tips-print-formattinginterior': 'Ohne ein gut formatiertes Manuskript verliert Ihr Buch an Wert. Damit Sie dies vermeiden und keine Leser verpassen, haben wir einen ausführlichen Leitfaden für die richtige Formatierung Ihres Manuskripts verfasst.',
  'bookstep-Manuscript-tips-print-formattinginterior-link': 'Wie Sie Ihr Buch formatieren',
  'bookstep-Manuscript-tips-print-minimumpagecount-label': 'Mindestanzahl an Seiten',
  'bookstep-Manuscript-tips-print-minimumpagecount': 'Damit wir qualitativ hochwertige Bücher für Sie drucken können, müssen diese eine gewisse Anzahl an Seiten enthalten. Die erforderliche Seitenzahl hängt von der Art des zu druckenden Buches ab; insbesondere der Umschlag (Softcover oder Hardcover), die Papiersorte und die Abmessungen beeinflussen die Mindestanzahl der Seiten. Für ein Taschenbuch ist beispielsweise eine geringere Seitenzahl erforderlich als für ein gebundenes Buch. Diese Voraussetzung hängt von der verwendeten Papiersorte ab, da sie alle ein unterschiedliches Flächengewicht haben. Im ersten Schritt des Bucherstellungsprozesses, wo Sie Ihre Spezifikationen auswählen, zeigen wir Ihnen die Mindestanzahl an Seiten an, die für Ihr ausgewähltes Buch erforderlich ist.',
  'bookstep-Manuscript-tips-print-pageorientation-label': 'Seitenausrichtung',
  'bookstep-Manuscript-tips-print-pageorientation': 'Auch wenn es trivial oder offensichtlich klingen mag, ist es dennoch erwähnenswert: Die erste Seite Ihres Manuskripts ist immer auf der rechten Seite platziert. Auch wenn die Innenseite des Umschlags, das so genannte Vorsatzblatt, wie eine (leere) Seite aussieht, zählt sie nicht als solche. Die Seiten auf der linken Seite sind immer gerade nummeriert. Wenn also zwei Seiten nebeneinander liegen, befindet sich auf der linken Seite immer eine gerade Seite und auf der rechten Seite eine höhere, ungerade Seitenzahl. Dies ist praktisch, wenn Sie ein Fotobuch erstellen und eine ein Bild einfügen möchten, welches sich über zwei Seiten erstreckt. Beachten Sie, dass wir die Seitenzahlen nicht für Sie einfügen - Es liegt in Ihrer Verantwortung, sich darum zu kümmern, bevor Sie Ihr Manuskript hochladen. Unsere Manuskriptvorlagen enthalten jedoch Seitenzahlen. Wenn Sie also Probleme mit der Seitennummerierung haben, empfehlen wir Ihnen, sich eine dieser Vorlagen herunterzuladen und damit zu arbeiten.',
  'bookstep-Manuscript-tips-epub-epubexplain-label': 'Zu den ePubs',
  'bookstep-Manuscript-tips-epub-epubexplain': 'Eine eBook-Datei (ePub genannt) funktioniert möglicherweise etwas anders, als Sie es erwarten würden. Eine ePub-Datei hat zum Beispiel keine festen Seitenzahlen. Stattdessen ist sie "reflowable", was bedeutet, dass sich der Text an die Größe des Bildschirms anpasst, auf dem er gelesen wird. Auf einem kleineren Bildschirm werden die Sätze gekürzt und die Gesamtzahl der Seiten erhöht; Genau das Umgekehrte passiert auf einem Gerät mit einem größeren Bildschirm. Kurz gesagt, das Layout eines eBooks hängt von dem Gerät ab, das der Leser verwendet.',
  'bookstep-Manuscript-tips-epub-usingword-label': 'Bei der Verwendung von Word',
  'bookstep-Manuscript-tips-epub-usingword': 'Sie können ePubs mit Word erstellen, aber es gibt ein paar Regeln, die Sie dabei beachten sollten, um sicherzustellen, dass alles klappt. Erstens: Wenn Sie einen Seitenumbruch einfügen wollen, drücken Sie nicht einfach die Eingabetaste! Wenn Sie das tun, werden sie in Ihrem ePub als Leerzeilen angezeigt, was bedeutet, dass die Leser große Lücken im Text sehen werden. Wir empfehlen Ihnen, unseren Leitfaden zur Erstellung einer ePub-Datei mit Word zu lesen, bevor Sie beginnen. Sie können ihn lesen, indem Sie im oberen Bereich auf dieser Seite auf "Manuskript formatieren" klicken.',
  'bookstep-Manuscript-tips-epub-images-label': 'Bilder hinzufügen',
  'bookstep-Manuscript-tips-epub-images': 'Sie möchten in Ihrem eBook Bilder hinzufügen? Kein Problem! Gerne erinnern wir Sie jedoch daran, dass Sie bei der Erstellung Ihrer ePub-Datei in Word darauf achten müssen, dass Ihre Bilder "in einer Linie mit dem Text" und nicht im "Textumbruch" stehen. Sie können dies mit der Option "Position" auf der Registerkarte "Bildformatierung" in Word überprüfen und ändern.',
  'bookstep-Manuscript-tips-epub-cover-label': 'Einbindung des Covers in die ePub-Datei im Vergleich zur separaten Erstellung',
  'bookstep-Manuscript-tips-epub-cover': 'Wenn Sie eine ePub-Datei hochladen, haben Sie die Möglichkeit, ein Cover beizufügen, dies ist jedoch nicht zwingend erforderlich. Wenn Ihr Cover unseren Vorgaben entspricht, wird es automatisch integriert und im Schritt der Umschlaggestaltung angezeigt. Wenn Sie Ihr Manuskript als Word-Datei hochladen, ist dies nicht möglich, und Sie müssen Ihren Umschlag im Schritt “Cover-Designer” hochladen oder erstellen.',
  'bookstep-Manuscript-tips-epub-calibre-label': 'Calibre: Die Erstellung einer perfekten ePub-Datei',
  'bookstep-Manuscript-tips-epub-calibre': 'Wenn Sie Ihr eigenes ePub von Grund auf erstellen möchten, empfehlen wir Ihnen Calibre. Es ist ein benutzerfreundliches Programm, mit dem Sie eine ePub-Datei erstellen können. Sie können sogar Word-Dokumente importieren und sie als Basis für Ihr ePub verwenden. Weitere Informationen finden Sie in unseren Anleitungen zur Erstellung von ePub-Dateien mit Calibre.',
  'bookstep-Manuscript-tips-epub-checkepub-label': 'Überprüfen Sie Ihre ePub-Datei!',
  'bookstep-Manuscript-tips-epub-checkepub': 'Die ePub-Datei, welche wir nach dem Hochladen Ihres Manuskripts erstellen, ist die endgültige Version. Das bedeutet, dass dies die Version ist, welche Ihre Leser erhalten, wenn sie Ihr eBook kaufen. Deshalb ist es wichtig, dass Sie diese Datei gründlich überprüfen. Verwenden Sie dazu eine zuverlässige ePub-Software, wie Calibre, Adobe Digital Editions, Aldiko (für Android) oder Apple Books (für iOS). Sie können dafür auch einen E-Reader (z. B. ein Kindle) verwenden.',

  'bookstep-Manuscript-popup-bookspecs-bookspecs': 'Buchspezifikationen',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word': 'Microsoft Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word-description': 'Spezifikationen für Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof': 'Professionelles Layoutprogramm',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof-description': 'Zum Beispiel, inDesign oder QuarkXPress',
  'bookstep-Manuscript-popup-bookspecs-manuscriptspecs': 'Manuskriptanforderungen',
  'bookstep-Manuscript-popup-bookspecs-pagesize': 'Seitenformat (exkl. Beschnitt): {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-inclbleed': 'Seitenformat inkl. Beschnitt: {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-nospecificreq': 'keine spezifischen Anforderungen',
  'bookstep-Manuscript-popup-bookspecs-bleed': 'Beschnitt: {bleeds}',
  'bookstep-Manuscript-popup-bookspecs-bleed-nobleedreq': 'ohne Beschnitt',
  'bookstep-Manuscript-popup-bookspecs-bleed-top': 'Oben',
  'bookstep-Manuscript-popup-bookspecs-bleed-bottom': 'Unterseite',
  'bookstep-Manuscript-popup-bookspecs-bleed-outside': 'Außen',
  'bookstep-Manuscript-popup-bookspecs-bleed-inside': 'Innen',
  'bookstep-Manuscript-popup-bookspecs-nopagenumheadfoot': 'Keine Seitenzahlen, Kopf- oder Fußzeilen addieren',
  'bookstep-Manuscript-popup-bookspecs-imagesinline': 'Bilder müssen als “TEXT IN ZEILE” positioniert werden.',
  'bookstep-Manuscript-popup-bookspecs-uploadspecs': 'Upload-Anforderungen',
  'bookstep-Manuscript-popup-bookspecs-fileformats': 'Dateiformat: {formats}',
  'bookstep-Manuscript-popup-bookspecs-noprintmarks': 'Ohne Schnittmarken, aber inkl. Beschnit',
  'bookstep-Manuscript-popup-bookspecs-nospreads': 'Keine “Buch-Option’ bei der Seiteneinrichtung in Word einstellen',
  'bookstep-Manuscript-popup-bookspecs-imagesdpi': 'Bilder: min. {dpinum} dpi',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate': 'Word-Vorlage',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate-p1': 'Wenn Sie Ihr Manuskript in Microsoft Word formatieren, empfehlen wir Ihnen dringend, unsere Vorlage zu verwenden. Diese Vorlage ist für Ihr Buch maßgeschneidert und enthält bereits die richtigen Formatierungseinstellungen. Klicken Sie auf die untenstehende Schaltfläche, um die Vorlage herunterzuladen.',
  'bookstep-Manuscript-popup-bookspecs-word-downloadtemplate': 'Vorlage herunterladen',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins': 'Ränder und Seitengröße festlegen',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p1': 'Wenn Sie unsere Vorlage verwenden, müssen Sie sich nicht um Ränder oder Beschnitt kümmern. Die richtigen Einstellungen sind bereits auf das Dokument angewendet worden.',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p2': 'Wenn Sie nicht unsere Vorlage verwenden, müssen Sie sicherstellen, dass die Seitengröße Ihres Manuskripts mit den Abmessungen des von Ihnen gewählten Buchformats übereinstimmt. Ist dies nicht der Fall, werden wir die Seitengröße Ihrer hochgeladenen Datei automatisch an diese Maße anpassen. Dies kann zu Änderungen im Layout Ihres Buches führen. Sie müssen auch den Beschnitt berücksichtigen und ihn zu den Rändern hinzufügen.',
  'bookstep-Manuscript-popup-bookspecs-word-exampleimage-representsbleed': 'repräsentiert Beschnitt',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed': 'Was ist Beschnitt?',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed-p1': 'Um zu verhindern, dass beim Schneiden der Seiten unerwünschter weißer Raum am Rand entsteht, muss die Hintergrundfarbe oder das Bild etwas größer sein als die tatsächliche Seitengröße. Diesen zusätzlichen Rand wird als " Beschnitt " bezeichnet. Wenn Sie keine Farben oder Bilder haben, die bis zum Seitenrand reichen, führt das Vernachlässigen des Beschnitts nicht zu unbeabsichtigtem Weißraum, aber es kann zu leichten Unterschieden in der Formatierung Ihres Buches führen, da wir Ihr Dokument unter Umständen automatisch vergrößern müssen, um den Beschnitt hinzuzufügen. Sie können alle Änderungen in der Druckvorschau sehen, nachdem Sie Ihre Manuskriptdatei hochgeladen haben.',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-odd': 'Linke Seite (ungerade)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-even': 'Linke Seite (gerade)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-odd': 'Rechte Seite (ungerade)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-even': 'Rechte Seite (gerade)',
  'bookstep-Manuscript-popup-services-needhelp': 'Brauchen Sie Hilfe?',
  'bookstep-Manuscript-popup-services-doyouneedsupport': 'Brauchen Sie Hilfe bei Ihrem Buch?',
  'bookstep-Manuscript-popup-services-weofferpaidservices': 'Wir bieten eine Reihe von bezahlbaren Lektoratsdiensten an, wenn Sie Hilfe bei der Erstellung des perfekten Buches benötigen. Unser professionelles Team hilft Ihnen beim Korrekturlesen, der Rechtschreibprüfung, dem Lektorat, dem Layout und der Formatierung, der Erstellung einer professionellen ePub-Datei und vielem mehr.',
  'bookstep-Manuscript-popup-services-areyouinterested': 'Kontaktieren Sie uns für weitere Informationen. Wir werden uns innerhalb von 2 Werktagen mit Ihnen in Verbindung setzen.',
  'bookstep-Manuscript-popup-services-contactme': 'Kontakt aufnehmen',
  'bookstep-Manuscript-popup-services-requestsent': 'Anfrage erhalten',
  'bookstep-Manuscript-popup-services-wehavesentrequest': 'Wir haben Ihre Anfrage erhalten',
  'bookstep-Manuscript-popup-services-wewillcontactyousoon': 'Wir haben Ihre Anfrage nach weiteren Informationen über unsere Bearbeitungsdienste erhalten. Unser Team wird Sie innerhalb von 2 Werktagen mit weiteren Informationen kontaktieren.',

  'bookstep-Manuscript-guidepopup-wordtips': 'Tipps für Microsoft Word',
  'bookstep-Manuscript-guidepopup-wordtips-description': 'Tipps, wie Sie Ihr Manuskript mit der Verwendung von  Microsoft Word formatieren',
  'bookstep-Manuscript-guidepopup-pdffontsincl': 'PDF erstellen',
  'bookstep-Manuscript-guidepopup-pdffontsincl-description': 'Wie Sie eine PDF-Datei erstellen',
  'bookstep-Manuscript-guidepopup-createepub': 'ePub erstellen',
  'bookstep-Manuscript-guidepopup-createepub-description': 'Wie Sie eine ePub-Datei erstellen',
  'bookstep-Manuscript-guidepopup-colorpages': 'Farbige Seiten',
  'bookstep-Manuscript-guidepopup-colorpages-description': 'Farbige Seiten: alles, was Sie wissen müssen',

  // Bookstep: Writing
  'bookstep-Writing': 'Schreiben',
  'bookstep-Writing-intro-p1': 'Ein Buch zu schreiben ist eines der schönsten und lohnenswertesten Ziele, das man sich vornehmen kann. Gleichzeitig kann der Weg dorthin hart und steinig sein. Auch wenn wir Ihr Buch nicht für Sie schreiben können, ist es uns ein Anliegen Sie während des Prozesses zu unterstützen. Unabhängig davon, ob Sie Belletristik oder Sachbücher schreiben, ist es unerlässlich, dass Sie Ihren Schreibprozess strukturieren. Die meisten Autoren, die das nicht tun, fangen gut an und geraten zu einem späteren Zeitpunkt aber in eine Sackgasse. Tun Sie sich also einen Gefallen: Machen Sie sich einen Plan und halten Sie sich daran. Sie wissen nicht, wie? Gerne verweisen wir Sie an unseren Leitfaden.',
  'bookstep-Writing-helpwithwriting-title': 'Unterstützung beim Schreiben',
  'bookstep-Writing-createwritingplan-title': 'Leitfaden zum Schreiben',
  'bookstep-Writing-writeblurb-title': 'Schreiben Sie Ihren Klappentext',
  'bookstep-Writing-writeblurb-p1': 'Wir empfehlen, mit dem Verfassen des Klappentextes zu beginnen. Auf diese Weise sind Sie gezwungen, das große Ganze zu betrachten. Sie werden zweifellos mehr als eine Version Ihres Klappentextes schreiben. Teilen Sie die Version mit anderen und bitten Sie um Feedback.',
  'bookstep-Writing-writeblurb-p2': 'Auch wenn ein Klappentext nicht sehr lang ist, so ist es doch von unschätzbarem Wert, das Wesentliche Ihres Buches in ein paar Sätzen zusammenzufassen. Er hilft Ihnen, einen konkreten Schreibplan zu erstellen und dient als Grundlage für den Schreibprozess selbst. Außerdem kann der Klappentext über Erfolg oder Misserfolg eines Buches entscheiden, denn er ist einer der wichtigsten Faktoren, die Leser/innen bei ihrer Entscheidung beeinflussen, ob sie Ihrem Buch eine Chance geben wollen oder nicht.',
  'bookstep-Writing-writeblurb-howtowriteblurb': 'Wie man einen guten Klappentext schreibt',
  'bookstep-Writing-writeblurb-howtowriteblurb-p1': 'Das Beurteilen von bereits vorhandenen Klappentexten ist eine gute Methode, um zu lernen, wie man einen guten Klappentext schreibt. So bekommen Sie eine bessere Vorstellung davon, was die besten Methoden für einen Klappentext in dem von Ihnen gewählten Genre sind. Unabhängig von der Art des Buches, das Sie schreiben, gibt es jedoch einige allgemeine Kriterien, die jeder Klappentext erfüllen sollte:',
  'bookstep-Writing-writeblurb-howtowriteblurb-list1': 'Ein fesselnder erster Satz: Sie müssen den Leser von Anfang an fesseln. Dies kann durch ein besonders vielversprechendes Zitat, eine spannende Frage oder eine Lösung für ein Problem erreicht werden.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list2': 'Eine Zusammenfassung: Sie wollen dem Leser einen Überblick über die Geschichte geben, ohne die wichtigsten Punkte der Handlung zu verraten.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list3': 'Ein Versprechen: Der Leser möchte wissen, was er von Ihrem Buch erwarten kann: Wenn Sie Belletristik schreiben, bauen Sie Spannung auf oder reizen Sie ein Drama an. Wenn Sie ein Sachbuch schreiben, bieten Sie Lösungen für Probleme oder Wissen über ein bestimmtes Thema an.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list4': 'Über den Autor: Besonders wichtig bei Sachbüchern. Stellen Sie sicher, dass Sie Informationen über sich selbst angeben, die für das Buch relevant sind: Erfahrungen aus dem wirklichen Leben, andere Bücher, die Sie geschrieben haben, usw.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list5': 'Autorenfoto: Dies ist optional, aber viele, die sich für ein Foto entscheiden, machen es falsch. Achten Sie darauf, dass Sie ein Foto mit einer ausreichend hohen Auflösung (300 dpi) verwenden, da sonst die Gefahr besteht, dass Ihr Buch unprofessionell wirkt.',
  'bookstep-Writing-createplan-title': 'Einen Schreibplan erstellen',
  'bookstep-Writing-createplan-p1': 'Wie alle kreativen Prozesse kann auch das Schreiben nicht immer erzwungen werden. Dennoch legen wir Ihnen stark ans Herz, einen Schreibplan zu erstellen. Unterteilen Sie diesen Plan in zwei Teile:',
  'bookstep-Writing-createplan-list1': 'Die Planung: Wann werden Sie schreiben? Wie lange wird jede Schreib-Session dauern? Diese Entscheidungen sind Ihnen überlassen. Manche Schriftsteller/innen stehen früh auf, um etwas zu schreiben, während andere Nachteulen sind, die erst schreiben können, wenn sie andere Aufgaben erledigt haben. Erstellen Sie eine Struktur für Ihr Buch, unterteilen Sie es in Kapitel und machen Sie einen Plan für jedes Kapitel. Sie brauchen auch nicht von vorne anzufangen, sondern können an jeder beliebigen Stelle der Geschichte einsteigen, solange es Sie zum Schreiben anregt.',
  'bookstep-Writing-createplan-list2': 'Handlung: Arbeiten Sie zunächst die Grundzüge Ihrer Geschichte aus; Welche Figuren werden Sie einführen und wie werden sie sich entwickeln? Wie werden Sie sich auf die wichtigsten Punkten der Handlung vorarbeiten? Die beliebteste Struktur für ein perfektes Handlungsgerüst ist wahrscheinlich "die Heldenreise". Das gilt sogar für Sachbücher. Die Leserschaft folgt gerne einem Protagonisten, der Hindernisse überwindet und als Mensch wächst.',
  'bookstep-Writing-writefeedbackrewrite-title': 'Schreiben, schreiben, Feedback, umschreiben',
  'bookstep-Writing-writefeedbackrewrite-p1': 'Wenn es Ihnen gelungen ist, einen Entwurf Ihrer Handlung zu erstellen, ist es an der Zeit, die Lücken auszufüllen. Jetzt beginnt das eigentliche Schreiben Ihres Buches. Lassen Sie sich nicht entmutigen; Sie müssen sich nicht Kapitel für Kapitel durcharbeiten. Sie können genauso gut in der Mitte oder am Ende beginnen, oder dort, wo Sie gerade am meisten zum Schreiben inspiriert sind. Diese Methode ist weitaus produktiver und motivierender, als sich zu zwingen, alles der Reihe nach zu schreiben.',
  'bookstep-Writing-writefeedbackrewrite-p2': 'Sobald Sie ein paar Kapitel fertiggestellt haben, raten wir Ihnen, Ihre Arbeit Freunden und/oder Verwandten zum Lesen zu geben und Sie um ein ehrliches Feedback zu bitten. Achten Sie darauf, dass Sie Korrekturleser/innen auswählen, die vor konstruktiver Kritik nicht zurückschrecken; Schmeicheleien werden Ihnen hier nichts nützen. Es gibt ein paar Dinge, die Sie in Bezug auf Ihren Schreibstil im Hinterkopf behalten sollten:',
  'bookstep-Writing-writefeedbackrewrite-list1': 'Schreiben Sie in der Ich-Perspektive oder in der dritten Person?',
  'bookstep-Writing-writefeedbackrewrite-list2': 'Gegenwart oder Vergangenheitsform?',
  'bookstep-Writing-writefeedbackrewrite-list3': 'Konsistenz ist der Schlüssel',
  'bookstep-Writing-writefeedbackrewrite-list4': 'Zeigen, nicht erzählen',
  'bookstep-Writing-writefeedbackrewrite-list5': 'Bauen Sie Spannung und Atmosphäre auf, um Ihre Leser zu fesseln',
  'bookstep-Writing-feedbackediting-title': 'Feedback und Bearbeitung',
  'bookstep-Writing-feedbackediting-p1': 'Schreiben ist eine Kunstform, und Künstler neigen dazu, sich ihrer eigenen Arbeit gegenüber unsicher zu sein. Lassen Sie sich jedoch nicht von Selbstzweifeln entmutigen, das ist ein Teil, der zum Schreibprozess dazugehört. Nutzen Sie dieses Gefühl zu Ihrem Vorteil, indem Sie Ihre Korrekturleser um konstruktive Kritik bitten; Ihre Geschichte wird dadurch besser werden.',
  'bookstep-Writing-feedbackediting-p2': 'Sobald Ihr Manuskript fertig ist, sollten Sie es von so vielen Personen wie möglich Korrektur lesen lassen, vielleicht im Austausch gegen einen Kaffee. Denken Sie daran, dass die meisten Menschen immer noch lieber auf Papier lesen. Es könnte also ratsam sein, einige Kopien Ihres Manuskripts für die Korrekturleser auszudrucken, die dies tun. Die meisten Menschen fühlen sich geschmeichelt, wenn man sie um Korrekturlesen bittet, und sind gerne bereit zu helfen.',
  'bookstep-Writing-feedbackediting-p3': 'Es ist wichtig, dass Ihr Manuskript frei von Rechtschreib- und Grammatikfehlern ist. Nichts gefährdet den Eindruck, den die Leserschaft von Ihrem Text hat, so sehr, wie ein Rechtschreibfehler. Je nach Ihren Ambitionen und Mitteln können Sie unser Lektorat oder unseren Schnellscan in Anspruch nehmen. Ein Experte liest dann, je nach Ihrem Wunsch, entweder Ihr ganzes Manuskript oder einen Teil davon und gibt Ihnen Feedback bezüglich Stil, Kohäsion, Handlung, Charakterentwicklung usw.',
  'bookstep-Writing-nextstep': 'Nächster Schritt: Formatierung',
  'bookstep-Writing-uploadnow': 'Sofort hochladen',
  'bookstep-Writing-tips-title': 'Tipps zum Schreiben',
  'bookstep-Writing-tips-titlesubtitle-label': 'Titel und Untertitel',
  'bookstep-Writing-tips-titlesubtitle': 'Haben Sie bereits einen Titel und einen Untertitel für Ihr Buch im Kopf? Dann können Sie diesen Abschnitt überspringen, aber die meisten Menschen haben Schwierigkeiten, sich für einen Titel zu entscheiden. Wir empfehlen Ihnen, in einem Brainstorming einfach alle Ihre Titelideen aufzuschreiben. Wenn Sie alle Möglichkeiten auf einen Blick vor sich haben, werden sich wahrscheinlich schnell die Hauptkandidaten oder sogar einen eindeutigen Gewinner herauskristallisieren. Wir empfehlen Ihnen, die drei besten Titelkandidaten Ihren Freunden oder Social-Media-Followern zu präsentieren und sie zu fragen, welchen Titel sie bevorzugen. Letztendlich werden die Leute drei Dinge berücksichtigen, wenn sie entscheiden, ob sie Ihr Buch kaufen wollen: das Titelbild (einschließlich des Titels), den Klappentext und die Vorschau, also lohnt es sich, die Meinung verschiedener Leute zu diesen zentralen Aspekten Ihres Buches einzuholen.',
  'bookstep-Writing-tips-blurb-label': 'Klappentext',
  'bookstep-Writing-tips-blurb': 'Wenn Sie es schaffen, das Interesse der Leserschaft mit Ihrem Titelbild zu wecken, wird sie sich wahrscheinlich als Nächstes den Klappentext ansehen. Dies ist der Schlüsselmoment im Entscheidungsprozess Ihrer Kunden und kann über einen Verkauf entscheiden. Unabhängig davon, ob es sich bei Ihrem Buch um einen Roman oder ein Sachbuch handelt, gibt es ein paar Dinge, die Ihre potenziellen Leser/innen interessieren werden: Ist der Text ansprechend, gibt es ein Rätsel, welches das Buch lösen wird, oder gibt es eine interessante Frage, die Ihr Werk aufwirft und die sie neugierig macht? Der Klappentext muss Lust auf mehr machen. Wenn Ihnen das gelingt, stehen die Chancen gut, dass Ihr Buch gekauft wird. Lassen Sie also Ihren Klappentext von anderen Korrektur lesen und fragen Sie sie nach ihrem Feedback: Würden sie Ihr Buch aufgrund des Klappentextes kaufen?',
  'bookstep-Writing-tips-howdoyoufeelabouit-label': 'Rückmeldung erhalten',
  'bookstep-Writing-tips-howdoyoufeelabouit': 'Lassen Sie Ihre Arbeit frühzeitig von anderen Korrektur lesen. Ihr Manuskript muss noch nicht fertig sein, um wertvolles Feedback erhalten zu könneb. Ob Sie nun eine einzelne Seite oder mehrere Kapitel lesen lassen, bitten Sie um ehrliches Feedback. In der Regel freuen sich die Leute, Ihnen helfen zu können und Ihre Arbeit zu lesen. Sie könnten auch ein Kapitel auf einer Social-Media-Plattform Ihrer Wahl veröffentlichen und auf diese Weise um Feedback bitten. Mit dieser Methode schlagen Sie zwei Fliegen mit einer Klappe: Sie erhalten Feedback und machen gleichzeitig Werbung für Ihr Buch.',
  'bookstep-Writing-tips-marketing-label': 'Vermarktung',
  'bookstep-Writing-tips-marketing': '“Marketing? Ich habe mein Buch noch nicht einmal fertiggestellt!” Ganz genau. Jetzt ist der richtige Zeitpunkt, um darüber nachzudenken, wie Sie Ihr Buch vermarkten wollen. In diesem frühen Stadium können Sie klein anfangen, indem Sie etwas Interesse für Ihr Buch wecken. Teilen Sie Ihre Reise als Autor/in in den sozialen Medien, erzählen Sie, wie es Ihnen geht und welchen Schwierigkeiten Sie begegnen. Teilen Sie Ihren Umschlag in den sozialen Medien, sobald er fertig ist. Posten Sie Auszüge aus Ihren fertigen Kapiteln. Wahrscheinlich werden Sie viel Zuspruch erhalten. Dies sind nur einige Anregungen, die Sie in Ihren Marketingplan aufnehmen könnten. Vernachlässigen Sie die Buchvermarktung nicht, denn so bauen Sie sich ein Netzwerk von Anhängern und Botschaftern auf, das von unschätzbarem Wert sein wird, wenn Sie versuchen, Ihr Buch nach der Veröffentlichung bekannt zu machen.',
  'bookstep-Writing-tips-support-label': 'Wir unterstützen Sie',
  'bookstep-Writing-tips-support': 'Sie stehen auf dem Schlauch und brauchen Hilfe, oder Sie möchten einfach nur ein professionelles Feedback zu Ihrem Manuskript? Wir bieten mehrere erschwingliche Servicepakete an, mit denen Sie Ihr Manuskript an einen professionellen Autor, Lektor oder Coach schicken können, um es von diesem verbessern zu lassen. Sie können zwischen einem Schnelldurchlauf, einer vollständige Überprüfung, einer Rechtschreibprüfung, einem Lektorat und sogar Workshops auswählen. Wenn es Ihnen mit Ihrem Buch wirklich ernst ist, sollten Sie Ihr Netzwerk einbeziehen und/oder einen unserer Lektoren beauftragen, der Ihnen hilft, das bestmögliche Buch zu schreiben.',
  'bookstep-Writing-tips-dontgiveup-label': 'Geben Sie nicht auf!',
  'bookstep-Writing-tips-dontgiveup': 'So gut wie jeder Autor stößt hin und wieder auf ein Hindernis: Ernüchterung, Schreibblockade, was auch immer. Geben Sie nicht auf! Wenn nötig, machen Sie eine Pause und beschäftigen Sie sich eine Weile mit etwas anderem. Gehen Sie spazieren, reden Sie mit Leuten, lenken Sie sich auf andere Weise ab. Ihre Motivation wird früh genug zurückkommen.',

  // Bookstep: Formatting
  'bookstep-Formatting': 'Formatierung',
  'bookstep-Formatting-intro-p1': 'Der Text ist natürlich der wichtigste Teil Ihres Manuskripts, aber ohne eine gute Formatierung wird ihn wahrscheinlich niemand lesen wollen, unabhängig von seiner Qualität. Ein gut formatiertes Manuskript lässt sich mühelos lesen und ermöglicht es der Leserschaft, sich in Ihrer Geschichte zu verlieren, ohne abgelenkt oder verwirrt zu werden. Sie können Ihr Manuskript natürlich selbst formatieren, aber Sie können diese Arbeit auch einem Fachmann überlassen. Wenn Sie zu Letzterem tendieren, sollten Sie einen Blick auf unseren Formatierungsservice werfen. Möchten Sie es lieber selbst tun? Dann lesen Sie unseren Leitfaden, damit Sie das bestmögliche Ergebnis erzielen.',
  'bookstep-Formatting-helpwithformatting-title': 'Unterstützung bei der Formatiering',
  'bookstep-Formatting-guide-title': 'Leitfaden zur Formatierung',
  'bookstep-Formatting-guide-print-template-title': 'Vorlage',
  'bookstep-Formatting-guide-print-template-p1': 'Wenn Sie vorhaben, Ihr Manuskript selbst zu formatieren, empfehlen wir Ihnen, mit einem Dokument zu arbeiten, das zuvor korrekt eingerichtet wurde. Das spart Ihnen später Zeit und Mühe. Wenn Sie mit Word arbeiten, empfehlen wir Ihnen, unsere Vorlagen zu verwenden, die auf die Spezifikationen des von Ihnen gewählten Buchtyps abgestimmt sind. Sie berücksichtigen auch Titel- und Kolophonseiten, Seitenzahlen und Ränder. Wenn Sie Ihr Manuskript bereits fertiggestellt haben, können Sie den Text einfach in die Vorlage kopieren - kopieren Sie jedoch nicht alles auf einmal! Dadurch werden die Einstellungen der Vorlage überschrieben, und das würde natürlich den Zweck der Vorlage zunichte machen.',
  'bookstep-Formatting-guide-print-template-p2': 'Wenn Sie Ihr Manuskript mit einem professionellen Formatierungsprogramm wie InDesign erstellen, richten Sie Ihr Dokument gemäss den Vorgaben des gewählten Buchformats ein. Achten Sie darauf, dass an den Außenkanten jeder Seite ein Rand von 3 mm vorhanden ist (ohne die Innenränder, das heißt die rechte Seite der geraden Seiten und die linke Seite der ungeraden Seiten). Die genauen Spezifikationen für Ihr Buch erhalten Sie, wenn Sie oben auf die Schaltfläche “Buchspezifikationen” klicken.',
  'bookstep-Formatting-guide-print-template-p3': 'Denken Sie daran: eine ungerade Seite ist immer rechts und eine gerade Seite immer links platziert.',
  'bookstep-Formatting-guide-print-fonts-title': 'Schriftarten, Schriftgrössen und Zeilenabstände ',
  'bookstep-Formatting-guide-print-fonts-p1': 'Wir werden Ihnen nicht vorschreiben, welche Schriftart Sie verwenden sollen. Das ist eine persönliche Entscheidung und variiert von Buch zu Buch. Als Erstes müssen Sie sich entscheiden, ob Sie eine serifenbetonte oder eine serifenlose Schriftart verwenden möchten. Serifen sind die kleinen Linien, die an den Enden der Buchstaben angebracht sind und gelten allgemein als hilfreich, um gedruckten Text besser lesbar zu machen. Serifenschriften sind die klassische Wahl für gedruckte Bücher und Zeitungen. Beliebte Serifenschriften sind Cambria, Garamond, Georgia und Times New Roman.',
  'bookstep-Formatting-guide-print-fonts-p2': 'Serifenlose Schriftarten haben diese Linien nicht, was eine moderneren, minimalistischen Ästhetik aufweist. Diese Schriften werden häufig im Internet verwendet und gelten in diesem Fall als besser lesbar als Schriften mit Serifen. Beliebte serifenlose Schriftarten sind Arial, Calibri, Helvetica und Verdana.',
  'bookstep-Formatting-guide-print-fonts-p3': 'Außerdem müssen Sie sich für eine Schriftgröße und einen Zeilenabstand entscheiden. Auch hier gibt es keine richtig oder falsch; es kommt ganz auf die persönliche Vorliebe an. Im Allgemeinen sind die gängigsten Schriftgrößen 10, 11 und 12, je nach verwendeter Schriftart. Wenn sich Ihr Buch an ein älteres Publikum richtet, sollten Sie eine größere Schriftgröße und einen größeren Zeilenabstand verwenden (üblich sind Zeilenabstände zwischen 1,1 und 1,3). Drucken Sie ein paar Seiten Ihres Manuskripts aus, um zu prüfen, ob Sie mit Ihrer Schriftart und dem Zeilenabstand zufrieden sind.',
  'bookstep-Formatting-guide-print-moreoptions-title': 'Noch mehr Formatierungsmöglichkeiten',
  'bookstep-Formatting-guide-print-moreoptions-p1': 'Wir sind noch nicht fertig; es gibt noch einige Entscheidungen, die Sie treffen müssen, bevor Sie mit Ihrem Buch fortfahren. Hier ist eine Liste mit den wichtigsten Entscheidkriterien:',
  'bookstep-Formatting-guide-print-moreoptions-list1': 'Seitenlayout: Hier müssen Sie vor allem die Ränder beachten. Achten Sie darauf, dass sie nicht zu schmal sind; sie sollten etwa 2 cm betragen, mit etwas mehr Platz für den Bundsteg (die nach innen weisenden Seiten, an denen das Buch zusammengebunden wird). Ein Teil der Innenränder der Seiten wird verschwinden, da sie zum Binden des Buches verwendet werden. Unsere Vorlagen sind entsprechend ausgerichtet, so dass Sie sich keine Gedanken über die Ränder machen müssen. Wenn Sie ohne unsere Vorlagen arbeiten möchten, sollten Sie die folgenden Ränder verwenden:',
  'bookstep-Formatting-guide-print-moreoptions-list1-topmargin': 'Oberer Rand: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-bottommargin': 'Unterer Rand: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-innermargin': 'Innerer Rand: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-outermargin': 'Äußerer Rand: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list2': 'Neue Kapitel: Beginnen Sie jedes neue Kapitel auf einer neuen Seite. Sie können Ihren Kapiteln Namen, Nummern oder beides geben. Vergewissern Sie sich bei der Verwendung von Word, dass Sie die Formatvorlage "Kopfzeile 1" für Ihre Kapitelüberschrift/-nummer verwenden. Auf diese Weise stellen Sie sicher, dass die Formatierung für jedes Kapitel gleich ist.',
  'bookstep-Formatting-guide-print-moreoptions-list3': 'Absätze: Sie können die Absätze durch Leerzeilen trennen, was zu einem angenehmen Leseerlebnis führt. Sie können alternativ auch das erste Wort eines jeden Absatzes einrücken. Wie auch immer Sie sich entscheiden, achten Sie darauf, dass Ihre Absätze nicht zu lang sind, da dies für die Lesbarkeit Ihres Textes abträglich ist.',
  'bookstep-Formatting-guide-print-moreoptions-list4': 'Bilder und Text: Wenn der Inhalt Ihres Buches abwechselnd aus Text und Bildern besteht, wie z. B. in einem Kochbuch, dann platzieren Sie Ihre Bilder auf geraden Seiten (links) und Ihren Text auf ungeraden Seiten (rechts).',
  'bookstep-Formatting-guide-print-uploadscript-title': 'Ihr Manuskript hochladen',
  'bookstep-Formatting-guide-print-uploadscript-p1': 'Selbst wenn Sie Ihr Manuskript in Word geschrieben haben, empfehlen wir Ihnen, es vor dem Hochladen in eine PDF-Datei zu konvertieren. Dadurch wird das Risiko von Formatierungsfehlern während des Konvertierungsprozesses minimiert. Achten Sie darauf, dass jede Seite Ihres Manuskripts auch in der konvertierten PDF-Datei eine separate Seite ist (verwenden Sie keine sogenannten ’Spreads’), sonst wird es nicht richtig angezeigt.',
  'bookstep-Formatting-guide-print-uploadscript-p2': 'Sobald Sie Ihr Manuskript hochgeladen haben, konvertieren wir es in eine druckfertige PDF-Datei. Überprüfen Sie diese Datei gründlich; kontrollieren Sie alle Seiten, um sicherzustellen, dass sie richtig formatiert sind.',
  'bookstep-Formatting-guide-print-uploadscript-p3': 'Immer noch nicht zufrieden? Überhaupt kein Problem. Sie können eine neue Version Ihres Manuskripts so oft hochladen, wie Sie möchten. Dabei werden alle früheren Versionen, die Sie hochgeladen haben, überschrieben.',
  'bookstep-Formatting-guide-epub-howepubswork-title': 'Wie funktioniert eine ePub-Datei?',
  'bookstep-Formatting-guide-epub-howepubswork-p1': 'Bevor Sie sich mit der Gestaltung Ihres eBook an die Arbeit machen, sollten Sie wissen, wie eine ePub-Datei tatsächlich funktioniert, denn es gibt einige wichtige Unterschiede zwischen eBooks und anderen digitalen Dokumenten. So kann es sein, dass das, was Sie mit Ihrer Textverarbeitungssoftware erstellt haben, beim Lesen auf einem E-Reader nicht wie gewünscht erscheint.',
  'bookstep-Formatting-guide-epub-howepubswork-p2': 'Erstens sind eBooks, die über unsere Plattform veröffentlicht werden, immer im ePub-Format. Dies ist das weltweite Standardformat für eBooks, das heisst, dass es auf jedem E-Reader gelesen werden kann. Eine der wichtigsten Eigenschaften von ePub-Dateien ist, dass sie “reflowable” sind. Das bedeutet, dass sich der Text in der Datei an die Größe des Bildschirms anpasst, auf dem er gelesen wird. Auf einem kleineren Bildschirm werden die Sätze gekürzt und das Buch hat daher mehr Seiten als auf einem größeren Bildschirm. Das bedeutet, dass das Layout eines eBooks von dem Gerät abhängt, auf dem es gelesen wird.',
  'bookstep-Formatting-guide-epub-howepubswork-p3': 'So kann ein Textabschnitt in Word auf einer einzigen Seite erscheinen, auf einem E-Reader aber auf mehrere Seiten aufgeteilt sein. Kümmern Sie sich nicht um die exakte Positionierung von Zeilen und Absätzen, sondern achten Sie nur darauf, dass der Text einen logischen Fluss hat und der Abstand zwischen den Absätzen korrekt ist. So wird für die meisten Leser/innen ein optimales Ergebnis erzeugt.',
  'bookstep-Formatting-guide-epub-templatefonts-title': 'Vorlagen und Schriftarten',
  'bookstep-Formatting-guide-epub-templatefonts-p1': 'Wenn Sie Ihr eBook in Microsoft Word formatieren, empfehlen wir Ihnen, eine unserer Word-Vorlagen zu verwenden. Diese Vorlagen sind so aufgebaut, dass sie ein gutes eBook-Layout mit minimalem Aufwand gewährleisten, was weniger Arbeit für Sie bedeutet. Sie können unsere Vorlagen herunterladen, indem Sie oben auf die Schaltfläche “Word-Vorlage” klicken.',
  'bookstep-Formatting-guide-epub-templatefonts-p2': 'Schriftarten sind ein weiterer Punkt, über den Sie bei eBooks nicht zu viele Gedanken machen sollten. Bei den meisten E-Readern können die Benutzer/innen ihre eigenen Schriftarten auswählen, was bedeutet, dass die Schriftart, für die Sie sich ursprünglich entschieden haben, höchstwahrscheinlich außer Kraft gesetzt wird. Wir raten Ihnen daher, eine Schriftart zu wählen, die Ihnen zusagt und mit der Sie gerne arbeiten. Georgia und Times New Roman sind klassische Serifenschriften, während Arial, Helvetica oder Verdana geeignete serifenlose Schriftarten sind.',
  'bookstep-Formatting-guide-epub-chapterstoc-title': 'Kapitel und das Inhaltsverzeichnis',
  'bookstep-Formatting-guide-epub-chapterstoc-p1': 'Bei der Formatierung Ihres eBooks in Microsoft Word ist es wichtig, die sogenannten Überschriften der Formatvorlagen richtig zu verwenden, z. B. “Überschrift 1”, “Überschrift 2” und so weiter. Diese Formatvorlagen dienen zur Strukturierung Ihres eBooks und zur Erstellung des Inhaltsverzeichnisses während des Konvertierungsprozesses. Im Folgenden finden Sie eine kurze Liste der wichtigsten Formatvorlagen, mit denen Sie vertraut sein sollten:',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-normal': 'Normaler Text: Verwenden Sie diese Formatvorlage für den normalen Text in Ihrem Manuskript.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-title': 'Titel: Verwenden Sie diese Formatvorlage für den Titel Ihres Buches, z. B. auf der Titelseite.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading1': 'Überschrift 1: Verwenden Sie diese Überschrift für Kapitelüberschriften. Im Inhaltsverzeichnis erscheint diese Überschrift an oberster Stelle in der Hierarchie. Wir haben unsere Vorlagen so eingerichtet, dass diese Überschriften immer auf einer neuen Seite erscheinen.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading2': 'Überschrift 2: Verwenden Sie diese Überschrift für Untertitel, z. B. wenn Sie die Kapitel in Abschnitte unterteilen möchten. Im Inhaltsverzeichnis sind sie der Überschrift 1 direkt untergeordnet.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading3': 'Überschrift 3: Diese Überschrift können Sie für den eher unwahrscheinlichen Fall verwenden, dass Sie Ihre Unterabschnitte (unter “Überschrift 2”) in noch kleinere Abschnitte unterteilen wollen. In Romanen kommt dies nur selten vor, aber in wissenschaftlichen Büchern ist dies üblich. ',
  'bookstep-Formatting-guide-epub-chapterstoc-p2': 'Wenn Sie vorhaben, Ihr ePub manuell zu erstellen, funktionieren Kapitel und Überschriften ein wenig anders. Es ist daher wichtig, dass Sie Folgendes tun:',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-separatefiles': 'Stellen Sie sicher, dass jedes Kapitel Ihres ePub in einer separaten HTML-Datei steht. Diese Datei muss dann korrekt in der OPF-Datei gespeichert werden, wo die Reihenfolge der HTML-Dateien festgelegt wird.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh1tags': 'Die Kapitel müssen immer mit einem "h1"-HTML-Tag in Ihrer HTML-Datei beginnen. Dieser h1-Tag sollte den Titel und/oder die Nummer des Kapitels enthalten.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh2h3tags': 'Wenn Sie Zwischenüberschriften verwenden, können Sie h2, h3 oder h4 (usw.) Tags verwenden. Diese funktionieren genauso wie die Überschriften 1, 2, 3 usw. in Word. ',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-generatetoc': 'Stellen Sie sicher, dass Sie ein Inhaltsverzeichnis für Ihre ePub-Datei erstellen. Die meisten ePub-Programme können dies automatisch für Sie tun, indem sie eine "NCX"-Datei erstellen, die Ihrer ePub-Datei hinzugefügt wird.',
  'bookstep-Formatting-guide-epub-moreformattips-title': 'Zusätzliche Tipps zur Formatierung',
  'bookstep-Formatting-guide-epub-moreformattips-p1': 'Zusätzlich zu den bereits erwähnten Tipps gibt es noch ein paar Dinge, die Sie bei der Formatierung Ihres eBooks beachten sollten',
  'bookstep-Formatting-guide-epub-moreformattips-list1': 'Seitennummerierung: Stellen Sie sicher, dass Ihre Word- oder ePub-Datei keine Seitenzahlen enthält. Wie bereits erwähnt, machen die individuellen Einstellungen der einzelnen E-Reader Seitenzahlen überflüssig. Wenn Sie sie einfügen, führt das nur zu Problemen.',
  'bookstep-Formatting-guide-epub-moreformattips-list2': 'Nummerierte Listen: Wenn Sie zufällig eine nummerierte Liste in Ihr eBook einfügen möchten, sollten Sie wissen, dass dies zwar möglich ist, es aber gewisse Einschränkungen gibt. Achten Sie darauf, dass Ihre Liste nicht durch einen Textabschnitt unterbrochen wird und dann später im Text wieder aufgenommen wird (z. B. wenn die Punkte auf Ihrer Liste besonders lang sind). EPubs lassen dies nicht zu, d. h. Ihre Listen müssen immer zusammenhängen.',
  'bookstep-Formatting-guide-epub-moreformattips-list3': 'Bilder: Sie können durchaus Bilder in Ihre ePub-Datei einfügen. Achten Sie darauf, dass die Bilder im JPG- oder PNG-Format vorliegen und die Auflösung nicht zu groß ist (wir empfehlen eine obere Grenze von 1000x1000 Pixel).  ',
  'bookstep-Formatting-guide-epub-moreformattips-list4': 'Umschlag: Achten Sie beim Hochladen eines Word-Dokuments darauf, dass Sie das Cover NICHT in das Word-Dokument einfügen. Das Cover erstellen Sie im nächsten Schritt des Veröffentlichungsprozesses ("Umschlag-Design"). Wenn Sie Ihre eigene ePub-Datei erstellen, können Sie möglicherweise Ihr Cover in die Datei einfügen. Wenn Sie dies in einer ausreichend hohen Auflösung tun, exportiert unser System es automatisch in den Schritt “Umschlag-Design”.',
  'bookstep-Formatting-guide-epub-uploadscript-title': 'Ihr Manuskript hochladen',
  'bookstep-Formatting-guide-epub-uploadscript-p1': 'Sie können Ihr Manuskript mit jedem beliebigen Programm schreiben, aber wir akzeptieren nur Word-Dokumente (doc oder docx) oder ePubs, wenn es an der Zeit ist, ihr Manuskript hochzuladen. Wenn Sie also mit einem anderen Textverarbeitungsprogramm als Microsoft Word arbeiten, müssen Sie die Datei zunächst in eine Word-Datei oder ein ePub konvertieren, bevor Sie sie auf unsere Plattform hochladen können.',
  'bookstep-Formatting-guide-epub-uploadscript-p2': 'Ein Wort der Warnung: Unserer Erfahrung nach funktioniert die Konvertierung von Dateien, die in LibreOffice, Apple Pages oder anderen Textverarbeitungsprogrammen erstellt wurden, in doc- oder docx-Dateien nicht immer reibungslos, was zu Fehlermeldungen führen kann, wenn Sie versuchen, diese konvertierten Dateien hochzuladen.',
  'bookstep-Formatting-guide-epub-uploadscript-p3': 'Nachdem Sie Ihre Datei hochgeladen haben, konvertieren wir sie in ein ePub. Sie können diese ePub-Datei dann mit einem E-Reader oder einer Anwendung, die ePubs lesen kann, überprüfen. Es ist wichtig, dass Sie diese Datei gründlich überprüfen und sicherstellen, dass sie so perfekt wie möglich ist, da dies die Version ist, die wir Ihren Leser/innen zur Verfügung stellen werden.',
  'bookstep-Formatting-guide-epub-uploadscript-p4': 'Wenn Sie Probleme mit der Formatierung Ihres eBooks haben, können Sie jederzeit unseren eBook-Formatierungsservice in Anspruch nehmen. Bitte beachten Sie, dass diese zusätzliche Dienstleistung kostenpflichtig ist.',
  'bookstep-Formatting-guide-pdfebook-template-title': 'Vorlage',
  'bookstep-Formatting-guide-pdfebook-template-p1': 'Wenn Sie vorhaben, Ihr Manuskript selbstständig zu formatieren, empfehlen wir Ihnen dringend, so früh wie möglich mit der Arbeit in einem ordnungsgemäß eingerichteten Dokument zu beginnen; vorzugsweise, wenn Sie mit dem Schreiben Ihres Buches beginnen. Wenn Sie in Microsoft Word arbeiten, raten wir Ihnen, unsere Vorlagen zu verwenden. Falls Sie Ihr Manuskript bereits fertiggestellt haben, können Sie den Inhalt in die Vorlage kopieren, aber achten Sie darauf, dass Sie nicht das gesamte Manuskript auf einmal kopieren und einfügen! Dies könnte die Formatierung verändern, was zu Problemen beim Hochladen führt.',
  'bookstep-Formatting-guide-pdfebook-template-p2': 'Wenn Sie mit dem Layout Ihres Word-Dokuments bereits zufrieden sind, können Sie es auch einfach in eine PDF-Datei konvertieren und diese hochladen. Diese Methode ist in der Regel ein zuverlässiger Weg, um ein PDF-eBook zu erstellen.',
  'bookstep-Formatting-guide-pdfebook-template-p3': 'Wenn Sie ein professionelles Layoutprogramm wie InDesign verwenden, können Sie die Spezifikationen des von Ihnen gewählten Buchformats einfach auf das Dokument anwenden.',
  'bookstep-Formatting-guide-pdfebook-fonts-title': 'Schriftarten, Schriftgröße und Zeilenabstände',
  'bookstep-Formatting-guide-pdfebook-fonts-p1': 'Wir werden Ihnen nicht vorschreiben, welche Schriftart Sie verwenden sollen. Das ist eine persönliche Entscheidung und hängt vom Buch ab, welches Sie schreiben. Als Erstes müssen Sie entscheiden, ob Sie eine serifenbetonte oder eine serifenlose Schriftart verwenden möchten. Serifen sind die kleinen Linien, die an den Enden der Buchstaben angebracht sind, und gelten allgemein als hilfreich, um gedruckten Text besser lesbar zu machen. Serifenschriften sind bei Weitem die am häufigsten verwendete Schriftart in Büchern. Cambria, Garamond, Georgia und Times New Roman sind einige der beliebtesten Serifenschriften.',
  'bookstep-Formatting-guide-pdfebook-fonts-p2': 'Serifenlose Schriftarten hingegen sind einfacher und sehen eher "modern" aus. Sie werden häufig online und auf Buchumschlägen verwendet.  Arial, Calibri, Helvetica und Verdana sind einige der beliebtesten serifenlosen Schriften.',
  'bookstep-Formatting-guide-pdfebook-fonts-p3': 'Darüber hinaus müssen Sie sich auch für eine Schriftgröße und einen Zeilenabstand entscheiden. Auch hier gibt es keine richtige Antwort; das ist alles subjektiv und hängt auch sehr von der gewählten Schriftart ab. 10, 11 und 12 sind die gebräuchlichsten Schriftgrößen. Was den Zeilenabstand anbelangt, so ist ein Wert zwischen 1,1 und 1,3 üblich.',
  'bookstep-Formatting-guide-pdfebook-fonts-p4': 'Wenn Ihr Buch eine Zielgruppe mit einem hohen Durchschnittsalter hat, sollten Sie eine größere Schriftgröße und einen größeren Zeilenabstand in Betracht ziehen. Es kann hilfreich sein, ein paar Seiten Ihres Buches auszudrucken, um eine Vorstellung davon zu bekommen, welchen Aufbau Sie bevorzugen.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-title': 'Noch mehr Formatierungsmöglichkeiten',
  'bookstep-Formatting-guide-pdfebook-moreoptions-p1': 'Wir sind noch nicht fertig, liebe Autorinnen und Autoren, es gibt noch einige Entscheidungen zu treffen. Hier sind die wichtigsten davon:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1': 'Ränder: Stellen Sie sicher, dass sie groß genug sind; Ränder von rund 2 cm sind eine gute Faustregel. Falls Sie nicht unsere Vorlagen verwenden, können Sie ein ähnliches Layout erstellen, indem Sie die Ränder wie folgt einstellen:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-topmargin': 'Oberer Rand: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-bottommargin': 'Unterer Rand: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-leftmargin': 'Linker Rand: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-rightmargin': 'Rechter Rand: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list2': 'Neue Kapitel: Beginnen Sie neue Kapitel auf neuen Seiten. Dies führt zu einem besseren Leseerlebnis. Sie können Ihren Kapiteln entweder einen Titel, eine Nummer oder beides geben. Wie auch immer Sie sich entscheiden, stellen Sie sicher, dass Sie die Formatvorlage "Kopfzeile 1" auf Ihre Kapitelüberschriften in Word anwenden, um eine einheitliche Formatierung zu sicherzustellen.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list3': 'Absätze: Sie können Absätze entweder durch eine Leerzeile oder durch Einrücken des ersten Wortes eines neuen Absatzes trennen. Achten Sie darauf, dass Ihre Absätze nicht zu lang sind, da dies die Lesbarkeit beeinträchtigt.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-title': 'Ihr Manuskript hochladen ',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p1': 'Es steht Ihnen natürlich frei, für Ihr Manuskript ein beliebiges Textverarbeitungsprogramm zu verwenden, aber wenn es darum geht, ein Manuskript für ein PDF-eBook hochzuladen, akzeptieren wir nur PDFs. Unabhängig davon, wie Sie Ihr Manuskript erstellt haben, müssen Sie es in ein PDF-Format konvertieren, bevor Sie es auf unsere Plattform hochladen können. Die meisten Programme verfügen über ein integriertes Konvertierungswerkzeug, entweder unter “Speichern unter” oder “Exportieren”. Normalerweise müssen Sie nur eine dieser beiden Optionen auswählen und dann PDF als Dateiformat wählen.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p2': 'Sobald Sie Ihr Manuskript hochgeladen haben, konvertieren wir es in eine veröffentlichungsfähige PDF-Datei, die auf Ihrem Bildschirm erscheint. Prüfen Sie diese Datei gründlich und stellen Sie sicher, dass das Layout auf jeder Seite perfekt ist.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p3': 'Sie sind nicht zufrieden mit dem Ergebnis? Kein Problem, Sie können Ihr Manuskript in diesem Schritt beliebig oft ändern und erneut hochladen. Denken Sie daran, dass beim Hochladen einer neuen Version Ihres Manuskripts die alte Version überschrieben wird.',
  'bookstep-Formatting-nextstep': 'Nächster Schritt: hochladen',
  'bookstep-Formatting-tips-title': 'Formatierungstipps',
  'bookstep-Formatting-tips-print-newpage-label' : 'Neue Seite',
  'bookstep-Formatting-tips-print-newpage': 'Wenn ein Kapitel in der Mitte der Seite endet und Sie das nächste Kapitel auf einer neuen Seite beginnen möchten, sollten Sie niemals die Eingabetaste verwenden, um dorthin zu gelangen. Wenn Sie dies tun, entstehen bei der Konvertierung des Dokuments in eine PDF-Datei riesige Leerräume, die den gesamten Text verdrängen. Verwenden Sie daher immer die Tastenkombination CTRL + Enter (CMD + Enter auf dem Mac), um in Word einen Zeilenumbruch zu erzeugen. So werden jegliche Probleme vermieden. Dasselbe gilt für das Einfügen von Bildern in Ihr Manuskript: Drücken Sie nicht einfach die Eingabetaste, um Platz zu schaffen!',
  'bookstep-Formatting-tips-print-colophon-label': 'Impressum',
  'bookstep-Formatting-tips-print-colophon': 'Es ist üblich, dass Ihr Buch mit einer Titelseite und einem Impressum beginnt. Im Allgemeinen sehen die ersten Seiten eines Buches wie folgt aus:',
  'bookstep-Formatting-tips-print-colophon-page1': 'Erste Seite: Nur der Titel in großer Schrift',
  'bookstep-Formatting-tips-print-colophon-page2': 'Zweite Seite: Leere Seite',
  'bookstep-Formatting-tips-print-colophon-page3': 'Dritte Seite: Der Titel des Buches mit dem Namen des Autors darunter',
  'bookstep-Formatting-tips-print-colophon-page4': 'Vierte Seite: Dies ist das Impressum, die den Namen des Autors, die ISBN (falls vorhanden), den Namen des Umschlagdesigners, den Copyright-Vermerk, das Erscheinungsjahr und Verlagsportal: {publicbrand}',
  'bookstep-Formatting-tips-print-colophon-rest': 'Die oben aufgeführten Seiten sind nicht nummeriert, d. h. die Seitenzahlen Ihres Buches beginnen bei 5. Unsere Vorlagen sind nach diesem Standard aufgebaut.',
  'bookstep-Formatting-tips-print-images-label': 'Illustrationen',
  'bookstep-Formatting-tips-print-images': 'Selbstverständlich können Sie in Ihrem Manuskript Bilder hinzufügen, wenn Sie dies wünschen. Ein Bild mit einer Auflösung von 250 dpi ist ausreichend, um eine hohe Druckqualität zu gewährleisten. Platzieren Sie die Bilder einfach dort, wo sie in Ihrem Manuskript erscheinen sollen (achten Sie auf den Beschnitt, wenn Sie Bilder in Randnähe platzieren). Wenn Sie eine Doppelseite erstellen möchten, d. h. ein Bild, das sich über zwei Seiten erstreckt, können Sie das Bild in zwei Hälften schneiden und den linken Teil auf einer geraden Seite und den rechten Teil auf der folgenden ungeraden Seite platzieren.',
  'bookstep-Formatting-tips-print-wrongformat-label': 'Falsches Format?',
  'bookstep-Formatting-tips-print-wrongformat': 'Wenn Sie ein Dokument mit einem Format hochladen, das nicht mit dem von Ihnen gewählten Buchformat übereinstimmt, skalieren wir Ihr Dokument so, dass es mit Ihrem Buch übereinstimmt. Das kann reibungslos funktionieren, vor allem wenn es nur geringe Unterschiede gibt, aber es kann auch zu Formatierungsproblemen führen. Kurzum: Prüfen Sie die konvertierte PDF-Datei, die Sie von der Plattform erhalten, immer gründlich! Es wäre schade, wenn Sie ein Korrekturexemplar bestellen und dann feststellen, dass die Formatierung nicht stimmt.',
  'bookstep-Formatting-tips-print-colorpages-label': 'Farbe und Schwarz-Weiss',
  'bookstep-Formatting-tips-print-colorpages': 'Wenn Ihr Buch sowohl Schwarz-Weiß- als auch Farbseiten enthält, ist es wichtig, dass Sie ein bestimmtes Verfahren anwenden, um zu vermeiden, dass Ihre Schwarz-Weiß-Seiten in Farbe gedruckt werden. Klicken Sie hier, um einen umfassenden Überblick darüber zu erhalten, wie Sie Ihrem Buch Farbseiten hinzufügen können. {link}',
  'bookstep-Formatting-tips-print-colorpages-link': 'Klicken Sie hier, um mehr über den Umgang mit Farbeseiten zu erfahren.',
  'bookstep-Formatting-tips-print-proofcopy-label': 'Probedruck',
  'bookstep-Formatting-tips-print-proofcopy': 'Sie sind noch nicht so weit, ein Korrekturexemplar zu bestellen, aber es lohnt sich schon jetzt, es zu erwähnen: Kaufen Sie immer ein Korrekturexemplar, bevor Sie Ihr Manuskript veröffentlichen oder große Druckaufträge erteilen. Egal, wie gründlich Sie (oder Ihre Lektoren) Ihr Manuskript digital geprüft haben, in der Druckversion werden Ihnen immer ein paar Fehler auffallen. Tippfehler, ästhetische Details und Farben sind auf dem Bildschirm irgendwann nur noch schwer zu erkennen, und das gedruckte Exemplar wird immer etwas anders aussehen; Sie werden überrascht sein, was Sie alles finden. Es ist äußerst frustrierend, diese kleinen Fehler erst nach der Veröffentlichung oder dem Druck eines Buches zu entdecken. Auch für Korrekturexemplare gibt es Mengenrabatte, so dass Sie problemlos mehrere Exemplare bestellen und sich von anderen Personen beim Korrekturlesen Ihres endgültigen Entwurfs helfen lassen können.',
  'bookstep-Formatting-tips-epub-chapterheadings-label': 'Kapitelüberschriften',
  'bookstep-Formatting-tips-epub-chapterheadings': 'Um ein gutes eBook zu erstellen, ist es wichtig, die richtigen Kapitelüberschriften zu verwenden. In Microsoft Word können Sie dazu die sogenannten “Formatvorlagen” verwenden, z. B. “Überschrift 1”. Wenn Sie dies nicht tun, wird Ihr eBook ein einziger langer Text ohne Überschriften, ohne die Möglichkeit, zwischen den Kapiteln zu navigieren.',
  'bookstep-Formatting-tips-epub-colophon-label': 'Impressum',
  'bookstep-Formatting-tips-epub-colophon': 'Es ist üblich, dass ein Buch mit einer Titelseite und einem Impressum beginnt. Im Allgemeinen sind die ersten Seiten eines Buches in der folgenden Reihenfolge:',
  'bookstep-Formatting-tips-epub-colophon-page1': 'Erste Seite: Buchtitel, gefolgt von der Angabe des Autors',
  'bookstep-Formatting-tips-epub-colophon-page2': 'Zweite Seite: Dies ist das Impressum, die den Namen des Autors, die ISBN (falls vorhanden), den Namen des Umschlagdesigners, den Copyright-Vermerk, das Erscheinungsjahr und Verlagsportal: {publicbrand}',
  'bookstep-Formatting-tips-epub-colophon-rest': 'Wenn Sie Microsoft Word verwenden, achten Sie darauf, dass Sie zwischen diesen beiden Seiten einen Seitenumbruch einfügen, damit sie voneinander getrennt bleiben.',
  'bookstep-Formatting-tips-epub-pagenumbers-label': 'Keine Seitenzahlen',
  'bookstep-Formatting-tips-epub-pagenumbers': 'Achten Sie darauf, dass Ihre Manuskriptdatei keine Seitenzahlen oder andere Kopf- oder Fußzeilen enthält. Wenn Sie diese beibehalten, erscheinen sie zufällig im Text des eBooks selbst und nicht an der vorgesehenen Stelle.',
  'bookstep-Formatting-tips-epub-images-label': 'Bilder',
  'bookstep-Formatting-tips-epub-images': 'Sie können Ihrem eBook natürlich auch Bilder hinzufügen. Wenn Sie sich dafür entscheiden, denken Sie daran, dass das Bild "in einer Linie mit dem Text" platziert werden muss. Sie können keine sogenannten "schwebenden" Bilder mit Text an der Seite hinzufügen; Bilder müssen immer die gesamte Zeile mit dem begleitenden Text darüber und/oder darunter einnehmen.',
  'bookstep-Formatting-tips-pdfebook-newpage-label': 'Neue Seite',
  'bookstep-Formatting-tips-pdfebook-newpage': 'Wenn ein Kapitel auf einer Seite zu Ende ist und Sie das nächste Kapitel auf einer neuen Seite beginnen möchten, verwenden Sie niemals die Eingabetaste, um zu dieser neuen Seite zu navigieren. Andernfalls entstehen bei der Konvertierung Ihrer Datei in das PDF-Format große Leerräume im Dokument, wodurch das neue Kapitel viel weiter nach unten auf der Seite verschoben wird als beabsichtigt. Verwenden Sie in Word immer die Tastenkombination STRG + Enter (oder CMD + Enter, wenn Sie einen Mac verwenden), um auf einer neuen Seite zu beginnen. Dasselbe gilt, wenn Sie Ihrem Manuskript Bilder hinzufügen möchten, die eine gesamte Seite abdecken. Verwenden Sie niemals die Eingabetaste, um Platz für Ihr Bild zu schaffen.',
  'bookstep-Formatting-tips-pdfebook-colophon-label': 'Impressum',
  'bookstep-Formatting-tips-pdfebook-colophon': 'Es ist üblich, dass ein Buch mit einer Titelseite und einem Impressum beginnt. Im Allgemeinen sind die ersten Seiten eines Buches in der folgenden Reihenfolge angeordnet:',
  'bookstep-Formatting-tips-pdfebook-colophon-page1': 'Erste Seite: Buchtitel in großen Buchstaben',
  'bookstep-Formatting-tips-pdfebook-colophon-page2': 'Zweite Seite: Leere Seite',
  'bookstep-Formatting-tips-pdfebook-colophon-page3': 'Dritte Seite: Der Titel des Buches mit dem Namen des Autors darunter',
  'bookstep-Formatting-tips-pdfebook-colophon-page4': 'Vierte Seite: Dies ist das Impressum, die den Namen des Autors, die ISBN (falls vorhanden), den Namen des Umschlagdesigners, den Copyright-Vermerk, das Erscheinungsjahr und Verlagsportal: {publicbrand}',
  'bookstep-Formatting-tips-pdfebook-colophon-rest': 'Diese Seiten sind nicht nummeriert. Daher beginnen die Seitenzahlen, die ab der nächsten Seite enthalten sind, bei Nummer 5. Unsere Vorlagen sind bereits nach diesem Standard eingerichtet.',
  'bookstep-Formatting-tips-pdfebook-images-label': 'Bilder',
  'bookstep-Formatting-tips-pdfebook-images': 'Natürlich können Sie Ihrem Manuskript auch Bilder hinzufügen. Eine Auflösung von 96 dpi ist für eBooks ausreichend. Sie müssen die Bilder selbst in Ihr Manuskript einfügen; achten Sie nur darauf, dass zwischen dem Bild und dem Text genügend Platz vorhanden ist.',
  'bookstep-Formatting-tips-pdfebook-dimensions-label': 'Abmessungen',
  'bookstep-Formatting-tips-pdfebook-dimensions': 'Für die Abmessungen Ihrer PDF-Datei gibt es keine Einschränkungen. Es ist jedoch ratsam, ein Standardformat zu wählen, vorzugsweise zwischen A5 und A4, da sich ein extrem großes oder kleines Format negativ auf die Lesbarkeit Ihres eBooks auswirken kann.',

  // Account settings
  'AccountSettings-Security-button-2FA-title': 'Zweistufige Authentifizierung verwenden',
  'AccountSettings-Security-button-2FA-subtitle-enabled': 'Aktiviert',
  'AccountSettings-Security-button-2FA-subtitle-disabled': 'Deaktiviert',
  'AccountSettings-Security-button-2FA-subtitle': '{enableddisabled} • Wir fragen nach einem Code, wenn Sie sich mit einem neuen Gerät anmelden.',

  // Checkout
  'Checkout-Cart-title-Yourcart': 'Ihr Warenkorb',
  'Checkout-Cart-RemoveFromCart': 'Löschen',
  'Checkout-Cart-CartItem-Title-Buscards': 'Visitenkarten',
  'Checkout-Cart-CartItem-Title-Isbn': 'ISBN',
  'Checkout-Cart-CartItem-Originally': 'Ursprünglich: <span>{price}</span>',
  'Checkout-Cart-CartItem-Forbook': 'Für Buch: {bookTitle}',
  'Checkout-Cart-CartIsEmpty': 'Ihr Warenkorb ist leer.',
  'Checkout-Cart-title-Total': 'Gesamtsumme',
  'Checkout-Cart-total-Subtotal': 'Zwischensumme <span>{price}</span>',
  'Checkout-Cart-total-Discount': 'Rabatt <span>{price}</span>',
  'Checkout-Cart-total-Total': 'Gesamt <span>{price}</span>',
  'Checkout-Cart-Button-ProceedCheckout': 'Zur Kasse gehen',
  'Checkout-Cart-ErrorPopup-title': 'Einige Produkte in Ihrem Warenkorb können nicht bestellt werden',
  'Checkout-Cart-ErrorPopup-p1': 'Wenn Sie auf die Schaltfläche ‘Zur Kasse gehen’ klicken, werden die nicht verfügbaren Produkte aus Ihrem Warenkorb entfernt und Sie können zur Kasse gehen.',
  'Checkout-Cart-ErrorPopup-unavailableprods': 'Nicht verfügbare Produkte:',
  'Checkout-Cart-ErrorPopup-Button-Cancel': 'Abbrechen',
  'Checkout-Cart-ErrorPopup-Button-ContinueOrder': 'Zur Kasse gehen',
  'Checkout-Cart-WarningPopup-title': 'Sie sind dabei, eine ISBN und das entsprechende Buch zusammen zu bestellen.',
  'Checkout-Cart-WarningPopup-p1': 'Wenn Sie eine ISBN und das entsprechende Buch gleichzeitig bestellen, wird das Buch <b>KEINE</b> ISBN enthalten. Wir empfehlen Ihnen, zuerst die ISBN zu bestellen und dann das Buch zu bestellen, nachdem die ISBN Ihrem Buch zugewiesen wurde.',
  'Checkout-Cart-WarningPopup-p2': 'Wenn Sie auf die Schaltfläche ‘ISBN zuerst bestellen’ klicken, werden wir das/die folgende(n) Buch(er) aus Ihrem Warenkorb entfernen:',
  'Checkout-Cart-WarningPopup-Button-IgnoreContinue': 'Ignorieren und weitergehen',
  'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst': 'ISBN zuerst bestellen',
  'Checkout-Error-CheckoutSpecApiError': 'Während des Bezahlvorgangs ist ein unerwarteter Fehler aufgetreten. Bitte kontaktieren Sie den Support und fügen Sie einen Screenshot dieser Seite bei. Wir entschuldigen uns für die Unannehmlichkeiten.',
  'Checkout-CheckoutDetails-Button-Save': 'Speichern',
  'Checkout-CheckoutDetails-Button-Next': 'Weitergehen',
  'Checkout-CheckoutDetails-Button-Cancel': 'Abbrechen',
  'Checkout-CheckoutDetails-Button-NextStep': 'Nächster Schritt',
  'Checkout-CheckoutDetails-title-Email': 'E-Mail',
  'Checkout-CheckoutDetails-title-ShippingAddress': 'Lieferadresse',
  'Checkout-CheckoutDetails-title-BillingAddress': 'Rechnungsadresse',
  'Checkout-CheckoutDetails-title-ShippingMethod': 'Versandart',
  'Checkout-CheckoutDetails-Email-label-Email': 'E-Mail',
  'Checkout-CheckoutDetails-Email-Button-EditEmail': 'E-Mail ändern',
  'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress': 'Ihre Lieferadresse',
  'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress': 'Lieferadresse ändern',
  'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label': 'Identisch mit der Lieferadresse',
  'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress': 'Ihre Rechnungsadresse',
  'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress': 'Rechnungsadresse ändern',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-label': 'Kundenkategorie',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc': 'Geben Sie an, welche Art von Kunde Sie sind. Geschäftskunden benötigen eine gültige EU-Umsatzsteuer-Identifikationsnummer.',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private': 'Privatperson',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc': 'Kunde ohne EU MwSt.-Nummer',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company': 'Geschäftsperson',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc': 'Kunde mit EU MwSt.-Nummer',
  'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label': 'Wählen Sie Ihre bevorzugte Versandart',
  'Checkout-Address-Phoneline': 'Tel: {phone}',
  'Checkout-Address-Vatline': 'MwSt.-Nummer: {taxno}',
  'Checkout-Address-Input-Fn': 'Vorname',
  'Checkout-Address-Input-Ln': 'Nachname',
  'Checkout-Address-Input-L': 'Land',
  'Checkout-Address-GenericAddress-S': 'Straße und Hausnummer',
  'Checkout-Address-GenericAddress-P': 'PLZ',
  'Checkout-Address-GenericAddress-Nr': 'Hausnummer',
  'Checkout-Address-GenericAddress-Nr-Placeholder': 'Hausnummer',
  'Checkout-Address-GenericAddress-NrA': 'Hausnummer + Zusatz',
  'Checkout-Address-GenericAddress-NrA-Placeholder': 'Zusatz',
  'Checkout-Address-GenericAddress-C': 'Stadt',
  'Checkout-Address-GenericAddress-R': 'Bundesland',
  'Checkout-Address-Input-T': 'Telefon',
  'Checkout-Address-Input-Shipping-Cp': 'Firma (auf Adressaufkleber)',
  'Checkout-Address-Input-Cp': 'Firma',
  'Checkout-Address-Input-Shipping-V': 'Steuernummer',
  'Checkout-Address-Input-V': 'Umsatzsteuer-Identifikationsnummer',
  'Checkout-Address-Button-Save': 'Speichern',
  'Checkout-AddressStreetWarningNoNumber': 'Achtung: Ihre Adresse enthält keine Hausnummer',
  'Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND': 'Wir haben keine Adresse mit der eingegebenen PLZ, Hausnummer und dem Zusatz gefunden. Bitte überprüfen Sie, ob Sie alles richtig eingegeben haben.',
  'Checkout-PostalcodecheckError-INVALID_API_CALL': 'Bei der Suche nach Ihrer Adresse ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.',
  'Checkout-CheckoutOverview-title-YourDeliveryDetails': 'Ihre Bestelldaten',
  'Checkout-CheckoutOverview-title-Paymentmethod': 'Zahlungsmethode',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email': 'E-Mail',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress': 'Ihre Adresse',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress': 'Lieferadresse',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress': 'Rechnungsadresse',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod': 'Versandart',
  'Checkout-OrderSidebar-title-YourOrder': 'Ihre Bestellung',
  'Checkout-OrderSidebar-ProductItem-Quantity': 'Menge: {quantity}',
  'Checkout-OrderSidebar-totals-Shipping': 'Versand: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-Total': 'Gesammt: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-IncludedVAT': 'Inkl. MwSt: <span>{price}</span>',
  'CheckoutSuccess-title-Thankyou': 'Vielen Dank für Ihre Bestellung!',
  'CheckoutSuccess-YourOrderNoIs': 'Ihre Bestellnummer lautet: <b>{orderId}</b>',
  'CheckoutSuccess-IntroWhatToExpect': 'Sie haben gerade eine Bestellung bei uns aufgegeben. Je nachdem, was Sie bestellt haben, können Sie hier unten lesen, was Sie erhalten werden.',
  'CheckoutSuccess-title-PaperbackHardcover': 'Paperback / Hardcover',
  'CheckoutSuccess-title-Ebook': 'eBook',
  'CheckoutSuccess-title-Isbn': 'ISBN',
  'CheckoutSuccess-title-Buscards': 'Visitenkarten',
  'CheckoutSuccess-li1-OrderConfEmail': 'Sie erhalten eine Bestätigung per E-Mail',
  'CheckoutSuccess-li1-OrderNumberIs': 'Ihre Bestellnummer lautet: <b>{orderId}</b>.',
  'CheckoutSuccess-li1-OrderConfSentTo': 'Wir senden eine Auftragsbestätigung an {email}. Hinweis: Die E-Mail ist möglicherweise in Ihrer Spam-Box gelanden.',
  'CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD': 'Wir erstellen Ihr Buch via Print on Demand',
  'CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway': 'Ihre Bestellung kommt sofort in den Druck. Dies kann einige Tage dauern.',
  'CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped': 'Ihre Bestellung wird versandt',
  'CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail': 'Sie erhalten eine Versandbestätigung mit einem Tracking-Link per E-Mail.',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate': 'Voraussichtliches Versanddatum',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates': 'Zwischen {startdate} und {enddate}',
  'CheckoutSuccess-Ebook-li2-dlLinkEmail': 'Ihr Download-Link befindet sich in der Bestätigungs-E-Mail.',
  'CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow': 'Der Link zum Herunterladen Ihres E-Books befindet sich in der Bestätigungs-E-Mail. Sie können aber auch auf die Knopf hier unten klicken.',
  'CheckoutSuccess-Ebook-DownloadYourEbook': 'eBook herunterladen',
  'CheckoutSuccess-Isbn-li2-IsbnReady': 'Ihre ISBN ist in einer Stunde einsatzbereit',
  'CheckoutSuccess-Isbn-li2-IsbnReadyInStep': 'Sie finden die ISBN im Schritt ‘ISBN’ Ihres Buchprojektes. Wir fügen die ISBN automatisch auf der Rückseite Ihres Covers hinzu.',
  'CheckoutSuccess-Isbn-ContinueWithBook': 'Fahren Sie mit Ihrem Buch fort',
  'CheckoutSuccess-Isbn-ContinueWithBookClickHere': 'Klicken Sie hier, um die Arbeit an Ihrem Buch fortzusetzen',
  'CheckoutSuccess-Buscards-li2-WeMakeCards': 'Wir erstellen Ihre Visitenkaarten',
  'CheckoutSuccess-Buscards-li2-WePrintRightAway': 'Wir drucken gerade Ihre Visitenkarten. Die Zustellung dauert etwa eine Woche.',
  'CheckoutSuccess-Buscards-li2-NoShipmentMail': 'Hinweis: Sie erhalten keine separate Versandbestätigung für die Visitenkarten.',
  'CheckoutFailed-title-PaymentFailed': 'Ihre Zahlung ist fehlgeschlagen',
  'CheckoutFailed-SomethingWentWrongTryAgain': 'Bei der Bezahlung Ihrer Bestellung ist ein Fehler aufgetreten. Bitte versuchen Sie, erneut zu bezahlen.',
  'CheckoutFailed-button-CheckoutAgain': 'Erneut zur Kasse gehen',
  'CheckoutError-title-SomethingWrong': 'Etwas ist schief gelaufen',
  'CheckoutError-SomethingWentWrongContactUs': 'Beim Auschecken Ihrer Bestellung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support. Bitte geben Sie die Referenz an: {orderId}',
  'CheckoutError-button-TryAgain': 'Wiederholen',
  'CheckoutPending-title-PaymentInProgress': 'Ihre Zahlung wird überprüft',
  'CheckoutPending-PaymentProcessingTakesLonger': 'Leider dauert die Bearbeitung Ihrer Zahlung etwas länger. Daher können wir derzeit nicht mit Sicherheit sagen, ob Ihre Zahlung erfolgreich war.',
  'CheckoutPending-CheckAgainInFewHours': 'Bitte prüfen Sie Ihre E-Mail in den nächsten Stunden. Sobald wir die Bestätigung Ihrer Zahlung erhalten haben, schicken wir Ihnen eine Auftragsbestätigung per E-Mail. Sollten Sie diese Bestätigung nicht erhalten, überprüfen Sie bitte bei Ihrer Bank oder Kreditkarte, ob die Zahlung korrekt abgebucht wurde.',
  'CheckoutPending-OrderReference': 'JIhre Bestellnummer lautet: {orderId}',
  'CheckoutCancelled-title-PaymentCancelled': 'Sie haben die Zahlung annulliert',
  'CheckoutCancelled-TryAgainIfYouWant': 'Sie haben die Zahlung annulliert. Wenn dies nicht Ihre Intention war, klicken Sie bitte auf die Schaltfläche unten, um zur Kasse zu gehen.',
  'CheckoutCancelled-button-CheckoutAgain': 'Zur Kasse gehen',
  
  // All ISO-3166-1 alpha 2 countries
  'Country-AD': 'Andorra',
  'Country-AE': 'Vereinigte Arabische Emirate',
  'Country-AF': 'Afghanistan',
  'Country-AG': 'Antigua und Barbuda',
  'Country-AI': 'Anguilla',
  'Country-AL': 'Albanien',
  'Country-AM': 'Armenien',
  'Country-AO': 'Angola',
  'Country-AQ': 'Antarktis',
  'Country-AR': 'Argentinien',
  'Country-AS': 'Amerikanisch-Samoa',
  'Country-AT': 'Österreich',
  'Country-AU': 'Australien',
  'Country-AW': 'Aruba',
  'Country-AX': 'Åland',
  'Country-AZ': 'Aserbaidschan',
  'Country-BA': 'Bosnien und Herzegowina',
  'Country-BB': 'Barbados',
  'Country-BD': 'Bangladesch',
  'Country-BE': 'Belgien',
  'Country-BF': 'Burkina Faso',
  'Country-BG': 'Bulgarien',
  'Country-BH': 'Bahrain',
  'Country-BI': 'Burundi',
  'Country-BJ': 'Benin',
  'Country-BL': 'Saint-Barthélemy',
  'Country-BM': 'Bermuda',
  'Country-BN': 'Brunei Darussalam',
  'Country-BO': 'Bolivien',
  'Country-BQ': 'Karibische Niederlande',
  'Country-BR': 'Brasilien',
  'Country-BS': 'Bahamas',
  'Country-BT': 'Bhutan',
  'Country-BV': 'Bouvetinsel',
  'Country-BW': 'Botswana',
  'Country-BY': 'Weißrussland',
  'Country-BZ': 'Belize',
  'Country-CA': 'Kanada',
  'Country-CC': 'Kokosinseln',
  'Country-CD': 'Kongo-Kinshasa',
  'Country-CF': 'Zentralafrikanische Republik',
  'Country-CG': 'Kongo-Brazzaville',
  'Country-CH': 'Schweiz',
  'Country-CI': 'Elfenbeinküste',
  'Country-CK': 'Cookinseln',
  'Country-CL': 'Chile',
  'Country-CM': 'Kamerun',
  'Country-CN': 'China',
  'Country-CO': 'Kolumbien',
  'Country-CR': 'Costa Rica',
  'Country-CU': 'Kuba',
  'Country-CV': 'Kap Verde',
  'Country-CW': 'Curaçao',
  'Country-CX': 'Weihnachtsinsel',
  'Country-CY': 'Zypern',
  'Country-CZ': 'Tschechien',
  'Country-DE': 'Deutschland',
  'Country-DJ': 'Dschibuti',
  'Country-DK': 'Dänemark',
  'Country-DM': 'Dominica',
  'Country-DO': 'Dominikanische Republik',
  'Country-DZ': 'Algerien',
  'Country-EC': 'Ecuador',
  'Country-EE': 'Estland',
  'Country-EG': 'Ägypten',
  'Country-EH': 'Westsahara',
  'Country-ER': 'Eritrea',
  'Country-ES': 'Spanien',
  'Country-ET': 'Äthiopien',
  'Country-FI': 'Finnland',
  'Country-FJ': 'Fidschi',
  'Country-FK': 'Falklandinseln',
  'Country-FM': 'Mikronesien',
  'Country-FO': 'Färöer',
  'Country-FR': 'Frankreich',
  'Country-GA': 'Gabun',
  'Country-GB': 'Vereinigtes Königreich',
  'Country-GD': 'Grenada',
  'Country-GE': 'Georgien',
  'Country-GF': 'Französisch-Guayana',
  'Country-GG': 'Guernsey',
  'Country-GH': 'Ghana',
  'Country-GI': 'Gibraltar',
  'Country-GL': 'Grönland',
  'Country-GM': 'Gambia',
  'Country-GN': 'Guinea',
  'Country-GP': 'Guadeloupe',
  'Country-GQ': 'Äquatorialguinea',
  'Country-GR': 'Griechenland',
  'Country-GS': 'Südgeorgien und die Südlichen Sandwichinseln',
  'Country-GT': 'Guatemala',
  'Country-GU': 'Guam',
  'Country-GW': 'Guinea-Bissau',
  'Country-GY': 'Guyana',
  'Country-HK': 'Hongkong',
  'Country-HM': 'Heard und McDonaldinseln',
  'Country-HN': 'Honduras',
  'Country-HR': 'Kroatien',
  'Country-HT': 'Haiti',
  'Country-HU': 'Ungarn',
  'Country-ID': 'Indonesien',
  'Country-IE': 'Irland',
  'Country-IL': 'Israel',
  'Country-IM': 'Isle of Man',
  'Country-IN': 'Indien',
  'Country-IO': 'Britisches Territorium im Indischen Ozean',
  'Country-IQ': 'Irak',
  'Country-IR': 'Iran',
  'Country-IS': 'Island',
  'Country-IT': 'Italien',
  'Country-JE': 'Jersey',
  'Country-JM': 'Jamaika',
  'Country-JO': 'Jordanien',
  'Country-JP': 'Japan',
  'Country-KE': 'Kenia',
  'Country-KG': 'Kirgisistan',
  'Country-KH': 'Kambodscha',
  'Country-KI': 'Kiribati',
  'Country-KM': 'Komoren',
  'Country-KN': 'St. Kitts und Nevis',
  'Country-KP': 'Nordkorea',
  'Country-KR': 'Südkorea',
  'Country-KW': 'Kuwait',
  'Country-KY': 'Kaimaninseln',
  'Country-KZ': 'Kasachstan',
  'Country-LA': 'Laos',
  'Country-LB': 'Libanon',
  'Country-LC': 'St. Lucia',
  'Country-LI': 'Liechtenstein',
  'Country-LK': 'Sri Lanka',
  'Country-LR': 'Liberia',
  'Country-LS': 'Lesotho',
  'Country-LT': 'Litauen',
  'Country-LU': 'Luxemburg',
  'Country-LV': 'Lettland',
  'Country-LY': 'Libyen',
  'Country-MA': 'Marokko',
  'Country-MC': 'Monaco',
  'Country-MD': 'Moldawien',
  'Country-ME': 'Montenegro',
  'Country-MF': 'Saint-Martin',
  'Country-MG': 'Madagaskar',
  'Country-MH': 'Marshallinseln',
  'Country-MK': 'Nordmazedonien',
  'Country-ML': 'Mali',
  'Country-MM': 'Myanmar',
  'Country-MN': 'Mongolei',
  'Country-MO': 'Macao',
  'Country-MP': 'Nördliche Marianen',
  'Country-MQ': 'Martinique',
  'Country-MR': 'Mauretanien',
  'Country-MS': 'Montserrat',
  'Country-MT': 'Malta',
  'Country-MU': 'Mauritius',
  'Country-MV': 'Malediven',
  'Country-MW': 'Malawi',
  'Country-MX': 'Mexiko',
  'Country-MY': 'Malaysia',
  'Country-MZ': 'Mosambik',
  'Country-NA': 'Namibia',
  'Country-NC': 'Neukaledonien',
  'Country-NE': 'Niger',
  'Country-NF': 'Norfolkinsel',
  'Country-NG': 'Nigeria',
  'Country-NI': 'Nicaragua',
  'Country-NL': 'Niederlande',
  'Country-NO': 'Norwegen',
  'Country-NP': 'Nepal',
  'Country-NR': 'Nauru',
  'Country-NU': 'Niue',
  'Country-NZ': 'Neuseeland',
  'Country-OM': 'Oman',
  'Country-PA': 'Panama',
  'Country-PE': 'Peru',
  'Country-PF': 'Französisch-Polynesien',
  'Country-PG': 'Papua-Neuguinea',
  'Country-PH': 'Philippinen',
  'Country-PK': 'Pakistan',
  'Country-PL': 'Polen',
  'Country-PM': 'St. Pierre und Miquelon',
  'Country-PN': 'Pitcairninseln',
  'Country-PR': 'Puerto Rico',
  'Country-PS': 'Palästinensische Autonomiegebiete',
  'Country-PT': 'Portugal',
  'Country-PW': 'Palau',
  'Country-PY': 'Paraguay',
  'Country-QA': 'Katar',
  'Country-RE': 'Réunion',
  'Country-RO': 'Rumänien',
  'Country-RS': 'Serbien',
  'Country-RU': 'Russland',
  'Country-RW': 'Ruanda',
  'Country-SA': 'Saudi-Arabien',
  'Country-SB': 'Salomonen',
  'Country-SC': 'Seychellen',
  'Country-SD': 'Sudan',
  'Country-SE': 'Schweden',
  'Country-SG': 'Singapur',
  'Country-SH': 'St. Helena',
  'Country-SI': 'Slowenien',
  'Country-SJ': 'Svalbard und Jan Mayen',
  'Country-SK': 'Slowakei',
  'Country-SL': 'Sierra Leone',
  'Country-SM': 'San Marino',
  'Country-SN': 'Senegal',
  'Country-SO': 'Somalia',
  'Country-SR': 'Suriname',
  'Country-SS': 'Südsudan',
  'Country-ST': 'São Tomé und Príncipe',
  'Country-SV': 'El Salvador',
  'Country-SX': 'Sint Maarten (niederländischer Teil)',
  'Country-SY': 'Syrien',
  'Country-SZ': 'Eswatini',
  'Country-TC': 'Turks- und Caicosinseln',
  'Country-TD': 'Tschad',
  'Country-TF': 'Französische Süd- und Antarktisgebiete',
  'Country-TG': 'Togo',
  'Country-TH': 'Thailand',
  'Country-TJ': 'Tadschikistan',
  'Country-TK': 'Tokelau',
  'Country-TL': 'Osttimor',
  'Country-TM': 'Turkmenistan',
  'Country-TN': 'Tunesien',
  'Country-TO': 'Tonga',
  'Country-TR': 'Türkei',
  'Country-TT': 'Trinidad und Tobago',
  'Country-TV': 'Tuvalu',
  'Country-TW': 'Taiwan',
  'Country-TZ': 'Tansania',
  'Country-UA': 'Ukraine',
  'Country-UG': 'Uganda',
  'Country-UM': 'Amerikanische Überseeinseln',
  'Country-US': 'Vereinigte Staaten',
  'Country-UY': 'Uruguay',
  'Country-UZ': 'Usbekistan',
  'Country-VA': 'Vatikanstadt',
  'Country-VC': 'St. Vincent und die Grenadinen',
  'Country-VE': 'Venezuela',
  'Country-VG': 'Britische Jungferninseln',
  'Country-VI': 'Amerikanische Jungferninseln',
  'Country-VN': 'Vietnam',
  'Country-VU': 'Vanuatu',
  'Country-WF': 'Wallis und Futuna',
  'Country-WS': 'Samoa',
  'Country-XK': 'Kosovo',
  'Country-YE': 'Jemen',
  'Country-YT': 'Mayotte',
  'Country-ZA': 'Südafrika',
  'Country-ZM': 'Sambia',
  'Country-ZW': 'Simbabwe',
};

export default messages;
