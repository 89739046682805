const messages = {
  'Promotion': 'Promoção',
  'binding-1': 'Capa dura',
  'binding-3': 'Livro de bolso',
  'binding-6': 'eBook',
  'binding-7': 'PDF',
  'interiorcolor-bw': 'Preto/branco',
  'interiorcolor-fc': 'Cor',
  'interiorcolor-short-bw': 'P/B',
  'interiorcolor-short-fc': 'Cor',
  'Interior color': 'Cor interior',
  'Papertype-short': 'Papel',
  'Papertype-short-1': 'Creme',
  'Papertype-short-2': 'Creme',
  'Papertype-short-3': 'Branco',
  'Papertype-short-4': 'Branco',
  'Papertype-short-5': 'Semi-brilhante',
  'Papertype-short-6': 'Semi-brilhante',
  'Papertype-short-7': 'Branco',
  'Papertype-short-8': 'Branco brilhante',
  'Papertype-short-9': 'Tinted',
  'Papertype-short-10': '150 grams',
  'Papertype-short-12': 'Branco',
  'Amountpages-short': 'Páginas',
  'Amountcolorpages-short': 'Páginas coloridas',
  'Help-howdoesitwork-question': 'Como funciona?',
  'ISBN': 'ISBN',
  'Author website': 'Website do autor',
  'Author webshop': 'Webshop do autor',
  'authorwebsite-notEnabled': 'Não habilitado - {link}',
  'authorwebsite-notEnabled-enablenow': 'Habilitar agora',
  'Link to book page': 'Link para a página do livro',
  'button-downloadcover': 'Baixar capa',
  'button-downloadexcerpt': 'Baixar excerto',
  'button-copybooklink': 'Copiar link do livro',
  'button-copyannouncementtext': 'Copiar anúncio',
  'button-openinstagram': 'Abrir Instagram',
  'button-addtoshoppingcart': 'Adicionar ao carrinho de compras',
  'button-orderbooks': 'Pedir livros',
  'button-registerbook': 'Registo',
  'Channels-availability': 'Disponibilidade',
  'Channelname': 'Loja',
  'Price': 'Preço',
  'Your margin': 'Seu lucro',
  'Bookspec-ISBN-isbn': 'ISBN: {isbn}',
  'Bookspec-LastEdited-date': 'Última Versão {date}',

  'What do you have to do?': 'O que tens de fazer?',

  // form labels
  'label-announcementtext': 'Anúncio',
  'label-instagram-addbooklinktobio': 'Adiciona o link para o teu livro na tua biografia do Instagram',
  'label-instagram-downloadcover': 'Fazer o download da tua capa para a postagem',
  'label-instagram-openinstagram': 'Abrir Instagram',
  'label-instagram-chooseanimage': 'Escolher uma imagem',
  'label-attachbooklinktomessage': 'Adicionar na mensagem o link para o seu livro automaticamente',

  // zod validation errors
  'ZodValidationError-EmailRequired': 'O endereço de e-mail é obrigatório',
  'ZodValidationError-EmailNotValidEmail': 'Introduza um endereço de e-mail válido',
  'ZodValidationError-AddressFnRequired': 'O primeiro nome é obrigatório',
  'ZodValidationError-AddressFnLongerThan30': 'O primeiro nome não pode ter mais de 30 caracteres',
  'ZodValidationError-AddressLnRequired': 'O apelido é obrigatório',
  'ZodValidationError-AddressLnLongerThan30': 'O apelido não pode ter mais de 30 caracteres',
  'ZodValidationError-AddressSRequired': 'É necessário o número da rua e do edifício',
  'ZodValidationError-AddressSLongerThan50': 'O número da rua e do edifício não podem ter mais de 50 caracteres',
  'ZodValidationError-AddressS2LongerThan50': 'A segunda linha de endereço não pode ter mais de 50 caracteres',
  'ZodValidationError-AddressNrMustBeNumber': 'O número do edifício deve ser um número',
  'ZodValidationError-AddressPRequired': 'O código postal é obrigatório',
  'ZodValidationError-AddressPLongerThan20': 'O código postal não pode ter mais de 20 caracteres',
  'ZodValidationError-AddressCRequired': 'A cidade é obrigatória',
  'ZodValidationError-AddressCLongerThan35': 'A cidade não pode ter mais de 35 caracteres',
  'ZodValidationError-AddressRLongerThan35': 'A região não pode ter mais de 35 caracteres',
  'ZodValidationError-AddressLRequired': 'O país é obrigatório',
  'ZodValidationError-AddressTRequired': 'O telefone é obrigatório',
  'ZodValidationError-AddressCountryNotTwoLetterCode': 'O país deve ser um código ISO de 2 letras',
  'ZodValidationError-AddressCpLongerThan35': 'O nome da empresa não pode ter mais de 35 caracteres',
  'ZodValidationError-AddressTLongerThan20': 'O número de telefone não pode ter mais de 20 caracteres',
  'ZodValidationError-AddressFullNameLongerThan40': 'O nome completo não pode ter mais de 40 caracteres',
  'ZodValidationError-AddressPostalCodeIncorrect': 'O código postal está incorreto',
  'ZodValidationError-AddressS2SameAsS': 'A segunda linha de endereço não pode ser igual à linha anterior',
  'ZodValidationError-AddressVRequiredForCanarias': 'É necessário um número de identificação fiscal para as Ilhas Canárias',

  // guide popup
  'wikiguidepopup-title-selectaguide': 'Selecciona um guia',

  // errors
  'error-booknotpublished': 'Não podes promover esse livro porque ainda não foi publicado. Vai até ‘Meus Livros’ para editar esse livro.',
  'error-booknotavailable': 'O livro que estás tentando acessar não existe. Vai até ‘Meus Livros’ e seleciona um livro.',
  'error-booknotauthorized': 'Não tens autorização para acessar essa página. Caso esta mensagem esteja incorreta, por favor contacta o nosso suporte.',
  'error-bookunknownerror': 'Um erro inesperado ocorreu enquanto esta página carregava. Por favor atualiza a página para tentar novamente. Caso o problema persista, por favor contacta o nosso suporte e menciona a ID do livro: {bookid}',

  // promo popup
  'promoapp-publishedpopup-title': 'Parabéns, o seu livro está publicado!',
  'promoapp-publishedpopup-paragraph1': 'Conseguiu! O seu livro já foi publicado, por isso, aproveite o momento para comemorar! Partilhe com os seus amigos e desfrute da sua realização.',
  'promoapp-publishedpopup-paragraph2': 'Agora que o seu livro está à venda, é altura do próximo passo na sua jornada de autor: promovê-lo. Naturalmente, estaremos aqui para ajudar nessa tarefa. As nossas sugestões de promoção são algumas das formas mais eficazes de promover o seu livro.',
  'promoapp-publishedpopup-startbutton': 'Começar a promover',

  // promo categories
  'promoapp-havetodo-title': 'Ações que deverá realizar',
  'promoapp-shoulddo-title': 'Ações que recomendamos que tomes',
  'promoapp-coulddo-title': 'Ações que colocarão o seu livro num patamar profissional',
  'blocktitle-thingsyouneedtopromote': 'Elementos necessários para a promoção do seu livro',

  // promo overview
  'promoapp-overview-intro-p1': 'Escrever um livro é uma grande conquista por si só, mas é muito natural que também o queira vender aos leitores. Para isso, vai precisar de o promover. Para muitos autores, é aqui que começa o verdadeiro desafio. A promoção de um livro requer muito trabalho e dedicação, mas, se realizar as sugestões que damos, vai começar a ver resultados mais rápido do que possa pensar. Para que saiba por onde começar, montámos este guia com uma seleção de dicas e ferramentas para que possa tornar a promoção do seu livro mais simples e eficaz.',

  // list of all promotool descriptions
  'promotool-authorwebsite': 'Website do autor',
  'promotool-description-authorwebsite': 'A melhor maneira de vender os seus livros',
  'promotool-whatsapp': 'WhatsApp',
  'promotool-description-whatsapp': 'Venda o seu livro diretamente para os seus contactos',
  'promotool-socialmedia': 'Redes Sociais',
  'promotool-description-socialmedia': 'Divulgue o seu livro nas redes sociais para incentivar as vendas',
  'promotool-instagram': 'Instagram',
  'promotool-description-instagram': 'Promove o teu livro utilizando visuais incríveis',
  'promotool-facebook': 'Facebook',
  'promotool-description-facebook': 'Conecta-te com leitores e incentiva as vendas a partilhar conteúdos interessantes',
  'promotool-twitter': 'Twitter',
  'promotool-description-twitter': 'Usa a tua habilidade com palavras para promover e vender o teu livro',
  'promotool-linkedin': 'LinkedIn',
  'promotool-description-linkedin': 'Documenta a tua jornada de empreendedorismo como autor para inspirar a tua rede',
  'promotool-libraries': 'Bibliotecas',
  'promotool-description-libraries': 'Alcance novos leitores colocando o seu livro em bibliotecas',
  'promotool-nbdbiblion': 'NBD Biblion',
  'promotool-description-nbdbiblion': 'Get NBD to review your book and get it in the libraries',
  'promotool-nationallibrary': 'Biblioteca Nacional',
  'promotool-description-nationallibrary': 'Imortaliza o teu trabalho entregando uma cópia do teu livro na Biblioteca Nacional',
  'promotool-launchparty': 'Evento de Lançamento',
  'promotool-description-launchparty': 'Nenhuma publicação está completa sem um evento de lançamento do livro',
  'promotool-bookstores': 'Livrarias',
  'promotool-description-bookstores': 'Entre em contacto com livrarias locais para que vendam o seu livro',
  'promotool-pressrelease': 'Press Release',
  'promotool-description-pressrelease': 'Fazer com que a imprensa local saiba da existência do seu livro ajudará muito nas suas vendas',
  'promotool-businesscards': 'Cartões de Negócio',
  'promotool-description-businesscards': 'Impressiona possíveis clientes com os teus cartões de negócio personalizados',

  // launch party
  'launchparty-intro-p1': 'O lançamento de um livro é um passo integral no processo de promoção do teu livro. Não apenas é uma ótima maneira de chamar a atenção para o facto de o teu livro ter sido publicado, como também é uma forma muito agradável de marcar a ocasião e celebrar uma grande conquista com amigos e familiares. Tu podes fazer o teu evento de lançamento de livro do tamanho que quiseres, da maneira que estiveres alinhado com os objetivos que definiste para ti mesmo. Nós preparamos um guia para dois possíveis tipos de evento: um evento de lançamento menor e mais informal e um outro tipo maior e mais formal. Podes encontrar ambos os guias em seguida e decidir qual estilo combina mais com as tuas ambições pessoais.',
  'launchparty-bookdrinks-title': 'Lançamento de Livro Informal',
  'launchparty-bookdrinks-intro': 'Se quiseres manter o evento simples, podes fazer com que o teu lançamento de livro seja uma ocasião mais íntima do que tradicional. Não é preciso que o evento seja uma grande ocasião em um local alugado com convidados fazendo discursos e uma cobertura da mídia. Ao invés, podes simplesmente convidar amigos, familiares e conhecidos para juntarem-se a ti e celebrar essa conquista que obteves. Pensa no evento como uma festa para ti e para o teu livro. Pode ser uma maneira relativamente barata e fácil de atrair atenção para o teu status como autor publicado, e consequentemente vender alguns livros. Aqui estão alguns conselhos caso queiras planear um evento desta maneira:',
  'launchparty-bookdrinks-whenandwho-title': 'Quem convidar e quando',
  'launchparty-bookdrinks-whenandwho-paragraph1': 'Faz uma lista de todas as pessoas que queres convidar. Teus amigos, familiares, vizinhos e pessoas que contribuíram de alguma maneira para o teu livro podem ser boas escolhas. Escolhe uma data, cria um grupo em alguma rede social onde possas te comunicar com teus convidados e envia os convites! Certifica-te de que tuas mensagens sejam animadas e gentis, e lembra-te de incluir uma fotografia do teu livro!',
  'launchparty-bookdrinks-orderbooks-title': 'Pede exemplares do livro',
  'launchparty-bookdrinks-orderbooks-paragraph1': 'Quando mandares os convites do evento para os teus convidados, provavelmente não irá demorar muito até que possas ter uma boa ideia de quantas pessoas comparecerão. Podes assumir que cada convidado irá querer comprar uma cópia ou duas e, com isto em mente, podes fazer um pedido adiantado com um número suficiente de livros. Ao fazer isso, não precisarás te preocupar com a possibilidade de quaisquer possíveis imprevistos que possam impedir que teus livros sejam entregues a tempo da tua festa aconteçam.',
  'launchparty-bookdrinks-agenda-title': 'Programa-te',
  'launchparty-bookdrinks-agenda-paragraph1': 'Por mais que a ideia seja planear este evento de uma forma informal e espontânea, ainda seria bom que te programasses um pouco para assegurar-te de que a experiência seja memorável e de que os teus convidados fiquem com vontade de comprar o teu livro. Pergunta-te o que gostarias de dizer a eles, e considera a possibilidade de fazer um brinde. Queres presentear a primeira cópia do teu livro para alguém especial? Talvez gostarias de contar a todos sobre a tua experiência autopublicando um livro? Faz uma lista de todos os pontos que gostaria de tratar no evento.',
  'launchparty-bookdrinks-foodandhouse-title': 'Serve comidas e bebidas',
  'launchparty-bookdrinks-foodandhouse-paragraph1': 'Certifica-te de que terás bebidas e comidas suficientes para os teus convidados. É importante que as pessoas possam aproveitar o evento e se sentirem confortáveis enquanto o fazem. Lembra-te de incluir opções de bebidas que não tenham álcool e mantém em mente possíveis restrições alimentares que as pessoas possam ter. Além disso, certifica-te de que a tua casa esteja arrumada, limpa, e arruma os teus livros de maneira que chamem a atenção dos teus convidados. Também é uma boa ideia pedir para que alguém fique a tirar fotografias. Temos certeza que teus convidados irão adorá-las, e as fotografias também podem ser usadas como materiais promocionais para o teu livro após o evento.',
  'launchparty-bookdrinks-enjoy-title': 'Aproveita!',
  'launchparty-bookdrinks-enjoy-paragraph1': 'Por último, certifica-te de aproveitar a tua festa de lançamento do teu livro! As pessoas estarão contigo para comemorar essa grande vitória do teu lado, então aproveita a atenção! Precisarás decidir antes do evento se queres presentear cada convidado com um exemplar do livro ou se queres que eles comprem as suas cópias. Sabemos que a segunda opção pode parecer um pouco rude, mas é absolutamente normal e temos certeza que os teus convidados serão compreensivos. As pessoas que convidaste são próximas a ti e irão querer apoiar-te!',
  'launchparty-bookpresentation-title': 'Lançamento de Livro Formal',
  'launchparty-bookpresentation-intro': 'Um evento de lançamento de livro formal é simplesmente uma maneira mais ambiciosa de dar a sua festa. Claro que tens a opção de realizar o evento na tua própria casa, mas talvez seja bom que consideres a opção de alugar um local ao invés, como uma cafetaria ou uma livraria. Além disso, vais querer convidar a imprensa local e talvez até mesmo alguns influenciadores famosos das redes sociais em sua área. Esses passos aumentam a pressão do evento, então é bom que te prepares e tenhas uma programação sólida planeada. Aqui estão alguns exemplos de coisas que deves pensar sobre quando estiveres a planear o teu evento de lançamento de livro:',
  'launchparty-guestlist-title': 'Decide quem convidar',
  'launchparty-guestlist-paragraph1': 'Um bom lugar para começares é escrevendo uma lista de convidados. Escolhas óbvias são amigos, familiares, qualquer um que tenha contribuído para o teu livro de alguma forma e talvez colegas ou outras pessoas na tua rede profissional. Normalmente podes contar com pessoas animadas para celebrar este marco consigo, por isso não te sintas tímido em enviar convites! A razão pela qual é uma boa ideia começares com uma lista de convidados é que saber quantas pessoas irão comparecer torna a escolha de um local adequado para hospedar o evento muito mais fácil.',
  'launchparty-findalocation-title': 'Encontra uma localização e estabelece uma data',
  'launchparty-findalocation-paragraph1': 'Há muitos locais bem adaptados para um evento de lançamento de livros. Podes optar por acolhê-lo no conforto da tua própria casa, na tua cafetaria favorita, ou numa livraria local. Este último é o nosso favorito, pois achamos que estar rodeado de livros realmente melhora a atmosfera de um evento de lançamento de livro. Tenta garantir a tua localização de forma barata (ou gratuita) e envolve o proprietário no teu planeamento para que saibam o que esperar.',
  'launchparty-sendinvites-title': 'Envia os convites',
  'launchparty-sendinvites-paragraph1': 'Embora um convite físico não seja necessariamente a norma hoje em dia, isso por si só pode ser uma razão para experimentá-lo. Podes dar ao evento uma certa legitimidade, e te permite fazer coisas divertidas como fazer convites com a capa do teu livro neles. Claro que também podes optar por enviar convites por e-mail ou SMS. A vantagem disto é que é grátis e poupa-te tempo. Não te esqueças de pedir confirmações de presença; vais precisar de saber quantas pessoas estão a ir.',
  'launchparty-createagenda-title': 'Programação',
  'launchparty-createagenda-paragraph1': 'Deves planear os principais eventos do lançamento do livro com antecedência. Somos grandes defensores de que o evento de lançamento do teu livro seja o teu próprio evento, mas há certos agrafos que recomendamos que inclua:',
  'launchparty-createagenda-list1': 'Começa com uma bebida de boas-vindas e deixa os convidados se misturarem até que todos tenham chegado. Alguma música seria uma boa adição aqui também;',
  'launchparty-createagenda-list2': 'Assim que todos os convidados chegarem, tem um amigo ou outra pessoa reconhecível dar as boas-vindas a todos;',
  'launchparty-createagenda-list3': 'Uma sessão de perguntas e respostas onde os teus convidados podem perguntar-te sobre coisas como por que escolheu escrever um livro, sobre o que é, de onde te inspirou e se tem alguma anedota divertida sobre o processo;',
  'launchparty-createagenda-list4': 'Lê uma passagem do teu livro em voz alta para os teus convidados;',
  'launchparty-createagenda-list5': 'Assina os livros que as pessoas compram;',
  'launchparty-createagenda-list6': 'Oferece lanches e socializa.',
  'launchparty-contactmedia-title': 'Mídia',
  'launchparty-contactmedia-paragraph1': 'Recomendamos fortemente que tentes entrar em contacto com os meios de comunicação locais ou talvez até influenciadores relevantes nas redes sociais de tua área. Pergunta-lhes se estão interessados em cobrir o lançamento do teu livro e convida-lhes para o evento. Ficarias surpreendido com a frequência com que isto funciona, por isso não tenhas vergonha de tentar!',
  'launchparty-refreshments-title': 'Organiza lanches e bebidas',
  'launchparty-refreshments-paragraph1': 'O nosso conselho aqui é: mantém tudo simples. Escolhe opções populares e mantém a seleção limitada. Certifica-te de obter o ok do proprietário da tua localização previamente. Se tu estás a ter o evento em um lugar que normalmente não serve comida e bebidas, eles podem ter regras sobre esse tipo de coisa, e se é em um lugar que normalmente serve estas coisas, eles podem querer servir a sua própria comida e bebidas.',
  'launchparty-orderbooks-title': 'Livros',
  'launchparty-orderbooks-paragraph1': 'Certifica-te de ter cópias suficientes do teu livro à mão. Os teus convidados podem então comprar uma cópia durante o evento, e podes assiná-la no local para tornar a experiência mais pessoal. Tem em mente que se estiveres a hospedar o lançamento do teu livro numa livraria, o proprietário vai querer uma parte das vendas do livro. Certifica-te de negociar previamente as especificidades desta.',
  'launchparty-photos-title': 'Fotos e Vídeos',
  'launchparty-photos-paragraph1': 'Não só o teu evento de lançamento de livro é uma boa forma de sensibilizar e gerar algumas vendas, como também pode criar material de marketing futuro para ti. É por isso que é importante que arranjes alguém para tirar fotografias e/ou gravar o evento. Não tem de ser um fotógrafo profissional; provavelmente tens um amigo que sabe usar uma câmara. Em seguida, podes selecionar alguns dos materiais para publicar nas redes sociais e promover o teu livro.',
  'launchparty-followup-title': 'Acompanhamento',
  'launchparty-followup-paragraph1': 'Agradece aos teus convidados por terem vindo alguns dias depois do evento, o mais tardar. Deixa-os saber que aprecias o seu apoio e partilha as fotos e vídeos do evento. Tu também podes compartilhar estes com pessoas que não conseguiram comparecer, e até incluir um link para onde elas podem comprar o teu livro. Se conseguiste que algum órgão de comunicação local ou influenciadores locais comparecesse ou cobrisse o evento, certifica-te de que também o divulgou e interage com eles nas redes sociais.',

  // bookstores
  'bookstores-intro-p1': 'As livrarias de tijolos e argamassas continuam a ser um poderoso canal de vendas de livros, apesar do domínio cada vez maior das compras online. No entanto, como têm estado em declínio há algum tempo, as livrarias tendem a ser cautelosas ao armazenar livros de autores desconhecidos, uma vez que é geralmente considerado um risco desnecessário. No entanto, recomendamos entrar em contacto com as livrarias locais para saber se estariam dispostas a armazenar o teu livro. Ter o teu livro disponível numa loja física ainda resulta num forte impulso de legitimidade e dá uma grande oportunidade de marketing.',
  'bookstores-intro-p2': 'Se conseguires uma livraria para armazenar o teu livro eles vão querer uma parte dos lucros se e quando o teu livro for vendido, e estimamos que será geralmente de cerca de 20 a 40 por cento. Além disso, provavelmente só concordarão em armazenar o teu livro se não houver riscos, o que significa que eles se reservarão o direito de devolver-te quaisquer cópias não vendidas. Criamos uma lista de verificação que te dará uma visão geral das coisas a ter em mente quando se aproxima e negoceia com as livrarias.',
  'bookstores-checklist-title': 'Checklist para Livrarias',
  'bookstores-selectbookstores-title': 'Escolhe as livrarias certas',
  'bookstores-selectbookstores-p1': 'O primeiro passo é determinar quais as livrarias que pretendes abordar. Recomendamos fazer alguma pesquisa sobre o que está disponível perto de ti, fazer uma lista, e abordá-los um de cada vez.',
  'bookstores-contactbookstore-title': 'Entra em contacto com as livrarias',
  'bookstores-contactbookstore-p1': 'Depois de determinar em quais livrarias gostarias de vender o teu livro, é hora de contactá-las e marcar uma reunião introdutória.',
  'bookstores-contactbookstore-p2': 'Aqui estão as nossas dicas para o teu primeiro encontro:',
  'bookstores-contactbookstore-list1': 'Conta-lhes um pouco sobre ti mesmo',
  'bookstores-contactbookstore-list2': 'Conta-lhes sobre o teu livro: do que se trata, por que o escreveu, e qual é o teu público-alvo',
  'bookstores-contactbookstore-list3': 'Traz uma cópia do teu livro e deixa-a com eles. O proprietário vai, sem dúvida, querer examinar o livro antes de aceitá-lo',
  'bookstores-agreement-title': 'Elabora alguns acordos com a livraria',
  'bookstores-agreement-p1': 'Se a livraria estiver preparada para armazenar o teu livro, deverão ter alguns acordos em vigor no que diz respeito a várias coisas:',
  'bookstores-agreement-whobuysbooks': 'Quem está a pagar os livros?',
  'bookstores-agreement-buy-option1': 'Opção 1: Tu pagas pelos livros e a livraria vende-os em teu nome. Esta vai ser a opção mais atraente para a livraria, já que não correrão qualquer risco financeiro. Podes comprar o livro utilizando a funcionalidade integrada na plataforma Bookmundo e chegar a acordo sobre uma margem de lucro com a livraria. Não te esqueça de chegar a um acordo sobre quando vais ser pago e como vais ser informado das vendas.',
  'bookstores-agreement-buy-option2': 'Opção 2: A livraria compra os livros de ti. Esta é a solução mais fácil, pois ambos podem recolher automaticamente as respetivas margens de lucro. Se optar por esta opção, certifica-te de que definiste o alcance para "Nacional" no passo de preços na plataforma.',
  'bookstores-agreement-howlongavailable': 'Quanto tempo o livro estará à venda na livraria?',
  'bookstores-agreement-positioninstore': 'Onde será colocado/exibido o livro?',
  'bookstores-beactive-title': 'Sê ativo',
  'bookstores-beactive-p1': 'Uma vez que o teu livro esteja disponível na loja, é imperativo que informes as pessoas sobre isso! Eis algumas formas de fazer isto:',
  'bookstores-beactive-list1': 'Uma forma festiva de o fazer seria organizar a tua festa de lançamento do livro na própria livraria. Consulta o nosso guia sobre lançamentos de livros para mais informações.',
  'bookstores-beactive-list2': 'Anuncia o facto de o teu livro estar disponível nesta loja em particular nas redes sociais e no teu website.',
  'bookstores-beactive-list3': 'Faz aparições na livraria e anuncia-as aos teus leitores de forma a gerar atenção e estimular as vendas',

  // press release
  'pressrelease-intro-p1': 'Um comunicado de imprensa é um depoimento oficial feito por ti na tua capacidade como autor para a mídia. No mundo da publicação, comunicados de imprensa são geralmente utilizados para anunciar a publicação de um livro. Como um autor autopublicado, provavelmente não terás os contactos que uma editora tradicional possui. Não deixa que isso te impeça, ainda podes entrar em contacto com a mídia local. Jornais locais e sites de notícias estão sempre em busca de conteúdo, e ficarias surpreso com o tamanho do interesse que eles podem ter em um autor emergente.',
  'pressrelease-intro-p2': 'A nossa plataforma gera um comunicado de imprensa automático para ti. Ele é baseado na informação que preencheste durante os vários passos que precisou seguir para publicar o teu livro, como a capa, a sinopse, o ISBN, o preço e o link para a loja.',
  'pressrelease-intro-p3': 'Se quiseres aumentar as chances de receber resenhas oficiais da mídia, envia uma cópia do teu livro junto com o comunicado de imprensa para as organizações que contactares.',
  'pressrelease-identifytarget-title' : 'Certifica-te de que sabes qual é a tua audiência.',
  'pressrelease-identifytarget-p1': 'É importante que mantenhas a tua audiência em mente quando selecionares quem deve receber o teu comunicado de imprensa. Não faria sentido enviar um comunicado de imprensa para uma empresa da mídia que possui uma audiência completamente diferente da que queres alcançar.',
  'pressrelease-identifytarget-p2': 'Também gostaríamos de dizer que, enquanto temos falado apenas da mídia local até agora, não deves te limitar sem necessidade. Podes também considerar fazer uma pesquisa de sites que cobrem o mesmo assunto que escreveste o teu livro sobre. Quanto mais específico, melhor.',
  'pressrelease-collectcontact-title': 'Pesquisa detalhes de contacto',
  'pressrelease-collectcontact-p1': 'É a hora da parte mais entediante do processo: escrever uma lista estruturada de todas as pessoas que precisas contactar. Uma boa maneira de fazê-lo é criando uma planilha com todos os nomes e endereços de empresas que podes contactar, incluindo também o nome, e-mail e número de telefone de algum contacto que trabalhe lá. Tenta conseguir os detalhes de contacto de pessoas específicas sempre que for possível. Dependendo do tamanho da tua lista, podes também adicionar colunas que te ajudarão a manter a ordem sobre quem já contactaste e qual foi a resposta que recebeste.',
  'pressrelease-sendpressrelease-title': 'Envia o comunicado de imprensa',
  'pressrelease-sendpressrelease-p1': 'Quando fores enviar o comunicado de imprensa por e-mail, certifica-te de que o texto seja curto, persuasivo e direto ao ponto. Não reutiliza textos do comunicado de imprensa, e tenta deixá-lo interessante o suficiente para que o teu contacto fique curioso para saber mais. O maior objectivo é que o teu contacto abra o teu comunicado de imprensa. Certifica-te de mandar o comunicado como um ficheiro PDF. Podes também anexar uma parte do teu livro como PDF.',
  'pressrelease-sendpressrelease-p2': 'Para as empresas que forem as tuas maiores prioridades, podes considerar enviá-los uma cópia impressa do teu livro. É um investimento relativamente pequeno que tem o potencial de fazer uma enorme diferença. Finalmente, certifica-te também de indicar que estás disponível para entrevistas no teu e-mail.',
  'pressrelease-downloadbutton': 'Baixar comunicado de imprensa',
  'pressrelease-followup-title': 'Acompanhamento',
  'pressrelease-followup-p1': 'Não espere que publiquem o teu comunicado de imprensa ou que escrevam algo sobre o teu livro automaticamente. É sempre bom checar se fizeram ou se estão a planear fazer algo com o teu comunicado de imprensa. Considera reenviar o teu comunicado caso algumas semanas passem sem qualquer retorno.',
  'pressrelease-sharelinks-title': 'Divulga os links',
  'pressrelease-sharelinks-p1': 'Toda vez que o teu livro for mencionado na mídia, precisas partilhar o ocorrido nas tuas redes sociais. É um marketing de graça para o teu livro, e quanto mais pessoas verem, maiores serão as chances que comprarão o teu livro.',

  // NBD Biblion
  'nbdbiblion-copyandpastetext': 'Clica aqui para copiar o texto acima',

  // Business cards
  'businesscards-intro-p1': 'Os materiais promocionais são uma ótima forma de complementar a tua estratégia de marketing, já que podes adicionar um toque pessoal e tangível que muitas pessoas podem apreciar na era digital. É por isso que lhe damos a opção de imprimir os teus próprios cartões de negócio, nos quais podes colocar uma foto do teu livro juntamente com informações sobre onde comprá-lo. Os cartões de negócio são impressos a cores em cartão resistente de 400 gramas. O teu pedido será entregue dentro de duas semanas.',
  'businesscards-blocktitle-order': 'Pedir cartões de negócio',
  'businesscards-preview-label-front': 'Frente',
  'businesscards-preview-label-back': 'Verso',
  'businesscards-numberofcards-label': 'Número de cartões de negócio',
  'businesscards-selectnumberofcards-item-label': '{número} cartões - {preço}',

  // whatsapp
  'whatsapp-intro-p1': 'O WhatsApp, ou qualquer aplicativo de mensagens que utilize, é extremamente importante para obter vendas do seu livro. As pessoas que alcança através do WhatsApp compõem a sua própria rede pessoal, e, portanto, serão as mais propensas a apoiá-lo como autor e mais curiosas em ler o seu livro. Não te esqueças de envolvê-las no processo de criação do livro antes de dar a notícia da publicação e pedir-lhes o derradeiro favor de comprar o teu livro! Podes, por exemplo, mostrar-lhes as tuas ideias de capa e pedir as suas opiniões, ou pensar no título com eles. Podes até enviar-lhes a sinopse antes do tempo para os animar.',
  'whatsapp-intro-p2': 'Podem não parecer grandes vendas, as realizadas aos contactos que estão no seu WhatsApp, mas estes amigos serão, inicialmente, os seus maiores embaixadores. Então não negligencie este método de promoção!',
  'whatsapp-promoteviawhatsapp': 'Promova o seu livro no Whatsapp',
  'whatsapp-button-sendviawhatsapp': 'Enviar no WhatsApp',
  'whatsapp-week1-title': 'Semana 1 - Anúncio',
  'whatsapp-week1-message': 'Olá amigos, gostava que soubessem que finalmente publiquei o meu livro! Estou muito entusiasmado e agradecia que espalhassem a palavra se conhecerem alguém que possa estar interessado em o lê-lo!\n\nSe tu mesmo estiveres interessado em lê-lo, ou se quiseres oferecer um excelente presente a alguém, podes conhecer melhor ou comprar o meu livro aqui:',
  'whatsapp-week2-title': 'Semana 2 - Evento de Lançamento do Livro',
  'whatsapp-week2-message': 'Olá de novo e obrigado a todos os que já compraram o meu livro, vocês são fantásticos!\n\nSó queria que soubessem que vou organizar um evento de lançamento do meu livro! Claro que estão todos convidados. Haverá lanches, bebidas, e até uma breve leitura de um excerto meu! Até assino os seus livros se quiserem ;)\n\nVai ser muito agradável e seria uma honra se viessem!',
  'whatsapp-week3-title': 'Semana 3 - Obrigado por terem vindo!',
  'whatsapp-week3-message': 'Olá a todos! Obrigado a todos os que vieram ao evento de lançamento do meu livro, foi incrível tê-los todos comigo! Aqui estão algumas fotos que foram tiradas durante o evento.\n\nTambém gostaria de agradecer a todos que compraram um exemplar, significa muito para mim! Se não conseguiste comparecer e queres comprar um exemplar do meu livro, podes fazê-lo aqui mesmo:',
  'whatsapp-week4-title': 'Semana 4 - Pedido de Comentário',
  'whatsapp-week4-message': 'Olá!\n\nSe eu pudesse pedir um pequeno favor, seria uma grande ajuda para mim se pudesses escrever um pequeno comentário do meu livro e enviar-mo, e/ou publicá-lo no Goodreads. Faria maravilhas pela visibilidade do meu livro na Internet e ajudar-me-ia a chegar a mais pessoas. Ficaria mais do que feliz em retribuir o favor com um café um dia destes!',
  'whatsapp-week4-goodreadswarning': 'Nota: Antes de enviar essa mensagem, por favor certifica-te de que já adicionaste teu livro ao Goodreads.',
  'whatsapp-tips-title': 'Sugestões',
  'whatsapp-tips-groups-label': 'Crie grupos dedicados ao seu livro no WhatsApp',
  'whatsapp-tips-groups': 'É provável que tenha uma lista variada de contactos no WhatsApp. Pode ser uma boa ideia criar vários grupos e enviar mensagens especialmente trabalhadas para cada um destes grupos que correspondam ao seu nível habitual de familiaridade.',
  'whatsapp-tips-readtheroom-label': 'Não seja demasiado insistente',
  'whatsapp-tips-readtheroom': 'As pessoas com quem fala no Whatsapp são provavelmente as pessoas de quem é mais próximo, o que significa que provavelmente serão os seus leitores mais leais. No entanto não é correcto enviar lembretes constantes para comprarem o seu livro, é preferível agradecer às pessoas que já o compram. Esta é geralmente uma forma mais eficaz de motivar alguém interessado, mas que ainda não tenha comprado o seu livro, a fazê-lo.',
  'whatsapp-tips-askspread-label': 'Peça que espalhem a palavra',
  'whatsapp-tips-askspread': 'A promoção boca a boca é uma das formas mais poderosas de fazer com que o seu livro seja lido. Por isso, aproveite os seus grupos de WhatsApp e peça a amigos e familiares para espalhar a palavra dentro dos seus próprios círculos sociais.',
  'whatsapp-tips-participation-label': 'Crie um sentimento de participação',
  'whatsapp-tips-participation': 'Como já referimos, os seus contactos no WhatsApp provavelmente referem-se a pessoas que serão os seus leitores mais leais. Não se esqueça de lhes referir o quanto aprecia o seu apoio. É sempre bom lembrar-lhes o quão importantes são para o sucesso do seu livro.',

  // social media
  'socialmedia-intro-p1': 'Como com certeza sabes, as redes sociais são umas das mais importantes ferramentas promocionais disponíveis hoje. Como autor autopublicado, ser capaz de te comunicar diretamente com os leitores interessados no que estás a escrever é inestimável. Compilamos algumas dicas e ferramentas para vários canais de redes sociais abaixo, com mais para vir.',
  'socialmedia-tips-title': 'Sugestões',
  'socialmedia-tips-beconsistent-label': 'Sê consistente',
  'socialmedia-tips-beconsistent': 'Como escritor, tens uma voz única, uma forma particular de partilhar os teus pensamentos, e isso deve fazer parte da tua comunicação nas redes sociais também. É importante que partilhes os teus pensamentos de forma ativa e eloquente, e uses a tua voz de uma forma consistente que o teu público possa reconhecer.',
  'socialmedia-tips-beshareable-label': 'Sê partilhável',
  'socialmedia-tips-beshareable': 'Apesar de que devas desenvolver os teus próprios hábitos de publicação nas redes sociais e fazer o que fazes de melhor com isso, também é importante que cries conteúdos que possam ser facilmente partilhados pelos teus seguidores. Quanto mais os teus posts forem partilhados, mais atenção a tua conta receberá.',
  'socialmedia-tips-berelatable-label': 'Sê relacionável',
  'socialmedia-tips-berelatable': 'Claro que vais querer partilhar o máximo possível sobre o teu livro: preços, eventos futuros, etc. No entanto, é uma boa ideia dares uma espreitadela às pessoas sobre a tua vida pessoal, para partilhar momentos que são mais humanos e relacionáveis. Isto faz de ti mais do que um vendedor de livros. Deves também interagir com o conteúdo de outras pessoas; curte, comenta, e partilha os posts de outros.',
  'socialmedia-tips-hashtaglover-label': 'Sê um amante das hashtags',
  'socialmedia-tips-hashtaglover': 'Hashtags ainda são extremamente importantes. Evita hashtags genéricas; faz o esforço para implementar algumas que irão ajudar-te a encontrar as pessoas certas, e vice-versa. Faz alguma pesquisa sobre as hashtags relevantes na comunidade de escrita, por exemplo, antes de publicar. Precisamos dizer também que não deves usar hashtags para tudo. Concentra-te no conteúdo que realmente queres transmitir. Nem todos os posts precisam de hashtag.',
  'socialmedia-tips-beflexible-label': 'Sê flexível',
  'socialmedia-tips-beflexible': 'Todas as plataformas de redes sociais são diferentes. O Instagram tem tudo a ver com imagens. O LinkedIn tem tudo a ver com networking. O Facebook tem tudo a ver com partilhações. O Twitter tem tudo a ver com palavras. É importante que o teu conteúdo não seja igual em todas as plataformas; tens que te adaptar ao estilo e às normas de cada uma. A tua voz deve permanecer a mesma, mas a forma como a projetas deve ser flexível.',
  'socialmedia-tips-beanalytical-label': 'Sê analítico',
  'socialmedia-tips-beanalytical': 'Todas as plataformas de redes sociais fornecem análises, então faz uso disso! Deves prestar atenção ao tipo de público que estás a alcançar, aos melhores momentos para publicares, e ao gosto e tendências que se seguem. As tuas plataformas de redes sociais fornecer-lhe-ão essa informação assim que alterares o teu perfil para uma conta de negócios.',

  // Instagram
  'instagram-intro-p1': 'O Instagram é uma rede social que todo autor deve usar hoje em dia. O cenário do livro do Instagram, popularmente chamado de bookstagram, é uma enorme comunidade repleta de potenciais leitores. Construir uma forte presença no Instagram pode ser bastante difícil, pois requer que produzas conteúdo visual de alta qualidade regularmente. ',
  'instagram-intro-p2': 'Há três coisas principais que deves priorizar quando estiveres no Instagram: construir um feed esteticamente agradável, usar hashtags relevantes e variadas (adicionamos algumas hashtags de exemplo nos modelos de postagens, mas tens total liberdade para mudá-las e/ou adicionar outras) e publicar Stories.',
  'instagram-promoteoninstagram': 'Promove o teu livro no Instagram',
  'instagram-switchtophone': 'Ir para o teu celular',
  'instagram-switchtophone-description': 'Terás que utilizar o teu celular para postar conteúdo no Instagram. Podes escanear o código QR abaixo para ir à versão mobile desta página. A maioria dos smartphones irão escanear códigos QR automaticamente se abrires o aplicativo de câmara do aparelho e a apontares ao código. Se não podes escanear o código QR, também podes fazer o login no sistema pelo teu celular.',
  'instagram-setupinsta-title': 'Monta o teu Instagram',
  'instagram-week1-title': 'Semana 1 - Anúncio ',
  'instagram-week1-message': 'Aqui está, finalmente: a primeira cópia impressa do meu novo livro, {title}. Este é um marco tão grande para mim e eu não poderia estar mais entusiasmado por partilhá-lo convosco! Se queres me apoiar, o livro está disponível agora por {price}. O link está na minha bio!\n\n{keywords} #autopublicação #amantesdelivros #publicado #lançamentodelivro',
  'instagram-week2-title': 'Semana 2 - Post das Primeiras Vendas',
  'instagram-week2-chooseanimage-description': 'Quando fores criar o visual para o teu post de primeiras vendas, é importante que tornes ele pessoal, pois é isso que os teus seguidores querem ver. Tenta incluir o teu rosto, o teu livro… de preferência ambos. Por exemplo, podes postar uma foto tua parado em frente a uma livraria segurando uma cópia do teu livro.',
  'instagram-week2-message': 'Estou muito entusiasmado por ter vendido os meus primeiros 5 livros! Muito obrigado a todos os que compraram uma cópia! Não posso dizer-lhes o quanto agradeço o seu apoio. Espero que perdoem a autopromoção que estou prestes a fazer, mas se acreditas que a ideia de ler um livro que eu escrevi com as minhas próprias mãos é remotamente interessante, por favor considera ir ao meu site e ver se te interessas mesmo. Podes ler a sinopse, admirar a capa que escolhi, e talvez até mesmo comprar uma cópia? O link está na minha bio!\n\n#autopublicação #vidadeautor',
  'instagram-week3-title': 'Semana 3 - Post de Sorteio',
  'instagram-week3-chooseanimage-description': 'Quando estiveres a criar um visual para o teu post de sorteio, faz sentido que mostres o que exatamente estás a sortear. No nosso exemplo abaixo, nós estamos sorteando 5 cópias assinadas. Neste caso, podes tirar uma foto de 5 cópias do teu livro com uma estando aberta para mostrar a tua assinatura. Certifica-te que a iluminação esteja adequada para que os livros fiquem tão bonitos quanto podem.',
  'instagram-week3-message': 'Passaram-se umas semanas loucas desde que o meu livro {title} saiu, e estou sobrecarregado com a resposta incrivelmente positiva que obteve até agora, já para não falar do apoio que recebi pessoalmente. Obrigado a todos pelas vossas amáveis palavras. Se ainda não conseguiste pôr as mãos no livro, então o que vem a seguir pode interessar-te; vou sortear 5 cópias aos meus seguidores! Os livros serão todos assinados, por isso, no caso de eu ficar famoso, terás algo fixe na tua prateleira ;)\n\nTudo o que tens de fazer é curtir este post e marcar um amigo que achas que também pode se interessar pelo livro! Vou selecionar 5 comentários ao acaso e anunciar os vencedores nos meus Stories na próxima semana!\n\n#sorteio #bookstagram #autorindependente',
  'instagram-week4-title': 'Semana 4 - Pedido de Resenha',
  'instagram-week4-chooseanimage-description': 'Quando estiveres a pedir resenhas, estarás a depender da bondade de teus seguidores. Portanto, é importante que faças uso dos visuais para conectar-te com eles. Uma foto cândida de ti próprio segurando o teu livro para a câmara é uma maneira simples de conquistar este resultado. Se quiseres ser mais criativo, podes colocar alguns livros em uma cesta e escrever um cartaz com as palavras “Deixa-me uma resenha” na frente. Também podes optar por criar um Stories com o pedido de resenha ao invés de um post; essa opção tem maiores probabilidades de alcançar mais pessoas.',
  'instagram-week4-message': 'Olá a todos, estou aqui para vos pedir um favor!\n\nSe leste o meu livro, por favor, escreve uma pequena resenha sobre ele no Goodreads! Seria incrivelmente útil para aumentar a visibilidade dele online e fazê-lo chegar a mais pessoas. Mesmo que não tenhas tempo para escrever uma resenha, apenas uma classificação seria ótimo. Agradecia-te muito. Além disso, muito obrigado a todos os que compraram o meu livro, não fazem ideia do quanto significa para mim!\n\n#resenha #autopublicação #bookstagram #autorindependente',
  'instagram-tips-title': 'Sugestões',
  'instagram-tips-visuals-label': 'A importância dos visuais',
  'instagram-tips-visuals': 'Como deves saber, o Instagram tem tudo a ver com os visuais. Isto pode parecer um desafio para ti como um autor que tenta promover o teu livro, mas não tem de ser uma desvantagem. Algumas sugestões de postagens para o teu conteúdo do Instagram podem ser a capa do teu livro, algumas fotos nos bastidores do teu processo de escrita, e também do teu dia-a-dia como autor.',
  'instagram-tips-quotes-label': 'Citações',
  'instagram-tips-quotes': 'Para estimular a curiosidade do teu público, uma tática de sucesso é partilhar citações do teu livro! Apesar de o Instagram ser muito visual, editar fotografias com uma frase nelas é sempre uma boa forma de partilhar a tua escrita.',
  'instagram-tips-interact-label': 'Interage',
  'instagram-tips-interact': 'O teu foco não deve estar apenas na promoção do teu livro, mas também em envolver-te com o teu público. Quanto mais interação, mais atenção a tua conta receberá. É essencial que partilhes as publicações dos teus leitores nos teus Stories ou comentar as suas publicações. Mantém a comunidade envolvida.',
  'instagram-tips-bookstagram-label': 'Bookstagram',
  'instagram-tips-bookstagram': 'Há uma enorme comunidade de leitores de livros no Instagram que se reúnem sob o termo "Bookstagram". Esta comunidade online partilha fotografias dos livros que estão a ler e do que planeiam ler, e são extremamente influentes. Como autor, tu deves estender a mão e seguir estas pessoas, com o objetivo final de fazê-los partilhar o teu livro no seu perfil. Deves seguir contas com o número de seguidores entre 1.000-20.000 para começar, porque o seu engajamento é muitas vezes alto e é mais fácil para iniciares uma conversa. Lembra-te de criar mensagens personalizadas!',
  'instagram-tips-addlinks-label': 'Adiciona links',
  'instagram-tips-addlinks': 'É importante adicionar um link na tua biografia que conduza a uma página web onde o teu livro está à venda. Podes adicionar o link à nossa loja, ou ao teu site de autor, e as pessoas no teu Instagram poderão comprar o teu livro com alguns cliques!',
  'instagram-tips-balance-label': 'Cria um equilíbrio',
  'instagram-tips-balance': 'Tem em mente que o Instagram prioriza o engajamento, e não a partilha de conteúdos. Por isso, não é necessário publicar todos os dias. No entanto, é bom procurar publicar 2-3 dias por semana. Além disso, usar o Instagram Stories para partilhar fotografias menos editadas e com mais conteúdo do dia-a-dia pode fazer crescer o engajamento e chamar a atenção do Instagram.',
  'instagram-tips-contests-label': 'Executa sorteios',
  'instagram-tips-contests': 'Não há nada que as pessoas gostem mais do que ganhar coisas de graça. Criar um sorteio é a forma perfeita de chamares a atenção das pessoas para ti e de aumentar o teu público. Podes oferecer uma cópia assinada do teu livro e pedir às pessoas que sigam e comentem para participarem do sorteio!',
  'instagram-tips-scheduleposts-label': 'Agenda as tuas postagens',
  'instagram-tips-scheduleposts': 'Ser um autor é um trabalho árduo, e muitas vezes vais encontrar-te pressionado pelo tempo quando precisas de criar e carregar novos conteúdos. Uma boa estratégia é usar várias ferramentas online para agendar os teus posts. Ao fazê-lo, podes criar vários posts ao mesmo tempo e agendá-los para as próximas semanas. Se criares uma conta de negócios no Facebook, terás acesso ao Creator Studio, que te permite criar e agendar publicações tanto no Facebook como no Instagram. Este é um bom lugar para começar.',

  // facebook
  'facebook-copyandpastetext': 'Clica aqui para copiar o texto acima. Em seguida, copia este texto no espaço de status após clicar no botão de partilhar.',
  'facebook-button-shareonfb': 'Compartilhar no Facebook',
  'facebook-intro-p1': 'A melhor maneira para aproveitares ao máximo os teus esforços de marketing no Facebook é maximizar o teu engajamento. Para tal, tenta obter o maior número possível de curtidas e comentários e encontra grupos relevantes dos quais possas te tornar um membro.',
  'facebook-promoteviafacebook': 'Promove o teu livro no Facebook',
  'facebook-week1-title': 'Semana 1 - Anúncio',
  'facebook-week1-message': 'Hoje é o dia! Sou agora oficialmente um autor publicado. Tem sido uma longa jornada com muito trabalho duro, mas ter o meu livro em minhas mãos faz com que tudo tenha valido a pena. Caso não tenhas seguido o meu progresso e estejas interessado no que se trata este livro, aqui está a sinopse:\n\n{shortdesc}\n\nSe quiseres saber mais sobre o meu livro, ele está disponível agora no meu website:',
  'facebook-week2-title': 'Semana 2 - Post das Primeiras Vendas',
  'facebook-week2-message': 'Estou muito entusiasmado por ter vendido as primeiras 5 cópias do meu livro! Gostaria de agradecer imensamente a todos que compraram uma cópia, o seu apoio significa o mundo pra mim. Espero que perdoem a autopromoção que estou prestes a fazer, mas se acreditas que a ideia de ler um livro que eu escrevi com as minhas próprias mãos é remotamente interessante, por favor considera ir ao meu site e ver se te interessas mesmo. Podes ler a sinopse, admirar a capa que escolhi, e talvez até mesmo comprar uma cópia?',
  'facebook-week3-title': 'Semana 3 - Sorteio',
  'facebook-week3-message': 'Ter me tornado um autor tem sido ótimo até agora. Todos têm me apoiado imensamente e eu não poderia estar mais feliz do que estou. Eu nunca imaginei que o meu livro pudesse ser tão bem recebido e, para celebrar, estou organizando um sorteio! Portanto, se ainda não tiveres adquirido uma cópia tua, é fácil participar: tudo o que precisas fazer é curtir e partilhar este post e deixar um comentário descrevendo o teu livro favorito com o mínimo possível de palavras. Eu escolherei os cinco melhores comentários e enviarei às pessoas que os escreveram uma cópia assinada do {title}.',
  'facebook-week4-title': 'Semana 4 - Pedido de Resenha',
  'facebook-week4-message': 'Muito obrigado a todos os que compraram o meu livro; vocês são fantásticos! Voltei para pedir-te outro pequeno favor... Seria uma grande ajuda para mim se fosses ao aplicativo Goodreads e escrevesses uma resenha para o meu livro! Mesmo apenas uma classificação seria tremenda, e se não já tiveres uma conta no Goodreads ou não quiseres criar uma, uma resenha em qualquer plataforma ou em qualquer loja também seria muito apreciada. Obrigado com antecedência!',
  'facebook-tips-title': 'Sugestões',
  'facebook-tips-authorpage-label': 'Página de autor',
  'facebook-tips-authorpage': 'É importante que cries páginas para desenvolver conteúdos específicos no Facebook. Ao criares uma página de autor, o teu público poderá conhecer-te melhor e obter as informações mais recentes sobre o teu livro.',
  'facebook-tips-engagement-label': 'Engajamento',
  'facebook-tips-engagement': 'O algoritmo do Facebook dá muita importância ao engajamento. Não há garantia de que os teus seguidores e amigos irão ver todas as tuas publicações, então a melhor maneira de maximizar o número de impressões que o teu conteúdo pode receber é encorajando o engajamento. Quanto mais curtidas e comentários tiveres, maior é a probabilidade de o Facebook dar prioridade às tuas publicações! Se lidares com isso corretamente, causarás um efeito bola de neve no qual as curtidas e partilhamentos que teus posts receberem estimularão mais engajamento, tornando os teus posts mais visíveis e levando a mais engajamento ainda!',
  'facebook-tips-sellyourbook-label': 'Vendendo o teu livro',
  'facebook-tips-sellyourbook': 'É importante que coloques links que liguem diretamente aos canais de vendas onde o teu livro está disponível, como a nossa loja ou o teu website. É importante que facilites o acesso do teu público ao teu livro e às informações sobre ele e sobre ti mesmo.',
  'facebook-tips-promoteothers-label': 'Promove outros livros',
  'facebook-tips-promoteothers': 'Este conselho pode parecer contraproducente, mas é, na verdade, uma estratégia comumente utilizada. Quanto mais interagires com outros autores, mais te ajudarão em retorno. É sempre bom partilhar resenhas e promover alguns livros para fazeres parte de uma comunidade ativa.',
  'facebook-tips-sharewriting-label': 'Partilha tuas citações',
  'facebook-tips-sharewriting': 'Para que o teu público te conheça como autor, é importante partilhar algumas das tuas escritas. Por isso deverias criar publicações incluindo algumas citações ou passagens do teu livro! As citações são importantes para motivar o interesse das pessoas em comprar o teu livro e acompanhar a tua jornada como autor.',
  'facebook-tips-startagroup-label': 'Cria um grupo ou participa de um',
  'facebook-tips-startagroup': 'Os grupos são um aspeto central do Facebook. Muitas pessoas gostam de fazer parte de comunidades menores no grande mundo do Facebook. Como autor, tu podes encontrar grupos relevantes dentro do teu género, que são excelentes lugares para partilhar mais sobre ti e o teu livro. Podes até criar um grupo do zero para construíres lentamente a tua própria comunidade no Facebook!',

  // Twitter
  'twitter-intro-p1': 'O Twitter é o lugar para as pessoas que podem fazer as suas palavras contarem, por isso, como escritor, deves sentir-te em casa.',
  'twitter-intro-p2': 'Para que o Twitter funcione para ti é preciso tweetar e, talvez ainda mais importante, retweetar com frequência. Fica de olho em hashtags relevantes para livros, publicações, seu género em particular e até mesmo os teus interesses pessoais. Certifica-te de contribuir com substância para quaisquer discussões com as quais te envolveres. O potencial de vendas do Twitter é muito menos direto do que o do Instagram, mas pode, no entanto, ser útil para obter seguidores e, por extensão, embaixadores para o teu livro.',
  'twitter-promoteviatwitter': 'Promove o teu livro no Twitter',
  'twitter-button-shareviatwitter': 'Compartilhar no Twitter',
  'twitter-charcount': 'Usaste {chars} de 280 caracteres',
  'twitter-week1-title': 'Semana 1 - Anúncio',
  'twitter-week1-message': 'O meu livro, {title} finalmente saiu! Podes ir ao meu site para encomendares uma cópia tua, ou lê alguns capítulos de amostras para teres uma ideia do livro primeiro! #autorindependente',
  'twitter-week2-title': 'Semana 2 - Post das Primeiras Vendas',
  'twitter-week2-message': 'Está a acontecer! Os 5 primeiros livros foram vendidos! Muito obrigado a todos os que compraram uma cópia. Se mais alguém estiver interessado no meu livro, {title}, podes obtê-lo no meu site.',
  'twitter-week3-title': 'Semana 3 - Sorteio ',
  'twitter-week3-message': 'Atenção #amantesdelivros! Está na hora de um #sorteio! Se queres ganhar uma cópia assinada do meu livro, segue-me, curte e retweeta este tweet, e deixa um comentário explicando por que queres uma cópia do livro!',
  'twitter-week4-title': 'Semana 4 - Pedido de Resenha',
  'twitter-week4-message': 'Atenção a todos os leitores! Se leste o meu livro, {título} e gostaste, por favor, considera ir ao Goodreads e deixar-me uma resenha! Não só gostaria de ler as tuas opiniões, como também faria maravilhas à visibilidade do livro! #autopublicação',
  'twitter-tips-title': 'Sugestões',
  'twitter-tips-sharequotes-label': 'Partilha citações',
  'twitter-tips-sharequotes': 'Como estás, sem dúvida, familiarizado com o Twitter, não vamos aprofundar o básico. Como é uma plataforma focada em mensagens concisas e baseadas em texto, é muito bem adaptada aos escritores. Uma forma óbvia de fazer uso do Twitter é partilhar citações do teu livro. Tenta mostrar ao teu público quem és como escritor.',
  'twitter-tips-interaction-label': 'Interação acima de tudo',
  'twitter-tips-interaction': 'O Twitter não se trata apenas de partilhar as tuas próprias ideias, mas também de participar em discussões em curso; provavelmente ainda mais quando és novo na plataforma. O Twitter cria a possibilidade para que possas interagir com potenciais leitores, outros autores e, claro, com os teus próprios fãs. Quanto mais interação, melhor!',
  'twitter-tips-balance-label': 'Equilíbrio',
  'twitter-tips-balance': 'Com as vendas de livros como o teu principal objetivo, é fácil perder-te na constante comercialização do teu livro. No entanto, é importante que mostres um lado pessoal, e ainda melhor, engraçado de ti mesmo. As pessoas não querem ler constantemente tweets que se resumem a "compra o meu livro", eles também querem ouvir as tuas histórias e ter um vislumbre da tua vida!',
  'twitter-tips-pinapost-label': 'Fixa um tweet',
  'twitter-tips-pinapost': 'Se estiveres a publicar regularmente no Twitter e estiveres preocupado que informações específicas sobre o teu livro possam perder-se ou ser ignoradas, uma boa ideia seria fixar um tweet! Podes fixar um tweet envolvente que se liga à compra do teu livro e que todos podem ver uma vez que entram no teu perfil. Então só tens de os levar lá.',
  'twitter-tips-bio-label': 'Biografia',
  'twitter-tips-bio': 'Muito do teu tempo no Twitter é focado apenas em tweetar, mas não negligencies a tua biografia! Será visível para todos, por isso é um bom lugar para espremeres algumas palavras descrevendo-te e incluindo o título do teu livro. Faz-te parecer interessante!',

  // LinkedIn
  'linkedin-intro-p1': 'O LinkedIn tem mais potencial do que se pensa para autores autopublicados. Para começar, a plataforma foi praticamente feita à medida para aqueles que escrevem não-ficção. No entanto, mesmo que tenhas escrito um livro de ficção ainda podes encontrar uma audiência que te apoies no LinkedIn. É uma plataforma adequada para partilhares a tua escrita e a tua jornada de publicação de uma perspectiva que gostamos de chamar de "autorempresário". Enquadra o teu livro como um projeto e fala sobre os desafios e recompensas envolvidos no teu trabalho. Mas não te esqueças de ser humano!',
  'linkedin-intro-p2': 'Postar no LinkedIn requer que estejas um pouco mais preparado do que em outras plataformas. É melhor que evites ser muito “vendedor” e ao invés foques em partilhar conhecimento e experiências. Por exemplo, escrever um artigo irá requerer um certo esforço mas será muito mais bem recebido na plataforma do que uma postagem completamente focada em vendas.',
  'linkedin-promotevialinkedin': 'Promove o teu livro no LinkedIn',
  'linkedin-copyandpastetext': 'Clica aqui para copiar o texto acima. Em seguida, copia este texto no espaço de status após clicar no botão de partilhar.',
  'linkedin-button-shareonlinkedin': 'Compartilhar no LinkedIn',
  'linkedin-week1-title': 'Semana 1 - Anúncio',
  'linkedin-week1-message': 'Estou extremamente orgulhoso ao anunciar que o meu livro, {title}, finalmente foi publicado! Me tornar um autor sempre foi um sonho meu e realizá-lo tem sido uma experiência incrível. Se tiver algum feedback sobre o livro ou quiser discuti-lo em detalhe comigo, eu adoraria conectar-me convosco! Para aqueles que estão interessados, clique neste link para poder saber mais!\n\n#autorempresário #publicação #livros',
  'linkedin-week2-title': 'Semana 2 - Lançamento do Livro',
  'linkedin-week2-message': 'Organizei o meu primeiro evento de lançamento para o meu livro alguns dias atrás, e foi incrível! Poder ver todos reunidos para celebrar comigo a publicação do meu livro foi ao mesmo tempo muito animador e gratificante. Gostaria de agradecer novamente a todos os que compareceram e, para todos que não puderam aparecer, aqui estão algumas fotos legais tiradas no evento!\n\n#lançamentodelivro #autopublicado #autor',
  'linkedin-week3-title': 'Semana 3 - Workshop',
  'linkedin-week3-message': 'O meu livro {title} tem vendido muito bem recentemente, e estive notando um grande interesse não apenas no assunto de que tratei no meu livro, mas também nos processos de escrita e autopublicação. Isso me deu a ideia de organizar um workshop gratuito para discutir processos de escrita, autopublicação, e como é ser um autor independente convosco! Acredito que seria muito gratificante trocar ideias e pensamentos uns com os outros. Caso esteja interessado, deixe-me saber!\n\n#workshop #autorempresário',
  'linkedin-week4-title': 'Semana 4 - Sorteio',
  'linkedin-week4-message': 'Publicar o meu livro foi uma das coisas mais desafiadoras que já fiz, mas valeu a pena. Eu nunca imaginei que o livro acabaria vendendo tão bem, e eu gostaria de celebrar esta vitória distribuindo 5 cópias de graça! Se quiser participar do sorteio, basta reagir a este post e deixar um comentário descrevendo porque acredita que o assunto que tratei no meu livro é importante hoje! Os donos dos 5 comentários mais inspiradores ganharão uma cópia do {title}!\n\n#autorempresário #autopublicação #sorteio',
  'linkedin-tips-title': 'Sugestões',
  'linkedin-tips-finishprofile-label': 'Termina de montar o teu perfil... e realmente termina',
  'linkedin-tips-finishprofile': 'Isto pode parecer óbvio, mas muitas pessoas adiam completar todos os passos dos seus perfis no Linkedin. Não terminar o teu perfil significa que há menos maneiras de as pessoas encontrarem ou identificarem-se contigo.',
  'linkedin-tips-connect-label': 'Conecta-te',
  'linkedin-tips-connect': 'O Linkedin tem tudo a ver com networking, já que a plataforma baseia-se na criação de ligações com as pessoas. Tens de te certificar de que adicionas outros autores, potenciais leitores e assim por diante. Criar conteúdo para uma pequena rede não te levarás a lugar algum.',
  'linkedin-tips-publisharticles-label': 'Publica artigos',
  'linkedin-tips-publisharticles': 'O Linkedin oferece a possibilidade de publicar artigos e é extremamente útil para um aspirante a autor! Podes usar esta plataforma para partilhar partes do teu livro, ou talvez escrever artigos nos quais partilhas dicas e experiências que outros aspirantes a autores possam desfrutar. As pessoas tendem a envolver-se e ler muito no Linkedin, e os artigos são perfeitos para um autor que tem algo a dizer! Além disso, os artigos são uma ótima maneira de canalizar o tráfego para o teu site.',
  'linkedin-tips-jobposition-label': 'Faz com que o teu livro seja a tua descrição de emprego',
  'linkedin-tips-jobposition': 'Podes colocar o teu livro como o teu próprio emprego! O título refere-se ao teu nome e a empresa seria o nome do teu livro. Desta forma, as pessoas podem ver claramente o teu livro no teu perfil Linkedin. Podes adicionar as datas que trabalhaste no teu livro juntamente com uma boa descrição. Esta é também uma maneira perfeita de anunciar potenciais continuações.',
  'linkedin-tips-behuman-label': 'Sê humano',
  'linkedin-tips-behuman': 'Uma vez que o Linkedin é conhecido principalmente por ser uma plataforma de negócios, as pessoas muitas vezes pensam que precisam ser formais quando lá se vão publicar. No entanto, se fores um autor que tentas vender o teu livro, ganharás pontos desenvolvendo um perfil personalizado e deves focar-te nisso! Não tenta ser algo que não és. As pessoas no LinkedIn enlouquecem por histórias de crescimento pessoal e superação de desafios, por isso usa isso a teu favor.',

  // Libraries
  'libraries-intro-p1': 'Todo autor legítimo deve ter o seu livro na Biblioteca Nacional. Na verdade, a BNP tem o direito de ter uma cópia do teu livro; isso é conhecido como depósito legal. Ainda assim, mesmo sem contar com as formalidades, depositar o teu trabalho na Biblioteca Nacional é uma ótima maneira de te legitimar como autor. É também uma ótima oportunidade de marketing! Se conseguires que um bom número de pessoas procurem pelo teu livro nas suas bibliotecas locais, podes até acabar vendendo umas cópias!',

  // Upload zone
  'uploadzone-default-title': 'Carrega o teu ficheiro',
  'uploadzone-default-title-progress': 'A carregar o seu manuscrito',
  'uploadzone-filerequirements': 'Requisitos do Arquivo: max. {filesize}, formatos: {filetypes}',
  'uploadzone-button-selectfile': 'Escolhe o ficheiro',
  'uploadzone-ordragfilehere': 'arrasta-o para aqui',
  'uploadzone-dropfiletoupload': 'Arraste para aqui um arquivo para o carregar',

  // Book problem translations
  'bookproblem-link-moreinfo': 'Mais informações',
  'bookproblem-stepScriptSizeModifiedUploadAgain': 'Mudaste o formato do teu livro. Por favor, volta a carregar o teu manuscrito.',
  'bookproblem-stepScriptColorModifiedUploadAgain': 'Mudaste as definições de cor do teu manuscrito. Por favor, volta a carregar o teu manuscrito.',
  'bookproblem-stepScriptBindingModifiedUploadAgain': 'Mudaste o teu tipo de livro. Por favor, volta a carregar o teu manuscrito.',
  'bookproblem-stepScriptReaddirectionModifiedUploadAgain': 'You’ve changed the language of your book to one with a different writing directionality. Please reupload your manuscript so that the directionality corresponds with your chosen language.',
  'bookproblem-stepScriptManuscriptConversionFailed': 'Não fomos capazes de converter o teu ficheiro. Isto provavelmente aconteceu porque o teu ficheiro está corrompido. Tenta reparar e carregar de novo o teu ficheiro.',
  'bookproblem-stepScriptManuscriptColorpagesNotCalculated': 'Não fomos capazes de calcular correctamente o número de páginas coloridas do teu livro. Por favor contacta-nos para ajudar a resolver este problema, ou carrega uma nova versão do teu manuscrito.',
  'bookproblem-stepScriptManuscriptConversionTechnicalError': 'Ocorreu um erro técnico na conversão do teu manuscrito. Código de erro: {lasterror}',
  'bookproblem-stepScriptFontsNotEmbedded': 'Há fontes neste documento que não estão incorporadas corretamente. Consulta o nosso guia sobre a criação de um PDF para mais informações.',
  'bookproblem-stepScriptEbookConversionHasErrors': 'Detectámos vários erros ao converter o teu eBook. Tens de corrigir estes problemas antes de poderes publicar o teu livro.',
  'bookproblem-printerConstraintNumberpagesLessThanMinimum': 'O teu livro não contém páginas suficientes. Certifica-te de que o teu livro contém pelo menos {min} páginas (a contagem actual de páginas é {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanMaximum': 'O teu livro contém demasiadas páginas. Certifica-te de que o teu livro contém no máximo {max} páginas (a contagem actual de páginas é {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanCoverwidth': 'O teu livro contém demasiadas páginas para o formato e tipo de papel que escolheste. Certica-te de que o teu livro contém no máximo  {max} páginas, ou escolhe outro tipo de papel e/ou um formato mais estreito.',

  // Bookstep: manuscript
  'bookstep-Manuscript': 'Manuscrito',
  'bookstep-Manuscript-intro-p1-1': 'Se queres que o teu livro seja um sucesso, é essencial teres um manuscrito bem escrito e profissionalmente formatado. É por isso que é importante que tomes o teu tempo neste passo. É muito menos inconveniente conseguir logo fazer isto do que ter que voltar atrás e refazê-lo mais tarde. Estaremos ao teu lado para fornecer suporte durante o processo de escrita. Sente-te à vontade para utilizar os nossos serviços se desejares que um profissional dê uma vista de olhos no teu trabalho. Se o teu manuscrito já estiver concluído, podes simplesmente carregá-lo como um documento Word ou PDF. Após o carregares, vamos converter o arquivo num PDF pronto para impressão. Certifica-te que verificas este arquivo cuidadosamente e solicita sempre uma cópia de teste antes de publicares o teu livro. Existem poucas coisas tão irritantes quanto encontrar erros de escrita e outras falhas num livro já publicado.',
  'bookstep-Manuscript-intro-p1-3': 'Se queres que o teu livro seja um sucesso, é essencial teres um manuscrito bem escrito e profissionalmente formatado. É por isso que é importante que tomes o teu tempo neste passo. É muito menos inconveniente conseguir logo fazer isto do que ter que voltar atrás e refazê-lo mais tarde. Estaremos ao teu lado para fornecer suporte durante o processo de escrita. Sente-te à vontade para utilizar os nossos serviços se desejares que um profissional dê uma vista de olhos no teu trabalho. Se o teu manuscrito já estiver concluído, podes simplesmente carregá-lo como um documento Word ou PDF. Após o carregares, vamos converter o arquivo num PDF pronto para impressão. Certifica-te que verificas este arquivo cuidadosamente e solicita sempre uma cópia de teste antes de publicares o teu livro. Existem poucas coisas tão irritantes quanto encontrar erros de escrita e outras falhas num livro já publicado.',
  'bookstep-Manuscript-intro-p1-6': 'Se queres que o teu livro seja um sucesso, é essencial teres um manuscrito bem escrito e profissionalmente formatado. É por isso que é importante que tomes o teu tempo neste passo. É muito menos inconveniente conseguir fazer logo isto do que ter que voltar e refazê-lo mais tarde. Estaremos ao teu lado para fornecer suporte durante o processo de escrita. Ao criar um eBook, podes carregar um documento Word ou um arquivo ePub. A nossa plataforma irá processar o teu arquivo carregado, convertê-lo num ePub (se necessário), e criar uma versão pronta para ser publicada. Verifica completamente o arquivo pronto para publicação antes de continuares. Se estás a planear carregar um documento Word, é importante que uses uma formatação compatível com ePub, caso contrário, o eBook pode não ser legível. Se desejares carregar um ePub, recomendamos usares o Calibre para criá-lo.',
  'bookstep-Manuscript-intro-p1-7': 'Se quiseres que o teu livro seja um sucesso, é essencial teres um manuscrito bem escrito e profissionalmente formatado. É por isso que é importante tomares o teu tempo nesta etapa. Vamos acompanhar-te e dar-te apoio durante o processo de escrita. Podes carregar o teu manuscrito finalizado diretamente na nossa plataforma como PDF. Depois de carregares o teu manuscrito, mostraremos uma antevisão da versão final. Verifique este ficheiro de pré-visualização minuciosamente! Há poucas coisas tão aborrecidas como encontrar erros de digitação e outros erros no teu livro publicado.',
  'bookstep-Manuscript-button-nextstep': 'Próximo passo',
  'bookstep-Manuscript-resources-toolstoimprovescript': 'Ajuda com o teu manuscrito',
  'bookstep-Manuscript-resources-wordtemplate': 'Modelo e definições do Word',
  'bookstep-Manuscript-resources-wordtemplate-description': 'Utiliza estas definições e modelos para formatares o teu livro no Word',
  'bookstep-Manuscript-resources-bookspecs': 'Especificações para profissionais',
  'bookstep-Manuscript-resources-bookspecs-description': 'Configurações para InDesign, QuarkXPress, etc.',
  'bookstep-Manuscript-resources-bookformatting': 'Formata o teu livro',
  'bookstep-Manuscript-resources-bookformatting-description': 'Um pequeno guia sobre a melhor forma de formatares o teu livro',
  'bookstep-Manuscript-resources-services-needhelp': 'Precisas de ajuda?',
  'bookstep-Manuscript-resources-services-needhelp-description': 'Informação sobre os nossos serviços pagos',
  'bookstep-Manuscript-UploadBlock-title-0': 'O meu manuscrito',
  'bookstep-Manuscript-UploadBlock-title-1': 'Estamos a converter o seu manuscrito',
  'bookstep-Manuscript-UploadBlock-title-2': 'Verifica o teu manuscrito ',
  'bookstep-Manuscript-uploadblock-stillwriting-title': 'Ainda estou a escrever',
  'bookstep-Manuscript-uploadblock-stillwriting-description': 'Ainda estás a trabalhar no teu manuscrito? Se precisas de inspiração, talvez queiras dar uma vista de olhos nas nossas dicas ou nos vários serviços de publicação que oferecemos.',
  'bookstep-Manuscript-uploadblock-stillwriting-button': 'Escrever',
  'bookstep-Manuscript-uploadblock-stillformatting-title': 'Formatação',
  'bookstep-Manuscript-uploadblock-stillformatting-description': 'Ainda precisa de formatar o teu manuscrito? Usa os nossos modelos ou consulta os nossos serviços de formatação para te certificares de que o teu manuscrito é o melhor possível.',
  'bookstep-Manuscript-uploadblock-stillformatting-button': 'Formatar',
  'bookstep-Manuscript-uploadblock-conversioninprogress-title': 'Um momento por favor, estamos a verificar e converter o seu manuscrito',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1': 'Verifica a tua pré-visualização de impressão',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-desc': 'Convertemos o teu manuscrito num PDF pronto para impressão. O teu livro vai ser impresso como é mostrado neste documento, por isso verifica-o minuciosamente! Podes enviar uma nova versão sempre que quiseres.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-previewbutton': 'Verificar ficheiro de impressão',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3': 'Verifica a tua pré-visualização de impressão',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-desc': 'Convertemos o teu manuscrito num PDF pronto para impressão. O teu livro vai ser impresso como é mostrado neste documento, por isso verifica-o minuciosamente! Podes enviar uma nova versão sempre que quiseres.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-previewbutton': 'Verificar ficheiro de impressão',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6': 'Verifica o teu eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-desc': 'Vamos converter o teu arquivo num eBook. Esta é a versão final do teu manuscrito, a versão que os leitores vão receber ao adquirir o teu livro, portanto, certifica-te de verificá-la cuidadosamente! Podes carregar uma nova versão do teu manuscrito sempre que quiseres.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-previewbutton': 'Verifica o teu eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7': 'Verifica o teu PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-desc': 'Convertemos o teu manuscrito num PDF. Esta é a versão final do teu manuscrito, a versão que os leitores vão receber ao adquirir o teu livro, portanto, certifica-te de verificá-la cuidadosamente! Podes carregar uma nova versão sempre que quiseres.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-previewbutton': 'Verifica o teu PDF eBook',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-uploadnewbutton': 'Carregar novo ficheiro',
  'bookstep-Manuscript-tips-title': 'Dicas para um bom manuscrito',
  'bookstep-Manuscript-tips-print-sizeinterior-label': 'Formatação interior / especificações',
  'bookstep-Manuscript-tips-print-sizeinterior-word': 'Se estiveres a usar o Word, recomendamos que descarregues um dos nossos modelos de manuscrito, clicando no botão acima. Este modelo foi criado especialmente para o tipo de livro que selecionaste, o que significa que não precisas de te preocupar com a sua formatação e podes apenas preencher o seu texto. Esta é a maneira mais fácil de garantir que o layout do teu livro corresponde ao do teu manuscrito.',
  'bookstep-Manuscript-tips-print-sizeinterior-indesign': 'Se estiveres a usar o InDesign ou outro programa de formatação profissional, clica no botão denominado “Especificações do livro”. Vais encontrar aí todas as informações de que precisas para formatar o manuscrito perfeito.',
  'bookstep-Manuscript-tips-print-images-label': 'Como adicionar imagens ao teu manuscrito',
  'bookstep-Manuscript-tips-print-images': 'Se desejares, podes, obviamente, adicionar imagens ao teu manuscrito. Uma imagem com uma resolução de 250 dpi é suficiente para garantir imagens impressas de alta qualidade. Basta colocares as imagens onde queres que apareçam no teu manuscrito (caso queiras colocar imagens perto das bordas, tem cuidado com o sangramento). Se quiseres fazer uma página espelhada, ou seja, uma imagem que cobre duas páginas, podes cortar a imagem a meio e colocar a secção mais à esquerda numa página par, e a metade mais à direita na página ímpar seguinte.',
  'bookstep-Manuscript-tips-print-colophon-label': 'Página de Colofão',
  'bookstep-Manuscript-tips-print-colophon-p1': 'É normal que o teu livro comece com uma página de título e uma página de colofão. De modo geral, a ordem é a seguinte:',
  'bookstep-Manuscript-tips-print-colophon-firstpage': 'Primeira página: Exibe o título do livro',
  'bookstep-Manuscript-tips-print-colophon-secondpage': 'Segunda página: Vazia',
  'bookstep-Manuscript-tips-print-colophon-thirdpage': 'Terceira página: Título do livro, seguido do nome do autor',
  'bookstep-Manuscript-tips-print-colophon-fourthpage': 'Quarta página: Esta é a página colofão, que deve incluir o nome do autor, o ISBN (se tiveres um), o nome do designer da capa, a declaração de direitos autorais, o ano de publicação e a indicação de que é um livro publicado pela {publicbrand}',
  'bookstep-Manuscript-tips-print-colophon-p2': 'Estas páginas são conhecidas como a "capa" do teu livro. As páginas listadas acima não são numeradas. Isso significa que a numeração das tuas páginas vai começar na página 5. Os nossos modelos de manuscrito já estão configurados de acordo com este padrão.',
  'bookstep-Manuscript-tips-print-formattinginterior-label': 'Formatar o teu bloco de texto',
  'bookstep-Manuscript-tips-print-formattinginterior': 'Sem um manuscrito bem formatado, o teu livro perde valor. Para te ajudarmos a evitar isso e a não perderes leitores, escrevemos um guia completo sobre como podes formatar o teu manuscrito de maneira adequada.',
  'bookstep-Manuscript-tips-print-formattinginterior-link': 'Como formatar o teu manuscrito',
  'bookstep-Manuscript-tips-print-minimumpagecount-label': 'Número mínimo de páginas',
  'bookstep-Manuscript-tips-print-minimumpagecount': 'Para imprimirmos livros de qualidade, eles precisam de conter um certo número de páginas. O número mínimo de páginas varia dependendo do tipo de livro que está a ser impresso, mais especificamente, consoante o tipo de capa, o tipo de papel e as dimensões do livro. Por exemplo, um livro de bolso tem um número mínimo de páginas menor do que um livro de capa dura. Este requisito varia de acordo com o tipo de papel usado, pois todos têm uma gramatura diferente. Na primeira etapa do processo de criação do livro, onde escolhes as suas especificações, mostramos-te o número mínimo de páginas necessárias para o livro que queres selecionar.',
  'bookstep-Manuscript-tips-print-pageorientation-label': 'Orientação da página',
  'bookstep-Manuscript-tips-print-pageorientation': 'Apesar de isto parecer trivial ou óbvio, vale a pena mencioná-lo: a primeira página do teu manuscrito está sempre posicionada à direita. Mesmo que a parte interna da capa, chamada folha final, pareça uma página em branco, ela não é contabilizada como uma. As páginas à esquerda são sempre pares. Como tal, quando duas páginas são adjacentes uma à outra, haverá sempre uma página par à esquerda e uma página com um número mais alto e ímpar à direita. É útil teres isso em mente se estiveres a fazer um álbum de fotos e quiseres uma página espelhada. Lembra-te de que nós não adicionamos os números de página - é algo que precisas de fazer antes de carregares o teu manuscrito.  No entanto, os nossos modelos de manuscrito incluem números de página, portanto, se tiveres problemas com a numeração das páginas podes tentar utilizar um deles.',
  'bookstep-Manuscript-tips-epub-epubexplain-label': 'Sobre ePubs',
  'bookstep-Manuscript-tips-epub-epubexplain': 'Um ficheiro eBook (chamado ePub) funciona de uma maneira um pouco diferente daquela que estás à espera. Por exemplo, um ePub não tem um número de páginas definido. Em vez disso, é redimensionável, o que significa que o texto se adapta ao tamanho da tela em que está a ser lido.  Numa tela menor, as frases serão encurtadas e o número total de páginas aumenta. O inverso acontece num dispositivo com uma tela maior. Resumindo, o layout de um eBook depende do dispositivo usado pelo leitor.',
  'bookstep-Manuscript-tips-epub-usingword-label': 'Utilizar o Word',
  'bookstep-Manuscript-tips-epub-usingword': 'Podes usar o Word para criar o teu ePub, porém, existem algumas regras que deves seguir para garantir que ele funciona corretamente. Em primeiro lugar, não basta carregares na tecla Enter quando quiseres inserir uma quebra de página. Se fizeres isso, o espaço vai aparecer como linhas em branco no teu ePub, o que significa que os leitores verão grandes lacunas no texto. Recomendamos que leias o nosso guia sobre como criar um ePub utilizando o Word antes de começares. Podes lê-lo clicando em ‘Formatar Manuscrito’ no topo desta página.',
  'bookstep-Manuscript-tips-epub-images-label': 'Adicionar imagens',
  'bookstep-Manuscript-tips-epub-images': 'Queres adicionar imagens ao teu Ebook? Não há problema! Tem apenas em conta que, se estiveres a criar o teu ePub no Word, as tuas imagens devem estar “alinhadas com o texto” e não com “quebra de texto”. Podes verificar e alterar isso utilizando a opção “Posição” no guia de formatação de imagens no Word.',
  'bookstep-Manuscript-tips-epub-cover-label': 'Incluir uma capa no ePub vs fazê-lo separadamente',
  'bookstep-Manuscript-tips-epub-cover': 'Ao carregares um arquivo ePub, tens a opção de incluir uma capa, embora isso não seja obrigatório. Assumindo que a tua capa atende às nossas especificações, iremos incorporá-la automaticamente e exibi-la na etapa de design da capa. Se quiseres carregar o teu manuscrito como um arquivo do Word, não será possível fazer essa incorporação e terás que enviar ou criar a tua capa na etapa “design da capa”.',
  'bookstep-Manuscript-tips-epub-calibre-label': 'Calibre: criar o ePub perfeito',
  'bookstep-Manuscript-tips-epub-calibre': 'Se desejas criar do zero o teu próprio ePub, recomendamos que utilizes a aplicação Calibre. É um programa de fácil utilização que permite a criação de um arquivo ePub. Podes até mesmo importar documentos do Word e usá-los como base para o teu ePub. Para obteres mais informações, confere os nossos guias sobre como fazer um ePub usando o Calibre.',
  'bookstep-Manuscript-tips-epub-checkepub-label': 'Verifica o teu ePub!',
  'bookstep-Manuscript-tips-epub-checkepub': 'O arquivo ePub que criamos após carregares o teu manuscrito é a versão final, o que significa que é a versão que os teus leitores vão receber ao comprarem o teu eBook. É por isso que é crucial que verifiques este arquivo completamente. Usa um software ePub confiável para o fazeres, como o Calibre, o Adobe Digital Editions, o Aldiko (para Android) ou o Apple Books (para iOS). Também podes usar um e-reader (por exemplo, um Kindle).',

  'bookstep-Manuscript-popup-bookspecs-bookspecs': 'Especificações do Livro',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word': 'Microsoft Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word-description': 'Utiliza estas definições e modelos para formatares o teu livro no Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof': 'Especificações para profissionais',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof-description': 'Configurações para InDesign, QuarkXPress, etc.',
  'bookstep-Manuscript-popup-bookspecs-manuscriptspecs': 'Especificações do manuscrito',
  'bookstep-Manuscript-popup-bookspecs-pagesize': 'Tamanho da página (excluindo sangramento): {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-inclbleed': 'Tamanho da página incl. sangramento: {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-nospecificreq': 'nenhum requisito',
  'bookstep-Manuscript-popup-bookspecs-bleed': 'Sangramento: {bleeds}',
  'bookstep-Manuscript-popup-bookspecs-bleed-nobleedreq': 'Sangramento não necessário',
  'bookstep-Manuscript-popup-bookspecs-bleed-top': 'superior',
  'bookstep-Manuscript-popup-bookspecs-bleed-bottom': 'inferior',
  'bookstep-Manuscript-popup-bookspecs-bleed-outside': 'externa',
  'bookstep-Manuscript-popup-bookspecs-bleed-inside': 'interna',
  'bookstep-Manuscript-popup-bookspecs-nopagenumheadfoot': 'Não adicione números às páginas, cabeçalhos e rodapés',
  'bookstep-Manuscript-popup-bookspecs-imagesinline': 'Certifique-se de alinhar as imagens com o texto. Não use imagens avulsas',
  'bookstep-Manuscript-popup-bookspecs-uploadspecs': 'Especificações de upload',
  'bookstep-Manuscript-popup-bookspecs-fileformats': 'Formato do arquivo: {formats}',
  'bookstep-Manuscript-popup-bookspecs-noprintmarks': 'Nenhuma marca de impressão, corte ou sangramento',
  'bookstep-Manuscript-popup-bookspecs-nospreads': 'Não use spreads',
  'bookstep-Manuscript-popup-bookspecs-imagesdpi': 'Imagens: min. {dpinum} dpi',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate': 'Modelo Word',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate-p1': 'Ao formatares o teu manuscrito no Microsoft Word, recomendamos vivamente que utilizes o nosso modelo. Este modelo é feito por medida para o teu livro e já tem as configurações de formatação correctas. Descarrega o modelo, clicando no botão abaixo.',
  'bookstep-Manuscript-popup-bookspecs-word-downloadtemplate': 'Descarregar modelo',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins': 'Definir as margens corretas e o tamanho da página',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p1': 'Se estiveres a utilizar o nosso modelo, não terás de te preocupar com margens ou sangramento. As definições corretas já foram aplicadas ao documento.',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p2': 'Se não estiveres a utilizar o nosso modelo, terás de te certificar de que o tamanho da página no teu documento manuscrito corresponde às dimensões do formato do livro que escolheste. Se não o fizeres, escalaremos automaticamente o tamanho da página do teu ficheiro carregado para corresponder a essas dimensões. Isto pode levar a alterações no layout do teu livro. Além disso, terás de ter em conta o sangramento e adicioná-lo às tuas margens.',
  'bookstep-Manuscript-popup-bookspecs-word-exampleimage-representsbleed': 'representa o sangramento',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed': 'O que é o sangramento?',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed-p1': 'Para evitar espaço branco indesejado na margem das páginas quando estas estão a ser cortadas, é necessário tornar a cor ou imagem de fundo ligeiramente maior do que o tamanho real da página. Este pedaço extra de margem é o que chamamos "sangramento". Se não tiveres cores ou imagens que cheguem à margem da página, ignorar o sangramento não levará a qualquer espaço branco não intencional, mas pode levar a ligeiras diferenças na formatação do teu livro, uma vez que podemos precisar de aumentar automaticamente o teu documento para adicionar este sangramento. Poderás ver quaisquer alterações na pré-visualização da impressão depois de ter carregado o teu ficheiro manuscrito.',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-odd': 'Página esquerda (ímpar)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-even': 'Página esquerda (par)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-odd': 'Página direita (ímpar)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-even': 'Página direita (par)',
  'bookstep-Manuscript-popup-services-needhelp': 'Precisas de ajuda?',
  'bookstep-Manuscript-popup-services-doyouneedsupport': 'Precisas de apoio adicional?',
  'bookstep-Manuscript-popup-services-weofferpaidservices': 'Oferecemos vários serviços pagos para te ajudar a melhorar o teu manuscrito. Por exemplo, oferecemos os serviços de um professor de escrita, feedback sobre o teu manuscrito (assim como, uma verificação rápida ou uma edição completa do mesmo). E podemos, ainda, tratar da formatação do teu manuscrito ou fornecer-te um ePub profissional.',
  'bookstep-Manuscript-popup-services-areyouinterested': 'Estás interessado? Clica no botão abaixo e entraremos em contacto contigo dentro de 2 dias úteis.',
  'bookstep-Manuscript-popup-services-contactme': 'Contacta-me',
  'bookstep-Manuscript-popup-services-requestsent': 'Enviamos o teu pedido',
  'bookstep-Manuscript-popup-services-wehavesentrequest': 'Enviamos o teu pedido',
  'bookstep-Manuscript-popup-services-wewillcontactyousoon': 'Enviamos o teu pedido de informações adicionais sobre os nossos serviços pagos. Entraremos em contacto contigo dentro de dois dias úteis para discutir as opções!',
  
  'bookstep-Manuscript-guidepopup-wordtips': 'Dicas para Microsoft Word',
  'bookstep-Manuscript-guidepopup-wordtips-description': 'As nossas dicas para te ajudar a criar um livro com o Microsoft Word',
  'bookstep-Manuscript-guidepopup-pdffontsincl': 'Criar um PDF',
  'bookstep-Manuscript-guidepopup-pdffontsincl-description': 'Um pequeno guia sobre como criar um ficheiro PDF',
  'bookstep-Manuscript-guidepopup-createepub': 'Criar ePub',
  'bookstep-Manuscript-guidepopup-createepub-description': 'Um pequeno guia sobre como criar um ficheiro ePub',
  'bookstep-Manuscript-guidepopup-colorpages': 'Guia de páginas a cores',
  'bookstep-Manuscript-guidepopup-colorpages-description': 'As nossas dicas sobre como gerir correctamente páginas coloridas',

  // Bookstep: Writing
  'bookstep-Writing': 'Escrita',
  'bookstep-Writing-intro-p1': 'Escrever um livro é uma das coisas mais divertidas e recompensadoras que podes tentar fazer, mas há vezes em que também pode ser bastante difícil. Mesmo que não possamos escrever o teu livro por ti, podemos apoiar-te durante o processo. Quer estejas a escrever ficção ou não, é fundamental que estrutures bem o teu processo de escrita. A maioria dos escritores começa com energia e bom humor e acaba por ficar preso no meio do processo. Portanto, faz um favor a ti próprio: elabora um plano e cumpre-o. Não sabes como? Consulta o nosso guia.',
  'bookstep-Writing-helpwithwriting-title': 'Ajuda com a escrita',
  'bookstep-Writing-createwritingplan-title': 'Guia de escrita',
  'bookstep-Writing-writeblurb-title': 'Escreve a tua sipnose',
  'bookstep-Writing-writeblurb-p1': 'Recomendamos que comeces por escrever a tua sipnose. Desta forma, serás forçado a considerar a visão geral da tua obra. Sem dúvida que vais escrever mais do que uma versão da tua sinopse. Compartilha essas versões com outras pessoas e pede o seu feedback.',
  'bookstep-Writing-writeblurb-p2': 'Embora uma sinopse não seja muito longa, o processo de destilar a essência do teu livro em algumas frases é um exercício inestimável. Vai ajudar-te a criar um plano de escrita sólido e funcionar como uma base para o processo de escrita em si. Além disso, a sinopse pode incentivar ou não a venda do teu livro, já que é um dos principais fatores que os leitores consideram ao decidir se lhe darão ou não uma oportunidade.',
  'bookstep-Writing-writeblurb-howtowriteblurb': 'Como escrever uma boa sipnose',
  'bookstep-Writing-writeblurb-howtowriteblurb-p1': 'Como em qualquer outra coisa, uma boa maneira de aprender a escrever uma boa sinopse é através de um estudo de sinopses já existentes. Isto vai dar-te uma ideia mais clara de quais são as melhores práticas para a escrita de uma sinopse dentro do género do teu livro. No entanto, independentemente do tipo de livro que estejas a planear escrever, existem alguns critérios gerais que todas as sipnoses devem cumprir:',
  'bookstep-Writing-writeblurb-howtowriteblurb-list1': 'Uma primeira frase cativante: precisas intrigar o leitor desde o início. Isso pode ser alcançado apresentando uma citação particularmente promissora, fazendo uma pergunta convincente ou oferecendo uma solução para um problema.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list2': 'Um resumo: deves dar ao leitor um esboço da história sem revelar nenhum ponto importante do enredo.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list3': 'Uma promessa: o leitor quer saber o que pode esperar do teu livro. Se estás a escrever ficção, cria suspense ou provoca algum drama. Se estás a escrever não-ficção, oferece soluções para problemas ou conhecimento acerca de um determinado assunto.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list4': 'Sobre o autor: Certifica-te que incluis informações sobre ti que sejam relevantes para o livro; experiências da vida real, outros livros que escreveste, etc. Isto é particularmente relevante para não ficção.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list5': 'Foto do autor: opcional, mas muitos dos autores que optam por incluir uma, fazem-no de forma errada. Tenta usar uma foto com uma resolução alta o suficiente (300 dpi), caso contrário, corres o risco de fazer o teu livro parecer pouco profissional.',
  'bookstep-Writing-createplan-title': 'Como criar um plano de escrita',
  'bookstep-Writing-createplan-p1': 'Escrever, como qualquer outro processo criativo, não pode sempre ser forçado. No entanto, aconselhamos-te vivamente a criar um plano de escrita. Podes dividi-lo em duas partes:',
  'bookstep-Writing-createplan-list1': 'Planejamento: quando vais escrever? Quanto tempo vai durar cada sessão de escrita? Essas escolhas dependem de ti. Algumas pessoas acordam cedo para escrever, enquanto outras são criaturas da noite, que só podem escrever depois de concluírem outras tarefas. Cria um esboço para o teu livro, divide-o em capítulos e faz um plano para cada um deles. Não sintas que precisas de começar do início. Podes pular para qualquer ponto da história desde que isso te leve a escrever.',
  'bookstep-Writing-createplan-list2': 'Enredo: resolve primeiro os traços gerais da tua história; que personagens vais apresentar e como é que elas se vão desenvolver? Como é que se vai desenrolar a história para chegar aos principais pontos da narrativa? A fórmula mais popular para um enredo é provavelmente a “jornada do herói". Isto acontece até mesmo em livros de não ficção. As pessoas tendem a gostar de seguir um protagonista e ver a maneira como ele supera obstáculos e cresce como pessoa.',
  'bookstep-Writing-writefeedbackrewrite-title': 'Escrever, escrever, feedback, reescrever',
  'bookstep-Writing-writefeedbackrewrite-p1': 'Se conseguiste criar um esboço do teu enredo, está na hora de preencheres as suas lacunas. É aqui que entra a escrita, propriamente dita, do teu livro. Não desanimes, não precisas de te arrastar capítulo por capítulo. Podes facilmente começar no meio ou no final, ou onde sintas que tens mais inspiração. Este método tende a ser muito mais produtivo do que te forçares a escrever tudo em ordem.',
  'bookstep-Writing-writefeedbackrewrite-p2': 'Assim que terminares alguns capítulos, pede a amigos e/ou familiares que leiam o teu trabalho e te dêem opiniões honestas. Certifica-te que escolhes revisores que não se intimidem com críticas construtivas, já que elogios não te vão ajudar muito nesta fase do processo. Existem algumas coisas que deves ter em mente em relação à tua escrita:',
  'bookstep-Writing-writefeedbackrewrite-list1': 'Vais escrever de uma perspectiva de primeira ou terceira pessoa?',
  'bookstep-Writing-writefeedbackrewrite-list2': 'Presente ou pretérito?',
  'bookstep-Writing-writefeedbackrewrite-list3': 'A consistência é a chave.',
  'bookstep-Writing-writefeedbackrewrite-list4': 'Mostra, não digas.',
  'bookstep-Writing-writefeedbackrewrite-list5': 'Cria tensão e uma atmosfera que atraia os teus leitores.',
  'bookstep-Writing-feedbackediting-title': 'Feedback e edição',
  'bookstep-Writing-feedbackediting-p1': 'Escrever é uma forma de arte e os artistas tendem a ser inseguros sobre o seu próprio trabalho. Não deixes que as dúvidas sejam desencorajadoras, são uma parte normal do processo. Em vez disso, usa esse sentimento para pedires aos teus revisores que te façam uma crítica construtiva para melhorares ainda mais a tua história.',
  'bookstep-Writing-feedbackediting-p2': 'Assim que o teu manuscrito estiver concluído, pede ao maior número de pessoas para o rever, talvez em troca de um café. Lembra-te de que a maioria das pessoas ainda prefere ler em papel, então pode ser uma boa ideia imprimires algumas cópias do teu manuscrito para os revisores que o fazem. A maioria das pessoas sente-se lisonjeada quando lhe pedem este tipo de ajuda e ficará feliz em ajudar.',
  'bookstep-Writing-feedbackediting-p3': 'É importante teres a certeza de que o teu manuscrito está livre de erros ortográficos e gramaticais. Nada prejudica mais a impressão do leitor sobre a tua escrita que um erro gramatical. Dependendo das tuas ambições e meios, podes querer considerar os nossos serviços de edição ou verificação rápida. Um especialista irá ler todo o teu manuscrito ou parte dele, e dará o seu feedback sobre o estilo, consistência, enredo, desenvolvimento do personagem, etc.',
  'bookstep-Writing-nextstep': 'Próximo passo: formatação',
  'bookstep-Writing-uploadnow': 'Carregue agora',
  'bookstep-Writing-tips-title': 'Dicas de escrita',
  'bookstep-Writing-tips-titlesubtitle-label': 'Titulo e subtitulo',
  'bookstep-Writing-tips-titlesubtitle': 'Já tens um título e um subtítulo em mente para o teu livro? Nesse caso, pode pular esta parte, mas a maioria das pessoas tende a ter problemas para decidir um título. Recomendamos que anotes todas as tuas ideias de títulos numa sessão de brainstorming (chuva de ideias). Provavelmente irás selecionar rapidamente os principais concorrentes, ou até mesmo um vencedor absoluto, quando tiveres todas as opções à tua frente. Recomendamos que mostres os três principais candidatos ao título aos teus amigos ou seguidores das redes sociais e lhes perguntes que título eles preferem. No final das contas, as pessoas vão ter em consideração três coisas principais ao determinarem se querem comprar o teu livro: a capa (incluindo o título), a sinopse e a pré-visualização, então, vale a pena saberes a opinião popular sobre estes aspetos do teu livro.',
  'bookstep-Writing-tips-blurb-label': 'Sipnose',
  'bookstep-Writing-tips-blurb': 'Se conseguires despertar o interesse do leitor com a tua capa, é provável que ele dê uma vista de olhos na sinopse. Este é o momento-chave no processo de tomada de decisão dos teus clientes e vai decidir se uma venda é concluída ou não. Quer o teu livro seja de ficção ou de não-ficção, há algumas coisas em que os teus potenciais leitores estarão interessados: o texto chama à sua atenção? Há um problema que o teu livro vai resolver? Ou uma questão interessante que vai levantar? A sinopse precisa deixar o leitor a desejar saber mais. Se conseguires fazer isso, há uma boa possibilidade de que o teu livro seja comprado. Portanto, pede que as pessoas revejam a sinopse do teu livro e te digam se o comprariam só com base nela.',
  'bookstep-Writing-tips-howdoyoufeelabouit-label': 'Qual é a tua opinião?',
  'bookstep-Writing-tips-howdoyoufeelabouit': 'Tenta que as pessoas revejam o teu trabalho desde o início. O teu manuscrito não precisa de estar concluído para que obtenhas opiniões valiosas sobre ele. Quer consigas que as pessoas leiam uma única página ou vários capítulos, pede opiniões honestas. Geralmente, as pessoas vão gostar de ler o teu trabalho e de te ajudar. Também podes postar um capítulo nas redes sociais e pedir feedback dessa forma. Este último método permite matar dois coelhos com uma cajadada: tu recebes feedback e, simultaneamente, promoves o teu livro.',
  'bookstep-Writing-tips-marketing-label': 'Marketing',
  'bookstep-Writing-tips-marketing': '“Marketing? Eu ainda nem terminei o meu livro!” Exatamente. Este é o momento de começares a pensar sobre como vais comercializar o teu livro. Nesta fase inicial, podes começar aos poucos a despertar algum interesse por ele. Compartilha a tua jornada como escritor nas redes sociais, fala sobre como tudo está a correr e explica as dificuldades que estás a enfrentar. Podes também publicar a tua capa quando estiver pronta e partes dos teus capítulos já concluídos. É provável que recebas muito incentivo. Estas são apenas algumas ideias que podes incluir no teu plano de marketing. Não o negligencies; é a melhor forma de construir uma rede de seguidores e embaixadores, que será muito importante ao tentar fazer com que o teu livro seja notado depois de publicado.',
  'bookstep-Writing-tips-support-label': 'Suporte',
  'bookstep-Writing-tips-support': 'Estás bloqueado e precisas de ajuda ou queres apenas alguma opinião profissional sobre o teu manuscrito? Oferecemos vários pacotes de serviços a preços acessíveis, permitindo que envies o teu manuscrito a um escritor, editor ou coach profissional e fazendo com que estes possam aprimorar o teu manuscrito. Podes optar por uma verificação rápida, uma revisão completa, uma verificação ortográfica, edição e até mesmo workshops. Se realmente levas o teu livro a sério, envolve a tua rede de contactos e/ou contrata um dos nossos editores para te ajudar a criar o melhor livro possível.',
  'bookstep-Writing-tips-dontgiveup-label': 'Não desistas!',
  'bookstep-Writing-tips-dontgiveup': 'Praticamente todos os autores se deparam com um obstáculo de vez em quando; desilusões, bloqueios de escrita, e tantas outras coisas. Não desistas! Se necessário, faz uma pausa e vai fazer outra coisa por um bocado. Dá uma caminhada, conversa com as pessoas, distrai-te de outras maneiras. A tua motivação vai voltar em breve. Se estiveres à procura de mais ajuda durante o processo de escrita, temos um livro para ti: ‘É assim que se escreve um livro’ é um guia de escrita que publicamos em colaboração com a coach de escrita Esther Jacobs. Ele cobre detalhadamente toda a jornada, desde a ideia inicial até ao livro publicado, e fornece conselhos para cada etapa do processo de escrita.',

  // Bookstep: Formatting
  'bookstep-Formatting': 'Formatação',
  'bookstep-Formatting-intro-p1': 'O texto é, obviamente, a parte mais importante do teu manuscrito, mas, independentemente da sua qualidade, sem uma boa formatação, a probabilidade de alguém o querer ler é baixa. Um manuscrito bem formatado facilita a leitura, permitindo que o leitor se perca na história sem se distrair ou ficar confuso. Podes formatar o teu manuscrito sozinho, mas também podes solicitar que um profissional faça isso por ti. Se estás mais inclinado para a segunda opção, podes verificar o nosso serviço de formatação. Queres ser tu a fazê-lo? Verifica as nossas diretrizes para garantir que obtens o melhor resultado possível.',
  'bookstep-Formatting-helpwithformatting-title': 'Apoio à formatação',
  'bookstep-Formatting-guide-title': 'Formatação: passo-a-passo',
  'bookstep-Formatting-guide-print-template-title': 'Modelos',
  'bookstep-Formatting-guide-print-template-p1': 'Se estás a planear formatar o teu manuscrito por conta própria, recomendamos que trabalhes num documento que tenha sido previamente configurado. Isso vai poupar-te tempo e esforço. Se estiveres a usar o Word, sugerimos que uses um dos nossos modelos; eles são configurados para corresponderem às especificações do tipo de livro selecionado. Também contêm o título e as páginas de rosto, os números das páginas e as margens. Se já terminaste o teu manuscrito, podes simplesmente copiar o texto para o modelo - apenas não copies tudo de uma vez! Isso vai sobrescrever as configurações do modelo, algo que anula o propósito de o utilizar.',
  'bookstep-Formatting-guide-print-template-p2': 'Se estiveres a usar um programa de formatação profissional como o InDesign para criares o teu manuscrito, configura o teu documento de acordo com as especificações do formato de livro que escolheste. Certifica-te de ter uma margem de 3mm ao redor das bordas externas de cada página, ou seja, no lado direito das páginas pares e no lado esquerdo das páginas ímpares. Podes obter as especificações exatas do teu livro clicando no botão ‘Especificações do livro’ acima.',
  'bookstep-Formatting-guide-print-template-p3': 'Lembra-te: uma página ímpar está sempre à direita e uma página par está sempre à esquerda.',
  'bookstep-Formatting-guide-print-fonts-title': 'Fontes, tamanho de fontes, e espaçamento de linha',
  'bookstep-Formatting-guide-print-fonts-p1': 'Nós não te vamos dizer que fonte deves usar. É uma escolha pessoal e varia dependendo do livro. A primeira coisa que precisas de decidir é se desejas usar uma fonte serif ou sans serif. Serifas são pequenas linhas anexadas ao final das letras e considera-se que ajudam a tornar o texto impresso mais legível. As fontes com serifa são a escolha clássica para a impressão de livros e jornais. Fontes serif populares incluem Cambria, Garamond, Georgia e Times New Roman.',
  'bookstep-Formatting-guide-print-fonts-p2': 'As fontes sans serif não têm essas linhas, resultando numa estética mais moderna e minimalista. Este tipo de fontes é bastante usado online já que se considera que, nesse contexto, oferece uma melhor legibilidade do que as fontes serif. As fontes sans serif populares incluem Arial, Calibri, Helvetica e Verdana.',
  'bookstep-Formatting-guide-print-fonts-p3': 'Além disso, vais precisar de decidir o tamanho da fonte e o espaçamento das linhas. Mais uma vez, não há uma escolha correta; Tudo se resume a uma preferência pessoal. Geralmente, os tamanhos de fonte mais comuns são 10, 11 e 12, dependendo da fonte que está a ser usada. Se o teu livro tiver um público-alvo mais velho, usa um tamanho de fonte maior e mais espaçamento entre as linhas (normalmente entre 1,1 e 1,3). Imprimir algumas páginas do teu manuscrito é uma boa maneira de verificares se estás satisfeito com a fonte e o espaçamento que escolheste.',
  'bookstep-Formatting-guide-print-moreoptions-title': 'Mais opções de formatação',
  'bookstep-Formatting-guide-print-moreoptions-p1': 'Ainda não terminamos; existem mais algumas escolhas que precisas de fazer antes de continuares. Aqui está uma lista das mais importantes:',
  'bookstep-Formatting-guide-print-moreoptions-list1': 'Layout da página: o principal a se ter em mente são as margens. Certifica-te de que não são muito pequenas; cerca de 2 cm para a lombada (os lados voltados para dentro das páginas, onde o livro é encadernado). Parte das margens internas das páginas vai desaparecer, pois serão usadas para encadernar o livro. Os nossos modelos são feitos tendo isso em conta, então não precisas de te preocupar com as margens. Se não quiseres usar os nossos modelos, usa as seguintes medidas:',
  'bookstep-Formatting-guide-print-moreoptions-list1-topmargin': 'Margem superior: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-bottommargin': 'Margem inferior: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-innermargin': 'Margem interna: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-outermargin': 'Margem externa: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list2': 'Novos capítulos: começa cada novo capítulo numa nova página. Podes dar nomes, números ou ambos aos capítulos. Se estiveres a usar o Word, certifica-te de usar o estilo ‘Cabeçalho 1’ para o título/ número do capítulo. Desta maneira, garantes que a formatação de cada capítulo é a mesma.',
  'bookstep-Formatting-guide-print-moreoptions-list3': 'Parágrafos: podes optar por separar os parágrafos com linhas em branco, o que resulta numa experiência de leitura agradável. Também podes escolher recuar a primeira palavra de cada parágrafo. Independentemente do que escolhas, certifica-te de que os teus parágrafos não são muito longos, pois isso prejudica a legibilidade do teu texto.',
  'bookstep-Formatting-guide-print-moreoptions-list4': 'Imagens e texto: se o conteúdo do teu livro alternar entre texto e imagens, como num livro de receitas, coloca as imagens nas páginas pares (à esquerda) e o texto nas páginas ímpares (à direita).',
  'bookstep-Formatting-guide-print-uploadscript-title': 'Carregar o teu manuscrito',
  'bookstep-Formatting-guide-print-uploadscript-p1': 'Podes escrever o teu manuscrito usando qualquer software de processamento de texto da  tua preferência, mas, na nossa plataforma, só aceitamos que carregues arquivos do Word (.doc ou .docx) e PDFs. Portanto, se estiveres a trabalhar num programa diferente do Word (por exemplo, no Pages) vais precisar de converter o teu manuscrito num PDF, antes de continuares.',
  'bookstep-Formatting-guide-print-uploadscript-p2': 'Mesmo que tenhas escrito o teu manuscrito no Word, recomendamos que o convertas em PDF antes de o carregares na nossa plataforma. Isto minimiza o risco de aparecimento de erros de formatação durante o processo de conversão. Certifica-te de que cada página do teu manuscrito também é uma página separada no teu PDF convertido. Depois de enviares o teu manuscrito, vamos convertê-lo num PDF pronto para impressão. Verifica este arquivo completamente; verifica todas as páginas para te certificares de que estão formatadas corretamente.',
  'bookstep-Formatting-guide-print-uploadscript-p3': 'Ainda não estás contente? Não há problema. Podes carregar uma nova versão do teu manuscrito quantas vezes quiseres. A nossa plataforma vai substituir todas as versões anteriores que carregaste.',
  'bookstep-Formatting-guide-epub-howepubswork-title': 'Como funciona um ePub?',
  'bookstep-Formatting-guide-epub-howepubswork-p1': 'Antes de começares a trabalhar no teu eBook, é importante saberes como funcionam realmente, já que existem algumas diferenças importantes entre os eBooks e outros documentos digitais. Como tal, o que criaste utilizando o teu software de processamento de texto pode não aparecer como pretendes quando for lido num leitor electrónico.',
  'bookstep-Formatting-guide-epub-howepubswork-p2': 'Em primeiro lugar, os eBooks publicados através da nossa plataforma estarão sempre no formato ePub. Este é o formato padrão e universal dos eBooks, o que significa que pode ser lido em qualquer leitor eletrónico. Uma das características mais importantes dos ficheiros de ePub é que são fluídos. Isto significa que o texto no ficheiro adapta-se ao tamanho do ecrã em que está a ser lido. Num ecrã mais pequeno, as frases serão encurtadas, e o livro terá, portanto, mais páginas do que teria num ecrã maior. Isto significa que a disposição de um eBook depende do dispositivo que está a ser utilizado para o ler.',
  'bookstep-Formatting-guide-epub-howepubswork-p3': 'Como resultado, um segmento de texto pode aparecer numa única página no Word, mas ser dividido por várias páginas quando visto num leitor eletrónico. Tenta não te preocupar com o posicionamento exacto das linhas e parágrafos; em vez disso, certifica-te apenas de que o texto tem um fluxo lógico e de que o espaçamento dos parágrafos está correto. Isto produzirá um resultado óptimo para a maioria dos leitores.',
  'bookstep-Formatting-guide-epub-templatefonts-title': 'Modelos e Fontes',
  'bookstep-Formatting-guide-epub-templatefonts-p1': 'Ao formatares o teu livro eletrónico no Microsoft Word, recomendamos que um dos nossos modelos Word. Estes modelos são criados para assegurar um bom layout do teu eBook com o mínimo de incómodo, o que significa menos trabalho para ti. Podes descarregar os nossos modelos, clicando no botão “Word Template” acima.',
  'bookstep-Formatting-guide-epub-templatefonts-p2': 'As fontes são outra coisa em que não deves pensar muito quando estás a pensar no teu eBook. A grande maioria dos leitores electrónicos permite aos utilizadores selecionar as suas próprias fontes, o que significa que qualquer fonte que tenhas escolhido originalmente para escrever será, muito provavelmente, anulada. O nosso conselho seria, portanto, simplesmente selecionar uma fonte que te pareça boa e com a qual gostes de trabalhar. Georgia e Times New Roman são algumas escolhas clássicas de serifas, enquanto que Aria, Helvetica, ou Verdana tendem a ser boas opções se preferires sans serif.',
  'bookstep-Formatting-guide-epub-chapterstoc-title': 'Capítulos e o Índice',
  'bookstep-Formatting-guide-epub-chapterstoc-p1': 'Ao formatares o teu ebook no Microsoft Word, é importante fazeres um uso correto dos chamados “estilos”, por exemplo “Titulo 1”, “Titulo 2”, e assim por diante. Estes estilos são utilizados para estruturar o teu ebook e para gerar o índice durante o processo de conversão. O que se segue é uma pequena lista de estilos essenciais com que precisas de estar familiarizado:',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-normal': 'Texto normal: usado para o corpo do texto no teu manuscrito',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-title': 'Título: Usa isto para escrever o título do teu livro, por exemplo, na página de título',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading1': 'Título 1: Utiliza este título para títulos de capítulo. O título 1 está no topo da hierarquia da  página no índice. Configuramos os nossos modelos para que esse estilo apareça sempre numa nova página.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading2': 'Título 2:  Usa este título para subtítulos, se quiseres dividir os títulos dos capítulos em várias seções diferentes. No índice, este estilo está subordinado ao ‘Título 1’. Subtítulos raramente são usados ​​em romances, mas em livros educacionais, por exemplo, são bastante comuns.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading3': 'Título 3: este estilo pode ser usado se quiseres aprofundar ainda mais um tópico, algo que não seria possível apenas com um um único subtítulo. Eles são praticamente inexistentes em romances, mas podem aparecer em livros académicos.',
  'bookstep-Formatting-guide-epub-chapterstoc-p2': 'Se estás a planear criar o teu ePub manualmente, os capítulos e títulos funcionarão de forma um pouco diferente. Por conseguinte, é importante que faças o seguinte:',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-separatefiles': 'Certifica-te de que cada capítulo do teu ePub está num ficheiro HTML separado. Este ficheiro deve ser corretamente guardado no ficheiro OPF, onde a ordem dos ficheiros HTML é estabelecida.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh1tags': 'Os capítulos devem sempre começar com uma etiqueta HTML “h1” no teu ficheiro HTML. Esta etiqueta h1 deve conter o título e/ou número do capítulo.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh2h3tags': 'Ao utilizares subtítulos, podes utilizar etiquetas h2, h3, ou h4 (etc.). Estas funcionam da mesma forma que os títulos 1, 2, 3, etc. no Word.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-generatetoc': 'Certifique-se de gerar um índice para o teu ficheiro ePub. A maioria dos programas ePub pode fazê-lo automaticamente utilizando um ficheiro “NCX” que será adicionado ao teu ePub.',
  'bookstep-Formatting-guide-epub-moreformattips-title': 'Dicas de formatação extra',
  'bookstep-Formatting-guide-epub-moreformattips-p1': 'Para além das dicas já mencionadas, há mais algumas coisas que vale a pena ter em mente ao formatar o teu livro electrónico:',
  'bookstep-Formatting-guide-epub-moreformattips-list1': 'Números de página: Números de página: certifica-te de que o teu ficheiro Word ou ePub não contém nenhum número de página. Como mencionado anteriormente, as configurações individuais de cada ereader tornam os números de página redundantes . A sua inclusão só irá causar problemas.',
  'bookstep-Formatting-guide-epub-moreformattips-list2': 'Listas numeradas: se queres incluir uma lista numerada no teu eBook, deves saber que, embora seja possível, existem certas limitações. Certifica-te de que a tua lista não é interrompida por um parágrafo apenas para retomar mais tarde no texto (por exemplo, se os itens da tua lista forem particularmente longos). ePubs não permitem isto, o que significa que as tuas listas têm sempre de se conectar.',
  'bookstep-Formatting-guide-epub-moreformattips-list3': 'Imagens: podes adicionar imagens ao teu ficheiro ePub. Certifica-te de que as imagens que utilizas estão em formato JPG ou PNG, e que a sua resolução não é demasiado grande (recomendamos um limite superior de 1000x1000 pixels).',
  'bookstep-Formatting-guide-epub-moreformattips-list4': 'Capa: Ao carregares um documento Word, certifica-te de NÃO incluir a capa no documento Word. A capa é criada e/ou carregada na próxima etapa do processo de publicação (“Capa”). Se estiveres a criar o teu próprio ficheiro ePub, podes incluir a tua capa no ficheiro; se o fizeres correctamente e com uma resolução suficientemente alta, o nosso sistema irá exportá-la automaticamente para a etapa de capa.',
  'bookstep-Formatting-guide-epub-uploadscript-title': 'Carregar o teu manuscrito',
  'bookstep-Formatting-guide-epub-uploadscript-p1': 'Podes escrever o teu manuscrito utilizando qualquer programa que queiras, mas só aceitamos documentos Word (doc ou docx) ou ePubs quando for altura de os carregar. Isto significa que, se estiveres a trabalhar com um processador de texto que não o Microsoft Word, terás, primeiro, de converter o ficheiro para um ficheiro Word ou um ePub antes de o poderes carregar na nossa plataforma.',
  'bookstep-Formatting-guide-epub-uploadscript-p2': 'Uma palavra de aviso: na nossa experiência, a conversão de ficheiros criados no LibreOffice, Apple Pages ou outras aplicações de processamento de texto para ficheiros doc ou docx nem sempre corre bem, o que pode resultar em mensagens de erro quando tentares carregar estes ficheiros convertidos.',
  'bookstep-Formatting-guide-epub-uploadscript-p3': 'Depois de carregares o teu ficheiro, iremos convertê-lo num ePub. Poderás, depois, verificar este ePub usando um leitor electrónico ou uma aplicação que leia ePubs. É importante que revejas este ficheiro cuidadosamente e te certifiques de que ele está o mais próximo possível da perfeição, pois é a versão que vamos disponibilizar aos teus leitores.',
  'bookstep-Formatting-guide-epub-uploadscript-p4': 'Se tiveres dificuldades na formatação do teu eBook, podes utilizar o nosso serviço de formatação de eBooks. Tem em atenção que cobramos por este serviço.',
  'bookstep-Formatting-guide-pdfebook-template-title': 'Modelos',
  'bookstep-Formatting-guide-pdfebook-template-p1': 'Se estiveres a planear a formatação do teu manuscrito, recomendamos vivamente que comeces a trabalhar o mais cedo possível num documento devidamente preparado; de preferência quando começares a escrever o teu livro. Se estiveres a trabalhar no Microsoft Word, podes utilizar os nossos modelos. Caso já tenha terminado o teu manuscrito, podes simplesmente copiar o conteúdo para o modelo, mas assegura-te de não copiar e colar o manuscrito inteiro de uma só vez! Fazê-lo pode alterar a formatação, levando a problemas ao carregar o manuscrito.',
  'bookstep-Formatting-guide-pdfebook-template-p2': 'Se já estiveres satisfeito com a formatação do teu documento Word, podes simplesmente convertê-lo para PDF e carregá-lo. Este método é geralmente uma forma fiável de criar um Ebook em PDF.',
  'bookstep-Formatting-guide-pdfebook-template-p3': 'Se estiveres a utilizar um software profissional de design como o InDesign, podes aplicar as especificações do formato do teu livro escolhido ao documento.',
  'bookstep-Formatting-guide-pdfebook-fonts-title': 'Fontes, tamanho de letra, e espaçamento entre linhas',
  'bookstep-Formatting-guide-pdfebook-fonts-p1': 'Não te vamos dizer que fonte deves utilizar, já que essa escolha é bastante subjectiva e depende, até certo ponto, do tipo de livro que estás a escrever. A primeira decisão que precisas de tomar é se queres utilizar uma fonte serif ou sans serif. As fontes serif têm poucos “pés” nas extremidades dos traços. Geralmente, eles servem para ajudar a orientar os olhos do leitor ao longo da página. As fontes serif são, de longe, o tipo de letra mais utilizado nos livros. Cambria, Garamond, Georgia, e Times New Roman são algumas das fontes de serifas mais populares.',
  'bookstep-Formatting-guide-pdfebook-fonts-p2': 'As fontes Sans serif são mais simples e tendem a parecer mais “modernas”. São normalmente usadas em capas de livros e nos meios online. Algumas das fontes Sans serif mais populares são Arial, Calibri, Helvetica, e Verdana.',
  'bookstep-Formatting-guide-pdfebook-fonts-p3': 'Além disso, também é necessário decidir sobre o tamanho da fonte e o espaçamento entre linhas. Mais uma vez, não há aqui uma resposta correcta; é tudo subjetivo e também muito dependente da fonte que escolheste. 10, 11, e 12 são os tamanhos de fonte mais utilizados. Quanto ao espaçamento entre linhas, algo entre 1,1 e 1,3 é convencional.',
  'bookstep-Formatting-guide-pdfebook-fonts-p4': 'Se o teu livro tem um público-alvo com uma idade mais avançada, pode valer a pena considerar um tamanho de letra e um espaçamento de linha maiores. Pode ser útil imprimir algumas páginas do teu livro para se ter uma ideia da configuração que preferes.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-title': 'Mais opções de formatação',
  'bookstep-Formatting-guide-pdfebook-moreoptions-p1': 'Ainda não acabámos pessoal, ainda há algumas escolhas a fazer. Aqui estão as mais importantes:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1': 'Margens: Certifica-te de que são suficientemente grandes; 2 cm é uma escolha comum. Se não estiveres a utilizar os nossos modelos, então podes recriar uma disposição semelhante, definindo as margens da seguinte forma:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-topmargin': 'Margen superior: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-bottommargin': 'Margen inferior: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-leftmargin': 'Margen lados esquerdo: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-rightmargin': 'Margen lados direito: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list2': 'Novos capítulos: iniciar novos capítulos em novas páginas. Isto leva a uma melhor experiência de leitura. Podes dar aos teus capítulos um título, um número, ou ambos. Seja qual for a tua escolha, certifica-te de aplicar o estilo “Titulo 1” aos títulos dos teus capítulos no Word para te certificares de que a formatação é consistente.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list3': 'Parágrafos: Podes separar os parágrafos com uma linha em branco, ou através de um travessão da primeira palavra de um novo parágrafo. Basta te certificares de que os teus parágrafos não são demasiado longos; isso terá um impacto negativo na legibilidade.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-title': 'Carregar o teu manuscrito',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p1': 'É claro que podes utilizar o software de processamento de texto que desejares para escreveres o teu manuscrito, mas quando se trata de carregares um manuscrito para um eBook em PDF, só aceitamos PDFs. Como tal, independentemente de como criaste o teu manuscrito, terás de o converter para um PDF antes de o poderes carregar na nossa plataforma. A maioria dos programas tem uma ferramenta de conversão incorporada, em “Salvar como” ou “Exportar”. Normalmente, tudo o que precisas de fazer é selecionar uma destas opções e depois escolher PDF como o formato de ficheiro.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p2': 'Depois de teres carregado o teu manuscrito, iremos convertê-lo num PDF publicável que aparecerá no teu ecrã. Verifica este ficheiro minuciosamente e certifica-te de que o seu layout é perfeito em cada página.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p3': 'Não estás satisfeito com o resultado? Não há problema, nesta etapa, podes alterar e carregar de novo o teu manuscrito quantas vezes quiseres. Tem em atenção que, ao carregares uma nova versão do teu manuscrito, a versão antiga será alterada.',
  'bookstep-Formatting-nextstep': 'Próximo passo: carregar',
  'bookstep-Formatting-tips-title': 'Dicas de formatação',
  'bookstep-Formatting-tips-print-newpage-label' : 'Nova página',
  'bookstep-Formatting-tips-print-newpage': 'Quando um capítulo termina a meio da página e desejas iniciar o próximo capítulo numa nova página, nunca uses a tecla Enter. Se o fizeres, na conversão do documento em PDF, podem aparecer grandes espaços, deslocando todo o texto. Deves sempre usar CTRL + Enter (CMD + Enter no Mac) para criar uma quebra de linha no Word. Isso irá prevenir quaisquer problemas. O mesmo se aplica ao inserires imagens no teu manuscrito, resumidamente: não pressiones Enter para criares espaço!',
  'bookstep-Formatting-tips-print-colophon-label': 'Página de Colofão',
  'bookstep-Formatting-tips-print-colophon': 'É normal que o teu livro comece com uma página de título e uma página de colofão. De modo geral, a ordem é a seguinte:',
  'bookstep-Formatting-tips-print-colophon-page1': 'Primeira página: Exibe o título do livro',
  'bookstep-Formatting-tips-print-colophon-page2': 'Segunda página: Vazia',
  'bookstep-Formatting-tips-print-colophon-page3': 'Terceira página: Título do livro, seguido do nome do autor',
  'bookstep-Formatting-tips-print-colophon-page4': 'Quarta página: Esta é a página colofão, que deve incluir o nome do autor, o ISBN (se tiveres um), o nome do designer da capa, a declaração de direitos autorais, o ano de publicação e a indicação de que é um livro publicado pela {publicbrand}',
  'bookstep-Formatting-tips-print-colophon-rest': 'Estas páginas são conhecidas como a "capa" do teu livro. As páginas listadas acima não são numeradas. Isso significa que a numeração das tuas páginas vai começar na página 5. Os nossos modelos de manuscrito já estão configurados de acordo com este padrão.',
  'bookstep-Formatting-tips-print-images-label': 'Imagens',
  'bookstep-Formatting-tips-print-images': 'Claro que podes adicionar imagens ao teu manuscrito, se desejares. Uma imagem com uma resolução de 250 dpi é suficiente para garantir imagens impressas de alta qualidade. Basta colocares as imagens onde queres que apareçam no teu manuscrito (cuidado com o sangramento, se estiveres a colocar imagens perto das bordas). Se quiseres criar uma página espelhada, ou seja, uma imagem que cubra duas páginas, podes cortar a imagem ao meio e colocar a metade à esquerda numa página par, e a metade à direita na página ímpar seguinte.',
  'bookstep-Formatting-tips-print-wrongformat-label': 'Formato errado?',
  'bookstep-Formatting-tips-print-wrongformat': 'Ao carregares um documento com um formato que não corresponde ao formato de livro que escolheste, vamos redimensionar o documento para corresponder ao livro. Isso pode ocorrer sem problemas, especialmente se houverem apenas pequenas diferenças, mas também pode levar a problemas de formatação. Resumindo, verifica sempre cuidadosamente o PDF convertido que obtens da plataforma! Seria uma pena se solicitasses uma cópia de prova e só depois percebesses que a formatação está incorreta.',
  'bookstep-Formatting-tips-print-colorpages-label': 'Cor e Preto e Branco',
  'bookstep-Formatting-tips-print-colorpages': 'Se o teu livro contém páginas a preto e branco e páginas a cores, é importante que sigas um certo procedimento para evitar que as tuas páginas a preto e branco sejam impressas a cores. Clica aqui para saberes mais sobre como adicionar páginas a cores ao teu livro. {link}',
  'bookstep-Formatting-tips-print-colorpages-link': 'Clica aqui para leres todos os pormenores sobre como trabalhar com páginas a cores',
  'bookstep-Formatting-tips-print-proofcopy-label': 'Cópia de prova',
  'bookstep-Formatting-tips-print-proofcopy': 'Ainda não estás pronto para solicitar uma cópia de prova, mas vale a pena mencionar o seguinte: compra sempre uma cópia de prova antes de publicares o teu livro ou realizares um grande pedido de impressão. Não importa o quão minuciosamente verificaste (tu ou os teus revisores) o teu manuscrito digitalmente, vais sempre reparar em alguns erros na versão impressa. Erros de digitação, detalhes estéticos e cores ficam, a partir de um certo porto, difíceis de distinguir numa tela, e a cópia impressa vai ter sempre uma aparência ligeiramente diferente; vais ficar surpreso com o que encontrarás. É extremamente frustrante perceber esses pequenos erros depois de publicares ou imprimires o teu livro. Os descontos por quantidade também se aplicam a cópias de prova, portanto, podes facilmente solicitar várias e pedir a outras pessoas para te ajudarem a rever o teu rascunho final.',
  'bookstep-Formatting-tips-epub-chapterheadings-label': 'Títulos de capítulos',
  'bookstep-Formatting-tips-epub-chapterheadings': 'Para criar um bom Ebook, é importante utilizar os títulos dos capítulos certos. Podes fazê-lo no Microsoft Word utilizando os chamados “Estilos”, tais como “Título 1”. Se não o fizeres, o teu ebook será apenas um texto longo sem títulos, e sem nenhuma possibilidade de navegar entre capítulos.',
  'bookstep-Formatting-tips-epub-colophon-label': 'Páginas introdutórias',
  'bookstep-Formatting-tips-epub-colophon': 'É normal que o teu livro comece com uma página de título e uma página de rosto. De modo geral, a ordem é a seguinte:',
  'bookstep-Formatting-tips-epub-colophon-page1': 'Primeira página: Título do livro, seguido do nome do autor',
  'bookstep-Formatting-tips-epub-colophon-page2': 'Segunda página: Esta é a página colofão, que deve incluir o nome do autor, o ISBN (se tiveres um), o nome do designer da capa, a declaração de direitos autorais, o ano de publicação e a indicação de que é um livro publicado pela {publicbrand}',
  'bookstep-Formatting-tips-epub-colophon-rest': 'Se estiveres a utilizar o Microsoft Word, certifica-te de inserir uma quebra de página entre estas duas páginas, de modo a que fiquem separadas.',
  'bookstep-Formatting-tips-epub-pagenumbers-label': 'Sem número de páginas',
  'bookstep-Formatting-tips-epub-pagenumbers': 'Certifica-te de que não há números de página, ou outro texto de cabeçalho/rodapé no teu manuscrito. Se este tipo de itens forem deixados, aparecerão aleatoriamente no texto do teu eBook, em vez de aparecerem no local pretendido.',
  'bookstep-Formatting-tips-epub-images-label': 'Imagens',
  'bookstep-Formatting-tips-epub-images': 'Evidentemente, podes adicionar imagens ao teu eBook. Se optares por fazê-lo, tem em mente que as imagens têm que ser colocadas “em linha com o texto”. Não podes adicionar as chamadas imagens “flutuantes” com texto ao lado; as imagens devem ocupar sempre toda a linha com texto de acompanhamento acima e/ou abaixo delas.',
  'bookstep-Formatting-tips-pdfebook-newpage-label': 'Nova página',
  'bookstep-Formatting-tips-pdfebook-newpage': 'Quando um capítulo termina no meio da página e pretendes iniciar o capítulo seguinte numa nova página, nunca utilizes a tecla Enter para chegar à nova página. Se o fizeres, ao converteres o documento para PDF, aparecerão enormes espaços brancos ao longo de todo o texto. Portanto, deves sempre usar CTRL + Enter (CMD + Enter no Mac) para criar uma quebra de linha no Word. Isto evitará quaisquer problemas. O mesmo se aplica quando inserires imagens no teu manuscrito: por favor, não primas apenas Enter para deixar um espaço!',
  'bookstep-Formatting-tips-pdfebook-colophon-label': 'Página de Colofão',
  'bookstep-Formatting-tips-pdfebook-colophon': 'É normal que o teu livro comece com uma página de título e uma página de colofão. De modo geral, a ordem é a seguinte:',
  'bookstep-Formatting-tips-pdfebook-colophon-page1': 'Primeira página: Exibe o título do livro',
  'bookstep-Formatting-tips-pdfebook-colophon-page2': 'Segunda página: Vazia',
  'bookstep-Formatting-tips-pdfebook-colophon-page3': 'Terceira página: Título do livro, seguido do nome do autor',
  'bookstep-Formatting-tips-pdfebook-colophon-page4': 'Quarta página: Esta é a página colofão, que deve incluir o nome do autor, o ISBN (se tiveres um), o nome do designer da capa, a declaração de direitos autorais, o ano de publicação e a indicação de que é um livro publicado pela {publicbrand}',
  'bookstep-Formatting-tips-pdfebook-colophon-rest': 'Estas páginas são conhecidas como a "capa" do teu livro. As páginas listadas acima não são numeradas. Isso significa que a numeração das tuas páginas vai começar na página 5. Os nossos modelos de manuscrito já estão configurados de acordo com este padrão.',
  'bookstep-Formatting-tips-pdfebook-images-label': 'Images',
  'bookstep-Formatting-tips-pdfebook-images': 'Evidentemente, podes adicionar imagens ao teu manuscrito. Uma resolução de 96 dpi é suficiente para eBooks. Terás de ser tu a inserir as imagens no teu manuscrito; basta que te certifiques de que há espaço suficiente entre a imagem e o texto.',
  'bookstep-Formatting-tips-pdfebook-dimensions-label': 'Dimensões',
  'bookstep-Formatting-tips-pdfebook-dimensions': 'Não há restrições quanto às dimensões do teu PDF. No entanto, é uma boa ideia utilizares um tamanho padrão, de preferência algures entre A5 e A4, pois um formato extremamente grande ou pequeno pode ter um efeito negativo sobre a legibilidade do teu eBook.',

  // Account settings
  'AccountSettings-Security-button-2FA-title': 'Use a verificação em duas etapas',
  'AccountSettings-Security-button-2FA-subtitle-enabled': 'Ativado',
  'AccountSettings-Security-button-2FA-subtitle-disabled': 'Desligado',
  'AccountSettings-Security-button-2FA-subtitle': '{enableddisabled} • Solicitamos um código quando você faz login em um novo dispositivo.',

  // Checkout
  'Checkout-Cart-title-Yourcart': 'Teu carrinho de compras',
  'Checkout-Cart-RemoveFromCart': 'Apagar do carrinho',
  'Checkout-Cart-CartItem-Title-Buscards': 'Cartões de negócio',
  'Checkout-Cart-CartItem-Title-Isbn': 'ISBN',
  'Checkout-Cart-CartItem-Originally': 'Originalmente: <span>{price}</span>',
  'Checkout-Cart-CartItem-Forbook': 'Para livro: {bookTitle}',
  'Checkout-Cart-CartIsEmpty': 'O teu carrinho está vazio.',
  'Checkout-Cart-title-Total': 'Total',
  'Checkout-Cart-total-Subtotal': 'Subtotal <span>{price}</span>',
  'Checkout-Cart-total-Discount': 'Desconto <span>{price}</span>',
  'Checkout-Cart-total-Total': 'Total <span>{price}</span>',
  'Checkout-Cart-Button-ProceedCheckout': 'Finalizar compra',
  'Checkout-Cart-ErrorPopup-title': 'Alguns produtos do seu carrinho de compras não podem ser encomendados',
  'Checkout-Cart-ErrorPopup-p1': 'Se clicar no botão ‘Tramitar pedido’, eliminaremos todos os produtos indisponíveis do seu carrinho de compras e procederemos à finalização da compra.',
  'Checkout-Cart-ErrorPopup-unavailableprods': 'Produtos indisponíveis:',
  'Checkout-Cart-ErrorPopup-Button-Cancel': 'Cancelar',
  'Checkout-Cart-ErrorPopup-Button-ContinueOrder': 'Finalizar compra',
  'Checkout-Cart-WarningPopup-title': 'Está prestes a encomendar um ISBN e o livro associado em simultâneo.',
  'Checkout-Cart-WarningPopup-p1': 'Se encomendar um ISBN e o livro associado em simultâneo, o livro encomendado <b>NÃO</b> terá o ISBN. Recomendamos que encomende primeiro o ISBN e depois encomende o livro quando o ISBN tiver sido atribuído ao seu livro.',
  'Checkout-Cart-WarningPopup-p2': 'Se clicar no botão ‘Encomendar ISBN primeiro’, removeremos o(s) seguinte(s) livro(s) do seu carrinho:',
  'Checkout-Cart-WarningPopup-Button-IgnoreContinue': 'Ignorar e continuar',
  'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst': 'Encomendar ISBN primeiro',
  'Checkout-Error-CheckoutSpecApiError': 'Ocorreu um erro inesperado durante o checkout. Por favor, contacte o serviço de apoio e anexe uma captura do ecrã desta página. Desculpamo-nos pelo incómodo.',
  'Checkout-CheckoutDetails-Button-Save': 'Guardar',
  'Checkout-CheckoutDetails-Button-Next': 'Continuar',
  'Checkout-CheckoutDetails-Button-Cancel': 'Cancelar',
  'Checkout-CheckoutDetails-Button-NextStep': 'Próximo passo',
  'Checkout-CheckoutDetails-title-Email': 'E-mail',
  'Checkout-CheckoutDetails-title-ShippingAddress': 'Endereço de envio',
  'Checkout-CheckoutDetails-title-BillingAddress': 'Endereço de cobrança',
  'Checkout-CheckoutDetails-title-ShippingMethod': 'Método de envio',
  'Checkout-CheckoutDetails-Email-label-Email': 'E-mail',
  'Checkout-CheckoutDetails-Email-Button-EditEmail': 'Alterar e-mail',
  'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress': 'Teu endereço de envio',
  'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress': 'Alterar endereço de envio',
  'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label': 'Idêntico ao endereço de envio',
  'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress': 'Teur endereço de cobrança',
  'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress': 'Alterar endereço de cobrança',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-label': 'Tipo de cliente',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc': 'Especifique que tipo de cliente é. Os clientes empresariais exigem um número de IVA Europeu válido.',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private': 'Pessoa singular',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc': 'Cliente sem número Europeu de IVA',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company': 'Empresa',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc': 'Cliente com número Europeu de IVA',
  'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label': 'Escolha o teu método de envio preferido',
  'Checkout-Address-Phoneline': 'Tel: {phone}',
  'Checkout-Address-Vatline': 'Número de IVA: {taxno}',
  'Checkout-Address-Input-Fn': 'Primeiro nome',
  'Checkout-Address-Input-Ln': 'Sobrenome',
  'Checkout-Address-Input-L': 'País',
  'Checkout-Address-GenericAddress-S': 'Rua e número do edifício',
  'Checkout-Address-GenericAddress-P': 'Código postal',
  'Checkout-Address-GenericAddress-Nr': 'Número do edifício',
  'Checkout-Address-GenericAddress-Nr-Placeholder': 'Número',
  'Checkout-Address-GenericAddress-NrA': 'Número do edifício + complemento',
  'Checkout-Address-GenericAddress-NrA-Placeholder': 'Complemento',
  'Checkout-Address-GenericAddress-C': 'Cidade',
  'Checkout-Address-GenericAddress-R': 'Região',
  'Checkout-Address-Input-T': 'Telefone',
  'Checkout-Address-Input-Shipping-Cp': 'Empresa (na etiqueta de endereço)',
  'Checkout-Address-Input-Cp': 'Empresa',
  'Checkout-Address-Input-Shipping-V': 'Número fiscal',
  'Checkout-Address-Input-V': 'Número de IVA',
  'Checkout-Address-Button-Save': 'Guardar',
  'Checkout-AddressStreetWarningNoNumber': 'Atenção: o teu endereço não contém o número do estabelecimento',
  'Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND': 'Não foi possível encontrar um endereço para o código postal, número de edifício e complemento introduzidos. Por favor, verifique se introduziu tudo corretamente.',
  'Checkout-PostalcodecheckError-INVALID_API_CALL': 'Ocorreu um erro ao procurar o seu endereço. Por favor, contacte o serviço de apoio',
  'Checkout-CheckoutOverview-title-YourDeliveryDetails': 'Dados da encomenda',
  'Checkout-CheckoutOverview-title-Paymentmethod': 'Método de pagamento',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email': 'Email',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress': 'Teu endereço',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress': 'Endereço de envio',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress': 'Endereço de cobrança',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod': 'Método de envio',
  'Checkout-OrderSidebar-title-YourOrder': 'A tua encomenda',
  'Checkout-OrderSidebar-ProductItem-Quantity': 'Qtd.: {quantity}',
  'Checkout-OrderSidebar-totals-Shipping': 'Envio: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-Total': 'Total: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-IncludedVAT': 'Incluindo IVA: <span>{price}</span>',
  'CheckoutSuccess-title-Thankyou': 'Obrigado pela tua compra!',
  'CheckoutSuccess-YourOrderNoIs': 'O número do teu pedido é: <b>{orderId}</b>',
  'CheckoutSuccess-IntroWhatToExpect': 'Acabou de realizar uma encomenda. Veja abaixo quais os próximos passos.',
  'CheckoutSuccess-title-PaperbackHardcover': 'Capa Mole / Capa Dura',
  'CheckoutSuccess-title-Ebook': 'eBook',
  'CheckoutSuccess-title-Isbn': 'ISBN',
  'CheckoutSuccess-title-Buscards': 'Cartões de negócio',
  'CheckoutSuccess-li1-OrderConfEmail': 'Receberá a confirmação via e-mail',
  'CheckoutSuccess-li1-OrderNumberIs': 'O número do teu pedido é: <b>{orderId}</b>.',
  'CheckoutSuccess-li1-OrderConfSentTo': 'Vamos enviar-lhe uma confirmação de encomenda para {email}. Nota: o e-mail pode estar na sua caixa de spam.',
  'CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD': 'Estamos a Produzir o teu livro',
  'CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway': 'Estamos a produzir a tua encomenda. Estará pronta em poucos dias.',
  'CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped': 'A tua encomenda será expedida',
  'CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail': 'Receberá uma confirmação de envio via e-mail com um link através do qual poderá acompanhar o teu pedido.',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate': 'Data prevista para o envio',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates': 'Entre {startdate} e {enddate}',
  'CheckoutSuccess-Ebook-li2-dlLinkEmail': 'Encontrará o teu link para o download no e-mail de confirmação',
  'CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow': 'O link para fazer o download do teu eBook está no e-mail de confirmação. Em alternativa, pode clicar no botão abaixo.',
  'CheckoutSuccess-Ebook-DownloadYourEbook': 'Download do seu eBook',
  'CheckoutSuccess-Isbn-li2-IsbnReady': 'O teu ISBN estará atribuído no prazo máximo de uma hora',
  'CheckoutSuccess-Isbn-li2-IsbnReadyInStep': 'Pode encontrar o ISBN na etapa ISBN do teu livro. Adicionamos automaticamente o ISBN ao verso da tua capa.',
  'CheckoutSuccess-Isbn-ContinueWithBook': 'Continue com o teu livro',
  'CheckoutSuccess-Isbn-ContinueWithBookClickHere': 'Clique aqui para continuar a trabalhar no teu livro.',
  'CheckoutSuccess-Buscards-li2-WeMakeCards': 'Estamos a produzir os teus cartões de negócio',
  'CheckoutSuccess-Buscards-li2-WePrintRightAway': 'Já estamos a imprimir os teus cartões de negócio. Dentro de aproximadamente uma semana estarão entregues.',
  'CheckoutSuccess-Buscards-li2-NoShipmentMail': 'Nota: não receberá uma confirmação de envio separada para os cartões de negócio.',
  'CheckoutFailed-title-PaymentFailed': 'O seu pagamento falhou',
  'CheckoutFailed-SomethingWentWrongTryAgain': 'Algo correu mal durante o pagamento da sua encomenda. Por favor, tente novamente.',
  'CheckoutFailed-button-CheckoutAgain': 'Fazer novamente',
  'CheckoutError-title-SomethingWrong': 'Algo correu mal',
  'CheckoutError-SomethingWentWrongContactUs': 'Algo correu mal ao finalizar o pagamento da sua encomenda. Tente novamente ou contacte o serviço de apoio. Inclua a seguinte referência quando contactar o apoio ao cliente: {orderId}',
  'CheckoutError-button-TryAgain': 'Tentar novamente',
  'CheckoutPending-title-PaymentInProgress': 'O seu pagamento está a ser verificado',
  'CheckoutPending-PaymentProcessingTakesLonger': 'O processamento do seu pagamento está a demorar um pouco mais do que o previsto. De momento, não podemos informar definitivamente se o seu pagamento foi bem sucedido.',
  'CheckoutPending-CheckAgainInFewHours': 'Por favor, verifique o seu email nas próximas horas. Assim que recebermos a confirmação do seu pagamento, enviaremos um email de confirmação da encomenda. Se não receber esta confirmação, consulte a sua conta bancária ou o seu cartão de crédito para verificar se o pagamento foi efectuado com êxito.',
  'CheckoutPending-OrderReference': 'O seu número de encomenda é: {orderId}',
  'CheckoutCancelled-title-PaymentCancelled': 'O pagamento foi cancelado',
  'CheckoutCancelled-TryAgainIfYouWant': 'O pagamento foi cancelado. Se essa não era a sua intenção, clique no botão abaixo para efetuar o pagamento novamente.',
  'CheckoutCancelled-button-CheckoutAgain': 'Fazer novamente',

  // All ISO-3166-1 alpha 2 countries
  'Country-AD': 'Andorra',
  'Country-AE': 'Emirados Árabes Unidos',
  'Country-AF': 'Afeganistão',
  'Country-AG': 'Antígua e Barbuda',
  'Country-AI': 'Anguilla',
  'Country-AL': 'Albânia',
  'Country-AM': 'Armênia',
  'Country-AO': 'Angola',
  'Country-AQ': 'Antártica',
  'Country-AR': 'Argentina',
  'Country-AS': 'Samoa Americana',
  'Country-AT': 'Áustria',
  'Country-AU': 'Austrália',
  'Country-AW': 'Aruba',
  'Country-AX': 'Ilhas Aland',
  'Country-AZ': 'Azerbaijão',
  'Country-BA': 'Bósnia e Herzegovina',
  'Country-BB': 'Barbados',
  'Country-BD': 'Bangladesh',
  'Country-BE': 'Bélgica',
  'Country-BF': 'Burkina Faso',
  'Country-BG': 'Bulgária',
  'Country-BH': 'Bahrein',
  'Country-BI': 'Burundi',
  'Country-BJ': 'Benin',
  'Country-BL': 'São Bartolomeu',
  'Country-BM': 'Bermudas',
  'Country-BN': 'Brunei',
  'Country-BO': 'Bolívia',
  'Country-BQ': 'Países Baixos Caribenhos',
  'Country-BR': 'Brasil',
  'Country-BS': 'Bahamas',
  'Country-BT': 'Butão',
  'Country-BV': 'Ilha Bouvet',
  'Country-BW': 'Botsuana',
  'Country-BY': 'Bielorrússia',
  'Country-BZ': 'Belize',
  'Country-CA': 'Canadá',
  'Country-CC': 'Ilhas Cocos (Keeling)',
  'Country-CD': 'Congo (Kinshasa)',
  'Country-CF': 'República Centro-Africana',
  'Country-CG': 'Congo (Brazzaville)',
  'Country-CH': 'Suíça',
  'Country-CI': 'Costa do Marfim',
  'Country-CK': 'Ilhas Cook',
  'Country-CL': 'Chile',
  'Country-CM': 'Camarões',
  'Country-CN': 'China',
  'Country-CO': 'Colômbia',
  'Country-CR': 'Costa Rica',
  'Country-CU': 'Cuba',
  'Country-CV': 'Cabo Verde',
  'Country-CW': 'Curaçao',
  'Country-CX': 'Ilha Christmas',
  'Country-CY': 'Chipre',
  'Country-CZ': 'República Tcheca',
  'Country-DE': 'Alemanha',
  'Country-DJ': 'Djibuti',
  'Country-DK': 'Dinamarca',
  'Country-DM': 'Dominica',
  'Country-DO': 'República Dominicana',
  'Country-DZ': 'Argélia',
  'Country-EC': 'Equador',
  'Country-EE': 'Estônia',
  'Country-EG': 'Egito',
  'Country-EH': 'Saara Ocidental',
  'Country-ER': 'Eritreia',
  'Country-ES': 'Espanha',
  'Country-ET': 'Etiópia',
  'Country-FI': 'Finlândia',
  'Country-FJ': 'Fiji',
  'Country-FK': 'Ilhas Malvinas',
  'Country-FM': 'Micronésia',
  'Country-FO': 'Ilhas Faroe',
  'Country-FR': 'França',
  'Country-GA': 'Gabão',
  'Country-GB': 'Reino Unido',
  'Country-GD': 'Granada',
  'Country-GE': 'Geórgia',
  'Country-GF': 'Guiana Francesa',
  'Country-GG': 'Guernsey',
  'Country-GH': 'Gana',
  'Country-GI': 'Gibraltar',
  'Country-GL': 'Groenlândia',
  'Country-GM': 'Gâmbia',
  'Country-GN': 'Guiné',
  'Country-GP': 'Guadalupe',
  'Country-GQ': 'Guiné Equatorial',
  'Country-GR': 'Grécia',
  'Country-GS': 'Ilhas Geórgia do Sul e Sandwich do Sul',
  'Country-GT': 'Guatemala',
  'Country-GU': 'Guam',
  'Country-GW': 'Guiné-Bissau',
  'Country-GY': 'Guiana',
  'Country-HK': 'Hong Kong',
  'Country-HM': 'Ilha Heard e Ilhas McDonald',
  'Country-HN': 'Honduras',
  'Country-HR': 'Croácia',
  'Country-HT': 'Haiti',
  'Country-HU': 'Hungria',
  'Country-ID': 'Indonésia',
  'Country-IE': 'Irlanda',
  'Country-IL': 'Israel',
  'Country-IM': 'Ilha de Man',
  'Country-IN': 'Índia',
  'Country-IO': 'Território Britânico do Oceano Índico',
  'Country-IQ': 'Iraque',
  'Country-IR': 'Irã',
  'Country-IS': 'Islândia',
  'Country-IT': 'Itália',
  'Country-JE': 'Jersey',
  'Country-JM': 'Jamaica',
  'Country-JO': 'Jordânia',
  'Country-JP': 'Japão',
  'Country-KE': 'Quênia',
  'Country-KG': 'Quirguistão',
  'Country-KH': 'Camboja',
  'Country-KI': 'Quiribati',
  'Country-KM': 'Comores',
  'Country-KN': 'São Cristóvão e Névis',
  'Country-KP': 'Coreia do Norte',
  'Country-KR': 'Coreia do Sul',
  'Country-KW': 'Kuwait',
  'Country-KY': 'Ilhas Cayman',
  'Country-KZ': 'Cazaquistão',
  'Country-LA': 'Laos',
  'Country-LB': 'Líbano',
  'Country-LC': 'Santa Lúcia',
  'Country-LI': 'Liechtenstein',
  'Country-LK': 'Sri Lanka',
  'Country-LR': 'Libéria',
  'Country-LS': 'Lesoto',
  'Country-LT': 'Lituânia',
  'Country-LU': 'Luxemburgo',
  'Country-LV': 'Letônia',
  'Country-LY': 'Líbia',
  'Country-MA': 'Marrocos',
  'Country-MC': 'Mônaco',
  'Country-MD': 'Moldávia',
  'Country-ME': 'Montenegro',
  'Country-MF': 'São Martinho',
  'Country-MG': 'Madagascar',
  'Country-MH': 'Ilhas Marshall',
  'Country-MK': 'Macedônia do Norte',
  'Country-ML': 'Mali',
  'Country-MM': 'Mianmar (Birmânia)',
  'Country-MN': 'Mongólia',
  'Country-MO': 'Macau',
  'Country-MP': 'Ilhas Marianas do Norte',
  'Country-MQ': 'Martinica',
  'Country-MR': 'Mauritânia',
  'Country-MS': 'Montserrat',
  'Country-MT': 'Malta',
  'Country-MU': 'Maurício',
  'Country-MV': 'Maldivas',
  'Country-MW': 'Malawi',
  'Country-MX': 'México',
  'Country-MY': 'Malásia',
  'Country-MZ': 'Moçambique',
  'Country-NA': 'Namíbia',
  'Country-NC': 'Nova Caledônia',
  'Country-NE': 'Níger',
  'Country-NF': 'Ilha Norfolk',
  'Country-NG': 'Nigéria',
  'Country-NI': 'Nicarágua',
  'Country-NL': 'Países Baixos',
  'Country-NO': 'Noruega',
  'Country-NP': 'Nepal',
  'Country-NR': 'Nauru',
  'Country-NU': 'Niue',
  'Country-NZ': 'Nova Zelândia',
  'Country-OM': 'Omã',
  'Country-PA': 'Panamá',
  'Country-PE': 'Peru',
  'Country-PF': 'Polinésia Francesa',
  'Country-PG': 'Papua-Nova Guiné',
  'Country-PH': 'Filipinas',
  'Country-PK': 'Paquistão',
  'Country-PL': 'Polônia',
  'Country-PM': 'Saint Pierre e Miquelon',
  'Country-PN': 'Ilhas Pitcairn',
  'Country-PR': 'Porto Rico',
  'Country-PS': 'Territórios Palestinos',
  'Country-PT': 'Portugal',
  'Country-PW': 'Palau',
  'Country-PY': 'Paraguai',
  'Country-QA': 'Catar',
  'Country-RE': 'Reunião',
  'Country-RO': 'Romênia',
  'Country-RS': 'Sérvia',
  'Country-RU': 'Rússia',
  'Country-RW': 'Ruanda',
  'Country-SA': 'Arábia Saudita',
  'Country-SB': 'Ilhas Salomão',
  'Country-SC': 'Seicheles',
  'Country-SD': 'Sudão',
  'Country-SE': 'Suécia',
  'Country-SG': 'Singapura',
  'Country-SH': 'Santa Helena',
  'Country-SI': 'Eslovênia',
  'Country-SJ': 'Svalbard e Jan Mayen',
  'Country-SK': 'Eslováquia',
  'Country-SL': 'Serra Leoa',
  'Country-SM': 'San Marino',
  'Country-SN': 'Senegal',
  'Country-SO': 'Somália',
  'Country-SR': 'Suriname',
  'Country-SS': 'Sudão do Sul',
  'Country-ST': 'São Tomé e Príncipe',
  'Country-SV': 'El Salvador',
  'Country-SX': 'São Martinho (Países Baixos)',
  'Country-SY': 'Síria',
  'Country-SZ': 'Suazilândia',
  'Country-TC': 'Ilhas Turks e Caicos',
  'Country-TD': 'Chade',
  'Country-TF': 'Territórios Franceses do Sul',
  'Country-TG': 'Togo',
  'Country-TH': 'Tailândia',
  'Country-TJ': 'Tajiquistão',
  'Country-TK': 'Tokelau',
  'Country-TL': 'Timor-Leste',
  'Country-TM': 'Turcomenistão',
  'Country-TN': 'Tunísia',
  'Country-TO': 'Tonga',
  'Country-TR': 'Turquia',
  'Country-TT': 'Trinidad e Tobago',
  'Country-TV': 'Tuvalu',
  'Country-TW': 'Taiwan',
  'Country-TZ': 'Tanzânia',
  'Country-UA': 'Ucrânia',
  'Country-UG': 'Uganda',
  'Country-UM': 'Ilhas Menores Distantes dos Estados Unidos',
  'Country-US': 'Estados Unidos',
  'Country-UY': 'Uruguai',
  'Country-UZ': 'Uzbequistão',
  'Country-VA': 'Vaticano',
  'Country-VC': 'São Vicente e Granadinas',
  'Country-VE': 'Venezuela',
  'Country-VG': 'Ilhas Virgens Britânicas',
  'Country-VI': 'Ilhas Virgens Americanas',
  'Country-VN': 'Vietnã',
  'Country-VU': 'Vanuatu',
  'Country-WF': 'Wallis e Futuna',
  'Country-WS': 'Samoa',
  'Country-XK': 'Kosovo',
  'Country-YE': 'Iêmen',
  'Country-YT': 'Mayotte',
  'Country-ZA': 'África do Sul',
  'Country-ZM': 'Zâmbia',
  'Country-ZW': 'Zimbábue'
};

export default messages;
