import { UseFormReturn } from 'react-hook-form';
import { UserAddress } from '../../user';
import { EGridColType, FormItem, GridCol, MBSInput } from 'mbs-common';
import { useIntl } from 'react-intl';
import { getPostalCodeInputType } from '../../helper/addressSupport';
import { getPostalCodeValidator } from '../../helper/addressSupport';
import { useLazyPostalcodecheckQuery } from '../../api/apiSlice';
import { useState } from 'react';

export default function NLAddress({ methods }: { methods: UseFormReturn<UserAddress, any> }) {
  const [showStreetCityFields, setShowStreetCityFields] = useState(false);
  const [postalcodecheckError, setPostalcodecheckError] = useState<string | undefined>(undefined);
  const intl = useIntl();
  const {
    register,
    formState: { errors },
    trigger,
    setError,
    getValues,
    getFieldState,
    setValue,
    resetField
  } = methods;

  const [
    postalcodecheckTrigger,
    { data, isSuccess, isLoading, isError }
  ] = useLazyPostalcodecheckQuery();

  const handleBlur = () => {
    const [postalcode, buildingNumber, numberAddition] = getValues(['p', 'nr', 'nra']);
    const trimmedPostalcode = postalcode.trim();
    setShowStreetCityFields(false);

    let postalcodeIsCorrect = true;

    if (postalcode) {
      // we have to manually run the postal code test, because it probably won't run the refine since there will be other form errors
      const postalcodeValidator = getPostalCodeValidator('NL');

      if (postalcodeValidator) {
        postalcodeIsCorrect = postalcodeValidator.test(trimmedPostalcode);
      }
    }

    if (!postalcodeIsCorrect) {
      setPostalcodecheckError('ZodValidationError-AddressPostalCodeIncorrect');
    } else{
      setPostalcodecheckError(undefined);
    }

    if (postalcodeIsCorrect && buildingNumber) {
      trigger(['nr', 'nra']).then(() => {
        if (
          !getFieldState('p').error &&
          !getFieldState('nr').error &&
          !getFieldState('nra').error
        ) {
          // no errors, so check with postnl
          postalcodecheckTrigger({
            l: 'NL',
            p: trimmedPostalcode,
            nr: buildingNumber,
            nra: numberAddition ? numberAddition : undefined
          }, true)
            .unwrap()
            .then((value) => {
              setPostalcodecheckError(undefined);
              setValue('s', value.s);
              setValue('c', value.c);
              setValue('p', value.p);
              setShowStreetCityFields(true);
            })
            .catch((error) => {
              setShowStreetCityFields(false);
              resetField('s');
              resetField('c');
              if (error.data.error) {
                if (error.data.error === 'ADDRESS_NOT_FOUND') {
                  // set error to address not found
                  //console.log('adreserror loggen');
                  setPostalcodecheckError('Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND');
                } else {
                  // country not supported or invalid api call. We don't care and just give a generic error
                  setPostalcodecheckError('Checkout-PostalcodecheckError-INVALID_API_CALL');
                }
              }
            });
        }
      });
    }
  };

  return (
    <>
      <GridCol type={[EGridColType.Default12, EGridColType.Sm6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-P',
            defaultMessage: 'Postal code'
          })}
        >
          <MBSInput
            type={getPostalCodeInputType('NL')}
            maxLength={15}
            {...register('p')}
            onBlur={handleBlur}
            error={
              errors.p?.message
                ? intl.formatMessage({ id: errors.p?.message })
                : postalcodecheckError
                ? intl.formatMessage({ id: postalcodecheckError })
                : ''
            }
            autoComplete="postal-code"
          />
        </FormItem>
      </GridCol>
      <GridCol type={[EGridColType.Default12, EGridColType.Sm6]}>
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-NrA',
            defaultMessage: 'Number + addition'
          })}
          inputType="flex"
        >
          <MBSInput
            type="tel"
            maxLength={15}
            {...register('nr')}
            onBlur={handleBlur}
            error={errors.nr?.message && intl.formatMessage({ id: errors.nr?.message })}
            placeholder={intl.formatMessage({
              id: 'Checkout-Address-GenericAddress-Nr-Placeholder',
              defaultMessage: 'Number'
            })}
            autoComplete="off"
          />
          <MBSInput
            type="text"
            maxLength={15}
            {...register('nra')}
            onBlur={handleBlur}
            error={errors.nra?.message && intl.formatMessage({ id: errors.nra?.message })}
            placeholder={intl.formatMessage({
              id: 'Checkout-Address-GenericAddress-NrA-Placeholder',
              defaultMessage: 'Addition'
            })}
            autoComplete="off"
          />
        </FormItem>
      </GridCol>
      <GridCol
        type={[EGridColType.Default12]}
        style={{ display: showStreetCityFields ? 'block' : 'none' }}
      >
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-S',
            defaultMessage: 'Street and building number'
          })}
        >
          <MBSInput
            disabled={true}
            {...register('s')}
            error={errors.s?.message && intl.formatMessage({ id: errors.s?.message })}
          />
        </FormItem>
      </GridCol>
      <GridCol
        type={[EGridColType.Default12]}
        style={{ display: showStreetCityFields ? 'block' : 'none' }}
      >
        <FormItem
          label={intl.formatMessage({
            id: 'Checkout-Address-GenericAddress-C',
            defaultMessage: 'City'
          })}
        >
          <MBSInput
            disabled={true}
            {...register('c')}
            error={errors.c?.message && intl.formatMessage({ id: errors.c?.message })}
          />
        </FormItem>
      </GridCol>
    </>
  );
}
