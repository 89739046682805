const messages = {
  'Promotion': 'Promotie',
  'binding-1': 'Hardcover',
  'binding-3': 'Paperback',
  'binding-6': 'E-book',
  'binding-7': 'PDF',
  'interiorcolor-bw': 'Zwart/wit',
  'interiorcolor-fc': 'Kleur',
  'interiorcolor-short-bw': 'Z/W',
  'interiorcolor-short-fc': 'Kleur',
  'Interior color': 'Kleur binnenwerk',
  'Papertype-short': 'Papier',
  'Papertype-short-1': 'Crème',
  'Papertype-short-2': 'Crème',
  'Papertype-short-3': 'Wit',
  'Papertype-short-4': 'Wit',
  'Papertype-short-5': 'Semi-glans',
  'Papertype-short-6': 'Semi-glans',
  'Papertype-short-7': 'Wit',
  'Papertype-short-8': 'Wit glans',
  'Papertype-short-9': 'Getint',
  'Papertype-short-10': '150 grams',
  'Papertype-short-12': 'Wit',
  'Amountpages-short': 'Pagina’s',
  'Amountcolorpages-short': 'Kleurenpagina’s',
  'Help-howdoesitwork-question': 'Hoe werkt het?',
  'ISBN': 'ISBN',
  'Author website': 'Auteurswebsite',
  'Author webshop': 'Auteurswebshop',
  'authorwebsite-notEnabled': 'Niet ingesteld - {link}',
  'authorwebsite-notEnabled-enablenow': 'Nu instellen',
  'Link to book page': 'Link naar boek pagina',
  'button-downloadcover': 'Omslag downloaden',
  'button-downloadexcerpt': 'Inkijkexemplaar',
  'button-copybooklink': 'Boeklink kopiëren',
  'button-copyannouncementtext': 'Aankondigingstekst kopiëren',
  'button-openinstagram': 'Open Instagram App',
  'button-addtoshoppingcart': 'Plaats in winkelwagen',
  'button-orderbooks': 'Boeken bestellen',
  'button-registerbook': 'Boek registreren',
  'Channels-availability': 'Beschikbaarheid',
  'Channelname': 'Winkel',
  'Price': 'Prijs',
  'Your margin': 'Jouw marge',
  'Bookspec-ISBN-isbn': 'ISBN: {isbn}',
  'Bookspec-LastEdited-date': 'Laatste versie: {date}',

  'What do you have to do?': 'Wat moet je doen?',

  // form labels
  'label-announcementtext': 'Aankondigingstekst',
  'label-instagram-addbooklinktobio': 'Voeg de link naar je boek toe aan je Instagram bio',
  'label-instagram-downloadcover': 'Download je omslagafbeelding voor je post',
  'label-instagram-openinstagram': 'Open Instagram',
  'label-instagram-chooseanimage': 'Kies een goede afbeelding',
  'label-attachbooklinktomessage': 'Voeg automatisch de link van je boek toe aan het bericht',

  // zod validation errors
  'ZodValidationError-EmailRequired': 'E-mailadres is verplicht',
  'ZodValidationError-EmailNotValidEmail': 'Vul een geldig e-mailadres in',
  'ZodValidationError-AddressFnRequired': 'Voornaam is verplicht',
  'ZodValidationError-AddressFnLongerThan30': 'Voornaam mag niet langer dan 30 tekens zijn',
  'ZodValidationError-AddressLnRequired': 'Achternaam is verplicht',
  'ZodValidationError-AddressLnLongerThan30': 'Achternaam mag niet langer dan 30 tekens zijn',
  'ZodValidationError-AddressSRequired': 'Straat en huisnummer is verplicht',
  'ZodValidationError-AddressSLongerThan50': 'Straat en huisnummer mag niet langer dan 50 tekens zijn',
  'ZodValidationError-AddressS2LongerThan50': 'Tweede adresregel mag niet langer dan 50 tekens zijn',
  'ZodValidationError-AddressNrMustBeNumber': 'Huisnummer moet een getal zijn',
  'ZodValidationError-AddressPRequired': 'Postcode is verplicht',
  'ZodValidationError-AddressPLongerThan20': 'Postcode mag niet langer dan 20 tekens zijn',
  'ZodValidationError-AddressCRequired': 'Woonplaats is verplicht',
  'ZodValidationError-AddressCLongerThan35': 'Woonplaats mag niet langer dan 35 tekens zijn',
  'ZodValidationError-AddressRLongerThan35': 'Staat mag niet langer dan 35 tekens zijn',
  'ZodValidationError-AdressLRequired': 'Land is verplicht',
  'ZodValidationError-AddressTRequired': 'Telefoonnummer is verplicht',
  'ZodValidationError-AddressCountryNotTwoLetterCode': 'Land moet ISO 2 letter code zijn',
  'ZodValidationError-AddressCpLongerThan35': 'Bedrijfsnaam mag niet langer dan 35 tekens zijn',
  'ZodValidationError-AddressTLongerThan20': 'Telefoonnummer mag niet langer dan 20 tekens zijn',
  'ZodValidationError-AddressFullNameLongerThan40': 'Volledige naam mag niet langer dan 40 tekens zijn',
  'ZodValidationError-AddressPostalCodeIncorrect': 'Postcode onjuist voor het gekozen land',
  'ZodValidationError-AddressS2SameAsS': 'Tweede adresregel is gelijk aan eerste adresregel',
  'ZodValidationError-AddressVRequiredForCanarias': 'Belastingnummer is verplicht voor de Canarische Eilanden',

  // guide popup
  'wikiguidepopup-title-selectaguide': 'Kies een handleiding',

  // errors
  'error-booknotpublished': 'Je kunt dit boek niet promoten, omdat het op dit moment niet is gepubliceerd. Ga naar ‘Mijn boeken’ om je boek te bewerken.',
  'error-booknotavailable': 'Het boek dat je probeert te openen bestaat niet. Ga naar ‘Mijn boeken’ en selecteer een bestaand boek.',
  'error-booknotauthorized': 'Je hebt geen toegang tot deze pagina. Als deze melding niet klopt, neem contact op met support.',
  'error-bookunknownerror': 'Een onbekend probleem is opgetreden bij het laden van de pagina. Ververs de pagina om het nogmaals te proberen. Als het probleem aanblijft, neem contact op met support en vermeld boeknummer {bookid}',

  // promo popup
  'promoapp-publishedpopup-title': 'Gefeliciteerd, je bent een gepubliceerd auteur!',
  'promoapp-publishedpopup-paragraph1': 'Het is je gelukt! Je boek is nu gepubliceerd, dus neem een moment om het te vieren! Vertel het je vrienden, trakteer jezelf en geniet van wat je bereikt hebt.',
  'promoapp-publishedpopup-paragraph2': 'Nu je boek te koop is, is het tijd voor de volgende stap: het promoten van je boek. Natuurlijk begeleiden we je daarbij. We bieden je een reeks aan promotiemogelijkheden en adviseren je om ze allemaal te benutten.',
  'promoapp-publishedpopup-startbutton': 'Start promotie',

  // promo categories
  'promoapp-havetodo-title': 'Acties die je echt MOET doen',
  'promoapp-shoulddo-title': 'Actions die je ZOU moeten doen',
  'promoapp-coulddo-title': 'Acties die je KUNT doen (maar we je wel aanraden)',
  'blocktitle-thingsyouneedtopromote': 'Alles wat je nodig hebt om je boek te promoten',

  // promo overview
  'promoapp-overview-intro-p1': 'Een boek schrijven is een geweldige prestatie, maar waarschijnlijk wil je ook het maximale uit je boek halen met betrekking tot de verkoop. Om dat te realiseren, zul je het moeten promoten, een boek verkoopt zich helaas niet vanzelf. Voor veel auteurs is dit waar de echte uitdaging begint. Het vereist veel energie en enthousiasme, maar als je het goed aanpakt zul je zien dat je wordt beloond! Om je te ondersteunen hebben we een aantal tips, acties en promotietools ontwikkeld. Wanneer je deze benut, zul je zien dat je het maximale uit je boek haalt.',

  // list of all promotool descriptions
  'promotool-authorwebsite': 'Auteurswebshop',
  'promotool-description-authorwebsite': 'De beste manier om je boeken te verkopen',
  'promotool-whatsapp': 'WhatsApp',
  'promotool-description-whatsapp': 'Gebruik WhatsApp om je netwerk te informeren en om je boek te promoten',
  'promotool-socialmedia': 'Social media',
  'promotool-description-socialmedia': 'Plaats updates over jouw boek via je social media',
  'promotool-instagram': 'Instagram',
  'promotool-description-instagram': 'Update je Instagram volgers over je boek en je promotieactiviteiten',
  'promotool-facebook': 'Facebook',
  'promotool-description-facebook': 'Update je volgers en stimuleer de verkoop interessante content te posten',
  'promotool-twitter': 'Twitter',
  'promotool-description-twitter': 'Informeer je volgers over je activiteiten rondom je boek',
  'promotool-linkedin': 'LinkedIn',
  'promotool-description-linkedin': 'Inspireer je professionele netwerk door ze te informeren over jouw boek',
  'promotool-libraries': 'Bibliotheken',
  'promotool-description-libraries': 'Vergroot je publiek door je boek aan te bieden aan de bibliotheken',
  'promotool-nbdbiblion': 'NBD Biblion',
  'promotool-description-nbdbiblion': 'Laat NBD je boek recenseren en de bibliotheken je boek kopen',
  'promotool-nationallibrary': 'Koninklijke Bibliotheek',
  'promotool-description-nationallibrary': 'Deponeer exemplaren in de Koninklijke Bibliotheek',
  'promotool-launchparty': 'Boekborrel of boekpresentatie',
  'promotool-description-launchparty': 'Geen publicatie is compleet zonder een feestje',
  'promotool-bookstores': 'Boekhandels',
  'promotool-description-bookstores': 'Neem contact op met lokale boekhandels om je boek in de boekhandel te krijgen',
  'promotool-pressrelease': 'Persbericht',
  'promotool-description-pressrelease': 'Breng de lokale pers op de hoogte van jouw publicatie',
  'promotool-businesscards': 'Visitekaartjes',
  'promotool-description-businesscards': 'Gebruik visitekaartjes om zoveel mogelijk mensen te informeren over je boek',

  // launch party
  'launchparty-intro-p1': 'Een boekpresentatie mag natuurlijk niet ontbreken als onderdeel van de promotie van je boek. Hoe leuk is het om, na maanden van zwoegen aan je manuscript, je boek te vieren met diegene die dicht bij je staan! Jij bepaalt zelf hoe intiem of hoe groots je het aan wil pakken en of je het houdt op een mooie locatie, thuis of – noodgedwongen – online. Voor een succesvolle boekpresentatie hebben wij de meest relevante stappen voor je op een rijtje gezet. We maken onderscheid tussen de informele boekborrel en een wat meer formele boekpresentatie. Aan jou uiteraard om te bepalen wat het beste bij je past!',
  'launchparty-bookdrinks-title': 'Boekborrel',
  'launchparty-bookdrinks-intro': 'De boekborrel is een informele borrel die je thuis houdt en waarbij jij je goede vrienden en bijvoorbeeld mensen uit de buurt uitnodigt. Gewoon om even stil te staan bij de mijlpaal die je hebt bereikt. De voorbereiding vraagt heel weinig tijd en de borrel zal je veel voldoening geven. Je moet wel iets aan voorbereiding doen, we hebben de belangrijkste punten voor je op een rijtje gezet.',
  'launchparty-bookdrinks-whenandwho-title': 'Wanneer en wie uitnodigen?',
  'launchparty-bookdrinks-whenandwho-paragraph1': 'Maak een lijstje van de mensen die je er graag bij wil hebben. Denk aan je beste vrienden, mensen uit de buurt en degenen die hebben bijgedragen aan jouw boek. Bepaal een datum, maak een Whatsapp groep aan en verstuur de uitnodiging! Zorg voor een vrolijke boodschap en stuur uiteraard een foto van je boek mee!',
  'launchparty-bookdrinks-orderbooks-title': 'Bestel boeken',
  'launchparty-bookdrinks-orderbooks-paragraph1': 'Wanneer je de uitnodiging via Whatsapp hebt verstuurd, weet je al snel hoeveel mensen er komen. Ga ervan uit dat ze natuurlijk allemaal je boek willen hebben en bestel de boeken. Doe dit op tijd, zodat je niet hoeft te stressen of de boeken wel bezorgd zijn voordat jij je boekborrel hebt.',
  'launchparty-bookdrinks-agenda-title': 'Kort programma',
  'launchparty-bookdrinks-agenda-paragraph1': 'Ook al wordt het een gezellige, informele bijeenkomst, denk toch na over een kort programma. Wil je zelf wat zeggen? Wil je een toost uitbrengen? Wil je het eerste exemplaar aan een belangrijk iemand overhandigen? Wil je nog iets voorlezen uit je boek of vertellen over hoe uitdagend de reis was om een boek te schrijven? Werk dit even op een half A4tje uit.',
  'launchparty-bookdrinks-foodandhouse-title': 'Hapjes, huis, herinnering',
  'launchparty-bookdrinks-foodandhouse-paragraph1': 'Ongetwijfeld wat open deuren: zorg voor wat lekkere hapjes en drankjes, inclusief voldoende alcoholvrije drankjes. Je huis moet natuurlijk op orde zijn, geef je stapel boeken een prominente plek, laat ze het stralend middelpunt van de boekborrel zijn. Zorg ervoor dat iemand foto’s maakt, dat kan natuurlijk gewoon met de smartphone. Het zijn waardevolle herinneringen en je kunt ze later ook gebruiken voor op je social media.',
  'launchparty-bookdrinks-enjoy-title': 'Enjoy!',
  'launchparty-bookdrinks-enjoy-paragraph1': 'Geniet van je boekborrel, geef iedereen aandacht en vier het feit dat je auteur bent! Je moet vooraf bepalen of je iedereen een boek wil schenken of dat je vraagt of de mensen voor je boek willen betalen. Dat laatste is helemaal niet gek en zal iedereen begrijpen. Zorg voor een goede pen, signeer de boeken en schrijf in ieder boek een mooie persoonlijke boodschap. Wanneer je de mensen laat betalen: stuur ze per direct (of iets later) een Tikkie, wel zo gemakkelijk.',
  'launchparty-bookpresentation-title': 'Boekpresentatie',
  'launchparty-bookpresentation-intro': 'De boekpresentatie is wat formeler dan de boekborrel en vraagt wat meer voorbereiding. Het is echt de lancering van je boek en de start van je promotieactiviteiten en de verkoop. We hebben weer de belangrijkste zaken op een rijtje gezet voor je.',
  'launchparty-guestlist-title': 'Bepaal wie je gaat uitnodigen',
  'launchparty-guestlist-paragraph1': 'Begin met het opstellen van je gastenlijst. Denk aan je familie, vrienden, mensen die een bijdrage hebben geleverd aan jouw boek, je zakelijke netwerk. Reken erop dat iedereen het leuk vindt om deze mooie mijlpaal met jou te vieren. Als je weet hoeveel mensen er ongeveer komen dan is het ook makkelijker om een geschikte locatie te vinden.',
  'launchparty-findalocation-title': 'Zoek een locatie en bepaal de datum',
  'launchparty-findalocation-paragraph1': 'Veel locaties zijn geschikt. Je kunt ervoor kiezen om het thuis te organiseren, in je favoriete café, de plaatselijke bibliotheek. Onze favoriet is toch echter de lokale boekhandel, de sfeer van boeken tijdens een boekpresentatie is echt passend. Probeer de locatie kosteloos of tegen geringe kosten te gebruiken en bespreek vooraf met de eigenaar welke hapjes en drankjes worden geserveerd en wat de kosten ervan zijn.',
  'launchparty-sendinvites-title': 'Verstuur de uitnodigingen',
  'launchparty-sendinvites-paragraph1': 'Een papieren uitnodiging is tegenwoordig niet meer zo vanzelfsprekend, maar misschien is het toch leuk om voor jouw feestje een uitzondering te maken. Ter verfraaiing kun je de boekomslag op de uitnodiging plaatsen. Uiteraard kun je ook per e-mail of zelfs Whatsapp de uitnodigingen versturen. Dit kost niets en bespaart je tijd. Vergeet niet te vragen of ze reageren op de uitnodiging. Dan weet je in ieder geval hoeveel mensen er komen.',
  'launchparty-createagenda-title': 'Programma',
  'launchparty-createagenda-paragraph1': 'Je moet van tevoren bedenken wat je allemaal wil doen. We geloven in de vrije hand, maar plan zeker de volgende dingen:',
  'launchparty-createagenda-list1': 'Welkomstdrankje, eventueel met passend achtergrondmuziekje',
  'launchparty-createagenda-list2': 'Als alle gasten er zijn: welkomstwoord door een vriend(in) of een bekend persoon',
  'launchparty-createagenda-list3': 'Vraag en antwoord met jou als auteur (waarom boek geschreven, waar gaat het over, door wie geïnspireerd, leuke anekdotes tijdens het schrijven)',
  'launchparty-createagenda-list4': 'Een stukje voorlezen uit je boek: kies jouw favoriete passage',
  'launchparty-createagenda-list5': 'Signeren van mensen die jouw boek kopen',
  'launchparty-createagenda-list6': 'Borrel',
  'launchparty-contactmedia-title': 'Media',
  'launchparty-contactmedia-paragraph1': 'Benader de lokale media of social media influencers en vraag of ze een stukje over jouw boeken je boekpresentatie willen schrijven. Wees niet te verlegen om dit te doen!',
  'launchparty-refreshments-title': 'Regel hapjes en drankjes',
  'launchparty-refreshments-paragraph1': 'Ons motto: hou het simpel en lekker. Niet te veel keuze (bier, wijn, fris) en een beperkt aantal hapjes. Stem dit natuurlijk met de eigenaar van de locatie af.',
  'launchparty-orderbooks-title': 'Boeken',
  'launchparty-orderbooks-paragraph1': 'Zorg dat je voldoende boeken bij je hebt. De aanwezigen kunnen dan gelijk een exemplaar kopen (tenzij je ze wil weggeven) en jij kunt ze dan signeren en van een persoonlijk woordje voorzien. Indien je de boekpresentatie bij een boekhandels organiseert, dan wil de boekhandel hier natuurlijk ook aan verdienen. Stem dit goed van tevoren af.',
  'launchparty-photos-title': 'Foto’s en video',
  'launchparty-photos-paragraph1': 'Tegenwoordig is iedereen amateurfotograaf en video-artiest. Dus er zal geen gebrek zijn aan foto’s die worden gemaakt. Maar toch, organiseer wel dat iemand specifiek foto’s maakt van momenten waarvan je zeker wil dat er opnames zijn. Een video mag niet ontbreken.',
  'launchparty-followup-title': 'Follow-up',
  'launchparty-followup-paragraph1': 'Bedank een paar dagen na de presentatie alle aanwezigen en trakteer ze op de leukste foto’s! Vergeet niet om degenen die verhinderd waren ook een bericht te sturen met de foto’s en natuurlijk de kooplink van je boek! Heeft een influencer of de lokale media een artikel geschreven: deel dit met je netwerk.',

  // bookstores
  'bookstores-intro-p1': 'De boekhandel is nog steeds een zeer krachtig verkoopkanaal voor boeken, ondanks de enorme opkomst van online kopen. Boekhandels zijn echter zeer terughoudend in het opnemen van nieuwe boeken van relatief onbekende schrijvers. Toch raden we je aan om met de lokale boekhandel te gaan praten en ze te vragen of ze jouw boek in de boekhandel willen leggen. Indien ze bereid zijn dit te doen, dan willen ze een winstpercentage hebben (tussen de 20 en 40%) en ze willen het in de regel risicoloos doen. Dit betekent dat ze na verloop van tijd de niet verkochte exemplaren aan jou kunnen teruggeven. We hebben een checklist voor je gemaakt om snel tot goede afspraken met de boekhandel te komen.',
  'bookstores-intro-p2': 'Naast de boekhandel kun je ook nadenken of andere partijen jouw boek kunnen verkopen of dat er evenementen zijn waar je jouw boek kunt verkopen.',
  'bookstores-checklist-title': 'Checklist boek in de boekhandel',
  'bookstores-selectbookstores-title': 'Kies de juiste boekhandels',
  'bookstores-selectbookstores-p1': 'De eerste stap is bepalen bij welke boekhandels je jouw boek wil verkopen. We raden je hierbij aan te starten met één boekhandel.',
  'bookstores-contactbookstore-title': 'Neem contact op met de boekhandel',
  'bookstores-contactbookstore-p1': 'Zodra je bepaald hebt bij welke boekhandels je jouw boek graag wil verkopen, moet je contact opnemen met de boekhandel en vragen ze of ze bereid zijn met je in gesprek te gaan over het verkopen van jouw boek.',
  'bookstores-contactbookstore-p2': 'Onze tips voor het eerste gesprek:',
  'bookstores-contactbookstore-list1': 'Vertel over jezelf;',
  'bookstores-contactbookstore-list2': 'Vertel over je boek: waar gaat het over, waarom heb je het geschreven en voor wie is het bedoeld;',
  'bookstores-contactbookstore-list3': 'Neem je boek mee en laat een exemplaar achter; de boekhandel zal ongetwijfeld het boek eerst willen doornemen.',
  'bookstores-agreement-title': 'Maak afspraken met de boekhandel',
  'bookstores-agreement-p1': 'Wanneer de boekhandel bereid is je boek te verkopen, moet je afspraken maken over hoe dit in zijn werk gaat. De belangrijkste afspraken die je moet maken:',
  'bookstores-agreement-whobuysbooks': 'Wie koopt de boeken in? Er zijn twee opties:',
  'bookstores-agreement-buy-option1': 'Optie 1. Jij koopt de boeken zelf en de boekhandel verkoopt ze voor jou. Dit is de meest aantrekkelijke optie voor boekhandels omdat ze dan financieel geen risico lopen. Je koopt de boeken via eigen boek kopen en spreekt met de boekhandel jouw marge af. Vergeet niet af te spreken wanneer je wordt betaald en hoe je wordt geïnformeerd over de verkopen. In Nederland geldt de Wet op de vaste boekenprijs. Dit betekent dat een boek op elk verkooppunt dezelfde prijs moet hebben. Jij bent hiervoor verantwoordelijk. Een boekhandel zal tussen de 20 – 40% aan marge vragen.',
  'bookstores-agreement-buy-option2': 'Optie 2. De boekhandel koopt jouw boeken in. Deze optie is het makkelijkst, jij ontvangt automatisch je marge en de boekhandel krijgt automatisch haar boekhandelsmarge. Je moet dan wel in de stap Prijs gekozen hebben voor ‘Alle boekhandels’, dan kan namelijk de boekhandel jouw boeken bestellen.',
  'bookstores-agreement-howlongavailable': 'Hoe lang wordt het boek in de winkel te koop aangeboden?',
  'bookstores-agreement-positioninstore': 'Waar in de winkel komt het boek precies te liggen?',
  'bookstores-beactive-title': 'Wees actief',
  'bookstores-beactive-p1': 'Nadat je boek in de winkel ligt, is het belangrijk om kopers hiervan op de hoogte te brengen. Denk hierbij o.a. Aan de volgende opties:',
  'bookstores-beactive-list1': 'Het is interessant om in de boekhandel jouw boekpresentatie te geven. Zie {launchparty} voor meer informatie.',
  'bookstores-beactive-list2': 'Plaats berichten op sociale netwerk sites (zoals Facebook, LinkedIn), je eigen website, je blog en eventuele overige sites, dat je boek vanaf nu te koop is bij boekhandel.',
  'bookstores-beactive-list3': 'Loop af en toe langs bij de boekhandel om de verkoop te stimuleren.',

  // press release
  'pressrelease-intro-p1': 'Een persbericht is meer dan een bericht voor de pers. Het is de formele aankondiging van je boek die je naar diverse doelgroepen kunt sturen. Denk daarbij natuurlijk aan de lokale media, maar ook aan specifieke websites en aan influencers.',
  'pressrelease-intro-p2': 'Op basis van wat jij hebt ingegeven tijdens de publicatiestappen hebben wij automatisch een persbericht opgesteld. Hierin zijn alle relevante gegevens, zoals de voorkant van de omslag, de beschrijving, het isbn, de prijs en de kooplink opgenomen.',
  'pressrelease-intro-p3': 'Wil je maximale kans op een recensie? Stuur dan ook een papieren exemplaar van je boek!',
  'pressrelease-identifytarget-title' : 'Inventariseer je doelgroepen',
  'pressrelease-identifytarget-p1': 'Denk na welke partijen geïnteresseerd kunnen zijn in jouw boek. Het heeft geen zin om je persbericht naar de hele wereld te sturen als jouw boek niet aansluit bij hun doelgroep. Beperk je niet alleen tot de lokale media (krant, radio, websites), maar denk ook na welke websites specifiek gaan over het thema van je boek. Hetzelfde geldt voor influencers op Instagram, Facebook, LinkedIn, etc.',
  'pressrelease-identifytarget-p2': 'Neem ook je eigen netwerk op. Deze kun je zelfs vragen om jouw bericht weer te verspreiden in hun netwerk.',
  'pressrelease-collectcontact-title': 'Verzamel de gegevens',
  'pressrelease-collectcontact-p1': 'Dit is het minst leuke werk: het vastleggen van de gegevens. Om dit een beetje te structureren kun je het best een excel bestand maken met daarin de naam, de contactpersoon, het e-mailadres, het adres en het telefoonnummer. Probeer zoveel als mogelijk de juiste contactpersoon te vinden in plaats van een algemeen ‘info’ of ‘contact’ e-mailadres. Maak ook een aantal kolommen om de voortgang bij te houden. Denk bijvoorbeeld aan de kolommen Gestuurd?, Reactie?, Geplaatst?',
  'pressrelease-sendpressrelease-title': 'Verstuur het persbericht',
  'pressrelease-sendpressrelease-p1': 'Wanneer je je persbericht als e-mail verstuurt, zorg dan voor een korte, kernachtige tekst. Ga niet de tekst van het persbericht herhalen, maar wek wel de interesse op. Uiteindelijk gaat het erom dat ze je persbericht openen. Je kunt ervoor kiezen om de PDF van je manuscript mee te sturen, je kunt ook een fragment uit je boek als PDF meesturen. Bij partijen waarvan je de kans groot acht dat ze geïnteresseerd zijn: stuur een papieren exemplaar van je boek. Het is een kleine investering en het kan veel opleveren.',
  'pressrelease-sendpressrelease-p2': 'Tip: geef in je e-mail aan dat je bereid bent om een interview te geven.',
  'pressrelease-downloadbutton': 'Download persbericht',
  'pressrelease-followup-title': 'Follow-up',
  'pressrelease-followup-p1': 'Verwacht niet dat iedereen direct jouw persbericht plaatst of een stukje over je boek zal gaan schrijven. Controleer natuurlijk wel of ze iets met je persbericht hebben gedaan. Je kunt overwegen om na een paar weken het persbericht nog een keer te versturen, als een soort ‘reminder’.',
  'pressrelease-sharelinks-title': 'Deel de media links met je netwerk',
  'pressrelease-sharelinks-p1': 'Iedere keer dat een partij iets over je boek schrijft is dit gratis promotie voor je boek. Deel de links dan ook volop op social media en je eigen website.',

  // NBD Biblion
  'nbdbiblion-copyandpastetext': 'Klik hier om bovenstaande tekst te kopiëren',

  // Business cards
  'businesscards-intro-p1': 'Het gebruiken van visitekaartjes draagt bij aan de verkoop van je boek. Hoe mooi is het om een gepersonaliseerd visitekaartje aan mensen te kunnen geven? Met daarop prominent je boek en de link waar het kan worden gekocht. De visitekaartjes worden in full color gedrukt op stevig 400 grams karton. Binnen twee weken na je bestelling heb je ze in huis.',
  'businesscards-blocktitle-order': 'Visitekaartjes bestellen',
  'businesscards-preview-label-front': 'Voorkant',
  'businesscards-preview-label-back': 'Achterkant',
  'businesscards-numberofcards-label': 'Aantal visitekaartjes',
  'businesscards-selectnumberofcards-item-label': '{number} kaartjes - {price}',

  // whatsapp
  'whatsapp-intro-p1': 'Whatsapp is niet meer weg te denken uit het dagelijks leven. Hoe vaak app jij per dag? In hoeveel groepen zit je? Waarschijnlijk kun je je hele persoonlijke netwerk bereiken via Whatsapp, of welke messaging app je ook gebruikt. Gebruik Whatsapp om je eerste verkopen te realiseren, jouw persoonlijk netwerk zal het meest bereid zijn om jouw boek te kopen. Zorg ervoor dat je ze betrekt bij het creatieproces van het boek voordat je het nieuws van de publicatie aankondigt, en vraag ze om die ultieme gunst: het kopen van je boek! Je kunt ze bijvoorbeeld je ideeën voor de omslag laten zien en om hun mening vragen, of samen met ze brainstormen over de titel. Je zou ze zelfs van tevoren de flaptekst kunnen sturen om ze enthousiast te maken.',
  'whatsapp-intro-p2': 'Het lijkt misschien niet veel voor te stellen als je vrienden en familie je boek kopen, maar zij kunnen wel eens de grootste ambassadeurs van je boek zijn. Mond-tot-mondreclame is van het grootste belang, dus maak gebruik van deze promotie methode!',
  'whatsapp-promoteviawhatsapp': 'Promoot je boek via WhatsApp',
  'whatsapp-button-sendviawhatsapp': 'Stuur via WhatsApp',
  'whatsapp-week1-title': 'Week 1 - Aankondiging',
  'whatsapp-week1-message': 'Hoi iedereen, ik wilde jullie even laten weten dat het boek, waar ik het regelmatig over heb gehad, nu eindelijk gepubliceerd is! Ik ben er super enthousiast over en zou het erg waarderen als jullie het zouden willen verspreiden in jullie netwerk.\n\nJe mag natuurlijk ook zelf mijn boek kopen en mij erg blij maken! :-). Spoiler alert: het is ook zeer geschikt als cadeautje!\n\n',
  'whatsapp-week2-title': 'Week 2 - Boekborrel of boekpresentatie',
  'whatsapp-week2-message': 'Dank aan iedereen die mijn boek gekocht heeft, jullie zijn fantastisch!\n\nIk wilde jullie even laten weten dat ik een feest geef voor de lancering van mijn boek! Jullie zijn natuurlijk allemaal uitgenodigd. Er zullen hapjes en drankjes zijn, een lekker muziekje en ik ga een stukje voorlezen (niet te lang hoor). Voor de liefhebber: ik zal ook boeken signeren en een persoonlijke boodschap meegeven ;).\n\nHet wordt super gezellig en ik hoop dat jullie komen!',
  'whatsapp-week3-title': 'Week 3 - Bedankt voor het komen!',
  'whatsapp-week3-message': 'Hoi iedereen! Ik wil iedereen bedanken die naar mijn boekpresentatie is gekomen, het was geweldig om jullie allemaal daar te zien! Hier zijn wat leuke foto’s die ik heb genomen tijdens het feestje.\n\nIk wil ook graag iedereen bedanken die een exemplaar heeft gekocht, het betekent echt enorm veel voor me! Wanneer je er niet bij kon zijn en toch een exemplaar van mijn boek wil hebben, kun je dat hier doen:',
  'whatsapp-week4-title': 'Week 4 - Recensieverzoek',
  'whatsapp-week4-message': 'Hoi!\n\nAls ik jullie om een kleine gunst mag vragen - het zou me enorm helpen als jullie een korte recensie van het boek op Goodreads zouden kunnen schrijven. Het zou wonderen doen voor de online zichtbaarheid van mijn boek en het helpt mij om meer mensen te bereiken. Ik betaal je graag terug met een kopje koffie!',
  'whatsapp-week4-goodreadswarning': 'Let op: voordat je dit bericht verstuurt, is het belangrijk dat je je boek eerst op Goodreads aanmeldt!',
  'whatsapp-tips-title': 'Tips voor het gebruik van WhatsApp',
  'whatsapp-tips-groups-label': 'Maak speciale Whatsapp-groepen',
  'whatsapp-tips-groups': 'De kans is groot dat je een gevarieerde lijst van contacten hebt op Whatsapp. Het is een goed idee om verschillende groepen aan te maken en naar elk van deze groepen speciaal opgestelde berichten te sturen die overeenkomen met je gebruikelijke niveau van familiariteit. Gebruik natuurlijk ook je bestaande groepen, bijvoorbeeld het sportteam waar je in zit, de vriendengroep, etc.',
  'whatsapp-tips-readtheroom-label': 'Forceer het niet',
  'whatsapp-tips-readtheroom': 'De mensen met wie je ‘praat’ op Whatsapp zijn waarschijnlijk de mensen met wie je het beste kunt opschieten, ze zijn waarschijnlijk ook je meest loyale lezers. Onderhoud die loyaliteit door niet constant reminders te sturen om je boek te kopen, maar bedank de mensen die het boek kopen en deel leuke nieuwtjes met hen. Dit is veel effectiever dan het alleen maar vragen of ze je boek willen kopen.',
  'whatsapp-tips-askspread-label': 'Vraag mensen om het door te geven',
  'whatsapp-tips-askspread': 'Mond-tot-mondreclame is een van de krachtigste manieren om de bekendheid van je boek te verwerven. Het is ook een van de moeilijkste. Maak daarom gebruik van je Whatsapp-groepen en laat vrienden en familie het woord verspreiden binnen hun eigen netwerken.',
  'whatsapp-tips-participation-label': 'Creëer een gevoel van deelname',
  'whatsapp-tips-participation': 'Mensen op Whatsapp zullen waarschijnlijk je beste vrienden en kennissen zijn. Laat weten hoezeer je het op prijs stelt dat ze je boek kopen en de boodschap verspreiden. Nodig ze uit voor de lancering van je boek en andere boekgerelateerde evenementen en deel foto’s van deze evenementen in de groep. Herinner hen eraan hoe belangrijk ze zijn voor jouw succes.',

  // social media
  'socialmedia-intro-p1': 'Social media zijn tegenwoordig niet meer weg te denken en zijn bij uitstek geschikt om je boek te promoten. Als auteur is het van enorm belang om direct te communiceren met je netwerk waarvan een groot deel geïnteresseerd zal zijn in jouw boek. We hebben voor de meest relevante social media een aantal tools ontwikkeld, inclusief diverse tips hoe je deze het beste kunt gebruiken!',
  'socialmedia-tips-title': 'Tips voor social media',
  'socialmedia-tips-beconsistent-label': 'Wees consistent in je berichten',
  'socialmedia-tips-beconsistent': 'Als schrijver heb je je eigen unieke stem, een bepaalde manier waarop jij jouw gedachten en nieuwtjes deelt. Blijf dicht bij jezelf in al je uitingen, dat is hoe jouw volgers je kennen en wat ze van je verwachten. Wees consistent in al je posts op social media,zo blijf je betrouwbaar overkomen.',
  'socialmedia-tips-beshareable-label': 'Laat je berichten delen',
  'socialmedia-tips-beshareable': 'Het is belangrijk om berichten te plaatsen waarvan je volgers het leuk vinden om deze te delen met hun netwerk. Een opgewekte toon, een humoristisch of een nieuwswaardig bericht doen wonderen. Hoe meer jouw posts door je volgers worden gedeeld, hoe meer aandacht jij en je boek zullen krijgen.',
  'socialmedia-tips-berelatable-label': 'Toon jezelf',
  'socialmedia-tips-berelatable': 'Natuurlijk wil je zoveel mogelijk delen over je boek; over de omslag, je inkijkexemplaar, waar je het kan kopen, je promotie-activiteiten, etc, maar laat ook wat van jezelf zien. Geef een kijkje in je leven, deel persoonlijke momenten, toon emotie. Dit maakt je meer dan alleen iemand die graag een boek wil verkopen. Niet iedereen zal geïnteresseerd zijn in jouw boek, maar ze zullen je toch volgen - en je berichten delen - vanwege de leuke content die je uploadt.',
  'socialmedia-tips-hashtaglover-label': 'Wees een hashtag liefhebber',
  'socialmedia-tips-hashtaglover': 'Het toepassen van hashtags is ontzettend belangrijk. Hierdoor worden je berichten eerder gevonden. Gebruik niet teveel algemene hashtags, maar doe de moeite om  een paar unieke toe te voegen die je helpen de juiste mensen te bereiken. Ga bijvoorbeeld op zoek naar de top hashtags in de online schrijfcommunity voordat je iets post. Natuurlijk is het zo dat je niet voor alles een hashtag nodig hebt en gebruik er nooit teveel (tussen de 4 en 7 is prima).',
  'socialmedia-tips-beflexible-label': 'Wees flexibel',
  'socialmedia-tips-beflexible': 'Elk social media platform is anders. Bij Instagram draait alles om afbeeldingen. LinkedIn draait om netwerken. Facebook draait om delen. Twitter draait om woorden. Het is belangrijk dat je jouw content niet klakkeloos kopieert en op al je social media plaatst. Je moet je aanpassen aan de stijl en norm van elk platform. Je stem moet hetzelfde blijven, maar de manier waarop je het uitdraagt moet flexibel zijn.',
  'socialmedia-tips-beanalytical-label': 'Wees analytisch',
  'socialmedia-tips-beanalytical': 'Elk social media platform biedt analytics, maak daar gebruik van! Je moet bijvoorbeeld aandacht besteden aan het type publiek dat je wil bereiken en bereikt, de beste tijdstippen om berichten te plaatsen en de trends op het gebied van likes en volgers. Je social media platformen zullen je die informatie geven zodra je je profiel verandert in een zakelijk account.',

  // Instagram
  'instagram-intro-p1': 'Instagram is tegenwoordig een must voor iedere auteur. De boekenwereld op Instagram, bookstagram, is een gigantische community vol potentiële lezers. Het opbouwen van een goede Instagram-aanwezigheid is wel een uitdaging, omdat het vereist dat je regelmatig visuele content van hoge kwaliteit plaatst.',
  'instagram-intro-p2': 'Er zijn drie belangrijke dingen die je op Instagram prioriteit moet geven: het bouwen van een esthetisch aangename feed, het gebruiken van relevante en gevarieerde hashtags (we hebben een paar voorbeelden toegevoegd, maar voel je vrij om ze te veranderen of andere toe te voegen), en het plaatsen van verhalen.',
  'instagram-promoteoninstagram': 'Promoot je boek op Instagram',
  'instagram-switchtophone': 'Maak gebruik van je telefoon',
  'instagram-switchtophone-description': 'Je gebruikt je smartphone om content op Instagram te posten. Je kunt de QR-code hieronder scannen om naar de mobiele versie van deze pagina te gaan. De meeste smartphones scannen QR-codes automatisch wanneer je de camera-app opent en deze op de code richt. Als je de QR-code niet kunt scannen, kun je gewoon onze website openen op je smartphone.',
  'instagram-setupinsta-title': 'Instagram instellen',
  'instagram-week1-title': 'Week 1 - Aankondiging',
  'instagram-week1-message': 'Hier is het dan eindelijk: het eerste gedrukte exemplaar van mijn nieuwe boek, {title} - dit is zo’n enorme mijlpaal voor mij en ik kan niet blijer zijn om het met jullie te delen! Als je mij wil steunen, het boek is nu verkrijgbaar voor {price}, link in de bio!\n\n{keywords} #selfpublishing #boekenwurm #boekpresentatie',
  'instagram-week2-title': 'Week 2 - Eerste verkoop post',
  'instagram-week2-chooseanimage-description': 'Kies een goede afbeelding om te gebruiken bij je post. Bijvoorbeeld een afbeelding waarbij je een boek aan iemand overhandigt.',
  'instagram-week2-message': 'Ik ben dolblij dat ik mijn eerste 5 boeken heb verkocht! Hartelijk dank aan de kopers; ik kan jullie niet uitleggen hoezeer ik jullie steun op prijs stel. Als jullie mij net zo gelukkig willen laten voelen als deze lieve mensen hebben gedaan, ga dan naar mijn website en koop je eigen exemplaar van ‘{title}’ ;-). De link staat in de bio!\n\n#selfpublishing #schrijversleven #schrijversvaninstagram',
  'instagram-week3-title': 'Week 3 - Giveaway post',
  'instagram-week3-chooseanimage-description': 'Wanneer je een afbeelding maakt voor je giveaway, is het logisch om datgene dat je weggeeft te laten zien. In het voorbeeld hieronder geven we 5 exemplaren weg, dus in dat geval is het leuk om een foto van 5 exemplaren van je boek te laten zien (eventueel met één exemplaar open). Let erop dat de belichting in orde is, zodat de boeken er fantastisch uitzien!',
  'instagram-week3-message': 'Het zijn een paar hectische weken geweest sinds mijn boek {title} uit is, en ik ben overdonderd door de ontzettend positieve reacties die het tot nu toe heeft gekregen, om nog maar te zwijgen over de steun die ik persoonlijk heb gekregen. Ik dank jullie voor alle lieve woorden en reacties. Wanneer je het boek nog hebt gekocht, dan is het volgende wellicht interessant voor je; ik ga 5 exemplaren weggeven aan mijn volgers! De boeken zullen allemaal gesigneerd zijn, dus mocht ik echt beroemd worden, dan heb je iets cools op je boekenplank staan ;-)\n\nHet enige wat je hoeft te doen is deze post te liken en een vriend te taggen waarvan je denkt dat hij/zij ook geïnteresseerd zou kunnen zijn in mijn boek! Ik zal 5 willekeurige reacties selecteren en de winnaars volgende week in mijn stories bekendmaken!\n\n#giveaway #boekstagram #indieauthor',
  'instagram-week4-title': 'Week 4 - Recensieverzoek',
  'instagram-week4-chooseanimage-description': 'Als je om recensies vraagt, ben je afhankelijk van de welwillendheid van je volgers. Het is daarom belangrijk om visueel met ze te connecten. Je kunt dit bijvoorbeeld doen door een foto van jezelf met het boek te maken, of door bijvoorbeeld een aantal boeken in een mandje te leggen met een bordje/briefje ‘recenseer me’ erbij.',
  'instagram-week4-message': 'Hoi iedereen, ik wil jullie graag om een gunst vragen!\n\nAls je mijn boek hebt gelezen, schrijf er dan alsjeblieft een korte recensie over op Goodreads! Het zou mij enorm helpen om de zichtbaarheid van mijn boek te vergroten en om meer mensen te bereiken. Zelfs als je geen tijd hebt om een recensie te schrijven, zou alleen al een rating geweldig zijn. Ik zou het erg waarderen.\n\nOok heel erg bedankt aan iedereen die mijn boek heeft gekocht, jullie hebben geen idee hoeveel het voor me betekent!\n\n#recensie #boekstagram #selfpublishing #indieauthor',
  'instagram-tips-title': 'Tips voor het gebruik van Instagram',
  'instagram-tips-visuals-label': 'Alles draait om visuals',
  'instagram-tips-visuals': 'Zoals je waarschijnlijk al weet, draait alles op Instagram om visuals. Dit lijkt misschien een uitdaging voor jou als auteur om je boek te promoten, maar het is niet zo moeilijk hoor. Voor je Instagram feed kan je bijvoorbeeld de cover van je boek,  foto’s van je dagelijkse leven als auteur of foto’s van je boekpresentatie gebruiken. Ook kan je je volgers een kijkje achter de schermen geven met foto’s die je tijdens het schrijven hebt gemaakt. Tip: een beetje zelfspot en humor kan geen kwaad!',
  'instagram-tips-quotes-label': 'Citaten',
  'instagram-tips-quotes': 'Een succesvolle tactiek om de nieuwsgierigheid van je publiek te stimuleren is het delen van citaten uit je boek! Ook al is Instagram erg visueel, een interessante zin geplakt op een mooie foto is een goede manier om een succesvolle post te krijgen.',
  'instagram-tips-interact-label': 'Interactie',
  'instagram-tips-interact': 'Je focus moet niet alleen liggen op promotie, maar ook op de interactie met je publiek. Hoe meer interactie, hoe meer aandacht je account zal krijgen. Het is essentieel om de berichten van je lezers te delen op je verhalen of commentaar te geven op hun berichten. Houd je community betrokken.',
  'instagram-tips-bookstagram-label': 'Boekstagram',
  'instagram-tips-bookstagram': 'Er is een enorme community van boekenlezers op Instagram die allemaal bijeenkomen onder de overkoepelende term "bookstagram". Deze online community deelt foto’s van de boeken die ze lezen en wat ze van plan zijn om te lezen. Ze zijn zeer invloedrijk. We adviseren je om een aantal boekstagrammers te volgen met het doel dat ze jouw boek delen op hun profiel. Begin met accounts die 1k-20k volgers hebben, omdat hun engagement vaak hoog is en het voor jou makkelijker is om een gesprek te beginnen. Vergeet niet om persoonlijke berichten te maken!',
  'instagram-tips-addlinks-label': 'Links toevoegen',
  'instagram-tips-addlinks': 'Het is belangrijk om een link in je bio toe te voegen die leidt naar een webpagina waar je boek te koop is. Je kunt de link van onze webshop, je auteurswebshop of je eigen website toevoegen. Mensen die op je Instagram account belanden kunnen je boek dan in een paar klikken kopen!',
  'instagram-tips-balance-label': 'Creëer een balans',
  'instagram-tips-balance': 'Houd in gedachten dat Instagram prioriteit geeft aan betrokkenheid boven het delen van content. Het is dus niet nodig om elke dag te posten, maar het is goed om als doel 2-3 posts per week te plaatsen. Instagram Stories van alledaagse content kunnen ook helpen om meer aandacht en betrokkenheid op Instagram te krijgen!',
  'instagram-tips-contests-label': 'Organiseer wedstrijden/giveaways',
  'instagram-tips-contests': 'Er is niets waar mensen meer van houden dan het krijgen van dingen. Het organiseren van een giveaway is de perfecte manier om de aandacht van mensen op je te vestigen en om je publiek te laten groeien. Je kunt een gesigneerd exemplaar van je boek aanbieden en mensen vragen je te volgen en om mee te doen aan de giveaway!',
  'instagram-tips-scheduleposts-label': 'Plan je berichten',
  'instagram-tips-scheduleposts': 'Een auteur zijn is hard werken en je zult met regelmaat in tijdnood komen wanneer je nieuwe content moet creëren en uploaden. Een goede strategie is om verschillende online tools te gebruiken om je berichten te plannen. Op die manier kun je meerdere posts tegelijk maken en ze plannen voor de komende weken. Wanneer je een bedrijfsaccount aanmaakt op Facebook, krijg je toegang tot Creator Studio, waarmee je posts kunt maken en inplannen op zowel Facebook als Instagram. Dit is een goede plek om te beginnen!',

  // facebook
  'facebook-copyandpastetext': 'Klik hier om de bovenstaande tekst te kopiëren. Plak deze vervolgens in het status veld nadat je op de share knop geklikt hebt.',
  'facebook-button-shareonfb': 'Delen op Facebook',
  'facebook-intro-p1': 'Om het maximale uit je marketinginspanningen op Facebook te halen, is het realiseren van een hoge mate aan engagement essentieel. Probeer daarom zoveel mogelijk likes en reacties te krijgen. We hebben wat ideeën voor je op een rijtje gezet hoe je dit kunt realiseren. Voel je overigens helemaal vrij om je eigen berichten op te stellen. Ga ook op zoek naar relevante Facebook groepen om lid van te worden. En wees dan actief in deze groepen.',
  'facebook-promoteviafacebook': 'Promoot je boek op Facebook',
  'facebook-week1-title': 'Week 1 - Aankondiging',
  'facebook-week1-message': 'Vandaag is de dag! Ik ben nu officieel een gepubliceerde auteur!! Het was een serieuze bevalling, maar om nu mijn eigen boek in handen te hebben geeft bijzonder veel voldoening. Wil je weten waar het over gaat? Lees dan hier de flaptekst:\n\n{shortdesc}\n\nAls het goed is smaakt dit naar meer! Ga naar mijn website en koop het boek nu!',
  'facebook-week2-title': 'Week 2 - Eerste verkoop post',
  'facebook-week2-message': 'Ik ben dolblij dat ik mijn eerste 5 boeken heb verkocht! Hartelijk dank aan alle kopers; ik kan jullie niet uitleggen hoezeer ik jullie steun op prijs stel. Als jullie mij net zo gelukkig willen laten voelen als deze lieve mensen hebben gedaan, ga dan naar mijn website en koop je eigen exemplaar van {title}.',
  'facebook-week3-title': 'Week 3 - Giveaway',
  'facebook-week3-message': 'Een auteur zijn is echt fantastisch; zoveel enthousiaste mensen hebben mij ongelooflijk ondersteund, ik ben helemaal in de wolken. Ik had nooit gedacht dat mijn boek zo goed zou worden ontvangen. Om dit te vieren, organiseer ik een weggeefactie! Dus wanneer je nog geen exemplaar hebt, like en share deze post en laat een comment achter waarin je je favoriete boek beschrijft met zo min mogelijk woorden. Ik kies de vijf beste comments en stuur die een gesigneerd exemplaar!',
  'facebook-week4-title': 'Week 4 - Recensieverzoek',
  'facebook-week4-message': 'Hartelijk dank aan iedereen die mijn boek heeft gekocht; jullie zijn fantastisch! Ik wil jullie nog om een kleine gunst vragen - het zou me enorm helpen als jullie naar Goodreads gaan en een recensie over mijn boek schrijven! Zelfs een rating is al geweldig. Uiteraard waardeer ik een recensie op een ander platform (bv van de boekhandel) ook enorm! Bij voorbaat dank!',
  'facebook-tips-title': 'Tips voor het gebruik van Facebook',
  'facebook-tips-authorpage-label': 'Auteurspagina',
  'facebook-tips-authorpage': 'Het is belangrijk om op Facebook een aparte pagina te maken met specifieke content over jouw boek. Door deze auteurspagina leert je publiek je beter kennen en krijgt zo de meest recente informatie over jouw boek en al je promotie inspanningen.',
  'facebook-tips-engagement-label': 'Betrokkenheid',
  'facebook-tips-engagement': 'Facebook laat je berichten niet altijd aan je eigen volgers zien; het algoritme dat Facebook gebruikt richt zich op hoeveel engagement je berichten krijgen. Dus, hoe meer likes en reacties je hebt, hoe groter de kans dat Facebook jouw posts voorrang geeft! Vergeet dus niet je publiek te betrekken bij al je berichten die je plaatst!',
  'facebook-tips-sellyourbook-label': 'Je boek verkopen',
  'facebook-tips-sellyourbook': 'Het beste wat je kan doen is een link te plaatsen naar de verkoopkanalen waar je boek verkrijgbaar is, zoals bijvoorbeeld onze webshop, jouw auteurswebshop of je eigen website. Hoe minder klikken er zijn tot de aanschaf van je boek, hoe groter de kans dat mensen jouw boek kopen. Je kunt ook een link naar een boekhandel plaatsen, maar dan verdien je minder dus dit raden we je af.',
  'facebook-tips-promoteothers-label': 'Promoot ook andere boeken',
  'facebook-tips-promoteothers': 'Dit advies lijkt misschien averechts te werken op de verkoop van jouw boek maar het is een veelgebruikte strategie. Hoe meer interactie je hebt met andere auteurs, hoe meer zij jou ook zullen helpen. Het is goed om recensies over andere boeken te delen en deze boeken te promoten om zo deel uit te maken van een actieve community.',
  'facebook-tips-sharewriting-label': 'Deel fragmenten van je boek',
  'facebook-tips-sharewriting': 'Het delen van een paar hoofdstukken van jouw boek is ook een veel beproefde en succesvolle verkoopstrategie. Je publiek leert je werk kennen en kan geïnteresseerd raken. Dus: creëer een aantal posts met enkele fragmenten of hoofdstukken uit je boek! Teasers zijn belangrijk om mensen te interesseren en te motiveren om je boek te kopen.',
  'facebook-tips-startagroup-label': 'Start een groep of neem deel aan groepen',
  'facebook-tips-startagroup': 'Groepen zijn een essentieel onderdeel van Facebook. Veel mensen vinden het leuk om deel uit te maken van kleinere communities in de grote wereld van Facebook. Jij kunt altijd wel relevante groepen vinden die passen bij het thema van je boek. Dit zijn bij uitstek de plekken waar je de verkoop kunt stimuleren. Je kunt ook zelf een groep starten om je eigen Facebook-community op te bouwen!',

  // Twitter
  'twitter-intro-p1': 'Twitter is de perfecte plek voor mensen die met weinig woorden veel kunnen zeggen. Jij, als schrijver, zou je hier dus op je gemak moeten voelen!',
  'twitter-intro-p2': 'Om het meeste uit Twitter te halen, moet je vaak tweeten en, misschien nog belangrijker, retweeten. Houd hashtags in de gaten die relevant zijn voor boeken, publiceren, jouw specifieke genre en ook je persoonlijke interesses. Zorg ervoor dat je inhoudelijk bijdraagt aan alle discussies waar je aan deelneemt. Wel is het zo dat het verkooppotentieel van Twitter veel minder direct is dan dat van, bijvoorbeeld, Instagram. Niettemin is het nuttig om een schare aan volgers (en ambassadeurs) te hebben.',
  'twitter-promoteviatwitter': 'Promoot je boek op Twitter',
  'twitter-button-shareviatwitter': 'Delen op Twitter',
  'twitter-charcount': 'Je hebt {chars} van de 280 karakters gebruikt',
  'twitter-week1-title': 'Week 1 - Aankondiging',
  'twitter-week1-message': 'Mijn boek, {title} is eindelijk gepubliceerd! Ga naar mijn website om je eigen exemplaar te bestellen of de flaptekst te lezen! #indieauthor',
  'twitter-week2-title': 'Week 2 - Eerste verkoop post',
  'twitter-week2-message': 'Het is zover! De eerste 5 boeken zijn verkocht; dank aan alle kopers! Voor andere geïnteresseerden is mijn boek, {title}, verkrijgbaar op mijn website.',
  'twitter-week3-title': 'Week 3 - Giveaway',
  'twitter-week3-message': 'Het is tijd voor een #giveawayactie! Als je een exemplaar van mijn boek wil winnen: volg me, like en retweet deze tweet en laat een reactie achter waarin je uitlegt waarom je graag mijn boek wil hebben!',
  'twitter-week4-title': 'Week 4 - Recensieverzoek',
  'twitter-week4-message': 'Als je mijn boek, {title}, hebt gelezen dan waardeer ik het zeer als je op Goodreads een recensie wil plaatsen! Je helpt me hier enorm mee!',
  'twitter-tips-title': 'Tips voor het gebruik van Twitter',
  'twitter-tips-sharequotes-label': 'Deel citaten',
  'twitter-tips-sharequotes': 'Aangezien je ongetwijfeld bekend bent met Twitter, zullen we niet te veel op de basisprincipes ingaan. Omdat het platform gericht is op beknopte, op tekst gebaseerde berichten, is het zeer geschikt voor schrijvers die met woorden kunnen toveren. Een voor de hand liggende manier om Twitter te gebruiken is om citaten uit je boek te delen of om ze nieuwsgierig te maken. Laat je publiek zien wie je bent als schrijver!',
  'twitter-tips-interaction-label': 'Interactie boven alles',
  'twitter-tips-interaction': 'Wanneer je het maximale uit Twitter wil halen moet je niet alleen zelf tweets plaatsen, maar moet je ook deelnemen aan lopende discussies. Zeker in het begin is dat cruciaal. Twitter heeft de mogelijkheid voor interactie met potentiële lezers, andere auteurs en natuurlijk je eigen fans. Hoe meer interactie, hoe beter!',
  'twitter-tips-balance-label': 'Balans',
  'twitter-tips-balance': 'De verkoop van je boek is natuurlijk je hoofddoel maar het is makkelijk om jezelf te verliezen in de constante marketing van je boek. Het is echter belangrijk om een persoonlijke – en nog beter – een grappige kant van jezelf te laten zien. Mensen willen niet constant tweets lezen die neerkomen op ‘koop mijn boek’; ze willen ook je verhalen horen en een kijkje in je leven krijgen! Houd de balans dus goed in de gaten.',
  'twitter-tips-pinapost-label': 'Pin een tweet',
  'twitter-tips-pinapost': 'Als je regelmatig tweets plaatst op Twitter en bang bent dat specifieke informatie over je boek daardoor verloren gaat of over het hoofd wordt gezien, is het een goed idee om een tweet te pinnen! Je kunt bijvoorbeeld een tweet pinnen die een link bevat naar een verkoopkanaal van je boek, zodat het direct zichtbaar is voor iedereen die je Twitter profiel bezoekt. Dan hoeven ze alleen nog maar op de link te klikken!',
  'twitter-tips-bio-label': 'Bio',
  'twitter-tips-bio': 'Veel van je tijd op Twitter is gericht op tweeten maar verwaarloos je bio niet! Het is voor iedereen zichtbaar zijn dus het is een goede plek om korte iets over jezelf te zeggen en de titel van je boek te vermelden. Zorg dat je interessant overkomt!',

  // LinkedIn
  'linkedin-intro-p1': 'LinkedIn heeft meer potentie voor self-published auteurs dan je in eerste instantie zou denken. Ten eerste is het bijna op maat gemaakt voor diegenen die non-fictie hebben geschreven. Maar ook als je fictie hebt geschreven, kan je op LinkedIn een lezerspubliek vinden. Het is een platform dat zeer geschikt is om je schrijfervaringen te delen vanuit wat wij graag een “authorpreneurial” perspectief noemen. Beschouw het schrijven, de publicatie en de verkoop van je boek als een project en praat over de uitdagingen en beloningen die het werken eraan met zich meebrengen. Maar vermijd langdradige en monotone berichten en doe niet te veel aan platte promotie!',
  'linkedin-intro-p2': 'Op LinkedIn kun je ook lid worden van diverse groepen die interessant zijn voor je. Denk aan schrijversgroepen, uitgeefgroepen of special interest groepen die over het thema gaan van jouw boek. Het lidmaatschap van zo’n groep is niet direct om verkoop te realiseren, maar om de aspecten van het schrijven en uitgeven met elkaar te bespreken.',
  'linkedin-promotevialinkedin': 'Promoot je boek op LinkedIn',
  'linkedin-copyandpastetext': 'Klik hier om de bovenstaande tekst te kopiëren. Plak deze vervolgens in het status veld nadat je op de share knop geklikt hebt.',
  'linkedin-button-shareonlinkedin': 'Delen op LinkedIn',
  'linkedin-week1-title': 'Week 1 - Aankondiging',
  'linkedin-week1-message': 'Ik ben trots om te vertellen dat mijn boek, {title} is gepubliceerd! Auteur worden is altijd al een droom van me geweest en het is een ongelooflijke ervaring om die droom te realiseren. Graag wissel ik met jullie over dit interessante onderwerp van gedachten. Voor wie geïnteresseerd is in mijn boek: zie bijgaande link met meer informatie over het boek!\n\n#boek #boekentip #mijlpaal',
  'linkedin-week2-title': 'Week 2 - Boekpresentatie',
  'linkedin-week2-message': 'Wat een happening was mijn boekpresentatie!  Het was heel bijzonder om mijn netwerk eens in een andere setting bij elkaar te hebben. Nota bene met mijn boek als stralend middelpunt, ook al hoef ik niet perse op de voorgrond te treden. Voor diegene die er niet bij konden zijn: bijgaand een paar leuke foto’s!\n\n#boek #boekborrel #boekpresentatie',
  'linkedin-week3-title': 'Week 3 - Workshop',
  'linkedin-week3-message': 'De verkoop van mijn boek {title} gaat erg goed en ik merk dat het onderwerp enorm leeft. Ik ga graag met jullie daarover in gesprek en organiseer een gratis workshop. Ik kijk ernaar uit om jullie ideeën te bespreken en feedback te krijgen op mijn visie. Laat me weten of jullie dit ook interessant vinden.\n\n#boek #workshop',
  'linkedin-week4-title': 'Week 4 - Giveaway',
  'linkedin-week4-message': 'Het uitgeven van mijn boek was een hele bevalling, maar het heeft zich beloond! De verkoop gaat goed en mijn boek is inmiddels meer dan duizend keer verkocht! Om dit te vieren geef ik 5 exemplaren cadeau, maar daar moet je wel wat voor doen: Reageer op mijn bericht en geef in Twitter stijl aan wat jouw bijzondere ervaring is met het onderwerp van mijn boek. De meest inspirerende berichten worden beloond!\n\n#authorpreneur #selfpublishing #giveaway',
  'linkedin-tips-title': 'Tips voor het gebruik van LinkedIn',
  'linkedin-tips-finishprofile-label': 'Maak je profiel af... maar dan ook echt af',
  'linkedin-tips-finishprofile': 'Dit lijkt misschien voor de hand liggend, maar veel mensen stellen het voltooien van alle stappen van hun Linkedin profiel voor onbepaalde tijd uit. Je profiel niet afmaken betekent dat mensen je minder makkelijk vinden of zich minder met jou identificeren. Zorg dus voor een compleet profiel!',
  'linkedin-tips-connect-label': 'Connecties',
  'linkedin-tips-connect': 'Linkedin draait om netwerken; het platform is gebaseerd op het creëren van connecties met mensen. Je moet ervoor zorgen dat je andere auteurs, potentiële lezers, en dergelijke toevoegt. Het creëren van content voor een klein netwerk zal je niet veel verder brengen. Pas wel op: ga niet klakkeloos iedereen uitnodigen. Nodig altijd mensen uit waar een klik mee is: of zakelijk, of qua interesse.',
  'linkedin-tips-publisharticles-label': 'Publiceer artikelen',
  'linkedin-tips-publisharticles': 'Linkedin biedt de mogelijkheid om artikelen te plaatsen en dit is ontzettend handig voor een aspirant-auteur! Je kunt dit platform gebruiken om blogs te schrijven over het onderwerp van jouw boek en deel tips en ervaringen die andere auteurs interessant kunnen vinden. Mensen hebben de neiging om zich bezig te houden op Linkedin door veel berichten te lezen, en artikelen zijn perfect voor een auteur die iets te zeggen heeft! Bovendien zijn artikelen een geweldige manier om mensen naar je website te leiden. Zorg ervoor dat je niet puur commerciële artikelen plaatst met de verkooplink van je boek. Dat zal niet worden gewaardeerd.',
  'linkedin-tips-jobposition-label': 'Maak van je boek een functie',
  'linkedin-tips-jobposition': 'Je kunt je boek als een eigen baan presenteren! De titel zou verwijzen naar je naam en het bedrijf zou de naam van je boek zijn. Op deze manier kunnen mensen je boek duidelijk zien op je Linkedin profiel. Je kunt de data toevoegen waarop je aan je boek hebt gewerkt, samen met een leuke beschrijving. Dit is ook een leuke manier om potentiële vervolgboeken aan te kondigen.',
  'linkedin-tips-behuman-label': 'Wees persoonlijk',
  'linkedin-tips-behuman': 'Omdat Linkedin vooral bekend staat als een zakelijk platform, denken mensen vaak dat ze formeel moeten zijn als ze daar iets posten. Dat is maar ten dele waar, natuurlijk is het een zakelijk netwerk, maar jouw toon mag natuurlijk vriendelijk, optimistisch en persoonlijk zijn. Probeer niet iets te zijn wat je niet bent. Mensen op LinkedIn zijn gek op verhalen over persoonlijke groei en het overwinnen van uitdagingen, dus gebruik dat in je voordeel.',

  // Libraries
  'libraries-intro-p1': 'Iedere serieuze auteur hoort zijn boek in de bibliotheek te hebben. Het is immers een belangrijke mijlpaal van het pubiceren van een boek. Sterker nog: vrijwel alle nationale bibliotheken hebben formeel recht op een exemplaar. Naast deze formaliteit, is het natuurlijk ook een uitstekende promotiekans: als genoeg mensen je boek aanvragen bij lokale bibliotheken, maak je een goede kans om extra exemplaren van je boek te verkopen!',

  // Upload zone
  'uploadzone-default-title': 'Uploaden',
  'uploadzone-default-title-progress': 'Je manuscript wordt geupload',
  'uploadzone-filerequirements': 'Bestandseisen: max. {filesize}, formaten: {filetypes}',
  'uploadzone-button-selectfile': 'Kies bestand',
  'uploadzone-ordragfilehere': 'of sleep je bestand hierheen',
  'uploadzone-dropfiletoupload': 'Plaats hier een bestand om te uploaden',

  // Book problem translations
  'bookproblem-link-moreinfo': 'Meer informatie',
  'bookproblem-stepScriptSizeModifiedUploadAgain': 'Je hebt het formaat van je boek aangepast. Upload je manuscript opnieuw.',
  'bookproblem-stepScriptColorModifiedUploadAgain': 'Je hebt de kleur van het binnenwerk van je boek aangepast. Upload je manuscript opnieuw.',
  'bookproblem-stepScriptBindingModifiedUploadAgain': 'Je hebt het boektype aangepast. Upload je manuscript opnieuw.',
  'bookproblem-stepScriptReaddirectionModifiedUploadAgain': 'Je hebt de taal van je boek gewijzigd naar een taal met een andere leesrichting. Upload je manuscript opnieuw, zodat de leesrichting overeenkomt met de gekozen taal.',
  'bookproblem-stepScriptManuscriptConversionFailed': 'We hebben je bestand niet kunnen converteren. Dit komt hoogstwaarschijnlijk door een corruptie in je bestand. Probeer je bestand te repareren en upload het opnieuw.',
  'bookproblem-stepScriptManuscriptColorpagesNotCalculated': 'Het is niet gelukt om het juiste aantal kleurenpagina’s voor je boek te berekenen. Neem contact met ons op voor ondersteuning bij dit probleem of upload een aangepast bestand.',
  'bookproblem-stepScriptManuscriptConversionTechnicalError': 'Er is een technische fout opgetreden bij conversie van je manuscript. De foutmelding: {lasterror}',
  'bookproblem-stepScriptFontsNotEmbedded': 'Niet alle lettertypes zijn correct ingesloten in dit document. Meer informatie vind je in onze handleiding over het maken van een PDF bestand.',
  'bookproblem-stepScriptEbookConversionHasErrors': 'We hebben bij het converteren van je e-book verschillende fouten ontdekt. Je moet deze fouten oplossen voordat je je boek kunt publiceren.',
  'bookproblem-printerConstraintNumberpagesLessThanMinimum': 'Je boek heeft te weinig pagina’s. Zorg ervoor dat je boek minimaal {min} pagina’s bevat (het huidige aantal is {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanMaximum': 'Je boek heeft te veel pagina’s. Zorg ervoor dat je boek maximaal {max} pagina’s bevat (het huidige aantal is {pagecount}).',
  'bookproblem-printerConstraintNumberpagesMoreThanCoverwidth': 'Je boek heeft te veel pagina’s in combinatie met het gekozen formaat en de papiersoort. Zorg ervoor dat je boek maximaal {max} pagina’s bevat, of kies een andere papiersoort of smaller formaat.',

  // Bookstep: manuscript
  'bookstep-Manuscript': 'Manuscript',
  'bookstep-Manuscript-intro-p1-1': 'Wil jij van je boek een verkoopsucces maken? Dan is een goed geschreven en mooi opgemaakt manuscript belangrijk! Zorg er dus voor dat je hier voldoende tijd aan besteedt. We bieden je volop ondersteuning tijdens het schrijven en bij het opmaken en je kunt gebruikmaken van onze diensten. Is je manuscript al gereed? Dan kun je deze direct als Word of PDF bestand uploaden! Na het uploaden tonen wij een drukklare PDF, controleer deze goed en koop altijd een proefexemplaar voordat je publiceert. Het is zonde wanneer je er later achter komt dat er nog fouten in je manuscript zitten.',
  'bookstep-Manuscript-intro-p1-3': 'Wil jij van je boek een verkoopsucces maken? Dan is een goed geschreven en mooi opgemaakt manuscript belangrijk! Zorg er dus voor dat je hier voldoende tijd aan besteedt. We bieden je volop ondersteuning tijdens het schrijven en bij het opmaken en je kunt gebruikmaken van onze diensten. Is je manuscript al gereed? Dan kun je deze direct als Word of PDF bestand uploaden! Na het uploaden tonen wij een drukklare PDF, controleer deze goed en koop altijd een proefexemplaar voordat je publiceert. Het is zonde wanneer je er later achter komt dat er nog fouten in je manuscript zitten.',
  'bookstep-Manuscript-intro-p1-6': 'Wil jij van je boek een verkoopsucces maken? Dan is een goed geschreven en mooi opgemaakt manuscript belangrijk! Zorg er dus voor dat je hier voldoende tijd aan besteedt. We bieden je volop ondersteuning tijdens het schrijven en bij het opmaken van je ePub. Je kunt een Word document of een ePub uploaden, controleer de ePub die wij na het uploaden tonen goed. Indien je een Word document uploadt moet je je wel aan de spelregels houden om een goede ePub te krijgen. Je kunt ook een ePub bestand maken, dan adviseren we je om gebruik te maken van Calibre.',
  'bookstep-Manuscript-intro-p1-7': 'Wil jij van je boek een verkoopsucces maken? Dan is een goed geschreven en mooi opgemaakt manuscript belangrijk! Zorg er dus voor dat je hier voldoende tijd aan besteedt. We bieden je volop ondersteuning tijdens het schrijven en bij het opmaken van je e-book. Is je manuscript al gereed? Dan kun je deze direct als PDF bestand uploaden! Na het uploaden tonen wij een voorbeeld PDF: controleer deze goed! Het is zonde wanneer je er later achter komt dat er nog fouten in je manuscript zitten.',
  'bookstep-Manuscript-button-nextstep': 'Volgende stap',
  'bookstep-Manuscript-resources-toolstoimprovescript': 'Ondersteuning bij je manuscript',
  'bookstep-Manuscript-resources-wordtemplate': 'Word sjabloon en instellingen',
  'bookstep-Manuscript-resources-wordtemplate-description': 'Basisinstellingen en sjabloon voor de opmaak van je boek in Word',
  'bookstep-Manuscript-resources-bookspecs': 'Specificaties voor professionals',
  'bookstep-Manuscript-resources-bookspecs-description': 'Instellingen voor InDesign, QuarkXPress, etc.',
  'bookstep-Manuscript-resources-bookformatting': 'Boek opmaken',
  'bookstep-Manuscript-resources-bookformatting-description': 'Korte uitleg: manuscript opmaken',
  'bookstep-Manuscript-resources-services-needhelp': 'Hulp nodig?',
  'bookstep-Manuscript-resources-services-needhelp-description': 'Meer informatie over onze betaalde diensten',
  'bookstep-Manuscript-UploadBlock-title-0': 'Mijn manuscript',
  'bookstep-Manuscript-UploadBlock-title-1': 'Een moment, we converteren je manuscript',
  'bookstep-Manuscript-UploadBlock-title-2': 'Controleer je manuscript',
  'bookstep-Manuscript-uploadblock-stillwriting-title': 'Ik ben aan het schrijven',
  'bookstep-Manuscript-uploadblock-stillwriting-description': 'Nog bezig met je manuscript? Haal inspiratie uit onze praktische tips, onze schrijfmethodiek of maak gebruik van onze professionele services!',
  'bookstep-Manuscript-uploadblock-stillwriting-button': 'Schrijven',
  'bookstep-Manuscript-uploadblock-stillformatting-title': 'Mijn manuscript opmaken',
  'bookstep-Manuscript-uploadblock-stillformatting-description': 'Klaar met schrijven maar moet je manuscript nog worden opgemaakt? Zorg dat deze er pico bello uitziet en maak gebruik van onze sjablonen of diensten!',
  'bookstep-Manuscript-uploadblock-stillformatting-button': 'Opmaken',
  'bookstep-Manuscript-uploadblock-conversioninprogress-title': 'Een moment, we zijn je manuscript aan het controleren en converteren',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1': 'Controleer je online drukproef',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-desc': 'We hebben je manuscript geconverteerd naar een drukklare PDF. Jouw boek wordt straks exact gedrukt zoals deze PDF eruit ziet. Controleer deze PDF dus goed! Je kunt zo vaak als je wenst een nieuwe versie uploaden.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-1-previewbutton': 'Drukproef controleren',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3': 'Controleer je online drukproef',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-desc': 'We hebben je manuscript geconverteerd naar een drukklare PDF. Jouw boek wordt straks exact gedrukt zoals deze PDF eruit ziet. Controleer deze PDF dus goed! Je kunt zo vaak als je wenst een nieuwe versie uploaden.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-3-previewbutton': 'Drukproef controleren',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6': 'Controleer je e-book',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-desc': 'We hebben je manuscript geconverteerd naar een e-book. Jouw lezers ontvangen straks exact hetzelfde e-book (uiteraard met het omslag die je straks klaarmaakt eraan toegevoegd). Controleer dit ePub bestand dus goed! Je kunt zo vaak als je wenst een nieuwe versie uploaden.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-6-previewbutton': 'E-book controleren',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7': 'Controleer je PDF',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-desc': 'We hebben je manuscript geconverteerd naar een PDF. Jouw lezers ontvangen straks exact hetzelfde PDF bestand. Controleer deze PDF dus goed! Je kunt zo vaak als je wenst een nieuwe versie uploaden.',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-binding-7-previewbutton': 'PDF e-book controleren',
  'bookstep-Manuscript-uploadblock-label-step2checkscript-uploadnewbutton': 'Upload nieuwe versie',
  'bookstep-Manuscript-tips-title': 'Tips voor een goed manuscript',
  'bookstep-Manuscript-tips-print-sizeinterior-label': 'Formaat binnenwerk / specs',
  'bookstep-Manuscript-tips-print-sizeinterior-word': 'Indien je gebruikmaakt van Word, raden we je aan om ons sjabloon te downloaden via de bovenstaande knop ‘Word sjabloon’. Dit sjabloon heeft exact het juiste formaat voor jouw boek. Zo zorg je ervoor dat je boek zo goed mogelijk overeenkomt met datgene dat je in Word voorbereidt.',
  'bookstep-Manuscript-tips-print-sizeinterior-indesign': 'Maak je gebruik van InDesign of een ander professioneel opmaakprogramma, klik dan op de knop ‘Boekspecificaties’. Alle specificaties voor een perfect manuscript zijn daar toegelicht.',
  'bookstep-Manuscript-tips-print-images-label': 'Omgaan met afbeeldingen',
  'bookstep-Manuscript-tips-print-images': 'Je kunt uiteraard gebruikmaken van afbeeldingen in je manuscript. Een afbeelding met een resolutie van 250 dpi is voldoende om straks mooi te worden gedrukt. Je plaatst de afbeeldingen zelf in je manuscript op de plaats waar je wenst. Je kunt ook afbeeldingen tot aan de paginarand plaatsen. Indien je een spread (foto over 2 pagina’s) wil plaatsen: knip de foto in 2 delen en plaats de linkerkant op een even pagina en de rechterkant op de oneven pagina met het hogere paginanummer.',
  'bookstep-Manuscript-tips-print-colophon-label': 'Colofon',
  'bookstep-Manuscript-tips-print-colophon-p1': 'Het is gebruikelijk dat je een boek begint met de titelpagina’s en de colofonpagina. De eerste pagina’s van een boek zijn in de regel als volgt:',
  'bookstep-Manuscript-tips-print-colophon-firstpage': 'Eerste pagina: alleen de titel van het boek in een groot lettertype',
  'bookstep-Manuscript-tips-print-colophon-secondpage': 'Tweede pagina: leeg',
  'bookstep-Manuscript-tips-print-colophon-thirdpage': 'Derde pagina: titel van het boek en daaronder de auteursnaam',
  'bookstep-Manuscript-tips-print-colophon-fourthpage': 'Vierde pagina: dit is de colofonpagina, je neemt hierin op: de auteursnaam, het ISBN (indien van toepassing), wie verantwoordelijk is voor het omslagontwerp, je geeft aan dat jouw werk auteursrechtelijk beschermd is (copyright), het jaar van uitgave, uitgegeven via {publicbrand}',
  'bookstep-Manuscript-tips-print-colophon-p2': 'Deze pagina’s zijn niet genummerd. De paginanummers beginnen dus hierna, met paginanummer 5, ons sjabloon is reeds zo ingesteld.',
  'bookstep-Manuscript-tips-print-formattinginterior-label': 'Opmaak binnenwerk',
  'bookstep-Manuscript-tips-print-formattinginterior': 'Zonder een goede opmaak van je manuscript, verliest je boek aan waarde voor de lezer. Om je te helpen je boek zo goed mogelijk op te maken, hebben we een uitgebreide handleiding voor het opmaken van je boek ontwikkeld.',
  'bookstep-Manuscript-tips-print-formattinginterior-link': 'Klik hier voor meer informatie',
  'bookstep-Manuscript-tips-print-minimumpagecount-label': 'Minimum aantal pagina’s',
  'bookstep-Manuscript-tips-print-minimumpagecount': 'Om kwalitatief goede boeken te kunnen drukken geldt er een minimum aantal pagina’s. Dit is afhankelijk van de uitvoering van het boek: de omslag (paperback of hardcover), formaat van het boek en de papiersoort. Een paperback heeft een lager minimum aantal pagina’s dan een hardcover. Het minimum aantal pagina’s verschilt per papiersoort, omdat de dikte per papiersoort verschillend is. In de eerste stap, waar je de specificaties van je boek kiest, tonen wij het minimum aantal pagina’s.',
  'bookstep-Manuscript-tips-print-pageorientation-label': 'Indeling pagina’s links / rechts',
  'bookstep-Manuscript-tips-print-pageorientation': 'Dit lijkt logisch maar het kan geen kwaad om er nog even bij stil te staan. De eerste pagina van het manuscript is een rechterpagina. De binnenkant van de omslag telt dus niet mee. Deze is altijd wit. Linkerpagina’s zijn even pagina’s. Pagina’s die naast elkaar liggen zijn dus een linkerpagina (even) met 1 paginanummer minder dan de rechterpagina (oneven pagina). Wij plaatsen de paginanummers niet, dat moet je zelf doen. Onze Word sjablonen houden al rekening met de paginanummers.',
  'bookstep-Manuscript-tips-epub-epubexplain-label': 'Uitleg ePub',
  'bookstep-Manuscript-tips-epub-epubexplain': 'Een e-book bestand (een ePub) werkt in de praktijk iets anders dan je wellicht gewend bent. Een ePub bestand heeft namelijk geen vast paginaformaat, maar is ‘reflowable’. Dat betekent dat de tekst zich aanpast naar de grootte van het scherm. Bij een kleiner scherm worden zinnen automatisch korter gemaakt en krijgt je boek automatisch meer pagina’s dan bij een groter scherm. Dat betekent dat de opmaak van de het e-book afhangt van de reader waarop het boek gelezen wordt.',
  'bookstep-Manuscript-tips-epub-usingword-label': 'Spelregels voor Word',
  'bookstep-Manuscript-tips-epub-usingword': 'Word is geschikt om een goede ePub te maken, maar dan moet je je wel aan een aantal spelregels houden! Wanneer je bijvoorbeeld niet gebruik maakt van pagina-einden, maar met de ‘enter’ toets naar een nieuwe pagina gaat, zullen er veel witregels ontstaan in je e-book. Volg al onze aanwijzingen voor het maken van je e-book met Word dus goed op. Klik hiervoor bovenaan de pagina op ‘Boek opmaken’.',
  'bookstep-Manuscript-tips-epub-images-label': 'Omgaan met afbeeldingen',
  'bookstep-Manuscript-tips-epub-images': 'Uiteraard kun je in een e-book ook afbeeldingen gebruiken. Let hierbij wel op dat je de afbeeldingen op de juiste manier invoegt. Dit betekent dat je, wanneer je een Word bestand uploadt, de afbeeldingen ‘in de tekstregel’ moet positioneren en niet ‘zwevend’ met de tekst eromheen.',
  'bookstep-Manuscript-tips-epub-cover-label': 'Omslag in ePub of apart?',
  'bookstep-Manuscript-tips-epub-cover': 'Wanneer je ePub bestand uploadt, heb je de mogelijkheid om alvast een omslag toe te voegen aan dit bestand (maar dit is niet verplicht). Wanneer je vervolgens je ePub uploadt en de kwaliteit van je omslag goed genoeg is, nemen wij deze omslag automatisch over in de stap ‘omslag maken’. Wanneer je een Word bestand uploadt, is dit niet mogelijk en moet je het omslag zelf uploaden in de stap ‘omslag maken’.',
  'bookstep-Manuscript-tips-epub-calibre-label': 'Calibre: een perfecte ePub maken',
  'bookstep-Manuscript-tips-epub-calibre': 'Als je zelf je volledige ePub wilt maken, raden we je aan gebruik te maken van Calibre. Dat is een gebruiksvriendelijk programma waarmee je eenvoudig je ePub kan samenstellen. Je kunt zelfs een Word bestand importeren om als basis gebruiken voor je ePub. Bekijk onze handleiding over het maken van een ePub met Calibre voor meer informatie.',
  'bookstep-Manuscript-tips-epub-checkepub-label': 'Controleer je ePub!',
  'bookstep-Manuscript-tips-epub-checkepub': 'Het ePub bestand wat wij na het uploaden tonen is gelijk aan het bestand dat je lezers straks ontvangen. Het is dus cruciaal dat je dit bestand goed controleert. Maak hiervoor gebruik van een goede e-reader applicatie zoals bijv. Calibre, Adobe Digital Editions, Aldiko (op Android) of Apple Books (iPhone/iPad/Mac). Je kunt uiteraard ook een e-reader van bijv. Kobo gebruiken.',

  'bookstep-Manuscript-popup-bookspecs-bookspecs': 'Boekspecificaties',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word': 'Word sjabloon en instellingen',
  'bookstep-Manuscript-popup-bookspecs-selectboard-word-description': 'Basisinstellingen en sjablonen voor Word',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof': 'Specificaties voor professionals',
  'bookstep-Manuscript-popup-bookspecs-selectboard-prof-description': 'Instellingen voor InDesign, QuarkXPress, etc.',
  'bookstep-Manuscript-popup-bookspecs-manuscriptspecs': 'Manuscript specificaties',
  'bookstep-Manuscript-popup-bookspecs-pagesize': 'Afmetingen (excl. afloop): {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-inclbleed': 'Afmetingen incl. afloop: {sizes}',
  'bookstep-Manuscript-popup-bookspecs-pagesize-nospecificreq': 'geen eisen',
  'bookstep-Manuscript-popup-bookspecs-bleed': 'Afloop: {bleeds}',
  'bookstep-Manuscript-popup-bookspecs-bleed-nobleedreq': 'geen afloop noodzakelijk',
  'bookstep-Manuscript-popup-bookspecs-bleed-top': 'bovenkant',
  'bookstep-Manuscript-popup-bookspecs-bleed-bottom': 'onderkant',
  'bookstep-Manuscript-popup-bookspecs-bleed-outside': 'buitenkant',
  'bookstep-Manuscript-popup-bookspecs-bleed-inside': 'binnenkant',
  'bookstep-Manuscript-popup-bookspecs-nopagenumheadfoot': 'Voeg geen paginanummers, headers of footers toe.',
  'bookstep-Manuscript-popup-bookspecs-imagesinline': 'Zorg dat afbeeldingen ‘in de tekstregel’ staan. Gebruik geen zwevende afbeeldingen.',
  'bookstep-Manuscript-popup-bookspecs-uploadspecs': 'Upload specificaties',
  'bookstep-Manuscript-popup-bookspecs-fileformats': 'Bestandsformaat: {formats}',
  'bookstep-Manuscript-popup-bookspecs-noprintmarks': 'Geen print-, snij- of afloopmarkeringen',
  'bookstep-Manuscript-popup-bookspecs-nospreads': 'Gebruik geen spreads',
  'bookstep-Manuscript-popup-bookspecs-imagesdpi': 'Afbeeldingen: min. {dpinum} dpi',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate': 'Word sjabloon',
  'bookstep-Manuscript-popup-bookspecs-word-wordtemplate-p1': 'Voor de opmaak van je manuscript in Microsoft Word, raden we aan dat je gebruikmaakt van ons Word sjabloon. Dit sjabloon heeft de juiste afmetingen voor jouw boek en we hebben ervoor gezorgd dat de belangrijkste instellingen voor een goed boek alvast gedaan zijn. Klik op onderstaande knop om het sjabloon te downloaden.',
  'bookstep-Manuscript-popup-bookspecs-word-downloadtemplate': 'Download sjabloon',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins': 'Het juiste formaat en de juiste marges instellen',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p1': 'Als je gebruikmaakt van ons Word sjabloon, dan kun je onderstaande instellingen negeren.  Ons sjabloon maakt namelijk standaard al gebruik van deze instellingen.',
  'bookstep-Manuscript-popup-bookspecs-word-therightsizemargins-p2': 'Wanneer je zelf een document maakt (zonder ons sjabloon), is erg belangrijk dat het formaat van het bestand dat je uploadt overeenkomt met het gekozen boekformaat. Wanneer dit niet het geval is, schalen we je bestand automatisch naar het juiste formaat. Dit kan ervoor zorgen dat de opmaak van je boek verandert. Let ook op de afloop die wordt toegevoegd als marge voor het snijden.',
  'bookstep-Manuscript-popup-bookspecs-word-exampleimage-representsbleed': 'vertegenwoordigt afloop',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed': 'Wat is afloop?',
  'bookstep-Manuscript-popup-bookspecs-word-whatisbleed-p1': 'Om te voorkomen dat er bij snijden van je manuscript witranden ontstaan, is het noodzakelijk dat de achtergrondkleur of afbeelding iets groter is dan je daadwerkelijke boekformaat. Dit ‘extra’ stukje marge noemen we afloop. Als je geen kleuren of afbeeldingen hebt die tot de rand van de pagina lopen, dan kun je de afloop negeren zonder dat je problemen met witranden krijgt. Let op: het negeren kan wel voor kleine verschillen in de op opmaak van je manuscript zorgen, omdat we je document mogelijk automatisch iets opschalen om de afloop toe te voegen. Je ziet deze wijzigingen terug in de drukproef PDF die we tonen nadat je je manuscript geupload hebt.',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-odd': 'Linkerpagina (oneven)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-leftpage-even': 'Linkerpagina (even)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-odd': 'Rechterpagina (oneven)',
  'bookstep-Manuscript-popup-bookspecs-exampleimage-rightpage-even': 'Rechterpagina (even)',
  'bookstep-Manuscript-popup-services-needhelp': 'Hulp nodig?',
  'bookstep-Manuscript-popup-services-doyouneedsupport': 'Heb je ondersteuning nodig?',
  'bookstep-Manuscript-popup-services-weofferpaidservices': 'We bieden diverse diensten aan tegen betaling. Denk aan diensten op het gebied van het schrijven en beoordelen van je manuscript, zoals een leesscan en redactie. Maar we kunnen ook de opmaak van je manuscript verzorgen of het maken van een professionele epub.',
  'bookstep-Manuscript-popup-services-areyouinterested': 'Meer informatie nodig? Klik op de knop hieronder en we nemen binnen 2 werkdagen contact met je op!',
  'bookstep-Manuscript-popup-services-contactme': 'Ik wil graag meer weten',
  'bookstep-Manuscript-popup-services-requestsent': 'Aanvraag ontvangen',
  'bookstep-Manuscript-popup-services-wehavesentrequest': 'We hebben je aanvraag ontvangen',
  'bookstep-Manuscript-popup-services-wewillcontactyousoon': 'We hebben je vraag voor meer informatie over onze betaalde diensten ontvangen. We nemen binnen 2 werkdagen contact met je op om dit verder te bespreken!',

  'bookstep-Manuscript-guidepopup-wordtips': 'Tips voor Microsoft Word',
  'bookstep-Manuscript-guidepopup-wordtips-description': 'Onze tips om een goed boek te maken met Word',
  'bookstep-Manuscript-guidepopup-pdffontsincl': 'PDF maken',
  'bookstep-Manuscript-guidepopup-pdffontsincl-description': 'Een korte uitleg hoe je een goede PDF maakt',
  'bookstep-Manuscript-guidepopup-createepub': 'ePub maken',
  'bookstep-Manuscript-guidepopup-createepub-description': 'Een korte uitleg hoe je een goede ePub maakt',
  'bookstep-Manuscript-guidepopup-colorpages': 'Uitleg kleurenpagina’s',
  'bookstep-Manuscript-guidepopup-colorpages-description': 'Onze tips om goed met kleurenpagina’s om te gaan',

  // Bookstep: Writing
  'bookstep-Writing': 'Schrijven',
  'bookstep-Writing-intro-p1': 'Natuurlijk moet je zelf je manuscript schrijven en hoe leuk dit ook is, af en toe is het echt een uitdaging. Wij kunnen het schrijven niet van je overnemen, maar kunnen je er wel bij ondersteunen. Of je nu een fictie of non fictie boek schrijft, het is van belang dat je je eigen schrijfproces goed weet te managen. Het is zonde wanneer je vol goede moed begint en je halverwege vastloopt of het niet meer ziet zitten. Het opstellen en volgen van een stappenplan is daarbij cruciaal.',
  'bookstep-Writing-helpwithwriting-title': 'Ondersteuning bij het schrijven',
  'bookstep-Writing-createwritingplan-title': 'Stappenplan schrijven',
  'bookstep-Writing-writeblurb-title': 'Schrijf je flaptekst',
  'bookstep-Writing-writeblurb-p1': 'We adviseren je om te beginnen met het schrijven van je flaptekst. Dit dwingt je om na te denken over de essentie van je boek. Ook al is de flaptekst niet heel lang, het denkproces dat je – meerdere keren – doorloopt om tot een goede flaptekst te komen helpt je straks enorm bij het opstellen van een schrijfplan en bij het schrijven. Je zult merken dat je meerdere versies zal schrijven. Deel deze met anderen en vraag ze om feedback.',
  'bookstep-Writing-writeblurb-p2': 'Uiteindelijk heeft de flaptekst ook een grote commerciële waarde: naast de boekomslag is dit een van de bepalende factoren of mensen jouw boek kopen of niet.',
  'bookstep-Writing-writeblurb-howtowriteblurb': 'Hoe schrijf je een goede flaptekst?',
  'bookstep-Writing-writeblurb-howtowriteblurb-p1': 'Bestudeer eerst ter inspiratie flapteksten van andere boeken. Zo krijg je een goed beeld waar een flaptekst aan moet voldoen. Dan zijn de volgende elementen van cruciaal belang:',
  'bookstep-Writing-writeblurb-howtowriteblurb-list1': 'De eerste zin(nen): deze moeten raak zijn, de lezer intrigeren, nieuwsgierig maken. Een citaat uit het boek, een vraag of een ‘probleem benoemen’ kunnen goede openingen zijn.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list2': 'Samenvatting: dit is niet een samenvatting zoals we die vroeger op school leerden. Je neemt de lezer bij de hand, vertelt genoeg om de interesse op te wekken, maar verklapt niets.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list3': 'De belofte aan de lezer: wat mag de lezer verwachten? Bouw hier de spanning op bij fictieboeken of geeft een antwoord op een probleem bij non-fictie, zoals ‘Na dit boek weet u...’',
  'bookstep-Writing-writeblurb-howtowriteblurb-list4': 'Over de auteur: let op dat je hier niet een CV van maakt! Pik uit je historie wat relevant is voor de lezer, heb je zaken over het onderwerp van het boek meegemaakt? Vertel dit. Je kunt zeker je geboortejaar toevoegen.',
  'bookstep-Writing-writeblurb-howtowriteblurb-list5': 'Auteursfoto: zorg ervoor dat dit altijd een professionele foto is met een voldoende resolutie (300 dpi). Het hoeft geen zakelijk foto te te zijn, de foto kan passen bij het thema van het boek.',
  'bookstep-Writing-createplan-title': 'Maak een schrijfplan',
  'bookstep-Writing-createplan-p1': 'Schrijven is een creatief proces dat zich niet altijd laat sturen. Toch raden we je aan om een schrijfplan op te stellen. Deel deze op in 2 delen:',
  'bookstep-Writing-createplan-list1': 'Planning: Wanneer ga je schrijven? Hoe lang schrijf je per keer? Hoeveel wil je per keer schrijven? Hoe je dit invult is aan jou. De een begint ’s ochtends vroeg, de ander schrijft juist in de avond en nacht of wanneer er vrije tijd is. Maak een outline van je boek, deel het verhaal op in hoofdstukken en plan de hoofdstukken. Voel je niet verplicht om van het begin naar het eind te schrijven.',
  'bookstep-Writing-createplan-list2': 'Verhaallijn: werk je verhaallijn in hoofdlijnen uit, welke karakters voer je op en hoe ontwikkelen de karakters zich? Waar bouw je de spanning op, waar ben je beschrijvend. Een veel gebruikt trucje bij fictie is om ‘de reis van de held’ te beschrijven. Wanneer gebeurt er wat met hem. Ook voor non fictie boeken is de verhaallijn van groot belang. Hoe bouw je het verhaal op, hoe houd je de interesse van de lezer vast?',
  'bookstep-Writing-writefeedbackrewrite-title': 'Schrijf, schrijf, feedback, schrijf',
  'bookstep-Writing-writefeedbackrewrite-p1': 'Als het je gelukt is om structuur in je verhaallijn aan te brengen, dan is het een kwestie van ‘invullen’. Dat klinkt wat gemakkelijk voor het meest moeilijke onderdeel van je boek, het schrijven, maar dat is niet zo bedoeld. Het is namelijk niet zo dat je bij het begin moet beginnen en dan netjes hoofdstuk voor hoofdstuk moet gaan schrijven. Neem de vrijheid om die hoofdstukken te schrijven waarvoor je inspiratie hebt, waar je bijvoorbeeld al een duidelijk beeld bij hebt. Op deze wijze vul je de structuur spelenderwijs in.',
  'bookstep-Writing-writefeedbackrewrite-p2': 'Zodra je een paar hoofdstukken in concept gereed hebt, laat deze dan lezen door familie, vrienden of kennissen, waarvan je weet dat deze eerlijke en kritische feedback geven. Aan meelopers heb je in deze fase niets. Aandachtspunten om rekening mee te houden bij het schrijven:',
  'bookstep-Writing-writefeedbackrewrite-list1': 'Vanuit welk oogpunt vertel je het verhaal? Gebruik je de ik-vorm of de hij/zij-vorm.',
  'bookstep-Writing-writefeedbackrewrite-list2': 'Tegenwoordige tijd versus verleden tijd.',
  'bookstep-Writing-writefeedbackrewrite-list3': 'Consistentie in je verhaal.',
  'bookstep-Writing-writefeedbackrewrite-list4': 'Show, don’t tell: beschrijf niet alles letterlijk, laat de lezer het verhaal inkleuren.',
  'bookstep-Writing-writefeedbackrewrite-list5': 'Bouw de spanning op en houd deze vast.',
  'bookstep-Writing-feedbackediting-title': 'Feedback, redactie en correctie',
  'bookstep-Writing-feedbackediting-p1': 'Schrijven is een vorm van kunst. En kunstenaars zijn van nature onzeker over het geleverde werk. Voel je dus niet bezwaard indien je jezelf afvraagt of het wel goed is. Maar doe er wel wat mee! Laat in de beginfase hoofdstukken die je al af hebt lezen door mensen die opbouwend kritisch kunnen zijn. Daar wordt je verhaal beter van.',
  'bookstep-Writing-feedbackediting-p2': 'Zodra je hele manuscript gereed is, laat deze dan door meerdere mensen lezen. In ruil voor bijvoorbeeld een aardigheidje. Lezen van papier wordt nog door de meeste mensen gewaardeerd, dus laat een paar papieren exemplaren drukken en geef deze aan je reviewers. Ze zullen je dankbaar zijn en het leuk vinden je te helpen.',
  'bookstep-Writing-feedbackediting-p3': 'Zorg ervoor dat je manuscript volledig vrij is van taal- en stijlfouten. Lezers nemen een verhaal veel minder serieus wanneer bijvoorbeeld ergens een d of een t staat waar die echt niet hoort of wanneer een zin taalkundig niet kan. Neem je je boek echt serieus dan kun je ook een redactieslag of een quick scan overwegen. Een expert leest dan je een deel of je gehele manuscript en beoordeelt dit op de schrijfstijl, de consistentie, de plotwendingen, karakterontwikkelingen, etc.',
  'bookstep-Writing-nextstep': 'Volgende stap: opmaken',
  'bookstep-Writing-uploadnow': 'Direct uploaden',
  'bookstep-Writing-tips-title': 'Tips voor het schrijven',
  'bookstep-Writing-tips-titlesubtitle-label': 'Titel en subtitel',
  'bookstep-Writing-tips-titlesubtitle': 'Weet je de titel en subtitel al van je boek? Dan kun je dit overslaan, maar veel auteurs twijfelen nog lang over de titel. Schrijf alle (sub)titels die in je opkomen op, als een soort van brainstormsessie. Je zal merken dat er al heel snel een top drie of zelfs al een winnaar zich aandient. Deel de top drie met je netwerk, en vraag hen welke titel het meest aanspreekt. Probeer met een unieke en uitdagende titel te komen. Uiteindelijk bepalen kopers in 3 stappen of ze jouw boek kopen: de voorkant van je boekomslag en de titel, de achterflap, het inkijkexemplaar.',
  'bookstep-Writing-tips-blurb-label': 'Flaptekst',
  'bookstep-Writing-tips-blurb': 'Indien je boekomslag en titel potentiële kopers aanspreken, dan gaan ze je flaptekst lezen. Deze is verreweg het belangrijkst voor het koopproces van de klant. Voor fictie en non fictie boeken let de koper onwillekeurig op de volgende zaken: spreekt de tekst aan, wordt een probleem verholpen of wordt de koper uitgedaagd, wordt zijn nieuwsgierigheid gewekt? Na het lezen van de flaptekst moet de koper meer willen lezen. Als je dat lukt dan is de kans dat je boek wordt gekocht groot. Laat dus mensen je flaptekst lezen en vraag wat ze ervan vinden en of ze op basis hiervan het boek zouden kopen. Doen!',
  'bookstep-Writing-tips-howdoyoufeelabouit-label': 'Wat vind jij ervan?',
  'bookstep-Writing-tips-howdoyoufeelabouit': 'Laat anderen je werk al in een vroeg stadium lezen. Je manuscript hoeft helemaal niet gereed te zijn om belangrijke feedback te krijgen; laat een hoofdstuk of een bladzijde door iemand anders lezen. Vraag om een eerlijke terugkoppeling. Mensen vinden het leuk om te doen en om jou verder te helpen. Je kunt ook een hoofdstuk op social media posten en dus delen met je netwerk. Zo sla je twee vliegen in één klap: je krijgt feedback en je geeft alvast bekendheid aan je boek.',
  'bookstep-Writing-tips-marketing-label': 'Marketing',
  'bookstep-Writing-tips-marketing': 'Marketing? Ik ben nog aan het schrijven! Juist ja. Dit is hét moment om alvast over de marketing van je boek na te gaan denken. In deze fase kun je al mensen interesseren voor je boek: creëer alvast wat reuring over je boek. Vertel op je social media dat je bezig bent met je boek, hoe ver je bent, waar je tegenaan loopt. Je zult zien dat je positieve feedback krijgt. Deel een hoofdstuk met je volgers en vraag ze om feedback. Deel je boekomslag wanneer deze gereed is. Maak alvast een marketingplan. Zo bouw je een netwerk aan ambassadeurs op, zij gaan jou straks helpen om jouw boek bekendheid te geven!',
  'bookstep-Writing-tips-support-label': 'Ondersteuning',
  'bookstep-Writing-tips-support': 'Heb je hulp nodig omdat je even vast zit of je wenst professionele terugkoppeling over je manuscript? We bieden je diensten aan die betaalbaar zijn, door ervaren auteurs/redacteuren worden gegeven en waar jouw manuscript een stuk beter van wordt! Zo bieden we je een quickscan of volledig leesrapport, maar ook workshops, taalcorrectie en redactie aan. Neem je je boek echt serieus? Maak dan zeker gebruik van de mensen om je heen of van onze diensten.',
  'bookstep-Writing-tips-dontgiveup-label': 'Geef de moed niet op',
  'bookstep-Writing-tips-dontgiveup': 'Vrijwel iedere auteur heeft er wel eens mee te maken: het lukt niet meer, er komt geen fatsoenlijk woord meer uit je - figuurlijke - pen. Geef nooit op! Laat het werk desnoods even rusten, ga wandelen voor inspiratie, praat met mensen, zoek afleiding. De energie komt vanzelf wel weer. Wens je een meer uitgebreide leidraad voor tijdens het schrijven? Wij hebben met een ervaren auteur het boek ‘Zo schrijf je een bestseller’ gePubliceerd. Dit boek ondersteunt je tijdens iedere fase van het schrijven!',

  // Bookstep: Formatting
  'bookstep-Formatting': 'Opmaak',
  'bookstep-Formatting-intro-p1': 'De tekst van je manuscript is natuurlijk het belangrijkst, maar zonder een goede opmaak verliest je boek enorm aan waarde voor de lezer. Een mooi vormgegeven manuscript leest lekker waardoor de lezer zich helemaal kan inleven in jouw verhaal, zonder afgeleid te worden door storende opmaakfouten. Je kunt de opmaak zelf doen maar je kunt deze ook uitbesteden aan een professional, bijvoorbeeld via de opmaakdienst die we aanbieden. Wanneer je het zelf doet, zorg er voor dat dat je onze adviezen volgt zodat je een mooi opgemaakt manuscript krijgt.',
  'bookstep-Formatting-helpwithformatting-title': 'Ondersteuning bij het opmaken',
  'bookstep-Formatting-guide-title': 'Stappenplan opmaak',
  'bookstep-Formatting-guide-print-template-title': 'Sjabloon',
  'bookstep-Formatting-guide-print-template-p1': 'Wanneer je zelf jouw manuscript opmaakt dan adviseren we je om al tijdens het schrijven te werken in een document met de juiste instellingen. Werk je met Word, maak dan gebruik van onze sjablonen. Deze zijn al goed ingesteld en houden bijvoorbeeld rekening met de titel- en colofonpagina’s, de paginanummering en met de afloop. Indien je jouw manuscript al hebt geschreven, dan kun je deze kopiëren naar het sjabloon, maar houd er wel rekening mee dat je niet het gehele document in één keer kopieert! Dan worden namelijk de instellingen van dat document gekopieerd (in de regel A4) en veranderen dus de instellingen van je sjabloon.',
  'bookstep-Formatting-guide-print-template-p2': 'Werk je met een professioneel opmaakprogramma zoals InDesign, dan kun je eenvoudig de afmetingen van het document aanpassen naar het door jou gekozen boekformaat. Houd wel rekening met een afloop van 3mm rondom iedere pagina, behalve aan de binnenkant van de pagina (binnenkant = de rechterkant van een even pagina, de linkerkant van een oneven pagina). De exacte specificaties vind je door op de knop ‘Boekspecificaties’ hierboven te klikken.',
  'bookstep-Formatting-guide-print-template-p3': 'Let op: een oneven pagina is altijd een rechterpagina, een even pagina is een linkerpagina.',
  'bookstep-Formatting-guide-print-fonts-title': 'Lettertypes, grootte van de letter en regelafstand',
  'bookstep-Formatting-guide-print-fonts-p1': 'We gaan je niet vertellen welk lettertype je moet gebruiken. Dat is heel persoonlijk en kan ook per type boek verschillen. Je maakt eerst een keuze tussen schreef en schreefloos. Schreef lettertypes zijn letters waarbij er streepjes aan de uiteinden van de letters staan. Deze streepjes helpen de ogen van de lezer om de regel te volgen. Verreweg de meeste boeken hebben een schreeflettertype. Populaire lettertypes met schreef zijn Cambria, Garamond, Georgia en Times New Roman.',
  'bookstep-Formatting-guide-print-fonts-p2': 'Schreefloze letters zijn strakker en moderner en worden veelal bij websites en voor de boekomslag gebruikt. Veelgebruikte schreefloze lettertypes zijn  Arial, Calibri, Helvetica en Verdana.',
  'bookstep-Formatting-guide-print-fonts-p3': 'Daarnaast moet je ook nog bepalen welke lettergrootte en regelafstand je kiest. En weer geldt: er is niet één goed antwoord, het is zeer persoonlijk. De corpsgroottes 10, 11 en 12 worden het meest gebruikt. Dit hangt ook van het lettertype zelf af. Heeft jouw boek een iets oudere doelgroep? Gebruik dan niet een te kleine letter en een iets grotere regelafstand (een regelafstand tussen de 1,1 en 1,3 is gebruikelijk). Het kan helpen om een aantal pagina’s van je manuscript zelf te printen, dan zie je vaak direct wat je wel en niet goed vindt.',
  'bookstep-Formatting-guide-print-moreoptions-title': 'Nog meer opmaak keuzes',
  'bookstep-Formatting-guide-print-moreoptions-p1': 'We zijn er nog niet, er zijn nog meer keuzes te maken. We zetten de belangrijkste even op een rijtje:',
  'bookstep-Formatting-guide-print-moreoptions-list1': 'Zetspiegel: de witruimte rondom de tekst. Zorg er in ieder geval voor dat er niet te weinig ruimte is, ga uit van ruim 2 cm en neem iets meer ruimte aan de binnenkant van de pagina (binnenkant = de rechterkant van een even pagina, de linkerkant van een oneven pagina). Een deel van de binnenkant van een pagina verdwijnt immers in de rug van het boek. In ons sjabloon houden we rekening met de gangbare zetspiegel. Mocht je ons sjabloon niet gebruiken, dan kun je een zetspiegel aanhouden die vergelijkbaar is de volgende maten:',
  'bookstep-Formatting-guide-print-moreoptions-list1-topmargin': 'Marge bovenkant: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-bottommargin': 'Marge onderkant: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-innermargin': 'Marge binnenkant: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list1-outermargin': 'Marge buitenkant: {size}',
  'bookstep-Formatting-guide-print-moreoptions-list2': 'Nieuwe hoofdstukken: begin een nieuw hoofdstuk op een nieuwe pagina. Dat geeft rust. Je kunt de hoofdstukken gewoon nummeren of je kunt ze ook een naam geven. Let op: maak in Word gebruik van de stijl ‘Kop 1’ voor je hoofdstuktitel/nummer. Dan is de opmaak hiervan bij ieder hoofdstuk gelijk.',
  'bookstep-Formatting-guide-print-moreoptions-list3': 'Paragrafen: je kunt ervoor kiezen om een regel witruimte tussen de paragrafen te zetten. Ook dit geeft rust tijdens het lezen. Ook kun je ervoor kiezen om het eerste woord van een paragraaf te laten inspringen. Zorg ervoor dat de paragrafen niet onnodig lang zijn, dat bevordert de leesbaarheid niet.',
  'bookstep-Formatting-guide-print-moreoptions-list4': 'Tekst en beeld: wanneer je om en om een afbeelding op een pagina hebt staan en vervolgens een pagina tekst, bijvoorbeeld bij een dichtbundel of een receptenboek, plaats dan de afbeelding op de even pagina (linkerpagina) en de tekst op de rechterpagina.',
  'bookstep-Formatting-guide-print-uploadscript-title': 'Uploaden van je manuscript',
  'bookstep-Formatting-guide-print-uploadscript-p1': 'Je kunt met ieder tekstverwerkingsprogramma werken, maar voor het uploaden accepteren we alleen een Word bestand (doc of docx) of een PDF. Werk je dus met een andere tekstverwerker dan Word (bijvoorbeeld met Pages), dan moet je dus eerst een PDF van je document maken. Ook wanneer je met Word werkt adviseren we om eerst een PDF te maken en deze te uploaden. Zo voorkom je dat er eventuele verschillen optreden bij conversie. Let op: zorg dat iedere pagina een eigen pagina in je PDF bestand is (gebruik geen zogeheten ‘spreads’), anders gaat het converteren helemaal mis.',
  'bookstep-Formatting-guide-print-uploadscript-p2': 'Na het uploaden converteren wij je document in een printklare PDF die je vanzelf op het scherm ziet verschijnen. Controleer deze goed. Ga alle pagina’s langs en let goed op of de opmaak er perfect uitziet.',
  'bookstep-Formatting-guide-print-uploadscript-p3': 'Ben je nog niet tevreden? Geen probleem, je kunt zo vaak als je wenst in de stap Manuscript een nieuwe versie uploaden. De oude versie wordt dan overschreven en die bewaren we niet.',
  'bookstep-Formatting-guide-epub-howepubswork-title': 'Korte uitleg: hoe werkt een ePub',
  'bookstep-Formatting-guide-epub-howepubswork-p1': 'Voordat je aan de slag gaat met de opmaak van je e-book, is het belangrijk dat we eerst uitleggen hoe een e-book precies werkt. Een e-book werkt namelijk anders dan een ‘normaal’ boek of document, waardoor datgene dat je in je tekstverwerker ziet, wellicht niet overeenkomt met wat de lezer zal zien op zijn e-reader.',
  'bookstep-Formatting-guide-epub-howepubswork-p2': 'Allereerst: een e-book dat je via ons publiceert, wordt altijd een ‘ePub’ bestand. Dit is een wereldwijd standaard bestandsformaat voor e-books wat op vrijwel iedere e-reader gelezen kan worden.Een van de belangrijkste eigenschappen van ePub bestanden is dat ePubs ‘reflowable’ zijn. Dat betekent dat de tekst zich aanpast naar de grootte van het scherm. Bij een kleiner scherm worden zinnen automatisch korter gemaakt en krijgt je boek automatisch meer pagina’s dan bij een groter scherm. Dat betekent dat de opmaak van de het e-book afhangt van de reader waarop het boek gelezen wordt.',
  'bookstep-Formatting-guide-epub-howepubswork-p3': 'Het komt dus voor dat tekst in Word (of op jouw e-reader) op een bepaalde pagina staat, maar dat die tekst op (andere) e-readers over meerdere pagina’s verdeeld wordt. Probeer dus niet de focus te leggen op de exacte positie van een alinea op een pagina, of wanneer één of twee regels van een pagina vallen, maar richt je op dat de tekst logisch loopt en dat de afstand tussen alinea’s juist is. Je krijgt dan voor de meeste lezers het beste resultaat.',
  'bookstep-Formatting-guide-epub-templatefonts-title': 'Sjabloon en lettertypes',
  'bookstep-Formatting-guide-epub-templatefonts-p1': 'Voor de opmaak van je e-book in Microsoft Word, raden we aan dat je gebruikmaakt van ons Word sjabloon. In dit sjabloon hebben we ervoor gezorgd dat de belangrijkste instellingen voor een goed e-book alvast gedaan zijn. Dat scheelt je een hoop werk. Ons sjabloon kun je downloaden door hierboven op de knop ‘Word sjabloon’ te klikken.',
  'bookstep-Formatting-guide-epub-templatefonts-p2': 'Wat betreft de keuze voor lettertypes: steek hier niet al te veel tijd in. Vrijwel alle e-readers laten lezers namelijk zelf kiezen in welk lettertype de lezer het liefst leest. De keuze die jij meegeeft over het lettertype wordt op dat moment dus genegeerd ten faveure van het lettertype dat de lezer wil gebruiken. Ons advies: kies een lettertype wat je er goed uit vindt zien en werk daarmee verder. Goede opties zijn bijvoorbeeld Georgia of Times New Roman wanneer je een lettertype met schreef wilt gebruiken. Wil je liever schreefloos, dan raden we Arial, Helvetica of Verdana aan.',
  'bookstep-Formatting-guide-epub-chapterstoc-title': 'Hoofdstukken en inhoudsopgave',
  'bookstep-Formatting-guide-epub-chapterstoc-p1': 'Als je je e-book opmaakt met Microsoft Word, is het belangrijk dat je correct gebruik maakt van de opmaakstijlen ‘Kop 1’, ‘Kop 2’, ‘Titel’, etc. Deze stijlen gebruiken wij bij conversie namelijk om je e-book correct in hoofdstukken in te delen en de inhoudsopgave te genereren. Een korte opsomming van de stijlen waar je in ieder geval bekend mee moet zijn:',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-normal': 'Normaal/Standaard: deze stijl gebruik je voor de ‘normale’ alinea’s (bodytekst) in je manuscript',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-title': 'Titel: Titel gebruik je voor de titel van je boek op de voorpagina’s.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading1': 'Kop 1: Kop 1 gebruik je voor hoofdstuktitels. In de inhoudsopgave is dit het hoogste niveau. In ons sjabloon hebben we Kop 1 zo ingesteld dat deze kop automatisch op een nieuwe pagina begint.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading2': 'Kop 2: Kop 2 gebruik je voor subtitels, wanneer je je hoofdstuk in meerdere subkoppen wilt indelen. De subtitels met ‘kop 2’ zijn in de de inhoudsopgave ondergeschikt aan een ‘Kop 1’.',
  'bookstep-Formatting-guide-epub-chapterstoc-l1-heading3': 'Kop 3: Deze optie kun je eventueel gebruiken als je nog een niveau dieper wilt gaan dan ‘Kop 2’ in je boek. In praktijk kom je dit in romans vrijwel niet tegen. In managementboeken of educatieve boeken daarentegen wel.',
  'bookstep-Formatting-guide-epub-chapterstoc-p2': 'Wanneer je zelf het ePub bestand opmaakt, werkt het indelen in hoofdstukken iets anders. Het is in dat geval belangrijk dat je de volgende tips volgt:',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-separatefiles': 'Zorg ervoor dat ieder hoofdstuk in een apart ‘HTML’ bestand in je ePub bestand is. Dit bestand moet vervolgens op de juiste plek opgenomen zijn in het OPF bestand waar de volgorde van de html bestanden vastgelegd is.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh1tags': 'Zorg dat een hoofdstuk altijd begint met een ‘h1’ HTML tag in je HTML bestand. Deze h1 tag bevat de hoofdstuktitel (of het hoofdstuknummer).',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-useh2h3tags': 'Wanneer je gebruik wilt maken van subkoppen, dan kun je gebruikmaken van de h2, h3, h4, etc. tags. Dit werkt volgens dezelfde principes als ‘Kop 1’, ‘Kop 2’, ‘Kop 3’ in Word.',
  'bookstep-Formatting-guide-epub-chapterstoc-l2-generatetoc': 'Zorg ervoor dat je een een inhoudsopgave genereert voor je ePub bestand. De meeste ePub programma’s kunnen dat automatisch voor je doen en doen dit aan de hand van een ‘NCX’ bestand dat in je ePub wordt opgenomen.',
  'bookstep-Formatting-guide-epub-moreformattips-title': 'Extra opmaaktips',
  'bookstep-Formatting-guide-epub-moreformattips-p1': 'Naast de eerdere tips zijn er nog enkele punten die belangrijk zijn om in de gaten te houden bij de opmaak van je e-book:',
  'bookstep-Formatting-guide-epub-moreformattips-list1': 'Paginanummers: zorg dat je Word of ePub bestand geen paginanummers bevat. Paginanummers kloppen namelijk nooit en zullen op vreemde plekken in je e-book zichtbaar worden.',
  'bookstep-Formatting-guide-epub-moreformattips-list2': 'Genummerde opsommingen: het komt wellicht voor dat je een genummerde opsomming in je boek wil opnemen. Dit is op zich mogelijk, maar het is belangrijk dat deze opsomming niet onderbroken wordt door een alinea en vervolgens weer door gaat tellen. Dat is niet toegestaan in ePubs: een genummerde opsomming moet altijd aansluitend zijn.',
  'bookstep-Formatting-guide-epub-moreformattips-list3': 'Afbeeldingen: je kunt uiteraard afbeeldingen opnemen in je ePub bestand. Zorg ervoor dat deze afbeeldingen in JPG of PNG formaat worden opgenomen en zorg dat de resolutie niet te groot wordt (bijv. maximaal 1000x1000 pixels), anders wordt je ePub bestand ook erg groot, wat vervelend is met uploaden/downloaden. Let er daarnaast op dat afbeeldingen altijd onderdeel zijn van een alinea en niet ‘zwevend’ in je document staan. Je moet dus geen tekst naast een afbeeldingen positioneren: dat is niet toegestaan en leidt niet tot fraaie resultaten op e-readers.',
  'bookstep-Formatting-guide-epub-moreformattips-list4': 'Omslag: wanneer je een Word bestand uploadt, moet je je omslag NIET aan je Word document toevoegen. Het omslag upload je straks apart in de stap ‘Omslag’. Als je zelf een ePub bestand maakt, mag je het omslag eventueel alvast aan je ePub bestand toevoegen. Wanneer de resolutie hoog genoeg is, zullen we die uit je ePub exporteren en vast opnemen in de stap ‘Omslag’.',
  'bookstep-Formatting-guide-epub-uploadscript-title': 'Uploaden van je manuscript',
  'bookstep-Formatting-guide-epub-uploadscript-p1': 'Je kunt met ieder programma werken, maar voor het uploaden accepteren we alleen een Word bestand (doc of docx) of een ePub bestand. Wanneer je dus met een andere tekstverwerker dan Microsoft Word werkt, moet je dus eerst je document omzetten naar een Word bestand of een ePub.',
  'bookstep-Formatting-guide-epub-uploadscript-p2': 'Een waarschuwing: onze ervaring leert dat het omzetten van bijv. LibreOffice, OpenOffice of Apple Pages naar Microsoft Word gaat niet altijd feilloos en kan tot problemen/foutmeldingen leiden bij het uploaden.',
  'bookstep-Formatting-guide-epub-uploadscript-p3': 'Na het uploaden van je bestand controleren en converteren je bestand naar een correct ePub. Dit bestand kun je vervolgens controleren met een e-reader applicatie. Het is belangrijk dat je dit nauwkeurig doet: het ePub bestand dat we aan jou laten zien is ook het bestand wat lezers straks zullen ontvangen.',
  'bookstep-Formatting-guide-epub-uploadscript-p4': 'Mocht je er niet uitkomen, dan kun je uiteraard gebruik maken van onze opmaakdienst voor e-books. Dit is wel een betaalde dienst.',
  'bookstep-Formatting-guide-pdfebook-template-title': 'Sjabloon',
  'bookstep-Formatting-guide-pdfebook-template-p1': 'Wanneer je zelf jouw manuscript opmaakt dan adviseren we je om al tijdens het schrijven te werken in een document met de juiste instellingen. Werk je met Word, maak dan gebruik van onze sjablonen. Indien je jouw manuscript al hebt geschreven, dan kun je deze kopiëren naar het sjabloon, maar houd er wel rekening mee dat je niet het gehele document in één keer kopieert! Dan kunnen door het kopiëren de instellingen van het sjabloon veranderen.',
  'bookstep-Formatting-guide-pdfebook-template-p2': 'Mocht je al tevreden zijn met de opmaak van je huidige Word bestand, dan kun je ook overwegen direct een PDF van je Word bestand te maken en deze PDF te uploaden. Dit leidt bij een PDF e-book normaliter niet tot problemen.',
  'bookstep-Formatting-guide-pdfebook-template-p3': 'Werk je met een professioneel opmaakprogramma zoals InDesign, dan kun je eenvoudig de afmetingen van het document aanpassen naar het door jou gekozen boekformaat.',
  'bookstep-Formatting-guide-pdfebook-fonts-title': 'Lettertypes, grootte van de letter en regelafstand',
  'bookstep-Formatting-guide-pdfebook-fonts-p1': 'We gaan je niet vertellen welk lettertype je moet gebruiken. Dat is heel persoonlijk en kan ook per type boek verschillen. Je maakt eerst een keuze tussen schreef en schreefloos. Schreef lettertypes zijn letters waarbij er streepjes aan de uiteinden van de letters staan. Deze streepjes helpen de ogen van de lezer om de regel te volgen. Verreweg de meeste boeken hebben een schreeflettertype. Cambria, Garamond, Georgia en Times New Roman zijn populaire schreeflettertypes.',
  'bookstep-Formatting-guide-pdfebook-fonts-p2': 'Schreefloze letters zijn strakker en moderner en worden veelal bij websites en voor de boekomslag gebruikt. Arial, Calibri, Helvetica en Verdana behoren tot de meest gebruikte schreefloze lettertypes.',
  'bookstep-Formatting-guide-pdfebook-fonts-p3': 'Daarnaast moet je ook nog bepalen welke lettergrootte en regelafstand je kiest. En weer geldt: er is niet één goed antwoord, het is zeer persoonlijk. De corpsgroottes 10, 11 en 12 worden het meest gebruikt. Dit hangt ook van het lettertype zelf af. Heeft jouw boek een iets oudere doelgroep? Gebruik dan niet een te kleine letter en een iets grotere regelafstand (een regelafstand tussen de 1,1 en 1,3 is gebruikelijk). Het kan helpen om een aantal pagina’s van je manuscript zelf te printen, dan zie je vaak direct wat je wel en niet goed vindt.',
  'bookstep-Formatting-guide-pdfebook-fonts-p4': 'Heeft jouw boek een iets oudere doelgroep? Gebruik dan niet een te kleine letter en een iets grotere regelafstand.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-title': 'Nog meer opmaak keuzes',
  'bookstep-Formatting-guide-pdfebook-moreoptions-p1': 'We zijn er nog niet, er zijn nog meer keuzes te maken. We zetten de belangrijkste even op een rijtje:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1': 'Zetspiegel: de witruimte rondom de tekst. Zorg er in ieder geval voor dat er niet te weinig ruimte is, ga uit van ruim 2 cm Mocht je ons sjabloon niet gebruiken, dan kun je een zetspiegel aanhouden die vergelijkbaar is de volgende maten:',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-topmargin': 'Marge bovenkant: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-bottommargin': 'Marge onderkant: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-leftmargin': 'Marge links: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list1-rightmargin': 'Marge rechts: {size}',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list2': 'Nieuwe hoofdstukken: begin een nieuw hoofdstuk op een nieuwe pagina. Dat geeft rust. Je kunt de hoofdstukken gewoon nummeren of je kunt ze ook een naam geven. Let op: maak in Word gebruik van de stijl ‘Kop 1’ voor je hoofdstuktitel/nummer. Dan is de opmaak hiervan bij ieder hoofdstuk gelijk.',
  'bookstep-Formatting-guide-pdfebook-moreoptions-list3': 'Paragrafen: je kunt ervoor kiezen om een regel witruimte tussen de paragrafen te zetten. Ook dit geeft rust tijdens het lezen. Ook kun je ervoor kiezen om het eerste woord van een paragraaf te laten inspringen. Zorg ervoor dat de paragrafen niet onnodig lang zijn, dat bevordert de leesbaarheid niet.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-title': 'Uploaden van je manuscript',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p1': 'Je kunt met ieder tekstverwerkingsprogramma werken, maar voor het uploaden accepteren we voor PDF e-books uitsluitend PDF bestanden. Ongeacht welke tekstverwerker je gebruikt, moet je dus eerst een PDF van je document maken. Dit doe je in de meeste programma’s door de optie ‘Opslaan als’ te kiezen en in het daaropvolgende scherm het bestandsformaat op ‘PDF’ te zetten. In sommige tekstverwerkers vind je de optie echter onder ‘Exporteren’ i.p.v. ‘Opslaan als’.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p2': 'Na het uploaden controleren en converteren wij je document naar een publicatieklaar PDF bestand die je vanzelf op het scherm ziet verschijnen. Controleer deze goed. Ga alle pagina’s langs en let goed op of de opmaak er perfect uitziet.',
  'bookstep-Formatting-guide-pdfebook-uploadscript-p3': 'Ben je nog niet tevreden? Geen probleem, je kunt zo vaak als je wenst in de stap Manuscript een nieuwe versie uploaden. De oude versie wordt dan overschreven en die bewaren we niet.',
  'bookstep-Formatting-nextstep': 'Volgende stap: uploaden',
  'bookstep-Formatting-tips-title': 'Tips voor het opmaken',
  'bookstep-Formatting-tips-print-newpage-label' : 'Nieuwe pagina',
  'bookstep-Formatting-tips-print-newpage': 'Wanneer een hoofdstuk halverwege een pagina eindigt en je wil het nieuwe hoofdstuk op een volgende pagina beginnen, gebruik dan nooit de Enter toets om naar de nieuwe pagina te gaan. Bij de conversie naar een PDF ontstaat er door de vele keren dat je op de Enter-toets hebt gedrukt heel veel extra witruimte, waardoor het nieuwe hoofdstuk ergens halverwege de volgende pagina begint. Gebruik in Word altijd de combinatie van de CTRL en Enter toetsen (op de Mac: CMD + Enter), dan gaat het goed. Dit geldt ook wanneer je paginavullende foto’s in je manuscript plaatst: niet de Enter-toets gebruiken om ruimte te creëren.',
  'bookstep-Formatting-tips-print-colophon-label': 'Colofon',
  'bookstep-Formatting-tips-print-colophon': 'Het is gebruikelijk dat je een boek begint met titelpagina’s en het colofon. De eerste pagina’s van een boek zijn in de regel als volgt:',
  'bookstep-Formatting-tips-print-colophon-page1': 'Eerste pagina: alleen de titel van het boek in een groot lettertype',
  'bookstep-Formatting-tips-print-colophon-page2': 'Tweede pagina: leeg',
  'bookstep-Formatting-tips-print-colophon-page3': 'Derde pagina: titel van het boek en daaronder de auteursnaam',
  'bookstep-Formatting-tips-print-colophon-page4': 'Vierde pagina: dit is de colofonpagina, je neemt hierin op: de auteursnaam, het ISBN (indien van toepassing), wie verantwoordelijk is voor het omslagontwerp, je geeft aan dat jouw werk auteursrechtelijk beschermd is (copyright), het jaar van uitgave, uitgegeven via {publicbrand}',
  'bookstep-Formatting-tips-print-colophon-rest': 'Deze pagina’s zijn niet genummerd. De paginanummers beginnen dus hierna, met paginanummer 5, ons sjabloon is reeds zo ingesteld.',
  'bookstep-Formatting-tips-print-images-label': 'Afbeeldingen',
  'bookstep-Formatting-tips-print-images': 'Je kunt uiteraard gebruik maken van afbeeldingen in je manuscript. Een afbeelding met een resolutie van 250 dpi is voldoende om straks mooi te worden gedrukt. Je plaatst de afbeeldingen zelf in je manuscript op de plaats waar je ze wenst te hebben. Zorg ervoor dat er voldoende ruimte is tussen de afbeelding en de tekst. Je kunt uiteraard ook afbeeldingen tot aan de paginarand plaatsen. Indien je een foto over 2 pagina’s wil plaatsen: knip de foto in 2 delen en plaats de linkerkant op een even pagina en de rechterkant op de oneven pagina met het hogere paginanummer.',
  'bookstep-Formatting-tips-print-wrongformat-label': 'Verkeerd formaat?',
  'bookstep-Formatting-tips-print-wrongformat': 'Wanneer je een document in een ander formaat uploadt dan het formaat boek dat je in de eerste stap hebt gekozen, dan schalen wij je document automatisch naar het juiste formaat. Dit kan tot een goed resultaat leiden, zeker wanneer de formaatverschillen gering zijn, maar het kan ook hele lelijke gevolgen hebben. Controleer dus altijd de PDF, die je na het uploaden ziet, goed! Het is zonde wanneer je straks je proefexemplaar hebt ontvangen en het resultaat tegenvalt.',
  'bookstep-Formatting-tips-print-colorpages-label': 'Kleur en zwart-wit',
  'bookstep-Formatting-tips-print-colorpages': 'Als je boek zowel kleur als zwart-wit bevat, is het belangrijk dat je dit op de juiste manier doet, anders loop je het risico dat zwart-wit pagina’s ook als kleur gezien worden. {link}',
  'bookstep-Formatting-tips-print-colorpages-link': 'Klik hier om alle in’s en out’s over het omgaan met kleurenpagina’s te lezen.',
  'bookstep-Formatting-tips-print-proofcopy-label': 'Proefexemplaar',
  'bookstep-Formatting-tips-print-proofcopy': 'Je bent in deze fase nog niet zover om al een proefexemplaar te bestellen, maar we zeggen het toch alvast: koop altijd voordat je je boek publiceert (of voordat je grotere aantallen bestelt) een of meerdere proefexemplaren. De ervaring leert dat - hoe goed je ook je manuscript hebt gecontroleerd of anderen hebt laten meelezen - er altijd nog kleine dingetjes niet goed zijn. Een taalfoutje hier, een cosmetisch dingetje daar, op een gegeven moment zie je dat niet meer op je scherm. Tip: koop een paar exemplaren en laat mensen uit je netwerk meelezen. Je zult versteld staan wat ze nog vinden.',
  'bookstep-Formatting-tips-epub-chapterheadings-label': 'Hoofdstukken markeren',
  'bookstep-Formatting-tips-epub-chapterheadings': 'Het is voor een goed e-book heel belangrijk dat je hoofdstukken op de juiste manier markeert in je bestand. Dit doe je in Microsoft Word door gebruik te maken van de opmaakstijlen zoals ‘Kop 1’. Wanneer je dit niet doet, zal je e-book een lange tekst zonder hoofdstukken worden en dat is uiteraard niet de bedoeling!',
  'bookstep-Formatting-tips-epub-colophon-label': 'Colofon',
  'bookstep-Formatting-tips-epub-colophon': 'Het is gebruikelijk dat je een boek begint met titelpagina’s en het colofon. De eerste pagina’s van een boek zijn in de regel als volgt:',
  'bookstep-Formatting-tips-epub-colophon-page1': 'Eerste pagina: titel van het boek en daaronder de auteursnaam',
  'bookstep-Formatting-tips-epub-colophon-page2': 'Tweede pagina, dit is de colofonpagina, je neemt hierin op: de auteursnaam, het ISBN (indien van toepassing), wie verantwoordelijk is voor het omslagontwerp, je geeft aan dat jouw werk auteursrechtelijk beschermd is (copyright), het jaar van uitgave, uitgegeven via {publicbrand}',
  'bookstep-Formatting-tips-epub-colophon-rest': 'Indien je Word gebruikt: Zorg dat je voor deze pagina een pagina-einde invoegt, zodat het colofon wel op een aparte pagina komt.',
  'bookstep-Formatting-tips-epub-pagenumbers-label': 'Geen paginanummers',
  'bookstep-Formatting-tips-epub-pagenumbers': 'Let er goed op dat je bestand geen paginanummers of andere kop- of voetteksten bevat. Deze tekst zal op vreemde plaatsen in je e-book belanden, in plaats van dat het netjes bovenaan/onderaan de juiste pagina komt te staan.',
  'bookstep-Formatting-tips-epub-images-label': 'Afbeeldingen',
  'bookstep-Formatting-tips-epub-images': 'Je kunt je e-book uiteraard afbeeldingen opnemen. Hierbij moet je er goed op letten dat de afbeeldingen netjes ‘in de tekstregel’ staan uitgelijnd. Je kan afbeeldingen niet ‘zwevend’ opnemen met tekst die ernaast staat: een afbeelding moet volledig op de regel staan met de tekst erboven en eronder.',
  'bookstep-Formatting-tips-pdfebook-newpage-label': 'Nieuwe pagina',
  'bookstep-Formatting-tips-pdfebook-newpage': 'Wanneer een hoofdstuk halverwege een pagina eindigt en je wil het nieuwe hoofdstuk op een volgende pagina beginnen, gebruik dan nooit de Enter toets om naar de nieuwe pagina te gaan. Bij de conversie naar een PDF ontstaat er door de vele keren dat je op de Enter-toets hebt gedrukt soms onnodige extra witruimte, waardoor het nieuwe hoofdstuk ergens halverwege de volgende pagina begint. Gebruik in Word altijd de combinatie van de CTRL en Enter toetsen (op de Mac: CMD + Enter), dan gaat het goed. Dit geldt ook wanneer je paginavullende foto’s in je manuscript plaatst: niet de Enter-toets gebruiken om ruimte te creëren.',
  'bookstep-Formatting-tips-pdfebook-colophon-label': 'Colofon',
  'bookstep-Formatting-tips-pdfebook-colophon': 'Het is gebruikelijk dat je een boek begint met titelpagina’s en het colofon. De eerste pagina’s van een boek zijn in de regel als volgt:',
  'bookstep-Formatting-tips-pdfebook-colophon-page1': 'Eerste pagina: alleen de titel van het boek in een groot lettertype',
  'bookstep-Formatting-tips-pdfebook-colophon-page2': 'Tweede pagina: leeg',
  'bookstep-Formatting-tips-pdfebook-colophon-page3': 'Derde pagina: titel van het boek en daaronder de auteursnaam',
  'bookstep-Formatting-tips-pdfebook-colophon-page4': 'Vierde pagina: dit is de colofonpagina, je neemt hierin op: de auteursnaam, het ISBN (indien van toepassing), wie verantwoordelijk is voor het omslagontwerp, je geeft aan dat jouw werk auteursrechtelijk beschermd is (copyright), het jaar van uitgave, uitgegeven via {publicbrand}',
  'bookstep-Formatting-tips-pdfebook-colophon-rest': 'Deze pagina’s zijn niet genummerd. De paginanummers beginnen dus hierna, met paginanummer 5, ons sjabloon is reeds zo ingesteld.',
  'bookstep-Formatting-tips-pdfebook-images-label': 'Afbeeldingen',
  'bookstep-Formatting-tips-pdfebook-images': 'Je kunt uiteraard gebruik maken van afbeeldingen in je manuscript. Een afbeelding met een resolutie van 96 dpi is voldoende om straks mooi als e-book weergegeven te worden. Je plaatst de afbeeldingen zelf in je manuscript op de plaats waar je ze wenst te hebben. Zorg ervoor dat er voldoende ruimte is tussen de afbeelding en de tekst. Je kunt uiteraard ook afbeeldingen tot aan de paginarand plaatsen.',
  'bookstep-Formatting-tips-pdfebook-dimensions-label': 'Formaatkeuze',
  'bookstep-Formatting-tips-pdfebook-dimensions': 'Je kan ieder pagina-formaat PDF uploaden voor je PDF e-book. We hebben hiervoor geen minimum of maximum afmetingen. Het is echter wel verstandig om een redelijk gangbaar formaat te kiezen, omdat een extreem groot of zeer klein formaat onprettig kan lezen bij een lezer. Een formaat tussen A5 en A4 is het meest gangbaar.',

  // Account settings
  'AccountSettings-Security-button-2FA-title': 'Tweestapsverificatie gebruiken',
  'AccountSettings-Security-button-2FA-subtitle-enabled': 'Ingeschakeld',
  'AccountSettings-Security-button-2FA-subtitle-disabled': 'Uitgeschakeld',
  'AccountSettings-Security-button-2FA-subtitle': '{enableddisabled} • We vragen om een code wanneer je inlogt van een nieuw apparaat.',

  // Checkout
  'Checkout-Cart-title-Yourcart': 'Jouw winkelwagen',
  'Checkout-Cart-RemoveFromCart': 'Verwijder uit winkelwagen',
  'Checkout-Cart-CartItem-Title-Buscards': 'Visitekaartjes',
  'Checkout-Cart-CartItem-Title-Isbn': 'ISBN',
  'Checkout-Cart-CartItem-Originally': 'Origineel: <span>{price}</span>',
  'Checkout-Cart-CartItem-Forbook': 'Voor boek: {bookTitle}',
  'Checkout-Cart-CartIsEmpty': 'Je winkelwagen is leeg.',
  'Checkout-Cart-title-Total': 'Totaal',
  'Checkout-Cart-total-Subtotal': 'Subtotaal <span>{price}</span>',
  'Checkout-Cart-total-Discount': 'Korting <span>{price}</span>',
  'Checkout-Cart-total-Total': 'Totaal <span>{price}</span>',
  'Checkout-Cart-Button-ProceedCheckout': 'Verder naar bestellen',
  'Checkout-Cart-ErrorPopup-title': 'Enkele producten in je winkelwagen kunnen niet besteld worden',
  'Checkout-Cart-ErrorPopup-p1': 'Wanneer je op de knop ‘Doorgaan met bestellen’ klikt, verwijderen we de niet beschikbare producten uit je winkelwagen en kun je doorgaan met afrekenen.',
  'Checkout-Cart-ErrorPopup-unavailableprods': 'Niet beschikbare producten:',
  'Checkout-Cart-ErrorPopup-Button-Cancel': 'Annuleren',
  'Checkout-Cart-ErrorPopup-Button-ContinueOrder': 'Doorgaan met bestellen',
  'Checkout-Cart-WarningPopup-title': 'Je staat op het punt een ISBN en het bijbehorende boek samen te bestellen.',
  'Checkout-Cart-WarningPopup-p1': 'Als je een ISBN en het bijbehorende boek gelijktijdig bestelt, dan zal het boek <b>GEEN</b> ISBN bevatten. We raden je aan om eerst het ISBN te bestellen en vervolgens het boek te bestellen nadat het ISBN aan je boek is toegekend.',
  'Checkout-Cart-WarningPopup-p2': 'Wanneer je op de knop ‘Eerst ISBN bestellen’ klikt, verwijderen we de volgende boek(en) uit je winkelwagen:',
  'Checkout-Cart-WarningPopup-Button-IgnoreContinue': 'Negeren en doorgaan',
  'Checkout-Cart-WarningPopup-Button-OrderIsbnFirst': 'Eerst ISBN bestellen',
  'Checkout-Error-CheckoutSpecApiError': 'Er is een overwachte fout opgetreden tijdens het afrekenen. Neem contact op met support en stuur een screenshot mee van deze pagina. Onze excuses voor het ongemak.',
  'Checkout-CheckoutDetails-Button-Save': 'Opslaan',
  'Checkout-CheckoutDetails-Button-Next': 'Doorgaan',
  'Checkout-CheckoutDetails-Button-Cancel': 'Annuleren',
  'Checkout-CheckoutDetails-Button-NextStep': 'Volgende stap',
  'Checkout-CheckoutDetails-title-Email': 'E-mailadres',
  'Checkout-CheckoutDetails-title-ShippingAddress': 'Bezorgadres',
  'Checkout-CheckoutDetails-title-BillingAddress': 'Factuuradres',
  'Checkout-CheckoutDetails-title-ShippingMethod': 'Verzendmethode',
  'Checkout-CheckoutDetails-Email-label-Email': 'E-mail',
  'Checkout-CheckoutDetails-Email-Button-EditEmail': 'Wijzig e-mail',
  'Checkout-CheckoutDetails-ShippingAddress-label-YourShippingAddress': 'Jouw bezorgadres',
  'Checkout-CheckoutDetails-ShippingAddress-Button-EnterDifferentAddress': 'Ander bezorgadres',
  'Checkout-CheckoutDetails-BillingAddress-SameAsShipping-label': 'Hetzelfde als bezorgadres',
  'Checkout-CheckoutDetails-BillingAddress-label-YourBillingAddress': 'Jouw factuuradres',
  'Checkout-CheckoutDetails-BillingAddress-Button-EnterDifferentAddress': 'Ander factuuradres',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-label': 'Type klant',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-labelDesc': 'Geef aan wat voor type klant je bent. Zakelijke klanten hebben een valide EU BTW nummer nodig.',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private': 'Particulier',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Private-desc': 'Klant zonder EU BTW nummer',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company': 'Zakelijk',
  'Checkout-CheckoutDetails-BillingAddress-CustomerType-Company-desc': 'Klant met EU BTW nummer',
  'Checkout-CheckoutDetails-ShippingMethod-SelectMethod-label': 'Kies je gewenste verzendmethode',
  'Checkout-Address-Phoneline': 'Tel: {phone}',
  'Checkout-Address-Vatline': 'BTW nummer: {taxno}',
  'Checkout-Address-Input-Fn': 'Voornaam',
  'Checkout-Address-Input-Ln': 'Achternaam',
  'Checkout-Address-Input-L': 'Land',
  'Checkout-Address-GenericAddress-S': 'Straat en huisnummer',
  'Checkout-Address-GenericAddress-P': 'Postcode',
  'Checkout-Address-GenericAddress-Nr': 'Huisnummer',
  'Checkout-Address-GenericAddress-Nr-Placeholder': 'Huisnummer',
  'Checkout-Address-GenericAddress-NrA': 'Huisnummer + toevoeging',
  'Checkout-Address-GenericAddress-NrA-Placeholder': 'Toevoeging',
  'Checkout-Address-GenericAddress-C': 'Woonplaats',
  'Checkout-Address-GenericAddress-R': 'Provincie of Staat',
  'Checkout-Address-Input-T': 'Telefoonnummer',
  'Checkout-Address-Input-Shipping-Cp': 'Bedrijfsnaam (op adreslabel)',
  'Checkout-Address-Input-Cp': 'Bedrijfsnaam',
  'Checkout-Address-Input-Shipping-V': 'Belastingnummer',
  'Checkout-Address-Input-V': 'BTW nummer',
  'Checkout-Address-Button-Save': 'Opslaan',
  'Checkout-AddressStreetWarningNoNumber': 'Waarschuwing: je adres bevat geen huisnummer',
  'Checkout-PostalcodecheckError-ADDRESS_NOT_FOUND': 'We hebben geen adres gevonden met de ingevulde postcode, huisnummer en toevoeging. Controleer of je alles goed hebt ingevuld.',
  'Checkout-PostalcodecheckError-INVALID_API_CALL': 'Er is een fout opgetreden bij het zoeken naar je adres. Neem contact op met support.',
  'Checkout-CheckoutOverview-title-YourDeliveryDetails': 'Jouw bestelgegevens',
  'Checkout-CheckoutOverview-title-Paymentmethod': 'Betaalmethode',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-Email': 'E-mail',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-YourAddress': 'Jouw adres',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingAddress': 'Bezorgadres',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-BillingAddress': 'Factuuradres',
  'Checkout-CheckoutOverview-YourDeliveryDetails-label-ShippingMethod': 'Verzendmethode',
  'Checkout-OrderSidebar-title-YourOrder': 'Jouw bestelling',
  'Checkout-OrderSidebar-ProductItem-Quantity': 'Aantal: {quantity}',
  'Checkout-OrderSidebar-totals-Shipping': 'Verzendkosten: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-Total': 'Totaal: <span>{price}</span>',
  'Checkout-OrderSidebar-totals-IncludedVAT': 'Inclusief BTW: <span>{price}</span>',
  'CheckoutSuccess-title-Thankyou': 'Bedankt voor je bestelling!',
  'CheckoutSuccess-YourOrderNoIs': 'Je bestelnummer is: <b>{orderId}</b>',
  'CheckoutSuccess-IntroWhatToExpect': 'Je bestelling is geplaatst. We gaan direct aan de slag. Afhankelijk van het type bestelling kun je hieronder lezen wat je kunt verwachten.',
  'CheckoutSuccess-title-PaperbackHardcover': 'Paperback / hardcover',
  'CheckoutSuccess-title-Ebook': 'E-book',
  'CheckoutSuccess-title-Isbn': 'ISBN',
  'CheckoutSuccess-title-Buscards': 'Visitekaartjes',
  'CheckoutSuccess-li1-OrderConfEmail': 'Je ontvangt een bevestiging via e-mail',
  'CheckoutSuccess-li1-OrderNumberIs': 'Je bestelnummer is: <b>{orderId}</b>.',
  'CheckoutSuccess-li1-OrderConfSentTo': 'We sturen een orderbevestiging naar {email}. Let op: deze mail kan in je spam box belanden.',
  'CheckoutSuccess-PaperbackHardcover-li2-WeMakePOD': 'We maken je boeken via Print on Demand',
  'CheckoutSuccess-PaperbackHardcover-li2-WePrintRightAway': 'We gaan direct aan de slag om je bestelling te produceren. Daar gaan een aantal werkdagen overheen.',
  'CheckoutSuccess-PaperbackHardcover-li3-OrderWillShipped': 'Je bestelling wordt verzonden',
  'CheckoutSuccess-PaperbackHardcover-li3-ShipConfEMail': 'Je ontvangt een verzendbevestiging met een track & trace link via e-mail.',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate': 'Verwachte verzenddatum',
  'CheckoutSuccess-PaperbackHardcover-ExpectedShippingDate-BetweenDates': 'Tussen {startdate} en {enddate}',
  'CheckoutSuccess-Ebook-li2-dlLinkEmail': 'Je downloadlink zit in de bevestigingsmail',
  'CheckoutSuccess-Ebook-li2-dlLinkEmailOrClickBelow': 'De link om je e-book te downloaden zit in de bevestigingsmail die je ontvangt. Je kunt ook op de knop hieronder klikken om je e-book te downloaden.',
  'CheckoutSuccess-Ebook-DownloadYourEbook': 'Download je e-book',
  'CheckoutSuccess-Isbn-li2-IsbnReady': 'Je ISBN staat binnen 1 uur klaar',
  'CheckoutSuccess-Isbn-li2-IsbnReadyInStep': 'Je vindt je ISBN binnen 1 uur in de stap ‘ISBN’ van je boek. We plaatsen het ISBN automatisch op de achterkant van je omslag.',
  'CheckoutSuccess-Isbn-ContinueWithBook': 'Doorgaan met je boek',
  'CheckoutSuccess-Isbn-ContinueWithBookClickHere': 'Klik hier om verder te werken aan je boek',
  'CheckoutSuccess-Buscards-li2-WeMakeCards': 'We maken je visitekaartjes',
  'CheckoutSuccess-Buscards-li2-WePrintRightAway': 'We gaan direct aan de slag met je visitekaartjes. Het duurt ongeveer een week om ze te leveren.',
  'CheckoutSuccess-Buscards-li2-NoShipmentMail': 'Let op: je ontvangt geen aparte verzendbevestiging voor je visitekaartjes.',
  'CheckoutFailed-title-PaymentFailed': 'Je betaling is mislukt',
  'CheckoutFailed-SomethingWentWrongTryAgain': 'Er is iets misgegaan bij de betaling van je bestelling. Probeer opnieuw af te rekenen.',
  'CheckoutFailed-button-CheckoutAgain': 'Opnieuw afrekenen',
  'CheckoutError-title-SomethingWrong': 'Er is iets misgegaan',
  'CheckoutError-SomethingWentWrongContactUs': 'Er is een fout opgetreden bij het afrekenen van je bestelling. Probeer het opnieuw of neem contact op met support. Vermeld hierbij referentie: {orderId}',
  'CheckoutError-button-TryAgain': 'Opnieuw proberen',
  'CheckoutPending-title-PaymentInProgress': 'Je betaling wordt nog verwerkt',
  'CheckoutPending-PaymentProcessingTakesLonger': 'Het verwerken van je betaling duurt helaas wat langer. Hierdoor kunnen we op dit moment nog niet met zekerheid zeggen of je betaling gelukt is.',
  'CheckoutPending-CheckAgainInFewHours': 'Controleer de komende uren je e-mail. Wanneer we bevestiging ontvangen van je betaling, dan sturen we je een bestelbevestiging via e-mail. Als je deze bevestiging niet ontvangt, controleer dan met je bank of credit card of de betaling correct is afgeschreven.',
  'CheckoutPending-OrderReference': 'Je bestelnummer is: {orderId}',
  'CheckoutCancelled-title-PaymentCancelled': 'Je hebt de betaling geannuleerd',
  'CheckoutCancelled-TryAgainIfYouWant': 'Je hebt de betaling geannuleerd. Indien dit niet de bedoeling was, klik dan op de knop hieronder om af te rekenen.',
  'CheckoutCancelled-button-CheckoutAgain': 'Afrekenen',

  // All ISO-3166-1 alpha 2 countries
  'Country-AD': 'Andorra',
  'Country-AE': 'Verenigde Arabische Emiraten',
  'Country-AF': 'Afghanistan',
  'Country-AG': 'Antigua en Barbuda',
  'Country-AI': 'Anguilla',
  'Country-AL': 'Albanië',
  'Country-AM': 'Armenië',
  'Country-AO': 'Angola',
  'Country-AQ': 'Antarctica',
  'Country-AR': 'Argentinië',
  'Country-AS': 'Amerikaans-Samoa',
  'Country-AT': 'Oostenrijk',
  'Country-AU': 'Australië',
  'Country-AW': 'Aruba',
  'Country-AX': 'Åland',
  'Country-AZ': 'Azerbeidzjan',
  'Country-BA': 'Bosnië en Herzegovina',
  'Country-BB': 'Barbados',
  'Country-BD': 'Bangladesh',
  'Country-BE': 'België',
  'Country-BF': 'Burkina Faso',
  'Country-BG': 'Bulgarije',
  'Country-BH': 'Bahrein',
  'Country-BI': 'Burundi',
  'Country-BJ': 'Benin',
  'Country-BL': 'Saint-Barthélemy',
  'Country-BM': 'Bermuda',
  'Country-BN': 'Brunei',
  'Country-BO': 'Bolivia',
  'Country-BQ': 'Caribisch Nederland',
  'Country-BR': 'Brazilië',
  'Country-BS': 'Bahama’s',
  'Country-BT': 'Bhutan',
  'Country-BV': 'Bouvet',
  'Country-BW': 'Botswana',
  'Country-BY': 'Belarus',
  'Country-BZ': 'Belize',
  'Country-CA': 'Canada',
  'Country-CC': 'Cocoseilanden',
  'Country-CD': 'Congo-Kinshasa',
  'Country-CF': 'Centraal-Afrikaanse Republiek',
  'Country-CG': 'Congo-Brazzaville',
  'Country-CH': 'Zwitserland',
  'Country-CI': 'Ivoorkust',
  'Country-CK': 'Cookeilanden',
  'Country-CL': 'Chili',
  'Country-CM': 'Kameroen',
  'Country-CN': 'China',
  'Country-CO': 'Colombia',
  'Country-CR': 'Costa Rica',
  'Country-CU': 'Cuba',
  'Country-CV': 'Kaapverdië',
  'Country-CW': 'Curaçao',
  'Country-CX': 'Christmaseiland',
  'Country-CY': 'Cyprus',
  'Country-CZ': 'Tsjechië',
  'Country-DE': 'Duitsland',
  'Country-DJ': 'Djibouti',
  'Country-DK': 'Denemarken',
  'Country-DM': 'Dominica',
  'Country-DO': 'Dominicaanse Republiek',
  'Country-DZ': 'Algerije',
  'Country-EC': 'Ecuador',
  'Country-EE': 'Estland',
  'Country-EG': 'Egypte',
  'Country-EH': 'Westelijke Sahara',
  'Country-ER': 'Eritrea',
  'Country-ES': 'Spanje',
  'Country-ET': 'Ethiopië',
  'Country-FI': 'Finland',
  'Country-FJ': 'Fiji',
  'Country-FK': 'Falklandeilanden',
  'Country-FM': 'Micronesia',
  'Country-FO': 'Faeröer',
  'Country-FR': 'Frankrijk',
  'Country-GA': 'Gabon',
  'Country-GB': 'Verenigd Koninkrijk',
  'Country-GD': 'Grenada',
  'Country-GE': 'Georgië',
  'Country-GF': 'Frans-Guyana',
  'Country-GG': 'Guernsey',
  'Country-GH': 'Ghana',
  'Country-GI': 'Gibraltar',
  'Country-GL': 'Groenland',
  'Country-GM': 'Gambia',
  'Country-GN': 'Guinee',
  'Country-GP': 'Guadeloupe',
  'Country-GQ': 'Equatoriaal-Guinea',
  'Country-GR': 'Griekenland',
  'Country-GS': 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
  'Country-GT': 'Guatemala',
  'Country-GU': 'Guam',
  'Country-GW': 'Guinee-Bissau',
  'Country-GY': 'Guyana',
  'Country-HK': 'Hongkong SAR van China',
  'Country-HM': 'Heard en McDonaldeilanden',
  'Country-HN': 'Honduras',
  'Country-HR': 'Kroatië',
  'Country-HT': 'Haïti',
  'Country-HU': 'Hongarije',
  'Country-ID': 'Indonesië',
  'Country-IE': 'Ierland',
  'Country-IL': 'Israël',
  'Country-IM': 'Isle of Man',
  'Country-IN': 'India',
  'Country-IO': 'Brits Indische Oceaanterritorium',
  'Country-IQ': 'Irak',
  'Country-IR': 'Iran',
  'Country-IS': 'IJsland',
  'Country-IT': 'Italië',
  'Country-JE': 'Jersey',
  'Country-JM': 'Jamaica',
  'Country-JO': 'Jordanië',
  'Country-JP': 'Japan',
  'Country-KE': 'Kenia',
  'Country-KG': 'Kirgizië',
  'Country-KH': 'Cambodja',
  'Country-KI': 'Kiribati',
  'Country-KM': 'Comoren',
  'Country-KN': 'Saint Kitts en Nevis',
  'Country-KP': 'Noord-Korea',
  'Country-KR': 'Zuid-Korea',
  'Country-KW': 'Koeweit',
  'Country-KY': 'Kaaimaneilanden',
  'Country-KZ': 'Kazachstan',
  'Country-LA': 'Laos',
  'Country-LB': 'Libanon',
  'Country-LC': 'Saint Lucia',
  'Country-LI': 'Liechtenstein',
  'Country-LK': 'Sri Lanka',
  'Country-LR': 'Liberia',
  'Country-LS': 'Lesotho',
  'Country-LT': 'Litouwen',
  'Country-LU': 'Luxemburg',
  'Country-LV': 'Letland',
  'Country-LY': 'Libië',
  'Country-MA': 'Marokko',
  'Country-MC': 'Monaco',
  'Country-MD': 'Moldavië',
  'Country-ME': 'Montenegro',
  'Country-MF': 'Sint-Maarten',
  'Country-MG': 'Madagaskar',
  'Country-MH': 'Marshalleilanden',
  'Country-MK': 'Noord-Macedonië',
  'Country-ML': 'Mali',
  'Country-MM': 'Myanmar',
  'Country-MN': 'Mongolië',
  'Country-MO': 'Macao SAR van China',
  'Country-MP': 'Noordelijke Marianen',
  'Country-MQ': 'Martinique',
  'Country-MR': 'Mauritanië',
  'Country-MS': 'Montserrat',
  'Country-MT': 'Malta',
  'Country-MU': 'Mauritius',
  'Country-MV': 'Maldiven',
  'Country-MW': 'Malawi',
  'Country-MX': 'Mexico',
  'Country-MY': 'Maleisië',
  'Country-MZ': 'Mozambique',
  'Country-NA': 'Namibië',
  'Country-NC': 'Nieuw-Caledonië',
  'Country-NE': 'Niger',
  'Country-NF': 'Norfolk',
  'Country-NG': 'Nigeria',
  'Country-NI': 'Nicaragua',
  'Country-NL': 'Nederland',
  'Country-NO': 'Noorwegen',
  'Country-NP': 'Nepal',
  'Country-NR': 'Nauru',
  'Country-NU': 'Niue',
  'Country-NZ': 'Nieuw-Zeeland',
  'Country-OM': 'Oman',
  'Country-PA': 'Panama',
  'Country-PE': 'Peru',
  'Country-PF': 'Frans-Polynesië',
  'Country-PG': 'Papoea-Nieuw-Guinea',
  'Country-PH': 'Filipijnen',
  'Country-PK': 'Pakistan',
  'Country-PL': 'Polen',
  'Country-PM': 'Saint-Pierre en Miquelon',
  'Country-PN': 'Pitcairn',
  'Country-PR': 'Puerto Rico',
  'Country-PS': 'Palestijnse gebieden',
  'Country-PT': 'Portugal',
  'Country-PW': 'Palau',
  'Country-PY': 'Paraguay',
  'Country-QA': 'Qatar',
  'Country-RE': 'Réunion',
  'Country-RO': 'Roemenië',
  'Country-RS': 'Servië',
  'Country-RU': 'Rusland',
  'Country-RW': 'Rwanda',
  'Country-SA': 'Saoedi-Arabië',
  'Country-SB': 'Salomonseilanden',
  'Country-SC': 'Seychellen',
  'Country-SD': 'Soedan',
  'Country-SE': 'Zweden',
  'Country-SG': 'Singapore',
  'Country-SH': 'Sint-Helena',
  'Country-SI': 'Slovenië',
  'Country-SJ': 'Svalbard en Jan Mayen',
  'Country-SK': 'Slowakije',
  'Country-SL': 'Sierra Leone',
  'Country-SM': 'San Marino',
  'Country-SN': 'Senegal',
  'Country-SO': 'Somalië',
  'Country-SR': 'Suriname',
  'Country-SS': 'Zuid-Soedan',
  'Country-ST': 'Sao Tomé en Principe',
  'Country-SV': 'El Salvador',
  'Country-SX': 'Sint-Maarten (Nederlands deel)',
  'Country-SY': 'Syrië',
  'Country-SZ': 'Swaziland',
  'Country-TC': 'Turks- en Caicoseilanden',
  'Country-TD': 'Tsjaad',
  'Country-TF': 'Franse Gebieden in de zuidelijke Indische Oceaan',
  'Country-TG': 'Togo',
  'Country-TH': 'Thailand',
  'Country-TJ': 'Tadzjikistan',
  'Country-TK': 'Tokelau',
  'Country-TL': 'Oost-Timor',
  'Country-TM': 'Turkmenistan',
  'Country-TN': 'Tunesië',
  'Country-TO': 'Tonga',
  'Country-TR': 'Turkije',
  'Country-TT': 'Trinidad en Tobago',
  'Country-TV': 'Tuvalu',
  'Country-TW': 'Taiwan',
  'Country-TZ': 'Tanzania',
  'Country-UA': 'Oekraïne',
  'Country-UG': 'Oeganda',
  'Country-UM': 'Kleine afgelegen eilanden van de Verenigde Staten',
  'Country-US': 'Verenigde Staten',
  'Country-UY': 'Uruguay',
  'Country-UZ': 'Oezbekistan',
  'Country-VA': 'Vaticaanstad',
  'Country-VC': 'Saint Vincent en de Grenadines',
  'Country-VE': 'Venezuela',
  'Country-VG': 'Britse Maagdeneilanden',
  'Country-VI': 'Amerikaanse Maagdeneilanden',
  'Country-VN': 'Vietnam',
  'Country-VU': 'Vanuatu',
  'Country-WF': 'Wallis en Futuna',
  'Country-WS': 'Samoa',
  'Country-XK': 'Kosovo',
  'Country-YE': 'Jemen',
  'Country-YT': 'Mayotte',
  'Country-ZA': 'Zuid-Afrika',
  'Country-ZM': 'Zambia',
  'Country-ZW': 'Zimbabwe'
};

export default messages;
